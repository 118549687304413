/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Select from 'react-select';
import APIM from 'Services/APIM';
import CDATA from 'Services/CDATA';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants';
import {
  Button, DashboardView, DashboardBody, Error, Grid, Spinner, Toolbar,
} from './RPDashboard.styles';
import AppContext from '../../AppContext';
import NavLinkButton from 'Library/GrpGrid/NavLinkButton/NavLinkButton';
import 'Styles/SCSS/ag-theme-material.scss';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import UrlPoller from 'Library/UrlPoller/UrlPoller';

let PermissionPrefix = '';

class NAVDashboard extends Component {
  constructor(props) {
    super(props);
    this.PermissionPrefix = props.location.pathname.toLowerCase().endsWith('nav') ? 'dashboard/' : '';
    this.state = {
      api_name: this.props.api_name,
      displayedRowCount: 0,
      error_or_warning: 'Error',
      failed: false,
      loading: true,
      page: window.location.pathname,
      redirect: false, // What is the function of this?
      regulation_name: 'NAV',
      toolbarButtons: [
        <Button
          onClick={() => { this.refresh(); }}
          icon={['fas', 'sync']}
          iconSide="Round"
          size="Small"
        />,
        renderWithPermissions(() => (
          <Button
            key={3}
            onClick={() => { this.populateRuleReg(); }}
            size="Medium"
            type="Secondary"
            text="Rule Regulation Population"
          />
        ), this.PermissionPrefix + permissionTypes.generate, () => <></>),
      ],
      totalRowCount: 0,
      monitorUrl: '',
      monitorType: '',
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);

      // if (this.state.api_name === 'PRIIPS_app_VALIDATION_DASHBOARD') {
      // }

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const payload = {
        clientcode: this.context.state.selectedClient.code,
        reportingperiod: this.context.state.selectedReportingDate.code,
        ruleregulation: this.state.regulation_name,
      };
      if (this.state.api_name !== 'PRIIPS_app_VALIDATION_DASHBOARD') {
        payload.resulttodisplay = this.state.error_or_warning;
      }
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        payload,
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
      if (this.state.selectedValue) { 
        const selectedValue = this.state.selectedValue; 
        this.setState(selectedValue); 
      }
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  populateRuleReg = async () => {
    try {
      const ruleReportType = this.state.regulation_name;
      const data = {
        procedure: 'app.PopulateRuleRegulationDashboard',
        clientcode: this.context.state.selectedClient.code,
        parameters: {
          ClientCode: this.context.state.selectedClient.code,
          ReportingPeriod: this.context.state.selectedReportingDate.code,
          RuleRegulation: ruleReportType,
          FundCode: null,
          ClassCode: null
        },
      };

      APIM.makeRequest(
        'transaction',
        JSON.stringify(data),
        'Error in Rule Regulation Population for ' + ruleReportType + '.',
        Auth0SessionInfo.GetSessionInfo().accessToken,
      )
        .then((res) => {
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully queued Rule Regulation Population for ' + ruleReportType + '.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
          this.setState({ monitorUrl: res.statusQueryGetUri , monitorType: 'Rule Regulation Population for ' + ruleReportType});
          //this.refreshData();
        })
        .catch((error) => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'error',
            body: (
              <>
                Error - something went wrong and we were not able to run Rule Regulation Population
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });

    } catch(err) {
      console.log(err);
    }
  };

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    autoHeight: true,
    cellStyle: {
      'whiteSpace': 'normal',
      'lineHeight': '24px',
      padding: '12px',
      maxHeight: '72px',
    },
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    sort: x.dv_column.toLowerCase() === 'classcode_bk' ? {
      direction: 'desc',
      priority: 0,
    } : null,
    wrapText: true,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  handleSelect = (selectedValue) => {
    // console.log(event);
    this.setState({
      error_or_warning: selectedValue.label === 'All Warnings' ? 'Warning' : 'Error',
      api_name: selectedValue.value,
      selectedValue: selectedValue,
      loading: true,
    }, () => { this.refresh(); });
  }

  async refresh() {
    await this.setState({ loading: true, monitorUrl: undefined }, () => this.getMetadata());
    await this.getData();
  }

  onPollingDone(_this) {
    _this.setState({ monitorUrl: undefined });
  }

  render() {
    return (
      <DashboardView>
        <DashboardBody>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        exportName={this.state.api_name}
                        controls={this.state.api_name === 'PRIIPS_app_VALIDATION_DASHBOARD' ? this.state.toolbarButtons : null}
                        dropdown={(
                          <Select
                            isSearchable
                            options={[
                              { label: 'Share Class Dashboard', value: 'PRIIPS_app_VALIDATION_DASHBOARD' },
                              { label: 'Business Rule Dashboard', value: 'PRIIPS_app_VALIDATION_BY_RULE' },
                              { label: 'All Errors', value: 'PRIIPS_app_VALIDATION_RESULT_VIEW' },
                              { label: 'All Warnings', value: 'PRIIPS_app_VALIDATION_RESULT_VIEW' },
                            ]}
                            // components={{ Option: CustomOption }}
                            value={this.state.selectedValue}
                            ignoreAccents={false}
                            // filterOption={createFilter({ignoreAccents: false})}
                            onChange={this.handleSelect}
                            isOptionSelected={(option, selectedValue) => {return selectedValue.some((i) => i.label == option.label)}}
                          />
                        )}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }
                <Grid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                />
              </>
            )
          }
          {
            this.state.monitorUrl ? (
              <UrlPoller
                completeCallback={this.onPollingDone(this)}
                endStates={['Completed']}
                monitorUrl={this.state.monitorUrl}
                refreshIntervalSeconds={10}
                responseDisplayElementPath="$.customStatus"
                monitorType={this.state.monitorType}
              />
            ) : <></>
          }
        </DashboardBody>
      </DashboardView>
    );
  }
}

NAVDashboard.contextType = AppContext;

export default NAVDashboard;
