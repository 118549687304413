/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ToggleButton = styled.div`
  color: #57576B;
  font-size: 18px;
  cursor: pointer;
  margin: 1rem;
`;

export const ApproveToggle = styled(ToggleButton)`
  color: ${(props) => (props.active ? '#2EC4B6 ' : '#57576B')};
  &:hover {
    color: #29939b;
  }
`;

export const RejectToggle = styled(ToggleButton)`
  color: ${(props) => (props.active ? '#EE4266' : '#57576B')};
  &:hover {
    color: #b43562;
  }
`;

const ApprovalToggle = ({ value, onApprove, onReject }) => {
  const [isApproved, setApproved] = useState(value);

  const approveClick = () => {
    onApprove && onApprove();
    if (isApproved === 1) setApproved(null);
    else setApproved(1);
  };

  const rejectClick = () => {
    onReject && onReject();
    if (isApproved === 0) setApproved(null);
    else setApproved(0);
  };

  return (
    <Container>
      <ApproveToggle active={isApproved === 1} onClick={approveClick} type="radio">
        <FontAwesomeIcon icon={['fas', 'file-check']} />
      </ApproveToggle>
      <RejectToggle active={isApproved === 0} onClick={rejectClick} type="radio">
        <FontAwesomeIcon icon={['fas', 'file-times']} />
      </RejectToggle>
    </Container>
  );
};

export default ApprovalToggle;

ApprovalToggle.propTypes = {
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  value: PropTypes.oneOf([null, 0, 1]),
};
