/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Axios from 'axios';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import APIM from 'Services/APIM';
import CDATA from 'Services/CDATA';
import {
  Button, ButtonGroup, DashboardView, DashboardBody, DropFile, Error, Grid, Spinner, Toolbar,
} from './InsuranceProductClassDashboard.styles';
import AppContext from '../../AppContext';
import NavlinkButton from 'Library/GrpGrid/NavLinkButton/NavLinkButton';
import 'Styles/SCSS/ag-theme-material.scss';
import config from '../../config';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants';
import UrlPoller from 'Library/UrlPoller/UrlPoller';

// const { encKey } = config.sessionStorage;
const baseURLvar = config.apim.url;

// import RPpreview from './Sublevels/RPpreview';

// this exists just to speed up the select dropdown by disabling mouseover events,
// so now it's merely shitty instead of completely unusable

let permissionPrefix = '';

const insPreview = () => 'Preview Calc';
const insPreviewLink = (data) => ({
  pathname: '/regulatory-reporting/preview',
  productClassPk: data.product_class_pk,
});

class InsuranceProductClassDashboard extends Component {
  constructor(props) {
    super(props);
    permissionPrefix = props.location.pathname.toLowerCase().endsWith('insurance') ? 'insurancedashboard/' : '';
    this.state = {
      api_name: 'PRIIPS_app_INSURANCE_VALIDATION_DASHBOARD',
      displayedRowCount: 0,
      failed: false,
      leftButtons: [
        <ButtonGroup>
          {
            renderWithPermissions(() => (
              <Button
                key={0}
                onClick={() => { this.runErrHandlingCalcs(); }}
                size="Medium"
                type="Primary"
                text="Run Calculations and Compile Errors"
              />
            ), (permissionPrefix + permissionTypes.calculationandcompileerrors), () => <></>)
          }
          {
            renderWithPermissions(() => (
              <Button
                key={0}
                onClick={() => { this.runBulkUIOCalcs(); }}
                size="Medium"
                type="Primary"
                text="Run Bulk UIO Calculations"
              />
            ), (permissionPrefix + permissionTypes.runbulkuiocalculations), () => <></>)
          }
          {
            renderWithPermissions(() => (
              <Button
                key={0}
                onClick={() => { this.runBulkProdCalcs(); }}
                size="Medium"
                type="Primary"
                text="Run Bulk Product Calculations"
              />
            ), (permissionPrefix + permissionTypes.runbulkproductcalculations), () => <></>)
          }
        </ButtonGroup>,
      ],
      loading: true,
      monitorType: '',
      monitorUrl: '',
      page: window.location.pathname,
      pdfFiles: [],
      pdfMatchedRows: [],
      redirect: false,
      rowIndexLookup: {},
      totalRowCount: 0,
      zoneQueuedFileMessage: '',
    };
    this.onPDFDrop = this.onPDFDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getMetadata();
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.state.pdfFiles.length > 0) {
      const data = this.state.pdfMatchedRows.map((row) => {
        let lastRevised = row.data.formatted_date;
        if (this.context.state.selectedClient.code === 'HSF') {
          const result = parse(
            this.context.state.selectedReportingDate.code,
            'yyyy-MM-dd',
            new Date(),
          );
          lastRevised = format(result, 'dd/MM/yyyy');
        }

        const KIDLanguage = row.data.languages.split(',')[0];
        return {
          ISIN: row.data.classcode_bk ? row.data.classcode_bk : '',
          ReferenceDate: this.context.state.selectedReportingDate.code,
          ProductID: row.data.product_id,
          Language: KIDLanguage,
          ReportDesign: row.data.kid_template,
          ClientCode: this.context.state.selectedClient.code,
          FundManager: row.data.fund_manager,
          Products: row.data.prod_list,
          ShareClassName: row.data.of_full_share_class_name,
          LastRevised: lastRevised,
          Type: row.data.type,
          Filename: row.data.kid_file_name,
          User: this.context.state.userEmail,
        };
      });

      const url = `${baseURLvar}/pdf/birtdropped`;

      let formdata = new FormData();
      let files = this.state.pdfFiles;
      files.map((file, index) => {
        formdata.append(`pdfFiles${index}`, file);
      });
      formdata.append('message', JSON.stringify(data));

      const fileCount = this.state.pdfFiles.length;
      const s = fileCount > 1 ? 's' : '';
      const modalMessage = `Sending Request to upload ${fileCount} File${s}...`;
      this.setState({ zoneQueuedFileMessage: modalMessage });

      // const modalContent = <div><p>{`Sending Request for ${this.state.pdfFiles.length} KID${s}...`}</p></div>;
      // this.context.handlers.setModal('Sending Request ...', modalContent, null, null, true);

      const response = await Axios.post(
        url,
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'authorization': 'Bearer ' + Auth0SessionInfo.GetSessionInfo().accessToken,
          },
        },
      )
        .then((response) => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully uploaded '}
                <span>{this.state.pdfFiles.length}</span>
                {' KID(s)'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        }).catch((err) => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'error',
            body: (
              <>
                {'Error - something went wrong and we were not able to request the '}
                <span>{this.state.pdfFiles.length}</span>
                {' KID'}
                {this.state.pdfFiles.length > 1 ? 's' : ''}
                {' you submitted.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });
    }
    this.state.pdfFiles.length = 0;
    this.state.pdfMatchedRows.length = 0;
    this.setState({ zoneQueuedFileMessage: '' });
  }

  onPollingDone() {
    this.setState({ monitorUrl: undefined });
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);
      columnDefs.unshift({
        field: 'Preview Calc',
        headerName: 'Preview Calc',
        cellRendererFramework: (props) => (
          <NavlinkButton
            {...props}
            functionToGenerateContent={insPreview}
            functionToGenerateLink={insPreviewLink}
          />
        ),
      });
      columnDefs.unshift({
        field: 'SelectRow',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        maxWidth: 45,
        resizable: false,
      });

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        {
          clientcode: this.context.state.selectedClient.code,
          reportingperiod: this.context.state.selectedReportingDate.code,
          ruleregulation: this.state.regulation_name,
        },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });

      // Set row lookup by classcode
      let rowLookup = {};
      this.gridApi.forEachNode((rownode) => {
        let ISIN = rownode.data.classcode_bk;
        rowLookup[ISIN] = rownode.id;
      });
      this.setState({ rowIndexLookup: rowLookup }, () => {
      });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  onPDFDrop = (acceptedFiles) => {
    acceptedFiles.map((file) => {
      let filename = file.name.split('_')[0];
      filename = filename ? filename : file.name;
      const rowKey = filename.replace('.pdf', '');
      const rowIndex = this.state.rowIndexLookup[rowKey];
      const rowNode = this.gridApi.getRowNode(rowIndex);

      if (rowNode) {
        this.setState({ pdfFiles: this.state.pdfFiles.concat(file) });
        // set file upload json message payload state
        this.setState({ pdfMatchedRows: this.state.pdfMatchedRows.concat(rowNode) });
      } else {
        const toast = {
          type: 'info',
          body: (
            <>
              <span>File Name not found, Please check the file name.</span>
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      }
    });

    const fileCount = this.state.pdfFiles.length;
    const s = fileCount > 1 ? 's' : '';
    const av = fileCount > 1 ? 'are' : 'is';
    const zoneQueuedFileMessage = (fileCount > 0) ? `${fileCount} File${s} ${av} queued for upload.` : '';
    this.setState({ zoneQueuedFileMessage });
  }

  runErrHandlingCalcs = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {
              `Are you sure you want to run ${listOfRows.length} calculation(s)? 
                (This may take some time)`
            }
          </p>
          <Button onClick={() => this.errHandlingCalcs()} size="Medium" type="Primary" text="Run" />
          <Button onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to run.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  errHandlingCalcs = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimProductClassPks = listOfRows.map((row) => row.product_class_pk).join(',');
    const modalContent = <div><p>{`Approving ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);
    CDATA.makeRequest(
      'POST',
      'PRIIPS_app_RISK_PERF_CALCULATION_INSURANCE_BULK_ERROR_HANDLING',
      '',
      {
        Client_Code: this.context.state.selectedClient.code,
        Prod_Class_pks: delimProductClassPks,
        CalcDate: this.context.state.selectedReportingDate.code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Class_Name: 'Bulk Risk and Performance Calculation',
        Entity: 'Bulk Risk and Performance Calculation',
      },
      'Approve failed.',
    )
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.refreshData();
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' calculations'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  runBulkUIOCalcs = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to run ${listOfRows.length} calculation(s)? 
        (This may take some time)`}
          </p>
          <Button onClick={() => this.bulkUIOCalcs()} size="Medium" type="Primary" text="Run" />
          <Button onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to run.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  bulkUIOCalcs = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimProductClassPks = listOfRows.map((row) => row.product_class_pk).join(',');
    const modalContent = <div><p>{`Approving ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);
    CDATA.makeRequest(
      'POST',
      'PRIIPS_app_RISK_PERFORMANCE_INSURANCE_UIO_CALCULATION_BULK',
      '',
      {
        ClientCode: this.context.state.selectedClient.code,
        ProdClassPkList: delimProductClassPks,
        CalcDate: this.context.state.selectedReportingDate.code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Class_Name: 'Bulk Risk and Performance Calculation',
        Entity: 'Bulk Risk and Performance Calculation',
      },
      'Approve failed.',
    )
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.refreshData();
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' calculations'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  runBulkProdCalcs = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to run ${listOfRows.length} calculation(s)? 
        (This may take some time)`}
          </p>
          <Button onClick={() => this.bulkProdCalcs()} size="Medium" type="Primary" text="Run" />
          <Button onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to run.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  bulkProdCalcs = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimProductClassPks = listOfRows.map((row) => row.product_class_pk).join(',');
    const modalContent = <div><p>{`Approving ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);
    CDATA.makeRequest(
      'POST',
      'PRIIPS_app_RISK_PERFORMANCE_INSURANCE_PRODUCT_CALCULATION_BULK',
      '',
      {
        ClientCode: this.context.state.selectedClient.code,
        ProdClassPkList: delimProductClassPks,
        CalcDate: this.context.state.selectedReportingDate.code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Class_Name: 'Bulk Risk and Performance Calculation',
        Entity: 'Bulk Risk and Performance Calculation',
      },
      'Approve failed.',
    )
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.refreshData();
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' calculations'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }
  
  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  render() {
    const zonemessage = `Drag Files or Click to add to ${this.context.state.selectedClient.name}. 
                         Please name according to the UIO (e.g. DE000A0H8F7.pdf)`;
    const pdfzone = {
      zone: 'PDF',
      message: zonemessage,
      queuedFileMessage: this.state.zoneQueuedFileMessage,
      handleInputChange: this.onPDFDrop,
      handleSubmit: this.handleSubmit,
      acceptedFiles: '.pdf',
      maxFiles: 500,
    };
    return (
      this.state.redirect ? <Redirect to="/regulatory-reporting/ept/dashboard" /> : (
        <DashboardView>
          <DashboardBody>
            <DropFile {...pdfzone} />
            {
              this.state.failed ? <Error text={this.state.failMessage} /> : (
                <>
                  {
                    this.state.loading ? <Spinner text="Loading data..." /> : (
                      this.gridApi && (
                        <Toolbar
                          api={this.gridApi}
                          working={this.state.toolbarWorking}
                          exportName={this.state.api_name}
                          leftControls={this.state.leftButtons}
                          controls={this.state.toolbarButtons}
                          count={{
                            total: this.state.totalRowCount,
                            showing: this.state.displayedRowCount,
                          }}
                        />
                      )
                    )
                  }
                  <Grid
                    columnDefs={this.state.columnDefs}
                    rawColumnData={this.state.rawColumns}
                    onGridReady={this.onGridReady}
                    apiName={this.state.api_name}
                    onModelUpdated={this.onModelUpdated}
                    pivotable
                  />
                </>
              )
            }
          </DashboardBody>
          <div>
            {
              this.state.monitorUrl ? (
                <UrlPoller
                  monitorUrl={this.state.monitorUrl}
                  refreshIntervalSeconds={10}
                  monitorType={this.state.monitorType}
                  responseDisplayElementPath="$.customStatus"
                  endStates={['Completed']}
                  completeCallback={this.onPollingDone(this)}
                />
              ) : <></>
            }
          </div>
        </DashboardView>
      )
    );
  }
}

InsuranceProductClassDashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

InsuranceProductClassDashboard.contextType = AppContext;

export default InsuranceProductClassDashboard;
