/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import APIM from 'Services/APIM';
import CDATA from 'Services/CDATA';
import sasTokenApi from 'Services/sasTokenApi';
import {
  Button, ButtonGroup, DashboardBody, DashboardView, Error, Grid, Toolbar, Spinner,
} from './StaticSiteDashboard.styles';
import AppContext from 'AppContext';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import GrpButton from 'Library/GrpButton/GrpButton';
import 'Styles/SCSS/ag-theme-material.scss';
import config from 'config';
import { renderWithPermissions, userHasPermission } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants';
import UrlPoller from 'Library/UrlPoller/UrlPoller';

const account = (clientcode) => config.blob.pdfaccount;
const testSiteAccount = (clientcode) => config.blob.testSiteAccount(clientcode);
const liveSiteAccount = (clientcode) => config.blob.liveSiteAccount(clientcode);
const testSiteLink = (clientcode) => config.site.testSiteLink(clientcode);
const liveSiteLink = (clientcode) => config.site.liveSiteLink(clientcode);
const clientContainer = (clientcode) => clientcode.toLowerCase();
const ioContainer = 'cio';
const prodContainer = 'product';

class StaticSiteDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_app_INSURANCE_KID_DASHBOARD',
      displayedRowCount: 0,
      failed: false,
      loading: true,
      page: window.location.pathname,
      permissions: this.props.permissions,
      redirect: false,
      selectedFile: null,
      totalRowCount: 0,
      leftButtons: [
        <ButtonGroup>
          <Button
            key={0}
            onClick={() => { window.open(testSiteLink(this.context.state.selectedClient.code)); }}
            size="Medium"
            type="Primary"
            text="Go to Test Site"
          />
          <Button
            key={0}
            onClick={() => { window.open(liveSiteLink(this.context.state.selectedClient.code)); }}
            size="Medium"
            type="Primary"
            text="Go to Live Site"
          />
        </ButtonGroup>,
      ],
      toolbarButtons: [
        <ButtonGroup>
          {renderWithPermissions(() => (
            <Button
              key={0}
              onClick={() => { this.runDelete(); }}
              size="Medium"
              type="Primary"
              text="Delete Selected Files from Test Site"
            />
          ), permissionTypes.deleteSelectedFiles, () => <></>)}
          {renderWithPermissions(() => (
            <Button
              key={0}
              onClick={() => { this.runPublishKIDDataTest(); }}
              size="Medium"
              type="Primary"
              text="Publish to Test Site"
            />
          ), permissionTypes.publishSelectedTest, () => <></>)}
          {renderWithPermissions(() => (
            <Button
              key={0}
              onClick={() => { this.runPublishKIDDataLive(); }}
              size="Medium"
              type="Primary"
              text="Publish to Live Site"
            />
          ), permissionTypes.publishToLive, () => <></>)}
        </ButtonGroup>,
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);
      columnDefs.unshift({
        field: 'Download',
        headerName: 'Download',
        cellRendererFramework: (props) => (
          <Button
            key={0}
            onClick={() => { this.downloadBlob(props.data); }}
            size="Medium"
            type="Primary"
            disabled={this.renderDownloadKIDBtn(props.data)}
            text="Download KID"
          />
        ),
        sortable: true,
        cellClass: 'ag-cell',
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
      });
     columnDefs.unshift({
        field: 'SelectRow',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        maxWidth: 45,
        resizable: false,
      });

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  }

  async getData() {
    const accountName = account(this.context.state.selectedClient.code);
    const containerName = clientContainer(this.context.state.selectedClient.code);
    const blobName = null;
    const sasToken = await sasTokenApi.get(accountName, containerName, blobName, Auth0SessionInfo.GetSessionInfo().accessToken);
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        {
          clientcode: this.context.state.selectedClient.code,
          reportingperiod: this.context.state.selectedReportingDate.code,
          ruleregulation: this.state.regulation_name,
        },
        'Error retrieving data.',
      );

      // get app blob storage blobs
      const appStorageUrl = `https://${accountName}.blob.core.windows.net/${containerName}?include=metadata&prefix=pdfs%2F${this.context.state.selectedReportingDate.code}&restype=container&comp=list&${sasToken}`;

      await axios({
        method: 'GET',
        url: appStorageUrl,
        headers: {
          'x-ms-version': '2018-03-28',
          'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
        },
      })
        .then((response) => {
          const parser = new XMLParser();
          let jsonObj = parser.parse(response.data);
          let id = 0;

          const blobArray = jsonObj.EnumerationResults.Blobs.Blob.map((blob) => {
            id += 1;
            const FilePath = blob.Name.split('/');
            const fileName = FilePath[FilePath.length - 1];
            // console.log(blob);
            _.forEach(data.value, (row) => {
              if (row.kid_filename === fileName) {
                row.app_blob_name = `${blob.Name}`;
                row.upload_user = `${blob.Metadata.upload_user ?? ''}`;
                const externalUpload = blob.Metadata.external_upload ? blob.Metadata.external_upload : '';
                if (externalUpload === 'Y') {
                  if (blob.Properties['Last-Modified']) {
                    row.uploaded_date = format(new Date(blob.Properties['Last-Modified']), 'yyyy-MM-dd hh:mm:ss');
                  }
                }
              }
            });
          });
        })
        .catch((err) => {
          _.forEach(data.value, (row) => {
            row.app_blob_name = 'Not Found';
          });
        });

      // Test Site CIO Data
      this.loadTestSiteData(data);

      // Live Site Data
      this.loadLiveSiteData(data);

      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  renderDownloadKIDBtn = (data) => {
    if (data.app_blob_name === '' || data.app_blob_name === 'Not Found') {
      return true;
    }
    return false;
  }

  downloadBlob = async (data) => {
    const dlname = data.kid_filename;
    const accountName = account(this.context.state.selectedClient.code);
    const containerName = clientContainer(this.context.state.selectedClient.code);
    const targetID = `https://${accountName}.blob.core.windows.net/${containerName}/${data.app_blob_name}`;
    const sasToken = await sasTokenApi.get(accountName, containerName, data.app_blob_name, Auth0SessionInfo.GetSessionInfo().accessToken);
    if (this.state.loading === false) {
      this.setState({ loading: true }, () => {
        axios.request(
          `${targetID}?${sasToken}`,
          {
            method: 'GET',
            headers: {
              'x-ms-version': '2018-03-28',
            },
            responseType: 'blob',
          },
        )
          .then((res) => {
            fileDownload(res.data, dlname);
            this.setState({ loading: false });
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (error.res) {
              alert(`Code: ${error.response.data.error.code}\r\nMessage: ${error.response.data.error.message}`);
            } else {
              alert(`Error: ${error.message}`);
            }
          });
      });
    }
  }
  
  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.forEachNode((rowNode) => {
      rowNode.setId('item-classcode-' + rowNode.data.classcode_bk);
      rowNode.id = 'item-classcode' + rowNode.data.classcode_bk;
    });

    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  runDelete = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to delete ${listOfRows.length} KID(s)? 
            (This may take some time)`}
          </p>
          <Button onClick={() => this.deleteFiles()} size="Medium" type="Primary" text="Run" />
          <Button onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to delete all file on the Test Site? 
          (This may take some time)`}
          </p>
          <Button
            onClick={() => this.deleteFiles()}
            size="Medium"
            type="Primary"
            text="Run"
          />
          <Button
            onClick={() => this.context.handlers.toggleModal()}
            size="Medium"
            type="Primary"
            text="Cancel"
          />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    }
  }

  deleteFiles = async () => {
    const listOfRows = this.gridApi.getSelectedRows();

    const data = listOfRows.length > 0 ? listOfRows.map((row) => ({
      ClientCode: this.context.state.selectedClient.code,
      ReferenceDate: this.context.state.selectedReportingDate.code,
      BlobName: row.kid_filename,
      Type: row.type,
      User: this.context.state.userEmail,
    })) : [{
      ClientCode: this.context.state.selectedClient.code,
      ReferenceDate: this.context.state.selectedReportingDate.code,
      BlobName: 'DeleteAll',
      Type: 'All',
      User: this.context.state.userEmail,
    }];
      // console.log(delimcategory[0])
    APIM.makeRequest('deleteFromTest', JSON.stringify(data), 'Error Deleting KID from Test', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              <span>{listOfRows.length}</span>
              {' KID(s) will be deleted from Test Site. This may take some time to complete.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to delete the '}
              <span>{listOfRows.length}</span>
              {' KID'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  runPublishKIDData = () => {
    const listOfRows = this.gridApi.getSelectedRows();

    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to publish ${listOfRows.length} KID(s)? 
          (This may take some time)`}
          </p>
          <Button onClick={() => this.publishKIDData()} size="Medium" type="Primary" text="Run" />
          <Button onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to run.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  onPollingDone() {
    this.setState({ monitorUrl: undefined });
  }

  publishKIDData = async () => {
    const listOfRows = this.gridApi.getSelectedRows();
    
    const data = listOfRows.length > 0 ? listOfRows.map((row) => ({
      ClientCode: this.context.state.selectedClient.code,
      ReferenceDate: this.context.state.selectedReportingDate.code,
      BlobName: row.kid_filename,
      Type: row.type,
      User: this.context.state.userEmail,
      Active: row.active
    })) : [];
    
        APIM.makeRequest(
          'publishToTest',
          JSON.stringify(data),
          'Error Publishing PDF To Test',
          Auth0SessionInfo.GetSessionInfo().accessToken
        )
          .then(() => {
            this.context.handlers.closeModal();
            const toast = {
              type: 'success',
              body: (
                <>
                  <span>{listOfRows.length}</span>
                  {' KID(s) will be published to Test Site. This may take some time!'}
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          })
          .catch(() => {
            this.context.handlers.closeModal();
            const toast = {
              type: 'error',
              body: (
                <>
                  {'Error - something went wrong and we were not able to publish the '}
                  <span>{listOfRows.length}</span>
                  {' KID'}
                  {listOfRows.length > 1 ? 's' : ''}
                  {' you had selected.'}
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          });
      }

  /* Publish to Test */
  runPublishKIDDataTest = () => {
    const modalContent = (
      <div>
        <p>
          This action will <u><b>delete</b></u> and <u><b>replace</b></u>
          {` the current content of the ${this.context.state.selectedClient.code} Test Site with the current content of the ${this.context.state.selectedClient.code} ${this.context.state.selectedReportingDate.code} App. Blob Storage.`}
        </p>
        <p>Are you sure you want to proceed?</p>
        <p>This request may take some time!</p>
        <Button
          onClick={() => this.publishKIDDataTest()}
          size="Medium"
          text="Run"
          type="Primary"
        />
        <Button
          onClick={() => this.context.handlers.toggleModal()}
          size="Medium"
          text="Cancel"
          type="Primary"
        />
      </div>
    );
    this.context.handlers.setModal('Approve', modalContent, null, null, false);
    this.context.handlers.toggleModal();
  }

  publishKIDDataTest = () => {
    const modalContent = <div><p>Publishing to Test Site...</p></div>;
    this.context.handlers.setModal('Publishing ...', modalContent, null, null, true);
    const listOfRows = this.gridApi.getSelectedRows();
    
    var clientCode = this.context.state.selectedClient.code;
    var data = {
      ClientCode: clientCode,
      ReferenceDate: this.context.state.selectedReportingDate.code,
      User: this.context.state.userEmail,
      Rows: []
    }

    this.gridApi.forEachNode(node => {

      data.Rows.push({
        ClientCode: this.context.state.selectedClient.code,
        ReferenceDate: this.context.state.selectedReportingDate.code,
        BlobName: node.data.kid_filename,
        Type: node.data.type,
        User: this.context.state.userEmail,
        Active: node.data.active,
        Selected: node.selected
      })
    });

    APIM.makeRequest('publishToTest', JSON.stringify(data), 'Error Publishing PDF To Test Site', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              Your `Published to Test Site` request was successful.
              Please allow some time before validating using the link provided on this page
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Publish to Test Site' });
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to delete the '}
              <span>{listOfRows.length}</span>
              {' KID'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }
  /* ****************************** */

  /* Publish to Live */
  runPublishKIDDataLive = () => {
    const modalContent = (
      <div>
        <p>
          This action will <u><b>delete</b></u> and <u><b>replace</b></u>
          {` the current content of the ${this.context.state.selectedClient.code} Live Site with the current content of the ${this.context.state.selectedClient.code} Test Site.`}
        </p>
        <p>
          Are you sure you want to proceed?
        </p>
        <p>
          This request may take some time!
        </p>
        <Button onClick={() => this.publishKIDDataLive()} size="Medium" type="Primary" text="Run" />
        <Button onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Cancel" />
      </div>
    );
    this.context.handlers.setModal('Approve', modalContent, null, null, false);
    this.context.handlers.toggleModal();
  }

  publishKIDDataLive = () => {
    const modalContent = <div><p>{'Publishing to Live Site...'}</p></div>;
    this.context.handlers.setModal('Publishing ...', modalContent, null, null, true);
    const data = {
      ClientCode: this.context.state.selectedClient.code,
      ReferenceDate: this.context.state.selectedReportingDate.code,
      User: this.context.state.userEmail,
    };
      // console.log(delimcategory[0])
    APIM.makeRequest('publishToLive', JSON.stringify(data), 'Error Publishing PDF To Live Site', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'Your `Published to Live Site` request was successful. Please allow some time before validating using the link provided on this page'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Publish to Live Site' });
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to publish'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  async getBlobsList(url) {
    const blobsresult =
      await axios({
        method: 'GET',
        url: url,
        headers: {
          'x-ms-version': '2018-03-28',
          'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
        },
      });
    const parser = new XMLParser();
    let blobsdata = parser.parse(blobsresult.data);
    return blobsdata;
  }

  async loadTestSiteData(data) {
    let testSiteBlobsResult = '';
    let testSiteBlobs = [];
    let nextMarker = '';

    do {
      const ioUrl = `https://${testSiteAccount(this.context.state.selectedClient.code)}.blob.core.windows.net/${ioContainer}?include=metadata&restype=container&comp=list&marker=${nextMarker}`;
      testSiteBlobsResult = await this.getBlobsList(ioUrl);
      testSiteBlobs.push(testSiteBlobsResult.EnumerationResults.Blobs);
      //console.log(testBlobs);
      nextMarker = testSiteBlobsResult.EnumerationResults.NextMarker;
      //console.log(nextMarker);
    } while (nextMarker != '');

    //console.log(testBlobs);
    let id = 0;
    for (id = 0; id < testSiteBlobs.length; id++) {
      const blobArray = testSiteBlobs[id].Blob.map((blob) => {
        const fileName = blob.Name ?? '';
        _.forEach(data.value, (row) => {
          if (row.kid_filename === fileName) {
            row.test_site_blob_name = this.formatBlobSize(blob.Properties['Content-Length']);
            if (blob.Properties['Last-Modified']) {
              row.published_to_test_date = format(new Date(blob.Properties['Last-Modified']), 'yyyy-MM-dd hh:mm:ss');
            }
          }
        });
      });
    }

    const productUrl = `https://${testSiteAccount(this.context.state.selectedClient.code)}.blob.core.windows.net/${prodContainer}?include=metadata&restype=container&comp=list`;
    await axios({
      method: 'GET',
      url: productUrl,
      headers: {
        'x-ms-version': '2018-03-28',
        'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
      },
    })
      .then((response) => {
        const parser = new XMLParser();
        let jsonObj = parser.parse(response.data);
        let id = 0;
        const blobArray = jsonObj.EnumerationResults.Blobs.Blob.map((blob) => {
          id += 1;
          const fileName = blob.Name ?? '';
          _.forEach(data.value, (row) => {
            if (row.kid_filename === fileName) {
              row.test_site_blob_name = this.formatBlobSize(blob.Properties['Content-Length']);
              if (blob.Properties['Last-Modified']) {
                  row.published_to_test_date = format(new Date(blob.Properties['Last-Modified']), 'yyyy-MM-dd hh:mm:ss');
              }
            }
          });
        });
      })
      .catch((err) => {
        _.forEach(data.value, (row) => {
          row.test_site_blob_name = 'Not Found';
        });
      });
  }

  async loadLiveSiteData(data) {
    let liveSiteBlobsResult = '';
    let liveSiteBlobs = [];
    let nextMarker = '';

    do {
      const ioUrl = `https://${liveSiteAccount(this.context.state.selectedClient.code)}.blob.core.windows.net/${ioContainer}?include=metadata&restype=container&comp=list&marker=${nextMarker}`;
      liveSiteBlobsResult = await this.getBlobsList(ioUrl);
      liveSiteBlobs.push(liveSiteBlobsResult.EnumerationResults.Blobs);
      nextMarker = liveSiteBlobsResult.EnumerationResults.NextMarker;
    } while (nextMarker != '');

    let id = 0;
    for (id = 0; id < liveSiteBlobs.length; id++) {
      const blobArray = liveSiteBlobs[id].Blob.map((blob) => {
        const fileName = blob.Name ?? '';
        _.forEach(data.value, (row) => {
          if (row.kid_filename === fileName) {
            row.live_site_blob_name = this.formatBlobSize(blob.Properties['Content-Length']);
            if (blob.Properties['Last-Modified']) {
              row.published_to_live_date = format(new Date(blob.Properties['Last-Modified']), 'yyyy-MM-dd hh:mm:ss');
            }
          }
        });
      });
    }

    const productUrl = `https://${liveSiteAccount(this.context.state.selectedClient.code)}.blob.core.windows.net/${prodContainer}?include=metadata&restype=container&comp=list`;
    await axios({
      method: 'GET',
      url: productUrl,
      headers: {
        'x-ms-version': '2018-03-28',
        'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
      },
    })
      .then((response) => {
        const parser = new XMLParser();
        let jsonObj = parser.parse(response.data);
        let id = 0;
        const blobArray = jsonObj.EnumerationResults.Blobs.Blob.map((blob) => {
          id += 1;
          const fileName = blob.Name ?? '';
          _.forEach(data.value, (row) => {
            if (row.kid_filename === fileName) {
              row.live_site_blob_name = this.formatBlobSize(blob.Properties['Content-Length']);
              if (blob.Properties['Last-Modified']) {
                row.published_to_live_date = format(new Date(blob.Properties['Last-Modified']), 'yyyy-MM-dd hh:mm:ss');
              }
            }
          });
        });
      })
      .catch((err) => {
        console.log('Test Product blobArray Error', err);
        _.forEach(data.value, (row) => {
          row.test_site_blob_name = null;
        });
      });
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  render() {
    return (
      this.state.redirect ? <Redirect to="/regulatory-reporting/ept/dashboard" /> : (
        <DashboardView>
          <DashboardBody>
            {
              this.state.failed ? <Error text={this.state.failMessage} /> : (
                <>
                  {
                    this.state.loading ? <Spinner text="Loading data..." /> : (
                      this.gridApi && (
                        <Toolbar
                          api={this.gridApi}
                          controls={this.state.toolbarButtons}
                          count={{
                            total: this.state.totalRowCount,
                            showing: this.state.displayedRowCount,
                          }}
                          exportName={this.state.api_name}
                          leftControls={this.state.leftButtons}
                          working={this.state.toolbarWorking}
                        />
                      )
                    )
                  }
                  <Grid
                    apiName={this.state.api_name}
                    columnDefs={this.state.columnDefs}
                    onGridReady={this.onGridReady}
                    onModelUpdated={this.onModelUpdated}
                    pivotable
                    rawColumnData={this.state.rawColumns}
                  />
                </>
              )
          }
          </DashboardBody>

          <div>
            {
              this.state.monitorUrl ? (
                <UrlPoller
                  completeCallback={this.onPollingDone.bind(this)}
                  endStates={['Completed']}
                  monitorUrl={this.state.monitorUrl}
                  refreshIntervalSeconds={10}
                  responseDisplayElementPath="$.customStatus"
                  monitorType={this.state.monitorType }
                />
              ) : <></>
            }
          </div>
        </DashboardView>
    ));
  }

  formatBlobSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0';

    const unit = 1024;
    const precision = decimals < 0 ? 0 : decimals;

    return parseFloat((bytes / Math.pow(unit, 1)).toFixed(precision));
  }
}

StaticSiteDashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

StaticSiteDashboard.contextType = AppContext;

export default StaticSiteDashboard;
