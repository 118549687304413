import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import theme from '../../theme';

const Message = styled.div`
  background-color: ${(props) => {
    switch (props.type) {
      case 'Success': return theme.color.status.green;
      case 'Warning': return theme.color.status.yellow;
      case 'Error': return theme.color.status.red;
      default: return theme.color.first;
    }
  }};
  border-radius: 8px;
  padding: 1rem 2rem;
  color: #ffffff;
  font-weight: 300;
  box-sizing: border-box;
  margin: 1.6rem;
  display: block
`;

const Icon = styled(FontAwesomeIcon)`
  box-sizing: border-box;
  display: block;
  font-size: 15px;
  width: 15px;
  position: absolute;
`;

const Content = styled.p`
  box-sizing: border-box;
  display: block;
  margin: 0px;
  ${({ icon }) => (
    icon && css`
      padding-left: 2.4rem;`
  )}
`;

/**
 * A Message UI Component that displays just below the HomeHeader
 *
 * @param {string} content - the body of the message
 * @param {array} icon - the FontAwesomeIcon array
 * @param {string} type - Value corresponding to the style of Message
 * (Success, warning, Error, or default)
 */
const ArpMessage = ({ content, icon, type }) => (
  <Message
    aria-live="polite"
    role="alert"
    type={type}
  >
    {
      icon && <Icon icon={icon} />
    }
    <Content icon={icon}>
      {content}
    </Content>
  </Message>
);

export default ArpMessage;

ArpMessage.defaultProps = {
  content: 'This is a Message',
  icon: null,
  type: 'Message',
};

ArpMessage.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  icon: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['Success', 'Warning', 'Error', 'Message']),
};
