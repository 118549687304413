import { useContext } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import AppContext from 'AppContext';
import config from '../../config';

/**
 * Handles Redirects from Priips_Web to ArcRegUI
 * 
 * Test Payload:
 * TST
 * 2020-09-30
 * 
 * @returns <Redirect>
 */
const Outbound = () => {
  const context = useContext(AppContext);
  const location = useLocation();
  let destination = location.pathname;

  if(location.pathname.includes('Outbound')) {
    destination = location.pathname.substring(9);
  }

  const outboundPayload = {
    DestinationPageUrl: destination,
    ClientCode: context.state.selectedClient.code,
    ClientName: context.state.selectedClient.name,
    ClientType: context.state.selectedClient.type,
    ReportingPeriod: context.state.selectedReportingDate === null 
      ? null : context.state.selectedReportingDate.code,
  };

  const encodedPayload = btoa(JSON.stringify(outboundPayload));
  window.location.href= `${config.arcregui.url}/inbound/${encodedPayload}`
  return null;
};

export default withRouter(Outbound);
