import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const Modal = styled.div.attrs((props) => ({
  width: props.width || '400px',
}))`
  width: ${(props) => props.width};
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  `;

export const Title = styled.div`
  height: 40px;
  background-color: #380f6f;
  color: #fff;
  border-radius: 4px 4px 0 0;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0 15px;
  align-items: center;
`;

export const Spinner = styled(FontAwesomeIcon).attrs({
  className: 'fa-spin',
})`
  color: #fff;
  font-size: 20px;
`;

export const TitleText = styled.div`
  margin-left: 10px;
  color: #F9FAFB;
`;

export const Body = styled.div`
  padding: 15px;
  max-height: 75vh;
  overflow-y: ${(props) => props.overflow};
`;

export const Footer = styled.footer`
  border-top: 1px solid #d5d5d5;
  padding: 15px;
`;
