/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import axios from 'axios';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';
import config from '../../config';
import AppContext from '../../AppContext';
import {
  FileBrowser, FileBrowserContainer, Container, Dropzone, Spinner, Error,
} from './LoadMarketData.styles';
import sasTokenApi from '../../Services/sasTokenApi';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';

const { account } = config.blob;
const container = 'tr-api-input';
const outputContainer = 'tr-api-output';

class LoadMarketData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
    this.fetchData = this.listContainerBlobs.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  listContainerBlobs = async () => {
    const sasToken = await sasTokenApi.get(account, outputContainer, null, Auth0SessionInfo.GetSessionInfo().accessToken);
    axios({
      method: 'GET',
      url: `https://${account}.blob.core.windows.net/${outputContainer}?${sasToken}&include=metadata&restype=container&comp=list`,
      headers: {
        'x-ms-version': '2018-03-28',
        'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
      },
    })
      .then((response) => {
        const parser = new XMLParser();
        let jsonObj = parser.parse(response.data);
        let id = 0;
        const blobArray = jsonObj.EnumerationResults.Blobs.Blob.map((blob) => {
          id += 1;
          const FilePath = blob.Name.split('/');
          const Prefix = FilePath[0];
          const Suffix = FilePath[FilePath.length - 1];

          return {
            id,
            ...blob,
            FilePath,
            Prefix,
            Suffix,
            dateModified: blob.Properties['Last-Modified'],
            outputContainer,
            account: config.blob.account,
            size: blob.Properties['Content-Length'],
          };
        });

        this.setState({
          blobArray,
          loading: false,
        });
      });
  };

  render() {
    const { blobArray } = this.state;

    return (
      <Container>
        <Dropzone
          inputContent={`Drag Files or Click to add to ${this.context.state.selectedClient.name}`}
          uploadURL={`https://${account}.blob.core.windows.net/${container}`}
          clientCode={this.context.state.selectedClient.code}
        />

        <FileBrowserContainer>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              this.state.loading ? <Spinner text="Loading files..." /> : (
                <FileBrowser
                  rowData={blobArray}
                />
              ))
          }
        </FileBrowserContainer>
      </Container>
    );
  }
}
LoadMarketData.contextType = AppContext;


export default LoadMarketData;
