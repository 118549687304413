import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const statusCell = (props) => {
  const [icon, setIcon] = useState(null);
  const [style, setStyle] = useState(null);

  useEffect(() => {
    if (props.value === 1) {
      setIcon(['fal', 'check-circle'])
      setStyle({ color: '#2EC4B6' });
    }
    if (props.value === 0) {
      setIcon(['fal', 'exclamation-circle'])
      setStyle({ color: '#EE4266' });
    }
  }, [props.value]);

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        fontSize: '18px',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <FontAwesomeIcon icon={icon} style={style}/>
    </div>
  );
};

export default statusCell;
