import GrpError from 'Components/GrpError/GrpError';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import styled from 'styled-components';

export const RegEditView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RegEditBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Toolbar = styled(GrpToolbar)``;

export const GButton = styled(GrpButton)``;

export const Spinner = styled(GrpSpinner)``;

export const Error = styled(GrpError)``;
