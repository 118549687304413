import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import CryptoEnc from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
import CDATA from 'Services/CDATA';
import AppContext from 'AppContext';
import RPstandardPreview from './Sublevels/RPstandardPreview';
import RPstandardInputsPreview from './Sublevels/RPstandardInputsPreview';
import RPstandardReturnsPreview from './Sublevels/RPstandardReturnsPreview';
import RPstandardIntermediatePreview from './Sublevels/RPstandardIntermediatePreview';
import RPceptPreview from './Sublevels/RPceptPreview';
import RPceptPricesPreview from './Sublevels/RPceptPricesPreview';
import RPceptInputsPreview from './Sublevels/RPceptInputsPreview';
import RPceptIntermediatePreview from './Sublevels/RPceptIntermediatePreview';
import RPceptv2histInputPreview from './Sublevels/RPceptv2histInputPreview';
import RPceptv2histOutputPreview from './Sublevels/RPceptv2histOutputPreview';
import config from '../../config';
import GrpHeader from 'Library/GrpHeader/GrpHeader';
import GrpButton from 'Library/GrpButton/GrpButton';
import { LevelButton, SubLevelButton, LevelBlobButton } from './RiskAndPerformance.styles';
import { Client, ClientAndReportingDate, ReportingDate } from 'Components/HomeHeader/HomeHeader.styles';

const { encKey } = config.sessionStorage;

class RiskAndPerformanceRouter extends Component {
  constructor(props) {
    super(props);
    const sessionState = sessionStorage.getItem('PageStateStorage');
    const propsID = this.props.location.classcode_bk;

    let apiName = 'PRIIPS_app_Risk_And_Performance_AssetManager_Final_Update';
    if (propsID && this.props.location.regulation_name == 'CEPT') {
      apiName = 'PRIIPS_app_Risk_And_Performance_AssetManager_CEPT_Update';
    } else if (propsID && (this.props.location.regulation_name == 'EPTv2' || this.props.location.regulation_name == 'EMTv4')) {
      apiName = 'PRIIPS_app_Risk_And_Performance_AssetManager_Final_Update_RTS2';
    }

    if (propsID) {
      this.state = {
        page: window.location.pathname,
        api_name: apiName,
        approvable: this.props.location.approvable,
        Class_Code: this.props.location.classcode_bk,
        Class_Name: this.props.location.of_full_share_class_name,
        Fund_Code: this.props.location.fundcode_bk,
        regulation_name: this.props.location.regulation_name,
        redirect: false,
      };
      console.log("REG NAME: " + this.state.regulation_name);
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page.substring(0, 29) === '/regulatory-reporting/preview') {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
  }

  RPCalculation = (onlyNew) => {
    CDATA.makeRequest(
      'POST',
      this.state.api_name,
      '',
      {
        Client_Code: this.context.state.selectedClient.code,
        Class_Codes: this.state.Class_Code,
        CalcDate: this.context.state.selectedReportingDate.code,
        UPDATE: true,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Class_Name: '',
        Entity: `R&P ${this.state.regulation_name} Calculation`,
      },
      'Approve failed.',
    )
      .then(() => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {`You successfully ran the all the ${this.state.regulation_name} R&P calculations for `}
              <span>{this.state.Fund_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the calculation for '}
              <span>{this.state.Fund_Code}</span>
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  dateClick = () => {
    this.context.handlers.injectClientReportingIntoDrawer();
  };

  approve = (totalRowCount) => {
    if (totalRowCount === 0) {
      const toast = {
        type: 'error',
        body: (<>No data available to calculate.</>),
      };
      this.context.handlers.setToast(toast);
      return;
    }
    const modalContent = (
      <div>
        <p>
        {`Are you sure you want to run the ${this.state.Fund_Code} risk and performance calculations for the ${this.state.regulation_name} ?`}
        </p>
        <GrpButton
          onClick={() => (this.RPCalculation(0))}
          size="Medium"
          type="Primary"
          text=" Ok "
        />
        <GrpButton
          onClick={() => this.context.handlers.toggleModal()}
          size="Medium"
          type="Primary"
          text="Cancel"
        />
      </div>
    );
    this.context.handlers.setModal('', modalContent, null, null, false);
    this.context.handlers.toggleModal();
  }

  render() {
    // console.log('Regulation '+this.props.location.regulation_name)
    return (
      <>
        {
            this.state.regulation_name === 'CEPT' || this.state.regulation_name === 'CEPT_Perf_v2'  ? (
            <GrpHeader
              title="Regulatory Reporting"
              headerItems={[
                <LevelButton
                  to={{ pathname: '/regulatory-reporting/cept/dashboard' }}
                >
                  {this.state.regulation_name}
                  {' '}
                  Dashboard
                </LevelButton>,
              ]}
              subHeaderItems={[
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/ceptresults' }}
                >
                  R&P CEPT Results
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/ceptprices' }}
                >
                  R&P CEPT Prices
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/ceptinputs' }}
                >
                  R&P CEPT Inputs
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/ceptintermediate' }}
                >
                  R&P CEPT Intermediate Output
                </SubLevelButton>,
              ]}
              fileName={(
                <LevelBlobButton
                  actionType=""
                  size="Small"
                  text={this.state.Class_Name}
                  onClick={() => (this.context.state.userEmail === 'alex.gottemoller@dfinsolutions.com' ? console.log('LONG LIVE HARAMBE!!!') : console.log(this.state.Class_Name))}
                />
              )}
            />
          ) : this.state.regulation_name === 'CEPT_Hist_v2' ? (
            <GrpHeader
              title="Regulatory Reporting"
              headerItems={[
                <LevelButton
                  to={{ pathname: '/regulatory-reporting/ceptv2history/dashboard' }}
                >
                  {this.state.regulation_name}
                  {' '}
                  Dashboard
                </LevelButton>,
              ]}
              subHeaderItems={[
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/cepthistv2input' }}
                >
                  CEPTv2 History Input
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/cepthistv2output' }}
                >
                  CEPTv2 History Output
                </SubLevelButton>,
              ]}
            />
          ) : (
            // default to EPT and EMT previews (
            <GrpHeader
              title="Regulatory Reporting"
              headerItems={[
                <LevelButton
                  to={{ pathname: `/regulatory-reporting/${this.state.regulation_name.toLowerCase()}/dashboard` }}
                >
                  {this.state.regulation_name} Dashboard
                </LevelButton>,
              ]}
              subHeaderItems={[
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview' }}
                >
                  R&P Results
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/inputs' }}
                >
                  R&P Inputs
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/returns' }}
                >
                  R&P Returns
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/intermediate' }}
                >
                  R&P Intermediate Output
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/returnsforflexfunds' }}
                >
                  R&P Returns (Flex Fund)
                </SubLevelButton>,
                <SubLevelButton
                  to={{ pathname: '/regulatory-reporting/preview/intermediateforflexfunds' }}
                >
                  R&P Intermediate Output (Flex Fund)
                </SubLevelButton>,
              ]}
              fileName={(
                <LevelBlobButton
                  actionType=""
                  size="Small"
                  text={this.state.Class_Name}
                  onClick={() => (this.context.state.userEmail === 'alex.gottemoller@dfinsolutions.com' ? console.log('LONG LIVE HARAMBE!!!') : console.log(this.state.Class_Name))}
                />
              )}
              headerControls={(
                <ClientAndReportingDate
                  onClick={this.dateClick}
                  text={(
                    <div>
                      <Client>
                        {
                          !this.context.state.selectedClient.name === null ? (
                            'Select Client and Reporting Period'
                          ) : (this.context.state.selectedClient.name)
                        }
                      </Client>
                      <ReportingDate>
                        {
                          (this.context.state.selectedClient.name && this.context.state.selectedReportingDate)
                          && (this.context.state.selectedReportingDate.code)
                        }
                      </ReportingDate>
                    </div>
                  )}
                />
              )}
            />
          )
        }
        {
          <Switch>
            <Route
              exact
              path="/regulatory-reporting/preview"
              render={() => (
                <RPstandardPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/inputs"
              render={() => (
                <RPstandardInputsPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/returns"
              render={() => (
                <RPstandardReturnsPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/returnsforflexfunds"
              render={() => (
                <RPstandardReturnsPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/intermediate"
              render={() => (
                <RPceptIntermediatePreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/intermediateforflexfunds"
              render={() => (
                <RPstandardIntermediatePreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/ceptresults"
              render={() => (
                <RPceptPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/ceptprices"
              render={() => (
                <RPceptPricesPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/ceptinputs"
              render={() => (
                <RPceptInputsPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/ceptintermediate"
              render={() => (
                <RPceptIntermediatePreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/cepthistv2input"
              render={() => (
                <RPceptv2histInputPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/regulatory-reporting/preview/cepthistv2output"
              render={() => (
                <RPceptv2histOutputPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                />
              )}
            />
          </Switch>
        }
      </>
    );
  }
}

RiskAndPerformanceRouter.contextType = AppContext;

export default RiskAndPerformanceRouter;
