import styled from 'styled-components';
import Select from 'react-select';
import GrpGrid from '../../../Library/GrpGrid/GrpGrid';
import GrpButton from '../../../Library/GrpButton/GrpButton';
import GrpError from '../../../Components/GrpError/GrpError';
import GrpSpinner from '../../../Components/GrpSpinner/GrpSpinner';
import GrpToolbar from '../../../Library/GrpToolbar/GrpToolbar';
import GrpSelect from '../../../Library/GrpSelect/GrpSelect';
import GrpButtonGroup from '../../../Library/GrpButton/GrpButtonGroup/GrpButtonGroup'
import ApprovalToggle from '../../../Library/GrpGrid/ApprovalToggle/ApprovalToggle';

export const View = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Body = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ModalTableDiv = styled.div`
  overflow-y: scroll;
  max-height: 400px;
  margin-bottom: 10px;
`;

export const Grid = styled(GrpGrid)`
`;

export const Button = styled(GrpButton)`
`;

export const ApprovalControls = styled(GrpButtonGroup)`
`

export const FunctionSelect = styled(GrpSelect)`
`;

export const RegSelect = styled(Select)`
`;

export const Error = styled(GrpError)`
`;

export const Spinner = styled(GrpSpinner)`
`;

export const Toolbar = styled(GrpToolbar)`
`;

export const Approval = styled(ApprovalToggle);
