import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import fileDownload from 'js-file-download';
import GrpButton from '../../GrpButton/GrpButton';
import AppContext from '../../../AppContext';
import config from '../../../config';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import sasTokenApi from '../../../Services/sasTokenApi';
import Auth0SessionInfo from '../../../Services/Auth0SessionInfo';

const handleBlobDownload = async (event) => {
  const dlname = event.target.name;
  let url = event.target.id;

  const { encKey } = config.sessionStorage;
  const appStateInSession = sessionStorage.getItem('AppStateStorage');
  const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));
  var storage = sasTokenApi.splitStorageUrl(url);
  const sasToken = await sasTokenApi.get(storage.account, storage.container, null, Auth0SessionInfo.GetSessionInfo().accessToken);
  url += `?${sasToken}`;

  axios.request(
    url,
    {
      method: 'GET',
      headers: {
        'x-ms-version': '2018-03-28',
      },
      responseType: 'blob',
    },
  )
    .then((res) => {
      fileDownload(res.data, dlname);
    })
    .catch((error) => {
      console.log(error);
    });
};

const AuditTable = ({ clickedColumnName, data }) => {
  const context = useContext(AppContext);
  return (
    <div>
      {
        data.length > 1 ? (
          <div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>{clickedColumnName}</th>
                  <th>User</th>
                  <th>Action</th>
                  <th>Original File</th>
                  <th>Curated File</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.filter((item) => item.return_value !== '0').map((x) => (
                    <tr>
                      <td>{x.starttime}</td>
                      <td>{x.endtime}</td>
                      <td>{x.fieldvalue}</td>
                      <td>{x.mail}</td>
                      <td>{x.action_description}</td>
                      <td>
                        <a
                          href="#"
                          id={x.originalfileurl}
                          name={x.originalfilename}
                          onClick={(e) => handleBlobDownload(e)}
                        >
                          {x.originalfilename}
                        </a>
                      </td>
                      <td>
                        <a
                          href="#"
                          id={x.curationfileurl}
                          name={x.curationfile}
                          onClick={(e) => handleBlobDownload(e)}
                        >
                          {x.curationfile}
                        </a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        ) : (
          <div>
            <p>No audit history</p>
          </div>
        )
      }
      <div>
        <GrpButton
          text="Close"
          onClick={() => { context.handlers.closeModal(); }}
          size="Medium"
          type="Primary"
        />
      </div>
    </div>
  );
};

export default AuditTable;
