/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import GrpSelectBoxItem from './GrpSelectBoxItem/GrpSelectBoxItem';
import { SelectBox, SelectionPanel, ActiveBox } from './GrpSelectBox.styles';

const GrpSelectBox = ({
  collection, onChange, panelHeight, selectedID, title,
}) => {
  const [selected, setSelected] = useState(selectedID);
  const [stateCollection, setStateCollection] = useState(collection);
  // setStateCollection could probably be put in a whole new useEffect() to simplify things
  // console.log(`${title} props`, collection, selectedID);
  useEffect(() => {
    if (selectedID) {
      collection.forEach((item) => {
        if (item.code === selectedID) {
          item.selected = true;
          setSelected(item);
        }
      });
    }
    setStateCollection(collection);
  }, [selectedID, collection]);

  const handleSelect = (item) => {
    setSelected(item);
    const tempCollection = [...stateCollection];
    tempCollection.forEach((i) => {
      i.selected = false;
      if (i.code === item.code) {
        i.selected = true;
      }
    });
    setStateCollection(collection);
    onChange(item);
  };

  return (
    <SelectBox>
      <h3>
        <span>
          {title}
          {' '}
          {
            selected && (
              <ActiveBox>
                {selected.name}
              </ActiveBox>
            )
          }
        </span>
        {
          !stateCollection && (
            <span>
              <FontAwesomeIcon
                icon={['fas', 'circle-notch']}
                className="fa-spin"
              />
            </span>
          )
        }
      </h3>
      <SelectionPanel
        height={panelHeight}
      >
        {
          stateCollection && stateCollection.map((item) => {
            if (item.code === selectedID) {
              item.selected = true;
            }
            return (
              <GrpSelectBoxItem
                key={item.code}
                onClick={() => handleSelect(item)}
                item={item}
              />
            );
          })
        }
      </SelectionPanel>
    </SelectBox>
  );
};

GrpSelectBox.defaultProps = {
  collection: [],
  panelHeight: '300 px',
  selectedID: null,
  title: '',
};

GrpSelectBox.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
  })),
  selectedID: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
  })]),
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  panelHeight: PropTypes.string,
};

export default GrpSelectBox;
