import styled from 'styled-components';
import GrpError from '../../Components/GrpError/GrpError';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';

export const Error = styled(GrpError)``;

export const Grid = styled(GrpGrid)``;

export const Spinner = styled(GrpSpinner)``;

export const Toolbar = styled(GrpToolbar)``;

export const TransactionValidationErrorsBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const TransactionValidationErrorsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
