/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import {
  Col, Row, Nav, Navbar, Form, Button, ButtonToolbar, OverlayTrigger, Popover,
} from 'react-bootstrap';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import { Redirect } from 'react-router-dom';
import { RegEditView, RegEditBody, GButton, Toolbar, Spinner } from './RegulationEditor.styles';
import AppContext from '../../AppContext';
import '../../Styles/SCSS/ag-theme-material.scss';
import config from '../../config';
import CDATA from '../../Services/CDATA';

const { encKey } = config.sessionStorage;

class EMTeditor extends Component {
  constructor(props) {
    super(props);

    let sessionState = sessionStorage.getItem('PageStateStorage');
    let propsID = this.props.location.classcode_bk;
    // console.log(propsID)
    if (propsID) {
      this.state = {
        page: window.location.pathname,
        raw_api: this.props.raw_api,
        api_name: this.props.location.api_name,
        loading: false,
        failed: false,
        Class_Code: this.props.location.classcode_bk,
        Fund_Code: this.props.location.fundcode_bk,
        Reference_Language: this.props.location.language,
        eventKey: 'General Info',
        action_type: this.props.location.action_type,
        toolbarButtons: ['Placeholder' === 'Edit' ? <GButton key={0} onClick={() => {this.handleApprove();}} size="Medium" type="Primary" text="Approve" /> : <GButton key={0} onClick={() => {this.handleReject();}} size="Medium" type="Primary" text="Re-Open" />],
        validated: false,
        loaded: false,
        redirect: false,
      };
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page === window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async componentDidMount() {
    const urlParameters = CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and x_00050_Reporting_Date eq '${this.context.state.selectedReportingDate.code}' and x_00010_Financial_Instrument_Identifying_Data eq '${this.state.Class_Code}'`);
    try {
      const data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_app_EMT_OUTPUT',
        urlParameters,
        {},
        'Error retrieving data.',
      );

      let emtFields = data.value[0];
      console.log(data);

      this.setState(emtFields);
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  async saveEMT(emtSection) {
    if (emtSection === 'General') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          'PRIIPS_App_EMT(' + this.state.RECORD_ID + ')',
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_00010_Financial_Instrument_Identifying_Data: this.state.x_00010_Financial_Instrument_Identifying_Data,
            ept_type_of_identification_code_for_the_fund_share_or_portfolio: this.state.x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument,
            OF_Full_Share_Class_Name: this.state.x_00030_Financial_Instrument_Name,
            OF_Share_Class_Currency: this.state.x_00040_Financial_Instrument_Currency,
            reporting_period: this.state.x_00050_Reporting_Date,
            of_type_of_eu_directive: this.state.x_00060_Financial_Instrument_Legal_Structure,
            of_manco: this.state.x_00070_Financial_Instrument_Issuer_Name,
            of_fund_guarantor: this.state.x_00080_Financial_Instrument_Guarantor_Name,
            x_00090_Product_Category_or_Nature: this.state.x_00090_Product_Category_or_Nature,
            of_is_leveraged_financial_instrument_or_contingent_liability_instrument: this.state.x_00100_Leveraged_Financial_Instrument_or_Contingent_Liability_Instrument,
          },
          'EMT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: 'EMT Not Saved. Code: ' + err.response.data.error.code + '\r\nMessage: ' + err.response.data.err.message,
        });
      }
    } else if (emtSection === 'Investor') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          'PRIIPS_App_EMT(' + this.state.RECORD_ID + ')',
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            of_is_client_type_retail: this.state.x_01010_Investor_Type_Retail,
            of_is_client_type_professional: this.state.x_01020_Investor_Type_Professional,
            of_is_client_type_eligible_counterparty: this.state.x_01030_Investor_Type_Eligible_Counterparty,
            of_investor_with_basic_knowledge: this.state.x_02010_Basic_Investor,
            of_informed_investor: this.state.x_02020_Informed_Investor,
            of_advanced_investor: this.state.x_02030_Advanced_Investor,
            of_german_knowledge_and_experience: this.state.x_02040_Expert_Investor_Germany,
          },
          'EMT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: 'EMT Not Saved. Code: ' + err.response.data.error.code + '\r\nMessage: ' + err.response.data.err.message,
        });
      }
    } else if (emtSection === 'Risk') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          'PRIIPS_App_EMT(' + this.state.RECORD_ID + ')',
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            of_no_ability_for_any_capital_loss: this.state.x_03010_No_Capital_Loss,
            of_ability_for_limited_capital_losses: this.state.x_03020_Limited_Capital_Loss,
            of_level_of_limited_capital_loss_as_percentage: this.state.x_03030_Limited_Capital_Loss_Level,
            of_ability_for_total_capital_loss: this.state.x_03040_No_Capital_Guarantee,
            of_ability_for_losses_beyond_capital: this.state.x_03050_Loss_Beyond_Capital,
            OF_PRIIP_Summary_Risk_Indicator: this.state.x_04010_Risk_Tolerance_PRIIPS_Methodology,
            OF_SRRI: this.state.x_04020_Risk_Tolerance_UCITS_Metholodology,
            of_risk_tolerance_for_non_priips_and_non_ucits: this.state.x_04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_and_Non_UCITS,
            of_spanish_risk_tolerance_for_non_priips_and_non_ucits: this.state.x_04040_Risk_Tolerance_For_Non_PRIIPS_and_Non_UCITS_Spain,
            of_german_lowest_risk_tolerance: this.state.x_04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany,
          },
          'EMT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: 'EMT Not Saved. Code: ' + err.response.data.error.code + '\r\nMessage: ' + err.response.data.err.message,
        });
      }
    } else if (emtSection === 'Objectives') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          'PRIIPS_App_EMT(' + this.state.RECORD_ID + ')',
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            of_return_profile_preservation: this.state.x_05010_Return_Profile_Preservation,
            of_return_profile_growth: this.state.x_05020_Return_Profile_Growth,
            of_return_profile_income: this.state.x_05030_Return_Profile_Income,
            of_return_profile_hedging: this.state.x_05040_Return_Profile_Hedging,
            of_return_profile_options_or_leverage: this.state.x_05050_Option_or_Leveraged_Return_Profile,
            of_return_profile_other: this.state.x_05060_Return_Profile_Other,
            of_german_pension_scheme_return_profile: this.state.x_05070_Return_Profile_Pension_Scheme_Germany,
            OF_PRIIP_Recommended_Holding_Period: this.state.x_05080_Time_Horizon,
            of_maturity_date: this.state.x_05090_Maturity_Date,
            of_early_termination_for_structured_securities: this.state.x_05100_May_Be_Terminated_Early,
            of_mifid_specific_investment_need: this.state.x_05110_Specific_Investment_Need,
            of_eligible_for_execution_only_distribution: this.state.x_06010_Execution_Only,
            of_eligible_for_execution_only_with_appropriateness_test: this.state.x_06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services,
            of_eligible_for_advised_retail_distribution: this.state.x_06030_Investment_Advice,
            of_eligible_for_portfolio_management: this.state.x_06040_Portfolio_Management,
          },
          'EMT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: 'EMT Not Saved. Code: ' + err.response.data.error.code + '\r\nMessage: ' + err.response.data.err.message,
        });
      }
    } else if (emtSection === 'CostsExAnte') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          'PRIIPS_App_EMT(' + this.state.RECORD_ID + ')',
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            of_quotation_type_for_structured_securities: this.state.x_07010_Structured_Securities_Quotation,
            of_subscription_fee_maximum: this.state.x_07020_One_off_cost_Financial_Instrument_entry_cost,
            of_italian_maximal_one_off_entry_costs: this.state.x_07030_One_off_cost_Financial_Instrument_maximum_entry_cost_fixed_amount_Italy,
            of_priips_subscription_fee_in_favour_of_fund: this.state.x_07040_One_off_cost_Financial_Instrument_maximum_entry_cost_acquired,
            of_redemption_fee_maximum: this.state.x_07050_One_off_costs_Financial_Instrument_maximum_exit_cost,
            of_italian_maximal_one_off_exit_costs: this.state.x_07060_One_off_costs_Financial_Instrument_maximum_exit_cost_fixed_amount_Italy,
            of_redemption_fee_in_favour_of_fund: this.state.x_07070_One_off_costs_Financial_Instrument_maximum_exit_cost_acquired,
            x_07080_One_off_costs_Financial_Instrument_Typical_exit_cost: this.state.x_07080_One_off_costs_Financial_Instrument_Typical_exit_cost,
            of_mifid_one_off_exit_costs_for_structured_securities: this.state.x_07090_One_off_cost_Financial_Instrument_exit_cost_structured_securities_prior_RHP,
            x_07100_Financial_Instrument_Ongoing_costs: this.state.x_07100_Financial_Instrument_Ongoing_costs,
            x_07110_Financial_Instrument_Management_fee: this.state.x_07110_Financial_Instrument_Management_fee,
            of_distribution_fee: this.state.x_07120_Financial_Instrument_Distribution_fee,
            OF_PRIIPS_Ongoing_Transaction_Costs: this.state.x_07130_Financial_Instrument_Transaction_costs_ex_ante,
            x_07140_Financial_Instrument_Incidental_costs_ex_ante: this.state.x_07140_Financial_Instrument_Incidental_costs_ex_ante,
          },
          'EMT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: 'EMT Not Saved. Code: ' + err.response.data.error.code + '\r\nMessage: ' + err.response.data.err.message,
        });
      }
    } else if (emtSection === 'CostsExPost') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          'PRIIPS_App_EMT(' + this.state.RECORD_ID + ')',
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            of_ex_post_gross_entry_costs_for_structured_securities: this.state.x_08010_One_off_cost_Structured_Securities_entry_cost_ex_post,
            of_ex_post_one_off_exit_costs_for_structured_securities: this.state.x_08020_One_off_costs_Structured_Securities_exit_cost_ex_post,
            x_08030_Financial_Instrument_Ongoing_costs_ex_post: this.state.x_08030_Financial_Instrument_Ongoing_costs_ex_post,
            of_ex_post_accumulated_ongoing_costs_for_structured_securities: this.state.x_08040_Structured_Securities_Ongoing_costs_ex_post_accumulated,
            x_08050_Financial_Instrument_Management_fee_ex_post: this.state.x_08050_Financial_Instrument_Management_fee_ex_post,
            of_ex_post_distribution_fees_as_percentage: this.state.x_08060_Financial_Instrument_Distribution_fee_ex_post,
            of_ex_post_transaction_costs_as_percentage: this.state.x_08070_Financial_Instrument_Transaction_costs_ex_post,
            of_ex_post_incidental_costs_as_percentage: this.state.x_08080_Financial_Instrument_Incidental_costs_ex_post,
            of_ex_post_distribution_fees_period_start: this.state.x_08090_Beginning_Of_Calculation_Period,
            of_ex_post_distribution_fees_period_end: this.state.x_08100_End_Of_Calculation_Period,
          },
          'EMT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: 'EMT Not Saved. Code: ' + err.response.data.error.code + '\r\nMessage: ' + err.response.data.err.message,
        });
      }
    }
  }

  handleSubmit(event, emtSection) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true, Status: 'Ready for Review' });
      this.saveEMT(emtSection);
      event.preventDefault();
      event.stopPropagation();
      // console.log('here')
    } else {
      event.preventDefault();
      this.setState({ validated: true, Status: 'Ready for Review' });
      this.saveEMT(emtSection);
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    // console.log(target.name);

    this.setState({
      [name]: value,
    });
    sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
  }

  handleSelectChange(selectedItems) {
    this.setState({ selectedItems });
  }

  disableValidation() {
    this.setState({ validated: false });
  }

  handleApprove(event) {
    const modalContent = <div><p>Approving {this.state.Class_Code} EMT...</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);

    CDATA.makeRequest(
      'POST',
      'PRIIPS_App_APPROVE_EMT_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        RecordIDList: this.state.RECORD_ID.toString(),
        UserName: this.context.state.userEmail,
      },
      'Approve failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved the EMT for Portfolio: '}
              <span>{this.state.Class_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ action_type: 'View', status: 'Approved' });
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to approve the EMT for the Portfolio:'}
              <span> {this.state.Class_Code} </span>
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  handleReject(event) {
    const modalContent = <div><p>Re Opening {this.state.Class_Code} EMT...</p></div>;
    this.context.handlers.setModal('Re Opening ...', modalContent, null, null, true);

    CDATA.makeRequest(
      'POST',
      'PRIIPS_App_REOPEN_EMT_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        RecordIDList: this.state.RECORD_ID.toString(),
        UserName: this.context.state.userEmail,
      },
      'Re-Open failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'EMT re-opened successfully for the Portfolio: '}
              <span>{this.state.Class_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ action_type: 'Edit', status: 'Ready for review' });
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to re-open the EMT for the Portfolio'}
              <span> {this.state.Class_Code} </span>
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  refreshData() {
    this.setState({ loading: false }, () => this.saveEMT());
  }

  render() {
    return (
      this.state.redirect === true ? <Redirect to="/regulatory-reporting/emt/dashboard" /> : (
        <RegEditView>
          <RegEditBody>
            {
              <>
                <Navbar style={{ backgroundColor: '#e9e8f0' }}>
                  <Nav class="nav nav-tabs" style={{ backgroundColor: '#e9e8f0', marginLeft: -15 }} onSelect={selectedKey => this.setState({ eventKey: selectedKey })} onClick={() => { this.disableValidation(); }}>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="General Info">General Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Investor">Investor</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Risk">Risk</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Objectives">Objectives</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="CostsExAnte">CostsExAnte</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="CostsExPost">CostsExPost</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Nav className="ml-auto" style={{ marginRight: '40px' }}>
                    {
                      this.state.action_type === 'Edit' ? (
                        <GButton
                          text="Approve"
                          type="Primary"
                          size="Medium"
                          onClick={() => this.handleApprove()}
                        />
                      ) : (
                        <GButton
                          text="Re-Open"
                          type="Primary"
                          size="Medium"
                          onClick={() => this.handleReject()}
                        />
                      )
                    }
                  </Nav>
                  <Nav>
                    <Navbar.Collapse className="justify-content-end">
                      <Navbar.Text>
                        {this.state.Class_Code + ' - '}
                        <a style={{ color: '#6F08CD' }}>{this.state.eventKey}</a>
                      </Navbar.Text>
                    </Navbar.Collapse>
                    {
                      this.state.loading ? <Spinner text="Loading data..." /> : (
                        this.gridApi && (
                          <Toolbar
                            api={this.gridApi}
                            working={this.state.toolbarWorking}
                            exportName={this.state.api_name}
                            controls={this.state.toolbarButtons}
                            count={{
                              total: this.state.totalRowCount,
                              showing: this.state.displayedRowCount,
                            }}
                          />
                        )
                      )
                    }
                  </Nav>
                </Navbar>
                <Row
                  style={{ display: 'flex', flexDirection: '1', flexGrow: '1' }}
                  className="no-padding"
                >
                  {
                    this.state.eventKey === 'General Info' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={e => this.handleSubmit(e, 'General')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00010_Financial_Instrument_Identifying_Data'}
                                  >
                                    Identification of the financial instrument
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00010_Financial_Instrument_Identifying_Data.controlID">
                                  <Form.Label>00010_Financial_Instrument_Identifying_Data</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#F8F8FF' : '#F8F8FF' }}
                                    name="x_00010_Financial_Instrument_Identifying_Data"
                                    value={this.state.x_00010_Financial_Instrument_Identifying_Data}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    One of the following options is required in this field: ISIN, CUSIP, SEDOL, WKN , Bloomberg Ticker, BBGID, Reuters RIC, FIGI, LEI, Other code by members of the Association of National Numbering Agencies, Code attributed by the undertaking
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00020_Type_Of_Identification_Code_For_The_Financial_Instrument'}>
                                    Codification chosen to identify the financial instrument
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00020_Type_Of_Identification_Code_For_The_Financial_Instrument.controlID">
                                  <Form.Label>00020_Type_Of_Identification_Code_For_The_Financial_Instrument</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument"
                                    value={this.state.x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: 1, 2, 3, 4, 5, 6, 7, 8, 9, 99.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00030_Financial_Instrument_Name'}>
                                    Name of the financial instrument
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00030_Financial_Instrument_Name.controlID">
                                  <Form.Label>00030_Financial_Instrument_Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00030_Financial_Instrument_Name"
                                    value={this.state.x_00030_Financial_Instrument_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00040_Financial_Instrument_Currency'}>
                                    Denomination currency of the financial instrument
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00040_Financial_Instrument_Currency.controlID">
                                  <Form.Label>00040_Financial_Instrument_Currency</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00040_Financial_Instrument_Currency"
                                    value={this.state.x_00040_Financial_Instrument_Currency}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="ISO 4217 Currency Code"
                                    pattern="(AED|AFN|ALL|AMD|ANG|AOA|ARS|AUD|AWG|AZN|BAM|BBD|BDT|BGN|BHD|BIF|BMD|BND|BOB|BOV|BRL|BSD|BTN|BWP|BYN|BZD|CAD|CDF|CHE|CHF|CHW|CLF|CLP|CNY|COP|COU|CRC|CUC|CUP|CVE|CZK|DJF|DKK|DOP|DZD|EGP|ERN|ETB|EUR|FJD|FKP|GBP|GEL|GHS|GIP|GMD|GNF|GTQ|GYD|HKD|HNL|HRK|HTG|HUF|IDR|ILS|INR|IQD|IRR|ISK|JMD|JOD|JPY|KES|KGS|KHR|KMF|KPW|KRW|KWD|KYD|KZT|LAK|LBP|LKR|LRD|LSL|LYD|MAD|MDL|MGA|MKD|MMK|MNT|MOP|MRU[13]|MUR|MVR|MWK|MXN|MXV|MYR|MZN|NAD|NGN|NIO|NOK|NPR|NZD|OMR|PAB|PEN|PGK|PHP|PKR|PLN|PYG|QAR|RON|RSD|RUB|RWF|SAR|SBD|SCR|SDG|SEK|SGD|SHP|SLL|SOS|SRD|SSP|STN[15]|SVC|SYP|SZL|THB|TJS|TMT|TND|TOP|TRY|TTD|TWD|TZS|UAH|UGX|USD|USN|UYI|UYU|UYW|UZS|VES|VND|VUV|WST|XAF|XAG|XAU|XBA|XBB|XBC|XBD|XCD|XDR|XOF|XPD|XPF|XPT|XSU|XTS|XUA|XXX|YER|ZAR|ZMW|ZWL)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be in Currency Code ISO 4217 format.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00050_Reporting_Date'}>
                                    Date to which the data refer
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00050_Reporting_Date.controlID">
                                  <Form.Label>00050_Reporting_Date</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#F8F8FF' : '#F8F8FF' }}
                                    name="x_00050_Reporting_Date"
                                    value={this.state.x_00050_Reporting_Date}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00060_Financial_Instrument_Legal_Structure'}>
                                    Structured Securities or Structured Funds or UCITS or Non UCITS
                                  </Popover>
                                  )}
                              >
                                <Form.Group controlId="00060_Financial_Instrument_Legal_Structure.controlID">
                                  <Form.Label>00060_Financial_Instrument_Legal_Structure</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00060_Financial_Instrument_Legal_Structure"
                                    value={this.state.x_00060_Financial_Instrument_Legal_Structure}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="S / SF / U / N"
                                    pattern="(S|SF|U|N)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: S,SP,U,N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00070_Financial_Instrument_Issuer_Name'}>
                                    Name of Issuer of the financial instrument. The one who is responsible for the financial instrument management/issuance
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00070_Financial_Instrument_Issuer_Name.controlID">
                                  <Form.Label>00070_Financial_Instrument_Issuer_Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00070_Financial_Instrument_Issuer_Name"
                                    value={this.state.x_00070_Financial_Instrument_Issuer_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00080_Financial_Instrument_Guarantor_Name'}>
                                    Name of Guarantor of the financial instrument.
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00080_Financial_Instrument_Guarantor_Name.controlID">
                                  <Form.Label>00080_Financial_Instrument_Guarantor_Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00080_Financial_Instrument_Guarantor_Name"
                                    value={this.state.x_00080_Financial_Instrument_Guarantor_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">

                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00090_Product_Category_or_Nature'}>
                                    Designation of the respective product category or nature
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00090_Product_Category_or_Nature.controlID">
                                  <Form.Label>00090_Product_Category_or_Nature</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00090_Product_Category_or_Nature"
                                    value={this.state.x_00090_Product_Category_or_Nature}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00100_Leveraged_Financial_Instrument_or_Contingent_Liability_Instrument'}>
                                    Yes or No
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00100_Leveraged_Financial_Instrument_or_Contingent_Liability_Instrument.controlID">
                                  <Form.Label>00100_Leveraged_Financial_Instrument_or_Contingent_Liability_Instrument</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00100_Leveraged_Financial_Instrument_or_Contingent_Liability_Instrument"
                                    value={this.state.x_00100_Leveraged_Financial_Instrument_or_Contingent_Liability_Instrument}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N"
                                    pattern="(Y|N)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: Y, N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                  <Button
                                    style={{
                                      backgroundColor: '#6F08CD',
                                      border: '1px hidden',
                                      borderRadius: '100px',
                                      letterSpacing: '1.25px',
                                      marginLeft: '5px',
                                      marginRight: '5px',
                                      minHeight: '24px',
                                      padding: '0.5rem 1.2rem',
                                    }}
                                    variant="primary"
                                    type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Investor' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={e => this.handleSubmit(e, 'Investor')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-01010_Investor_Type_Retail'}>
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01010_Investor_Type_Retail.controlID">
                                  <Form.Label>01010_Investor_Type_Retail</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01010_Investor_Type_Retail"
                                    value={this.state.x_01010_Investor_Type_Retail}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-01020_Investor_Type_Professional'}>
                                    Yes or No or Neutral or Professional Per Se or Elective Professional or Both
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01020_Investor_Type_Professional.controlID">
                                  <Form.Label>01020_Investor_Type_Professional</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01020_Investor_Type_Professional"
                                    value={this.state.x_01020_Investor_Type_Professional}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral / P / E / B"
                                    pattern="(Y|N|Neutral|P|E|B)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N, Neutral,P,E,B.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-01030_Investor_Type_Eligible_Counterparty'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01030_Investor_Type_Eligible_Counterparty.controlID">
                                  <Form.Label>01030_Investor_Type_Eligible_Counterparty</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01030_Investor_Type_Eligible_Counterparty"
                                    value={this.state.x_01030_Investor_Type_Eligible_Counterparty}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-02010_Basic_Investor '}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02010_Basic_Investor .controlID">
                                  <Form.Label>02010_Basic_Investor </Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02010_Basic_Investor "
                                    value={this.state.x_02010_Basic_Investor}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-02020_Informed_Investor'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02020_Informed_Investor.controlID">
                                  <Form.Label>02020_Informed_Investor</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02020_Informed_Investor"
                                    value={this.state.x_02020_Informed_Investor}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-02030_Advanced_Investor'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02030_Advanced_Investor.controlID">
                                  <Form.Label>02030_Advanced_Investor</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02030_Advanced_Investor"
                                    value={this.state.x_02030_Advanced_Investor}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-02040_Expert_Investor_Germany'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02040_Expert_Investor_Germany.controlID">
                                  <Form.Label>02040_Expert_Investor_Germany</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02040_Expert_Investor_Germany"
                                    value={this.state.x_02040_Expert_Investor_Germany}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary" type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Risk' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={e => this.handleSubmit(e, 'Risk')}
                        >
                          <Row>
                            <Col>

                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-03010_No_Capital_Loss'}
                                  >
                                    Investor can bear no loss of capital. Minor losses especially due to costs possible. Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03010_No_Capital_Loss.controlID">
                                  <Form.Label>03010_No_Capital_Loss</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03010_No_Capital_Loss"
                                    value={this.state.x_03010_No_Capital_Loss}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-03020_Limited_Capital_Loss'}
                                  >
                                    Investor seeking to preserve capital or can bear losses limited to a level specified by the product. Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03020_Limited_Capital_Loss.controlID">
                                  <Form.Label>03020_Limited_Capital_Loss</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03020_Limited_Capital_Loss"
                                    value={this.state.x_03020_Limited_Capital_Loss}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigge
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-03030_Limited_Capital_Loss_Level'}
                                  >
                                    Loss up to XX%
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03030_Limited_Capital_Loss_Level.controlID">
                                  <Form.Label>03030_Limited_Capital_Loss_Level</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03030_Limited_Capital_Loss_Level"
                                    value={this.state.x_03030_Limited_Capital_Loss_Level}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-03040_No_Capital_Guarantee'}
                                  >
                                    No Capital Guarantee nor protection. 100% capital at risk. Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03040_No_Capital_Guarantee.controlID">
                                  <Form.Label>03040_No_Capital_Guarantee</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03040_No_Capital_Guarantee"
                                    value={this.state.x_03040_No_Capital_Guarantee}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-03050_Loss_Beyond_Capital'}
                                  >
                                    Loss Beyond the Capital. Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03050_Loss_Beyond_Capital.controlID">
                                  <Form.Label>03050_Loss_Beyond_Capital</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03050_Loss_Beyond_Capital"
                                    value={this.state.x_03050_Loss_Beyond_Capital}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>

                            </Col>
                            <Col>

                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-04010_Risk_Tolerance_PRIIPS_Methodology'}
                                  >
                                    SRI
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04010_Risk_Tolerance_PRIIPS_Methodology.controlID">
                                  <Form.Label>04010_Risk_Tolerance_PRIIPS_Methodology</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04010_Risk_Tolerance_PRIIPS_Methodology"
                                    value={this.state.x_04010_Risk_Tolerance_PRIIPS_Methodology}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="1 - 7"
                                    pattern="[1-7]{1}"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: 1, 2, 3, 4, 5, 6, 7.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-04020_Risk_Tolerance_UCITS_Metholodology'}
                                  >
                                    SRRI
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04020_Risk_Tolerance_UCITS_Metholodology.controlID">
                                  <Form.Label>04020_Risk_Tolerance_UCITS_Metholodology</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04020_Risk_Tolerance_UCITS_Metholodology"
                                    value={this.state.x_04020_Risk_Tolerance_UCITS_Metholodology}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="1 - 7"
                                    pattern="[1-7]{1}"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: 1, 2, 3, 4, 5, 6, 7.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_and_Non_UCITS'}
                                  >
                                    Low/medium/high
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_and_Non_UCITS.controlID">
                                  <Form.Label>04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_and_Non_UCITS</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_and_Non_UCITS"
                                    value={this.state.x_04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_and_Non_UCITS}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="L / M / H"
                                    pattern="(L|M|H)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be of the following values: 1, 2, 3, 4, 5, 6.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-04040_Risk_Tolerance_For_Non_PRIIPS_and_Non_UCITS_Spain'}
                                  >
                                    Spanish SRI
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04040_Risk_Tolerance_For_Non_PRIIPS_and_Non_UCITS_Spain.controlID">
                                  <Form.Label>04040_Risk_Tolerance_For_Non_PRIIPS_and_Non_UCITS_Spain</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04040_Risk_Tolerance_For_Non_PRIIPS_and_Non_UCITS_Spain"
                                    value={this.state.x_04040_Risk_Tolerance_For_Non_PRIIPS_and_Non_UCITS_Spain}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="1 - 6"
                                    pattern="[1-6]{1}"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be of the following values: 1, 2, 3, 4, 5, 6.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany'}
                                  >
                                    Yes or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany.controlID">
                                  <Form.Label>04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany"
                                    value={this.state.x_04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / Neutral"
                                    pattern="(Y|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: Y or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Objectives' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={e => this.handleSubmit(e, 'Objectives')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05010_Return_Profile_Preservation'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05010_Return_Profile_Preservation.controlID">
                                  <Form.Label>05010_Return_Profile_Preservation</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05010_Return_Profile_Preservation"
                                    value={this.state.x_05010_Return_Profile_Preservation}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05020_Return_Profile_Growth'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05020_Return_Profile_Growth.controlID">
                                  <Form.Label>05020_Return_Profile_Growth</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05020_Return_Profile_Growth"
                                    value={this.state.x_05020_Return_Profile_Growth}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05030_Return_Profile_Income'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05030_Return_Profile_Income.controlID">
                                  <Form.Label>05030_Return_Profile_Income</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05030_Return_Profile_Income"
                                    value={this.state.x_05030_Return_Profile_Income}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05040_Return_Profile_Hedging'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05040_Return_Profile_Hedging.controlID">
                                  <Form.Label>05040_Return_Profile_Hedging</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05040_Return_Profile_Hedging"
                                    value={this.state.x_05040_Return_Profile_Hedging}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05050_Option_or_Leveraged_Return_Profile'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05050_Option_or_Leveraged_Return_Profile.controlID">
                                  <Form.Label>05050_Option_or_Leveraged_Return_Profile</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05050_Option_or_Leveraged_Return_Profile"
                                    value={this.state.x_05050_Option_or_Leveraged_Return_Profile}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05060_Return_Profile_Other'}
                                  >
                                    Yes or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05060_Return_Profile_Other.controlID">
                                  <Form.Label>05060_Return_Profile_Other</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05060_Return_Profile_Other"
                                    value={this.state.x_05060_Return_Profile_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / Neutral"
                                    pattern="(Y|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: Y or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05070_Return_Profile_Pension_Scheme_Germany'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05070_Return_Profile_Pension_Scheme_Germany.controlID">
                                  <Form.Label>05070_Return_Profile_Pension_Scheme_Germany</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05070_Return_Profile_Pension_Scheme_Germany"
                                    value={this.state.x_05070_Return_Profile_Pension_Scheme_Germany}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>

                            </Col>
                            <Col>

                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05080_Time_Horizon'}
                                  >
                                    <span>RHP in years or Very Short Term (Less Than 1Y) or Short term (Less Than 3Y) or Medium term (Less Than 5Y) or Long term (Greater Than 5Y) or Neutral</span>
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05080_Time_Horizon.controlID">
                                  <Form.Label>05080_Time_Horizon</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05080_Time_Horizon"
                                    value={this.state.x_05080_Time_Horizon}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must either be a decimal value or one of the values V, S, M, L or Neutral
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05090_Maturity_Date'}
                                  >
                                    Date of Maturity
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05090_Maturity_Date.controlID">
                                  <Form.Label>05090_Maturity_Date</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05090_Maturity_Date"
                                    value={this.state.x_05090_Maturity_Date}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05100_May_Be_Terminated_Early'}
                                  >
                                    Yes or No or Neutral
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05100_May_Be_Terminated_Early.controlID">
                                  <Form.Label>05100_May_Be_Terminated_Early</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05100_May_Be_Terminated_Early"
                                    value={this.state.x_05100_May_Be_Terminated_Early}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / Neutral"
                                    pattern="(Y|N|Neutral)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: Y,N or Neutral.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-05110_Specific_Investment_Need'}
                                  >
                                    Yes or No or (Green investment, Ethical investment, Islamic banking, ESG, Other)

                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05110_Specific_Investment_Need.controlID">
                                  <Form.Label>05110_Specific_Investment_Need</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05110_Specific_Investment_Need"
                                    value={this.state.x_05110_Specific_Investment_Need}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y / N / G / E / I / S / O"
                                    pattern="(Y|N|G|R|I|S|O)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: Y,N,G,E,I,S,O
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-06010_Execution_Only'}
                                  >
                                    Retail or Professional or Both or Neither
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06010_Execution_Only.controlID">
                                  <Form.Label>06010_Execution_Only</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06010_Execution_Only"
                                    value={this.state.x_06010_Execution_Only}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="R / P / B / N"
                                    pattern="(R|P|B|N)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: R,P,B,N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services'}
                                  >
                                    Retail or Professional or Both or Neither
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services.controlID">
                                  <Form.Label>06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services"
                                    value={this.state.x_06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="R / P / B / N"
                                    pattern="(R|P|B|N)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: R,P,B,N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-06030_Investment_Advice'}
                                  >
                                    Retail or Professional or Both or Neither
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06030_Investment_Advice.controlID">
                                  <Form.Label>06030_Investment_Advice</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06030_Investment_Advice"
                                    value={this.state.x_06030_Investment_Advice}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="R / P / B / N"
                                    pattern="(R|P|B|N)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: R,P,B,N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-06040_Portfolio_Management'}
                                  >
                                    Retail or Professional or Both or Neither
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06040_Portfolio_Management.controlID">
                                  <Form.Label>06040_Portfolio_Management</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06040_Portfolio_Management"
                                    value={this.state.x_06040_Portfolio_Management}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="R / P / B / N"
                                    pattern="(R|P|B|N)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of the following values: R,P,B,N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>

                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'CostsExAnte' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={e => this.handleSubmit(e, 'CostsExAnte')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07010_Structured_Securities_Quotation'}
                                  >
                                    Unit quotation in Units figures or Percentage quotation of the amount to be invested
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07010_Structured_Securities_Quotation.controlID">
                                  <Form.Label>07010_Structured_Securities_Quotation</Form.Label>
                                  <Form.Control
                                    required={this.state.x_00060_Financial_Instrument_Legal_Structure === 'S' ? true : false}
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07010_Structured_Securities_Quotation"
                                    value={this.state.x_07010_Structured_Securities_Quotation}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="U / P"
                                    pattern="(U|P)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required if 00060_Financial_Instrument_Legal_Structure is set to S and must be one of the following values: U,P.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07020_One-off_cost_Financial_Instrument_entry_cost'}
                                  >
                                    Maximum not acquired to the fund (ETF are excluded). Expressed as a % of the amount to be invested.
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07020_One-off_cost_Financial_Instrument_entry_cost.controlID">
                                  <Form.Label>07020_One-off_cost_Financial_Instrument_entry_cost</Form.Label>
                                  <Form.Control
                                    required={this.state.x_00060_Financial_Instrument_Legal_Structure === 'S' ? true : false}
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07020_One_off_cost_Financial_Instrument_entry_cost"
                                    value={this.state.x_07020_One_off_cost_Financial_Instrument_entry_cost}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required for Structured securities and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07030_One-off_cost_Financial_Instrument_maximum_entry_cost_fixed_amount_Italy'}
                                  >
                                    Maximum fixed amount per subscription, not incorporated. Flat fee defined by the manufacturer
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07030_One-off_cost_Financial_Instrument_maximum_entry_cost_fixed_amount_Italy.controlID">
                                  <Form.Label>07030_One-off_cost_Financial_Instrument_maximum_entry_cost_fixed_amount_Italy</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07030_One_off_cost_Financial_Instrument_maximum_entry_cost_fixed_amount_Italy"
                                    value={this.state.x_07030_One_off_cost_Financial_Instrument_maximum_entry_cost_fixed_amount_Italy}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07040_One-off_cost_Financial_Instrument_maximum_entry_cost_acquired'}
                                  >
                                    Subscription fees acquired to the fund. Expressed as a % of the amount to be invested.
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07040_One-off_cost_Financial_Instrument_maximum_entry_cost_acquired.controlID">
                                  <Form.Label>07040_One-off_cost_Financial_Instrument_maximum_entry_cost_acquired</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07040_One_off_cost_Financial_Instrument_maximum_entry_cost_acquired"
                                    value={this.state.x_07040_One_off_cost_Financial_Instrument_maximum_entry_cost_acquired}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07050_One-off_costs_Financial_Instrument_maximum_exit_cost'}
                                  >
                                    Maximum not acquired to the fund (ETF are excluded). Expressed as a % of the NAV.
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07050_One-off_costs_Financial_Instrument_maximum_exit_cost.controlID">
                                  <Form.Label>07050_One-off_costs_Financial_Instrument_maximum_exit_cost</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07050_One_off_costs_Financial_Instrument_maximum_exit_cost"
                                    value={this.state.x_07050_One_off_costs_Financial_Instrument_maximum_exit_cost}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07060_One-off_costs_Financial_Instrument_maximum_exit_cost_fixed_amount_Italy'}
                                  >
                                    Maximum fixed amount per redemption, not incorporated. Flat fee defined by the manufacturer
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07060_One-off_costs_Financial_Instrument_maximum_exit_cost_fixed_amount_Italy.controlID">
                                  <Form.Label>07060_One-off_costs_Financial_Instrument_maximum_exit_cost_fixed_amount_Italy</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07060_One_off_costs_Financial_Instrument_maximum_exit_cost_fixed_amount_Italy"
                                    value={this.state.x_07060_One_off_costs_Financial_Instrument_maximum_exit_cost_fixed_amount_Italy}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07070_One-off_costs_Financial_Instrument_maximum_exit_cost_acquired'}
                                  >
                                    Maximum (ETF are excluded). Subscription fees acquired to the fund Expressed as a % of the NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07070_One-off_costs_Financial_Instrument_maximum_exit_cost_acquired.controlID">
                                  <Form.Label>07070_One-off_costs_Financial_Instrument_maximum_exit_cost_acquired</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07070_One_off_costs_Financial_Instrument_maximum_exit_cost_acquired"
                                    value={this.state.x_07070_One_off_costs_Financial_Instrument_maximum_exit_cost_acquired}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07080_One-off_costs_Financial_Instrument_Typical_exit_cost'}
                                  >
                                    Current exit cost linked to the RHP or Time to Maturity or 1Y (V) or 3Y(S) or 5Y (M L) (the value of 05080_Time_Horizon)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07080_One-off_costs_Financial_Instrument_Typical_exit_cost.controlID">
                                  <Form.Label>07080_One-off_costs_Financial_Instrument_Typical_exit_cost</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07080_One_off_costs_Financial_Instrument_Typical_exit_cost"
                                    value={this.state.x_07080_One_off_costs_Financial_Instrument_Typical_exit_cost}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07090_One-off_cost_Financial_Instrument_exit_cost_structured_securities_prior_RHP'}
                                  >
                                    Fixed amount
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07090_One-off_cost_Financial_Instrument_exit_cost_structured_securities_prior_RHP.controlID">
                                  <Form.Label>07090_One-off_cost_Financial_Instrument_exit_cost_structured_securities_prior_RHP</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07090_One_off_cost_Financial_Instrument_exit_cost_structured_securities_prior_RHP"
                                    value={this.state.x_07090_One_off_cost_Financial_Instrument_exit_cost_structured_securities_prior_RHP}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is only used for Structured securities and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07100_Financial_Instrument_Ongoing_costs'}
                                  >
                                    Expressed as a% of NAV of the Financial Product / per annum. Ex ante
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07100_Financial_Instrument_Ongoing_costs.controlID">
                                  <Form.Label>07100_Financial_Instrument_Ongoing_costs</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07100_Financial_Instrument_Ongoing_costs"
                                    value={this.state.x_07100_Financial_Instrument_Ongoing_costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07110_Financial_Instrument_Management_fee'}
                                  >
                                    Expressed as a % of NAV of the Financial Product / per annum. Ex Ante.
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07110_Financial_Instrument_Management_fee.controlID">
                                  <Form.Label>07110_Financial_Instrument_Management_fee</Form.Label>
                                  <Form.Control
                                    required={this.state.x_00060_Financial_Instrument_Legal_Structure !== null && this.state.x_00060_Financial_Instrument_Legal_Structure !== 'S' ? true : false}
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07110_Financial_Instrument_Management_fee"
                                    value={this.state.x_07110_Financial_Instrument_Management_fee}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required for Funds and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07120_Financial_Instrument_Distribution_fee'}
                                  >
                                    Expressed as a % of NAV of the Financial Product / per annum. Ex Ante.
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07120_Financial_Instrument_Distribution_fee.controlID">
                                  <Form.Label>07120_Financial_Instrument_Distribution_fee</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07120_Financial_Instrument_Distribution_fee"
                                    value={this.state.x_07120_Financial_Instrument_Distribution_fee}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value and should not be populated for Structured securities.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07130_Financial_Instrument_Transaction_costs_ex_ante'}
                                  >
                                    Expressed as a % of NAV of the Financial Product . Ex Ante
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07130_Financial_Instrument_Transaction_costs_ex_ante.controlID">
                                  <Form.Label>07130_Financial_Instrument_Transaction_costs_ex_ante</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07130_Financial_Instrument_Transaction_costs_ex_ante"
                                    value={this.state.x_07130_Financial_Instrument_Transaction_costs_ex_ante}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required for Funds and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-07140_Financial_Instrument_Incidental_costs_ex_ante'}
                                  >
                                    Expressed as a % of NAV of the Financial Product. Includes performance fees and others costs Ex ante
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07140_Financial_Instrument_Incidental_costs_ex_ante.controlID">
                                  <Form.Label>07140_Financial_Instrument_Incidental_costs_ex_ante</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07140_Financial_Instrument_Incidental_costs_ex_ante"
                                    value={this.state.x_07140_Financial_Instrument_Incidental_costs_ex_ante}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">

                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'CostsExPost' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={e => this.handleSubmit(e, 'CostsExPost')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08010_One-off_cost_Structured_Securities_entry_cost_ex_post'}>
                                    Fixed Amount
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08010_One-off_cost_Structured_Securities_entry_cost_ex_post.controlID">
                                  <Form.Label>08010_One-off_cost_Structured_Securities_entry_cost_ex_post</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08010_One_off_cost_Structured_Securities_entry_cost_ex_post"
                                    value={this.state.x_08010_One_off_cost_Structured_Securities_entry_cost_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is only used for Structured securities and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08020_One-off_costs_Structured_Securities_exit_cost_ex_post'}
                                  >
                                    Fixed amount
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08020_One-off_costs_Structured_Securities_exit_cost_ex_post.controlID">
                                  <Form.Label>08020_One-off_costs_Structured_Securities_exit_cost_ex_post</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08020_One_off_costs_Structured_Securities_exit_cost_ex_post"
                                    value={this.state.x_08020_One_off_costs_Structured_Securities_exit_cost_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is only used for Structured securities and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08030_Financial_Instrument_Ongoing_costs_ex_post'}
                                  >
                                    Definition tbd as a % of NAV of the Financial Product / per annum. Occured costs over one year
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08030_Financial_Instrument_Ongoing_costs_ex_post.controlID">
                                  <Form.Label>08030_Financial_Instrument_Ongoing_costs_ex_post</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08030_Financial_Instrument_Ongoing_costs_ex_post"
                                    value={this.state.x_08030_Financial_Instrument_Ongoing_costs_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08040_Structured_Securities_Ongoing_costs_ex_post_accumulated'}
                                  >
                                    Sum of each daily Recurring Product Costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08040_Structured_Securities_Ongoing_costs_ex_post_accumulated.controlID">
                                  <Form.Label>08040_Structured_Securities_Ongoing_costs_ex_post_accumulated</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08040_Structured_Securities_Ongoing_costs_ex_post_accumulated"
                                    value={this.state.x_08040_Structured_Securities_Ongoing_costs_ex_post_accumulated}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is only used for Structured securities and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08050_Financial_Instrument_Management_fee_ex_post'}
                                  >
                                    Definition tbd as a % of NAV of the Financial Product / per annum. Occured costs over one year
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08050_Financial_Instrument_Management_fee_ex_post.controlID">
                                  <Form.Label>08050_Financial_Instrument_Management_fee_ex_post</Form.Label>
                                  <Form.Control
                                    required={this.state.x_00060_Financial_Instrument_Legal_Structure !== null && this.state.x_00060_Financial_Instrument_Legal_Structure !== 'S' ? true : false}
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08050_Financial_Instrument_Management_fee_ex_post"
                                    value={this.state.x_08050_Financial_Instrument_Management_fee_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required for Funds and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08060_Financial_Instrument_Distribution_fee_ex_post'}
                                  >
                                    Definition tbd as a % of NAV of the Financial Product / per annum. Occured costs over one year
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08060_Financial_Instrument_Distribution_fee_ex_post.controlID">
                                  <Form.Label>08060_Financial_Instrument_Distribution_fee_ex_post</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08060_Financial_Instrument_Distribution_fee_ex_post"
                                    value={this.state.x_08060_Financial_Instrument_Distribution_fee_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08070_Financial_Instrument_Transaction_costs_ex_post'}
                                  >
                                    Definition tbd as a % of NAV of the Financial Product .Occured costs over one year
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08070_Financial_Instrument_Transaction_costs_ex_post.controlID">
                                  <Form.Label>08070_Financial_Instrument_Transaction_costs_ex_post</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08070_Financial_Instrument_Transaction_costs_ex_post"
                                    value={this.state.x_08070_Financial_Instrument_Transaction_costs_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08080_Financial_Instrument_Incidental_costs_ex_post'}
                                  >
                                    Definition tbd as a % of NAV of the Financial Product. Occured costs over one year
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08080_Financial_Instrument_Incidental_costs_ex_post.controlID">
                                  <Form.Label>08080_Financial_Instrument_Incidental_costs_ex_post</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08080_Financial_Instrument_Incidental_costs_ex_post"
                                    value={this.state.x_08080_Financial_Instrument_Incidental_costs_ex_post}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal value.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08090_Beginning_Of_Calculation_Period'}
                                  >
                                    Date of beginning of calculation period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08090_Beginning_Of_Calculation_Period.controlID">
                                  <Form.Label>08090_Beginning_Of_Calculation_Period</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08090_Beginning_Of_Calculation_Period"
                                    value={this.state.x_08090_Beginning_Of_Calculation_Period}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-08100_End_Of_Calculation_Period'}
                                  >
                                    Date of end of calculation period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08100_End_Of_Calculation_Period.controlID">
                                  <Form.Label>08100_End_Of_Calculation_Period</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08100_End_Of_Calculation_Period"
                                    value={this.state.x_08100_End_Of_Calculation_Period}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                </Row>
              </>
            }
          </RegEditBody>
        </RegEditView>
    ));
  }
}

EMTeditor.contextType = AppContext;

export default EMTeditor;
