import React from 'react';
import GrpButton from '../../GrpButton/GrpButton';

const NavLinkButton = (props) => (
  <GrpButton
    actionType="Link"
    className="Secondary"
    size="Small"
    text={props.functionToGenerateContent(props.data)}
    path={props.functionToGenerateLink(props.data)}
  />
);

export default NavLinkButton;
