import axios from 'axios';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import config from '../config';

const { encKey } = config.sessionStorage;

const baseUrl = config.sasTokenApi.url;

class sasTokenApi {
  get(accountName, containerName, blobName, authenticationToken) {
    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));

    const method = 'POST';
    const route = '/GetSasToken';
    const body = {
        accountName,
        containerName,
        blobName
    };
    const errorMessage = `Failed to acquire  token: ${JSON.stringify(body)}.`;

    return new Promise(async (resolve, reject) => {
      let requestUrl = `${baseUrl}${route}`;

      try {
        const API = axios.create({
          baseURL: baseUrl,
          timeout: 30000, // 30 seconds
          headers: {
            'Authorization' : `Bearer `+ authenticationToken,
            'Content-Type': 'application/json;charset=utf-8'
          },
        });
        
        const response = await API.request(requestUrl, { method, data: body });

        if (response.data) {
          resolve(response.data);
        } else {
          const machineErrorMesage = `No valid response from request to route ${route}, method (${method}), body (${JSON.stringify(body)}).`;
          this.logError(appState, requestUrl, JSON.stringify(body), errorMessage, machineErrorMesage);
        }
      } catch (err) {
        this.logError(appState, requestUrl, JSON.stringify(body), errorMessage, err);
      }
    });
  }

  logError(appState, requestUrl, payload, humanErrorMessage, machineErrorMessage) {
    console.log({
      'userId': appState.userEmail,
      'clientCode': appState.clientCode,
      'requestUrl': requestUrl,
      'payload': payload,
      'humanErrorMessage': humanErrorMessage,
      'machineErrorMessage': machineErrorMessage
    });
  }

  splitStorageUrl(url) {
    var regEx = /https?:\/\/([^\.]+)\.blob\.core\.windows\.net\/([^\/\?]+)\/?([^\?]+)?/;
    var result = regEx.exec(url);
    return {
      account: result[1],
      container: result[2],
      blob: result[3]
    };
  }
}

export default new sasTokenApi();
