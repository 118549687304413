/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import CDATA from 'Services/CDATA';
import classes from './Grid.module.scss';
import AppContext from '../../../AppContext';
// import GrpSelectBox from '../../Library/GrpSelectBox/GrpSelectBox';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import 'Styles/SCSS/ag-theme-material.scss';
import GrpButton from 'Library/GrpButton/GrpButton';

class Raw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api_name: 'PRIIPS_app_RAW_TABLE_PLUS_VALIDATION_VIEW',
      approvable: this.props.approvable,
      displayedRowCount: 0,
      entity: this.props.entity,
      failed: false,
      file_id: this.props.file_id,
      file_name: this.props.file_name,
      file_url: this.props.file_url,
      loading: true,
      mergeid: this.props.mergeid,
      raw_api: this.props.raw_api,
      table_name: this.props.table_name,
      totalRowCount: 0,
      trans_batch_id: this.props.trans_batch_id,
      toolbarButtons: [
        <GrpButton onClick={this.props.approve} size="Medium" type="Primary" text="Approve" />,
        <GrpButton onClick={this.props.reject} size="Medium" type="Primary" text="Reject" />,
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: this.state.mergeid,
        },
        'Error retrieving column names and metadata.',
      );
      this.setState({ columnDefs: this.mapColumns(columns.value), rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        {
          ClientCode: this.context.state.selectedClient.code,
          FileID: this.state.file_id,
          SchemaName: 'client_di_raw',
          TableName: this.state.table_name,
          TransBatchId: `${this.state.trans_batch_id}`,
        },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => { return item.return_value !== '0'; }).length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    editable: false,
    field: x.dv_column,
    headerName: x.display_name,
    headerTooltip: `System Field Name: ${x.dv_column}`,
    hide: x.is_hidden,
    tooltipShowDelay: 0.5,
    tooltipValueGetter: params => {
      if (params.node.data) {
        let errorDesc = '';
        let errorDescArray = [];
        let tooltipText = '';
        if (params.node.data.error_specifics) {
          errorDesc = params.node.data.error_specifics;
          errorDescArray = errorDesc.split('; ');
          errorDescArray.forEach((y) => {
            if (y.toLowerCase().indexOf(x.dv_column.toLowerCase() + ': ') === 0) {
              tooltipText = y;
            }
          });
        }
        if (params.node.data.warning_specifics) {
          errorDesc = params.node.data.warning_specifics;
          errorDescArray = errorDesc.split('; ');
          errorDescArray.forEach((y) => {
            if (y.toLowerCase().indexOf(x.dv_column.toLowerCase() + ': ') === 0) {
              tooltipText = y;
            }
          });
        }
        return (tooltipText);
      }
      return null;
    },
    cellStyle: params => {
      if (params.node.data) {
        let errorFields = '';
        let errorFieldsArray = [];
        let warningFields = '';
        let warningFieldsArray = [];
        let backgroundColor = '';
        if (params.node.data.warning_fields) {
          warningFields = params.node.data.warning_fields;
          warningFields = warningFields.toLowerCase();
          warningFieldsArray = warningFields.split('; ');
          if (warningFieldsArray.includes(x.dv_column.toLowerCase())) {
            backgroundColor = '#ffff001a';
          }
        }
        if (params.node.data.error_fields) {
          errorFields = params.node.data.error_fields;
          errorFields = errorFields.toLowerCase();
          errorFieldsArray = errorFields.split('; ');
          if (errorFieldsArray.includes(x.dv_column.toLowerCase())) {
            backgroundColor = '#ff00001a';
          }
        }
        return { backgroundColor };
      }
      return null;
    },
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  externalFilterChanged = (filterVal) => {
    this.setState({ filterVal },
      () => { this.gridApi.onFilterChanged(); });
  }

  doesExternalFilterPass = (node) => {
    if (this.state.filterVal === undefined || this.state.filterVal === '1') {
      return true;
    } else if (this.state.filterVal === '2' && node.data.error_fields) {
      return true;
    } else if (this.state.filterVal === '3' && node.data.warning_fields) {
      return true;
    } else if (this.state.filterVal === '4' && (node.data.warning_fields || node.data.error_fields)) {
      return true;
    }
    return false;
  }

  deletePrompt = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>Are you sure you want to delete {listOfRows.length} row(s)?</p>
          <GrpButton onClick={() => this.deleteRecords()} size="Medium" type="Primary" text="Delete" />
          <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Delete', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to delete.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  deleteRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimRecords = listOfRows.map((row) => row.record_id.toString()).join(',');

    const modalContent = <div><p>Deleting {listOfRows.length} row(s)...</p></div>;
    this.context.handlers.setModal('Deleting ...', modalContent, null, null, true);

    CDATA.makeRequest('POST',
      'PRIIPS_app_DELETE_BULK',
      '',
      {
        RecordIDList: delimRecords,
        UserName: this.context.state.userEmail,
        UserID: this.context.state.userAzureID,
        ClientCode_bk: this.context.state.selectedClient.code,
        SchemaName: 'client_di_raw',
        TableName: this.state.table_name,
      },
      'Delete failed.')
      .then((res) => {
        this.context.handlers.toggleModal();
        const toast = {
          type: 'success',
          body: (
            <>
              You successfully deleted <span>{listOfRows.length}</span> row
              {listOfRows.length > 1 ? 's' : ''}.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.gridApi.updateRowData({ remove: listOfRows });
        this.setState({ totalRowCount: (this.state.totalRowCount - listOfRows.length) });
      })
      .catch((error) => {
        this.context.handlers.toggleModal();
        const toast = {
          type: 'error',
          body: (
            <>
              Error - something went wrong and we were not able to delete the <span>{listOfRows.length}</span> row{listOfRows.length > 1 ? 's' : ''} you had selected.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        // console.log('delete error: ',error);
      });
  }

  render() {
    return (
      <div className={classes.Grid}>
        <div className={classes.GridBody}>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        deleter={this.state.approvable ? this.deletePrompt : null}
                        controls={this.state.approvable ? this.state.toolbarButtons : null}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        externalFilterDropdown={{
                          title: 'Filter:',
                          options: [
                            { label: 'Show All Records', value: '1' },
                            { label: 'Show Records With Errors', value: '2' },
                            { label: 'Show Records With Warnings', value: '3' },
                            { label: 'Show Records With Errors or Warnings', value: '4' },
                          ],
                          defaultValue: { label: 'Show All Records', value: '1' },
                        }}
                        externalFilterChanged={this.externalFilterChanged}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                  externalFilter={this.doesExternalFilterPass}
                />
              </>
            )
          }
        </div>
      </div>
    );
  }
}

Raw.contextType = AppContext;

export default Raw;
