/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import CDATA from '../../../Services/CDATA';
import { PreviewView, PreviewBody } from './RPPreview.styles';
import AppContext from '../../../AppContext';
// import GrpSelectBox from '../../Library/GrpSelectBox/GrpSelectBox';
import GrpToolbar from '../../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../../Library/GrpGrid/GrpGrid';
import GrpSpinner from '../../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../../Components/GrpError/GrpError';
import GrpButton from '../../../Library/GrpButton/GrpButton';
// import NavlinkButton from '../../../Library/GrpGrid/NavlinkButton/NavlinkButton';
import '../../../Styles/SCSS/ag-theme-material.scss';

class RPstandardIntermediatePreview extends Component {
  constructor(props) {
    super(props);
    
    let apiName = 'PRIIPS_app_Risk_And_Performance_AssetManager_Intermediate_Preview';
    if (this.props.regulation_name === 'EPTv2' || this.props.regulation_name === 'EMTv4') {
      apiName = 'PRIIPS_app_Risk_And_Performance_AssetManager_Intermediate_Preview_RTS2';
    }

    this.state = {
      page: window.location.pathname,
      api_name: apiName,
      flexibleFlag: false,
      raw_api: this.props.raw_api,
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      regulation_name: this.props.regulation_name,
      Class_Code: this.props.Class_Code,
      toolbarButtons: [
        <GrpButton onClick={() => this.props.approve(this.state.totalRowCount)} size="Medium" type="Primary" text="Run Calculations" />,
      ]
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      this.setState({ columnDefs: this.mapColumns(columns.value), rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        page: window.location.pathname,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        {
          Client_Code: this.context.state.selectedClient.code,
          Class_Code: this.state.Class_Code,
          CalcDate: this.context.state.selectedReportingDate.code,
          IsFlexible: this.state.page === '/regulatory-reporting/preview/intermediate' ? this.state.flexibleFlag : true,
        },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() , page: window.location.pathname});
  }


  approve = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            Are you sure you want to approve {listOfRows.length} row(s)?
          </p>
          <GrpButton onClick={() => this.approveRecords()} size="Medium" type="Primary" text="Approve" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to approve.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  render() {
    return (
      <PreviewView>
        <PreviewBody>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        exportName={this.state.api_name}
                        controls={this.state.toolbarButtons}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }
                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                />
              </>
            )
          }
        </PreviewBody>
      </PreviewView>
    );
  }
}

RPstandardIntermediatePreview.contextType = AppContext;

export default RPstandardIntermediatePreview;
