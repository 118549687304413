import React from 'react';
import PropTypes from 'prop-types';
import {
  Counter, Showing, Total, Display,
} from './GrpCounter.styles';

/** GrpCounter
 * - A counter to display how many items are showing in the grid.
 */
const GrpCounter = ({ count }) => (
  <Counter>
    <Showing>
      Showing
      <Display>
        {count.showing}
      </Display>
    </Showing>
    <Total>
      of
      <Display>
        {count.total}
      </Display>
    </Total>
  </Counter>
);

export default GrpCounter;

GrpCounter.propTypes = {
  count: PropTypes.shape({
    showing: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};
