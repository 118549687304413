import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ModalWrapper, Modal, Title, Spinner, TitleText, Body, Footer,
} from './GrpModal.styles';

const GrpModal = ({
  body, bodyOverflow, handler, spinner, title, width, footer,
}) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handler();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <ModalWrapper
      // onKeyPress={() => handler()}
      tabIndex={-1}
      role="menu"
    >
      <Modal
        width={width}
        // ref={wrapperRef}
      >
        <Title>
          {
            spinner && (
              <Spinner
                icon={['fas', 'circle-notch']}
              />
            )
          }
          <TitleText>{title}</TitleText>
        </Title>
        <Body overflow={bodyOverflow}>
          {body}
        </Body>
        {
          footer && (
            <Footer>
              {footer}
            </Footer>
          )
        }
      </Modal>
    </ModalWrapper>
  );
};

GrpModal.defaultProps = {
  body: <></>,
  bodyOverflow: 'auto',
  footer: <></>,
  handler: () => {},
  spinner: true,
  title: 'Title',
};

GrpModal.propTypes = {
  body: PropTypes.element,
  bodyOverflow: PropTypes.string,
  footer: PropTypes.element,
  handler: PropTypes.func,
  spinner: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
};

export default GrpModal;
