/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Select from 'react-select';
import CDATA from 'Services/CDATA';
import {
  Button, DashboardView, DashboardBody, Error, Grid, Spinner, Toolbar,
} from './RPDashboard.styles';
import AppContext from '../../AppContext';
import NavlinkButton from 'Library/GrpGrid/NavLinkButton/NavLinkButton';
import NavLinkButtonForLanguage from 'Library/GrpGrid/NavLinkButton/NavLinkButtonForLanguage';
import 'Styles/SCSS/ag-theme-material.scss';
import { renderWithPermissions, userHasPermission } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import APIM from 'Services/APIM';
import UrlPoller from 'Library/UrlPoller/UrlPoller';

const PreviewButton = () => 'Show Preview';

const PermissionPrefix = '';

const linkData = (data, path) => ({
  pathname: path,
  classcode_bk: data.classcode_bk,
  language: data.iso_languages,
  fundcode_bk: data.fundcode_bk,
  of_full_share_class_name: data.of_full_share_class_name,
  regulation_name: 'EPT',
  action_type: 'Edit',
});

const linkDataforMultiple = (data, path, language) => ({
  pathname: path,
  classcode_bk: data.classcode_bk,
  language,
  fundcode_bk: data.fundcode_bk,
  of_full_share_class_name: data.of_full_share_class_name,
  regulation_name: 'EPT',
  action_type: 'Edit',
});

const PreviewButtonLink = (data) => (data ? linkData(data, '/regulatory-reporting/preview') : null);

// const EditButtonLink = (data) => {
//   return data ? linkData(data, '/regulatory-reporting/EPTedit'): null;
//   };

const MultipleEditButtonLink = (data, language) => (data ? linkDataforMultiple(data, '/regulatory-reporting/EPTedit', language) : null);

class RPeptDashboard extends Component {
  constructor(props) {
    super(props);
    this.PermissionPrefix = props.location.pathname.toLowerCase().endsWith('ept') ? 'dashboard/' : '';
    this.state = {
      api_name: this.props.api_name,
      displayedRowCount: 0,
      error_or_warning: 'Error',
      failed: false,
      loading: true,
      page: window.location.pathname,
      permissions: this.props.permissions,
      redirect: false,
      regulation_name: 'EPT',
      totalRowCount: 0,
      toolbarButtons: [
        renderWithPermissions(() => (
          <Button
            key={0} onClick={() => { this.create(); }} size="Medium" type="Secondary" text="Create EPT in Bulk"
          />
        ), this.PermissionPrefix + permissionTypes.create, () => <></>),
        renderWithPermissions(() => (
          <Button
            key={1} onClick={() => { this.approve(); }} size="Medium" type="Primary" text="Run Calculations"
          />
        ), this.PermissionPrefix + permissionTypes.calculations, () => <></>),
        renderWithPermissions(() => (
          <Button
            key={2}
            onClick={() => { this.populateRuleReg(); }}
            size="Medium"
            type="Secondary"
            text="Rule Regulation Population"
          />
        ), this.PermissionPrefix + permissionTypes.generate, () => <></> ),
        <Button
          onClick={() => { this.refreshData(); }}
          icon={['fas', 'sync']}
          iconSide="Round"
          size="Small"
        />,
      ],
      monitorUrl: '',
      monitorType: '',
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);

      if (this.state.api_name === 'PRIIPS_app_VALIDATION_DASHBOARD') {
        columnDefs.unshift({
          field: 'Edit',
          headerName: 'EPT Status',
          cellRendererFramework: (props) => (
           props.data.status ? (
                props.data.status === 'Not Started' ? (
                <Button
                  className="Secondary"
                  size="Small"
                  text={<div style={{ color: '#8700FF' }}>Create</div>}
                  onClick={() => { this.createEPT(props.data.classcode_bk); }}
                />
              ) : props.data.status
            ) : null
          ),
          sortable: true,
          cellClass: 'ag-cell',
          enablePivot: false,
          enableRowGroup: false,
          enableValue: false,
        });

        if (userHasPermission(this.state.permissions, `${this.PermissionPrefix}run-calculations`)) {
          columnDefs.unshift({
            field: 'Preview',
            headerName: 'Risk & Performance',
            cellRendererFramework: (props) => (
              <NavlinkButton
                {...props}
                functionToGenerateContent={PreviewButton}
                functionToGenerateLink={PreviewButtonLink}
              />
            ),
            sortable: false,
            cellClass: 'ag-cell',
            valueGetter: (props) => PreviewButton(props.data),
            enablePivot: false,
            enableRowGroup: false,
            enableValue: false,
          });
        }

        columnDefs.unshift({
          field: 'SelectRow',
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sortable: false,
          maxWidth: 45,
          resizable: false,
        });
      }

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const payload = {
        clientcode: this.context.state.selectedClient.code,
        reportingperiod: this.context.state.selectedReportingDate.code,
        ruleregulation: this.state.regulation_name,
      };
      if (this.state.api_name !== 'PRIIPS_app_VALIDATION_DASHBOARD') {
        payload.resulttodisplay = this.state.error_or_warning;
      }
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        payload,
        'Error retrieving data.',
      );

      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
      if (this.state.selectedValue) {
        const selectedValue = this.state.selectedValue;
        this.setState(selectedValue);
      }
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    autoHeight: true,
    cellStyle: {
      'whiteSpace': 'normal',
      'lineHeight': '24px',
      padding: '12px',
      maxHeight: '72px',
    },
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    editable: x.editable,
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    // this is if you want to do a default sort for a specific column
    sort: x.dv_column.toLowerCase() === 'classcode_bk' ? {
      direction: 'desc',
      priority: 0,
    } : null,
    wrapText: true,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  approve = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to run the calculation for ${listOfRows.length} row(s)?`}
          </p>
          <Button onClick={() => this.approveRecords()} size="Medium" type="Primary" text="Ok" />
          <Button
            onClick={() => this.context.handlers.toggleModal()}
            size="Medium"
            type="Primary"
            text="Cancel"
          />
        </div>
      );
      this.context.handlers.setModal('Run R&P', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to run the calculation.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  approveRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimClassCodes = listOfRows.map((row) => row.classcode_bk).join(',');
    const modalContent = <div><p>{`Running Risk and Performance Calculation for ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Running Calculation ...', modalContent, null, null, true);
    const delimCategory = listOfRows.map((row) => row.category).filter((value, index, self) => self.indexOf(value) === index).join(',');
    // console.log(delimCategory[0])
    const data = {
      procedure: 'Risk_And_Performance_AssetManager_Final_Update',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Client_Code: this.context.state.selectedClient.code,
        Class_Codes: delimClassCodes,
        CalcDate: this.context.state.selectedReportingDate.code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Class_Name: '',
        Entity: `Calculation for the ${delimCategory} regulation`,
        UPDATE: true,
      },
    };

    APIM.makeRequest(
      'calculateRiskAndPerformance',
      JSON.stringify(data),
      'Error Calculating Risk And Performance. Approve failed.',
      Auth0SessionInfo.GetSessionInfo().accessToken,
    )
      .then((res) => {
        this.context.handlers.closeModal();

        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully queued the calculation for '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri , monitorType: 'Risk and Performance Calculation'});
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              Error - something went wrong and we were not able to run the calculation for
              <span> {listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  create = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to create the EPT for ${listOfRows.length} row(s)?`}
          </p>
          <Button onClick={() => this.createEPTBulk(true)} size="Medium" type="Primary" text="Update All Fields" />
          <Button onClick={() => this.createEPTBulk(false)} size="Medium" type="Primary" text="Update Only Calculated" />
          <p style={{ paddingTop: 10 }}>
            <Button
              onClick={() => this.context.handlers.toggleModal()}
              size="Medium"
              type="Primary"
              text="Cancel"
            />
          </p>
        </div>
      );
      this.context.handlers.setModal('Create EPT', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to create the EPT.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  populateRuleReg = async () => {
    try {
      const ruleReportType = this.state.regulation_name;
      const data = {
        procedure: 'app.PopulateRuleRegulationDashboard',
        clientcode: this.context.state.selectedClient.code,
        parameters: {
          ClientCode: this.context.state.selectedClient.code,
          ReportingPeriod: this.context.state.selectedReportingDate.code,
          RuleRegulation: ruleReportType,
          FundCode: null,
          ClassCode: null
        },
      };

      APIM.makeRequest(
        'transaction',
        JSON.stringify(data),
        'Error in Rule Regulation Population for ' + ruleReportType + '.',
        Auth0SessionInfo.GetSessionInfo().accessToken,
      )
        .then((res) => {
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully queued Rule Regulation Population for ' + ruleReportType + '.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
          this.setState({ monitorUrl: res.statusQueryGetUri , monitorType: 'Rule Regulation Population for ' + ruleReportType});
          //this.refreshData();
        })
        .catch((error) => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'error',
            body: (
              <>
                Error - something went wrong and we were not able to run Rule Regulation Population
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });

    } catch(err) {
      console.log(err);
    }
  };

  handleSelect = (selectedValue) => {
    this.setState({
      error_or_warning: selectedValue.label === 'All Warnings' ? 'Warning' : 'Error',
      api_name: selectedValue.value,
      selectedValue: selectedValue,
      loading: true,
    }, () => { this.refresh(); });
  }

  createEPTBulk = (update) => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimClassCodes = listOfRows.map((row) => row.classcode_bk).join(',');
    const modalContent = <div><p>{`Creating EPT for ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Creating EPT ...', modalContent, null, null, true);

    const data = {
      procedure: 'app.Create_EPT_Bulk',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        ClientCode_bk: this.context.state.selectedClient.code,
        Reference_Date: this.context.state.selectedReportingDate.code,
        ClassCodeList: delimClassCodes,
        UserName: this.context.state.userEmail,
        UpdateAll: update,
      },
    };
    this.makeCreateEPTBulkRequest(data);
  }

  async createEPT(classcode_bk) {
    // modal saying approving data
    const modalContent = (
      <div>
        <p>{`Creating EPT for ${classcode_bk} ...`}</p>
      </div>
    );
    this.context.handlers.setModal('Creating ...', modalContent, null, null, true);
    this.context.handlers.toggleModal();

    const data = {
      procedure: 'app.Create_EPT_Bulk',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        ClassCodeList: classcode_bk,
        UserName: this.context.state.userEmail,
        ClientCode_bk: this.context.state.selectedClient.code,
        Reference_Date: this.context.state.selectedReportingDate.code,
        UpdateAll: true,
      }
    };
    this.makeCreateEPTBulkRequest(data);
  }

  async makeCreateEPTBulkRequest(parameters) {
    APIM.makeRequest('transaction', JSON.stringify(parameters), 'Error Creating EPT', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You have submitted an EPT creation request, please hold'}.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'EPT'});
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <> 
              {'Error - something went wrong and we were not able to create EPT reports at this time.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  onPollingDone() {
    this.setState({ monitorUrl: undefined });
    this.refreshData();
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  async refresh() {
    await this.setState({ loading: true }, () => this.getMetadata());
    await this.getData();
    let columns = this.gridColumnApi.getAllGridColumns()
    .filter(x => x.colDef.hide === undefined || x.colDef.hide !== true);
    for (let i = 0; i < columns.length; i++){
      if(columns[i].colId.toLowerCase().includes('created') ){
        const sortModel = [
          {colId: columns[i].colId, sort: 'desc'}
        ];
        this.gridApi.setSortModel(sortModel);
      }
    }
  }

  render() {
    return (
      <DashboardView>
        <DashboardBody>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        exportName={this.state.api_name}
                        controls={this.state.api_name === 'PRIIPS_app_VALIDATION_DASHBOARD' ? this.state.toolbarButtons : null}
                        dropdown={
                          (
                            <Select
                              isSearchable
                              options={[
                                { label: 'Share Class Dashboard', value: 'PRIIPS_app_VALIDATION_DASHBOARD' },
                                { label: 'Business Rule Dashboard', value: 'PRIIPS_app_VALIDATION_BY_RULE' },
                                { label: 'All Errors', value: 'PRIIPS_app_VALIDATION_RESULT_VIEW' },
                                { label: 'All Warnings', value: 'PRIIPS_app_VALIDATION_RESULT_VIEW' },
                              ]}
                              // components={{ Option: CustomOption }}
                              // filterOption={createFilter({ignoreAccents: false})}
                              ignoreAccents={false}
                              onChange={this.handleSelect}
                              value={ this.state.selectedValue }
                              isOptionSelected={(option, selectedValue) => {return selectedValue.some((i) => i.label == option.label)}}
                            />
                          )
                        }
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }
                <Grid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                />
              </>
            )
          }
        </DashboardBody>
        <div>
          {
            this.state.monitorUrl ? (
              <UrlPoller
                monitorUrl={this.state.monitorUrl}
                monitorType={this.state.monitorType }
                refreshIntervalSeconds={5}
                responseDisplayElementPath="$.customStatus"
                endStates={['Completed']}
                completeCallback={() => this.onPollingDone()}
              />
            ) : <></>
          }
        </div>
      </DashboardView>
    );
  }
}

RPeptDashboard.contextType = AppContext;

export default RPeptDashboard;
