import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import createAuthClient from '@auth0/auth0-spa-js';
import config from './config';
import Auth0SessionInfo from './Services/Auth0SessionInfo';
import enableSessionStorageBetweenTabs from './enableSessionStorageBetweenTabs';

enableSessionStorageBetweenTabs();

createAuthClient({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: config.auth0.callbackUrl,
  audience: config.auth0.audience,
  useRefreshTokens: true
}).then(async authClient => {
  if (/code|state|error/.test(window.location.search)) {
    await authClient.handleRedirectCallback(window.location.search);
  }
  
  const authenticated = await authClient.isAuthenticated();
  var interval = null;
  let callbackURL = "/";

  if (authenticated)
  {
    await Auth0SessionInfo.SetSessionInfo(authClient);
    interval = await Auth0SessionInfo.SetRefreshTokenInterval(authClient);
  } else {
    callbackURL = window.location.pathname !== "/callback" ? window.location.pathname : "/";
    window.localStorage.setItem("callbackURL", callbackURL)
  }

  if (!authenticated) {
    authClient.loginWithRedirect();
    return;
  }

  ReactDOM.render(
    <BrowserRouter>
      <App authClient={authClient} interval={interval}/>
    </BrowserRouter>, document.getElementById('root'));
});

// DD 5/10/2021 This comes from the newer version of PWA template
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();