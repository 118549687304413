import React from 'react';
import { faFileCsv, faFileExcel } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { Exporter, ExporterTitle, ExporterIcon } from './GrpExporter.styles';

const GrpExporter = ({ exports }) => {
  const types = [];
  // eslint-disable-next-line no-unused-vars
  for (const type of exports.types) {
    if (type === 'csv') {
      types.push(
        <ExporterIcon
          key={type}
          icon={faFileCsv}
          onClick={() => { exports.onClick('csv'); }}
          onKeyPress={() => { exports.onClick('csv'); }}
        />,
      );
    } else if (type === 'excel') {
      types.push(
        <ExporterIcon
          key={type}
          icon={faFileExcel}
          onClick={() => { exports.onClick('excel'); }}
          onKeyPress={() => { exports.onClick('excel'); }}
        />,
      );
    }
  }

  return (
    <Exporter>
      <ExporterTitle>
        Export:
      </ExporterTitle>
      {types}
    </Exporter>
  );
};

GrpExporter.propTypes = {
  exports: PropTypes.shape({
    types: PropTypes.arrayOf(
      PropTypes.oneOf(['csv', 'excel', 'pdf', 'txt']),
    ),
    onClick: PropTypes.func,
  }).isRequired,
};

export default GrpExporter;
