/* eslint-disable object-curly-newline */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classes from './GrpButton.module.scss';

const cssHelper = ({ className, size, type, iconSide, disabled }) => {
  const classNames = [classes.GrpButton];
  if (className) {
    classNames.push(className);
  }

  if (disabled === true) {
    classNames.push(classes.Disabled);
  }

  switch (type) {
    case 'Primary':
      classNames.push(classes.Primary);
      break;
    case 'Secondary':
      classNames.push(classes.Secondary);
      break;
    case 'Tertiary':
      classNames.push(classes.Tertiary);
      break;
    case 'LevelBlob':
      classNames.push(classes.LevelBlob);
      break;
    default:
      break;
  }

  switch (size) {
    case 'Small':
      classNames.push(classes.Small);
      break;
    case 'Medium':
      classNames.push(classes.Medium);
      break;
    case 'Large':
      classNames.push(classes.Large);
      break;
    default:
      break;
  }

  switch (iconSide) {
    case 'Left':
      classNames.push(classes.Left);
      break;
    case 'Right':
      classNames.push(classes.Right);
      break;
    case 'Round':
      classNames.push(classes.Round);
      break;
    case 'Group':
      classNames.push(classes.Group);
      break;
    default:
      break;
  }

  return classNames.join(' ');
};

/**
 * A control that can be used to describe a variety of Button Elements.
 * Possible variations inclde Primary and Secondary,
 * both having with-icon variations as well as Text Only, and Round.
 */
const GrpButton = ({
  actionType, activeClassName, className, disabled, icon, iconSide, onClick, path, size, text, type,
}) => {
  const passName = cssHelper({ className, size, type, iconSide, disabled });
  let content;

  switch (actionType) {
    case 'Link':
      content = (
        <Link
          onClick={onClick}
          to={path}
          className={passName}
        >
          {
            iconSide === 'Left' && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
          {text}
          {
            ((iconSide === 'Group') || (iconSide === 'Round') || (iconSide === 'Right')) && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
        </Link>
      );
      break;
    case 'NavLink':
      content = (
        <NavLink
          onClick={onClick}
          to={path}
          className={passName}
          activeClassName={activeClassName}
        >
          {
            iconSide === 'Left' && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
          {text}
          {
            ((iconSide === 'Group') || (iconSide === 'Round') || (iconSide === 'Right')) && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
        </NavLink>
      );
      break;
    case 'Submit':
      content = (
        <button
          onClick={onClick}
          onKeyPress={onClick}
          className={passName}
          tabIndex={0}
          type="submit"
        >
          {
            iconSide === 'Left' && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
          {text}
          {
            ((iconSide === 'Group') || (iconSide === 'Round') || (iconSide === 'Right')) && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
        </button>
      );
      break;
    case 'ExtLink':
      content = (
        <a
          href={path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {
            iconSide === 'Left' && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
          {text}
          {
            ((iconSide === 'Group') || (iconSide === 'Round') || (iconSide === 'Right')) && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
        </a>
      );
      break;
    default:
      content = (
        <div
          onClick={onClick}
          onKeyPress={onClick}
          className={passName}
          role="button"
          tabIndex={0}
          disabled={disabled}
        >
          {
            iconSide === 'Left' && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
          {text}
          {
            ((iconSide === 'Group') || (iconSide === 'Round') || (iconSide === 'Right')) && (
              <div className={classes.IconBox}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }
        </div>
      );
  }
  return <>{content}</>;
};

GrpButton.defaultProps = {
  actionType: '',
  activeClassName: '',
  className: '',
  disabled: null,
  iconSide: '',
  icon: [],
  onClick: () => { },
  path: '',
  size: 'Medium',
  text: '',
  type: '',
};

GrpButton.propTypes = {
  actionType: PropTypes.oneOf(['Link', 'NavLink', 'Submit', '']),
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconSide: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['Small', 'Medium', 'Large']),
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.string,
};

export default GrpButton;
