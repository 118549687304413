/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import empty from './Icons/pre_queue_icon.svg';
import half from './Icons/in_queue_icon.svg';
import full from './Icons/post_queue_icon.svg';
import red from './Icons/failed_icon.svg';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 8px;
`;
const IconBox = styled.img`
  align-items: center;
  font-size: 14px;
  height: 14px;
`;
const TextBox = styled.div`
  color: black;
  font-size: 12px;
  margin-left: 8px;
  margin=right: 8px;
  padding: 8px;
`;
const LinkBox = styled.div`
`;

const Link = styled.a`
`;
/**
 * Status Cell Renderer that displays an Icon, String, and Link
 */
class ComplexStatus extends Component {
  getIcon = (statusValue) => {
    if (statusValue === 3) return (red);
    if (statusValue === 2) return (full);
    if (statusValue === 1) return (half);
    if (statusValue === 0) return (empty);
    return (null);
  }

  render() {
    return (
      <Container>
        {
          (this.props.statusValue !== null) && (
            <IconBox
              alt="Status"
              status={this.props.statusValue}
              src={this.getIcon(this.props.statusValue)}
            />
          )
        }
        <TextBox>
          {this.props.textValue}
        </TextBox>
        {
          this.props.linkValue && (
            <LinkBox>
              <Link
                href={this.props.linkValue}
                target="_blank"
              >
                View
              </Link>
            </LinkBox>
          )
        }
      </Container>
    );
  }
}

export default ComplexStatus;

ComplexStatus.propTypes = {
  linkValue: PropTypes.string,
  statusValue: PropTypes.oneOf([0, 1, 2, 3]),
  textValue: PropTypes.string,
};
