const theme = {
  color: {
    first: '#8700FF',
    firstOpacity: 'rgba(first, 0.06)',

    second: '#2D235A',
    secondOpacity: 'rgba(second, 0.06)',

    third: '#8278AA',
    thirdDark: '#640CB6',
    thirdDarker: '#370F6E',

    status: {
      red: '#EE4266',
      green: '#2EC4B6',
      yellow: '#ECA400',
      redHover: '#b43562',
      greenHover: '#29939b',
      yellowHover: '#b27d14',
    },
    grayDarker: '#3C3C55',
    grayDark: '#57576B',
    gray: '#78738C',
    grayLight: '#BEBED2',
    grayLighter: '#DCDCE1',
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif!default',
    fontFamilyIcons: 'Font Awesome 5 Pro',
    fontSizeGeneral: '1.5rem',
    lineHeightGeneral: '2.4rem',

    titleH1FontSize: '6.4rem',
    titleH1LineHeight: '9.6rem',
    titleH1FontWeight: 600,
    titleH1MarginBottom: 0,
    titleH1MarginTop: 0,
    titleH1Color: '#8700FF', // first

    titleH2FontSize: '5.6rem',
    titleH2LineHeight: '8.8rem',
    titleH2FontWeight: 600,
    titleH2MarginBottom: 0,
    titleH2MarginTop: 0,
    titleH2Color: '#8700FF', // first

    titleH3FontSize: '4.8rem',
    titleH3LineHeight: '8rem',
    titleH3FontWeight: 500,
    titleH3MarginBottom: 0,
    titleH3MarginTop: 0,
    titleH3Color: '#8700FF', // first

    titleH4FontSize: '4rem',
    titleH4LineHeight: '7.2rem',
    titleH4FontWeight: 500,
    titleH4MarginBottom: 0,
    titleH4MarginTop: 0,
    titleH4Color: '#8700FF', // first

    titleH5FontSize: '3.2rem',
    titleH5LineHeight: '6.4rem',
    titleH5FontWeight: 500,
    titleH5MarginBottom: 0,
    titleH5MarginTop: 0,
    titleH5Color: '#8700FF', // first

    titleH6FontSize: '2.4rem',
    titleH6LineHeight: '5.6rem',
    titleH6FontWeight: 300,
    titleH6MarginBottom: 0,
    titleH6MarginTop: '3.2rem',
    titleH6Color: '#2D235A', // Second

    paragraphFontWeight: '300',
    paragraphMarginBottom: '3.2rem',
  },
  backgroundColor: '#F2F1F6',
  themeColor: '#6F08CD',
  themeColorDark: '#380F6F',
  stdtextColor: '#999',
  stdBorder: '1px solid #d5d5d5',
  headingColor: '#2d235b',
  homeheaderHeight: '84px',
  homeasideWidth: '320px',
  homesubpanelHeight: '150px',
  homeasideCollapsedWidth: '120px',
  homepanelVerticalshadow: '0 2px 15px rgba(0, 0, 0, .1)',
  homepanelVerticalreverseshadow: '0 -2px -15px rgba(0, 0, 0, .1)',
  homepanelHorizontalshadow: '2px 0 15px rgba(0, 0, 0, .1)',
  homeasideCollapsebtnHeight: '100px',
  homesubpanelBackgroundColor: '#F9FAFB',
  homedrawerWidth: '500px',
  homedrawerShadow: '-3px 0 30px rgba(0, 0, 0, .15)',
  homecontextpanelHeight: '50px',
  dashAccent: '#E9E8F0',
  dashText: '#26213C',
  font: 'Fira Sans, sans-serif',
};

export default theme;
