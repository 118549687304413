/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import APIM from 'Services/APIM';
import CDATA from 'Services/CDATA';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';
import NavlinkButton from 'Library/GrpGrid/NavLinkButton/NavLinkButton';
import BlobLinkButton from 'Library/GrpGrid/BlobLinkButton/BlobLinkButton';
import UrlPoller from 'Library/UrlPoller/UrlPoller';
import AppContext from '../../AppContext';
import {
  ApprovalQueueBody, ApprovalQueueView, Button, ButtonGroup, Error, Grid, Spinner, Toolbar,
} from './ApprovalQueue.styles';

const recordsStagedContent = (data) => ((data && data.recordsstaged) && data.recordsstaged);
const recordsNotStagedContent = (data) => (
  (data && data.recordsnotstaged) && data.recordsnotstaged
);
const validationFailuresContent = (data) => (
  (data && data.validationfailures) && data.validationfailures
);
const curatedFileName = (data) => ((data && data.curationfile) && data.curationfile);
const curatedFileURL = (data) => ((data && data.curationfileurl) && data.curationfileurl);
const originalFileName = (data) => ((data && data.originalfilename) && data.originalfilename);
const originalFileURL = (data) => ((data && data.originalfileurl) && data.originalfileurl);

const linkData = (data, path) => ({
  pathname: path, // "/import/file/raw",
  mergeid: data.record_id,
  raw_api: `PRIIPS_client_di_raw_${data.table_name}`,
  file_id: data.dv_file_id,
  table_name: data.table_name,
  trans_batch_id: data.trans_batch_id,
  entity: data.entity,
  file_name: data.curationfile ? data.curationfile : data.originalfilename,
  file_url: data.curationfileurl ? data.curationfileurl : data.originalfileurl,
  approvable: data.recordsstaged !== 0 && data.merge_status === 'Waiting for Approval' ? true : false,
});

// const rawLink = (data) => {
//   return data ? linkData(data, '/import/file/raw') : null;
// };

const rawLink = (data) => (data ? linkData(data, '/import/file/raw') : null);
const validationExceptionsLink = (data) => (data ? linkData(data, '/import/file/validation-exceptions') : null);
const rowLevelErrorsLink = (data) => (data ? linkData(data, '/import/file/row-level-errors') : null);
const rejectAction = 'app.REJECT_MERGE';
const approveAction = 'app.MERGE_TABLE';

class ApprovalQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: this.props.location.api_name ? this.props.location.api_name : this.props.api_name,
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      filterVal: 'Waiting for Approval',
      toolbarButtons: [
        <ButtonGroup type="Primary">
          <Button onClick={() => { this.takeAction(approveAction); }} size="Medium" type="Primary" text="Approve" />
          <Button onClick={() => { this.takeAction(rejectAction); }} size="Medium" type="Primary" text="Reject" />
        </ButtonGroup>,
        <Button
          onClick={() => { this.refreshData(); }}
          icon={['fas', 'sync']}
          iconSide="Round"
          size="Small"
        />,
      ],
      monitorUrl: '',
      monitorType: '',
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);
      columnDefs.unshift({
        field: 'SelectRow',
        headerName: 'Select Row',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        maxWidth: 45,
        resizable: false,
      });

      columnDefs.push({
        field: 'Records Staged',
        headerName: 'Records Staged',
        cellRendererFramework: (props) => (
          <NavlinkButton
            {...props}
            functionToGenerateContent={recordsStagedContent}
            functionToGenerateLink={rawLink}
          />
        ),
        sortable: true,
        cellClass: 'ag-cell', // (x.overflow === 'Y' ? "overflow-cell" : "ag-cell")
        comparator: ((value1, value2) => (value1 - value2)),
        valueGetter: (props) => recordsStagedContent(props.data),
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
      });

      columnDefs.push({
        field: 'Records Not Staged',
        headerName: 'Records Not Staged',
        cellRendererFramework: (props) => (
          <NavlinkButton
            {...props}
            functionToGenerateContent={recordsNotStagedContent}
            functionToGenerateLink={rowLevelErrorsLink}
          />
        ),
        sortable: true,
        cellClass: 'ag-cell',
        comparator: ((value1, value2) => (value1 - value2)),
        valueGetter: (props) => recordsNotStagedContent(props.data),
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
      });

      columnDefs.push({
        field: 'Validation Failures',
        headerName: 'Validation Failures',
        cellRendererFramework: (props) => (
          <NavlinkButton
            {...props}
            functionToGenerateContent={validationFailuresContent}
            functionToGenerateLink={validationExceptionsLink}
          />
        ),
        sortable: true,
        cellClass: 'ag-cell',
        comparator: ((value1, value2) => (value1 - value2)),
        valueGetter: (props) => validationFailuresContent(props.data),
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
      });

      columnDefs.push({
        field: 'Original File',
        headerName: 'Original File',
        cellRendererFramework: (props) => (
          <BlobLinkButton
            {...props}
            functionToGenerateContent={originalFileName}
            functionToGenerateURL={originalFileURL}
          />
        ),
        sortable: true,
        cellClass: 'ag-cell',
        valueGetter: (props) => originalFileName(props.data),
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
        width: 400,
      });

      columnDefs.push({
        field: 'Curated File',
        headerName: 'Curated File',
        cellRendererFramework: (props) => (
          <BlobLinkButton
            {...props}
            functionToGenerateContent={curatedFileName}
            functionToGenerateURL={curatedFileURL}
          />
        ),
        sortable: true,
        cellClass: 'ag-cell',
        valueGetter: (props) => curatedFileName(props.data),
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
        width: 550,
      });
      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        { ClientCode: this.context.state.selectedClient.code },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    sort: x.dv_column.toLowerCase() === 'load_datetime' ? {
      direction: 'desc',
      priority: 0,
    } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  externalFilterChanged = (filterVal) => {
    this.setState({ filterVal },
      () => { this.gridApi.onFilterChanged(); });
  }

  doesExternalFilterPass = (node) => {
    switch (this.state.filterVal) {
      case 'Waiting for Approval': return node.data.merge_status === this.state.filterVal;
      case 'Merged': return node.data.merge_status === this.state.filterVal;
      case 'Error': return node.data.merge_status === this.state.filterVal;
      case 'Rejected': return node.data.merge_status === this.state.filterVal;
      default: return true;
    }
  }

  takeAction = (action) => {
    const listOfRows = this.gridApi.getSelectedRows();
    let calcName = action === approveAction ? 'New Data Approval' : 'New Data Rejection';
    const delimRecords = listOfRows.map((row) => row.record_id.toString()).join(',');

    const data = {
      procedure: action,
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        merge_queue_ids: delimRecords,
        client_code: this.context.state.selectedClient.code,
        mail: this.context.state.userEmail,
        user: this.context.state.userAzureID,
      },
    };

    APIM.makeRequest(
      'transaction',
      JSON.stringify(data),
      'Error. Approve failed.',
      Auth0SessionInfo.GetSessionInfo().accessToken
    )
      .then((res) => {
        this.context.handlers.closeModal();

        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully submitted for '}
              {action === approveAction ? 'approval' : 'rejection'}
              <span> {listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: calcName });
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the calculation for'}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  refreshData() {
    this.setState({ loading: true, monitorUrl: undefined }, () => this.getData());
  }

  render() {
    const refreshButton = [
      <Button
        onClick={() => { this.refreshData(); }}
        icon={['fas', 'sync']}
        iconSide="Round"
        size="Small"
      />,
    ];
    const yes = () => (
      <ApprovalQueueView>
        <ApprovalQueueBody>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        controls={
                          (this.state.filterVal === 'Waiting for Approval'
                           || this.state.filterVal === 'Error') ? this.state.toolbarButtons : refreshButton
                        }
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        exportName={this.state.api_name}
                        externalFilterDropdown={{
                          title: 'Status:',
                          options: [
                            { label: 'Pending Approval', value: 'Waiting for Approval' },
                            { label: 'Approved', value: 'Merged' },
                            { label: 'Rejected', value: 'Rejected' },
                            { label: 'Errors', value: 'Error' },
                          ],
                          defaultValue: { label: 'Pending Approval', value: 'Waiting for Approval' },
                        }}
                        externalFilterChanged={this.externalFilterChanged}
                        working={this.state.toolbarWorking}
                      />
                    )
                  )
                }

                <Grid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                  externalFilter={this.doesExternalFilterPass}
                />
              </>
            )
          }
          <div />
          {
            this.state.monitorUrl ? (
              <UrlPoller
                completeCallback={this.refreshData.bind(this)}
                endStates={['Completed']}
                monitorUrl={this.state.monitorUrl}
                refreshIntervalSeconds={10}
                responseDisplayElementPath="$.customStatus"
                monitorType={this.state.monitorType}
              />
            ) : <></>
          }
        </ApprovalQueueBody>
      </ApprovalQueueView>
    );

    return renderWithPermissions(yes);
  }
}

ApprovalQueue.contextType = AppContext;

export default ApprovalQueue;
