import React from 'react';
import PropTypes from 'prop-types';
// import classes from './GrpSpinner.module.scss';
import {
  SpinnerBoxWrapper, SpinnerBox, Spinner, Path, Name,
} from './GrpSpinner.styles';

const GrpSpinner = ({ text }) => (
  <SpinnerBoxWrapper>
    <SpinnerBox>
      <Spinner
        width="40px"
        height="40px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </Spinner>
      <Name>{text}</Name>
    </SpinnerBox>
  </SpinnerBoxWrapper>
);

GrpSpinner.propTypes = {
  text: PropTypes.string.isRequired,
};

export default GrpSpinner;
