import React, { useContext } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Container, ImportFileHeader, TemplateLink } from './LoadFile.styles';
import config from '../../config';
import AppContext from '../../AppContext';
import { renderWithPermissions } from '../../Components/Auth0/permissionFunctions';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';

const uploadApiUrl = config.dip.url;

const LoadFile = () => {
  const context = useContext(AppContext);
  const getTemplate = () => {
    if (context.state.selectedClient.type === 'INS') {
      return ('../DFIN_Ins_Ingestion_Template.xlsx');
    }
    return ('../DFIN_Ingestion_Template.xlsx');
  };

  // specify upload params and url for your files
  const getUploadParams = async ({ meta, file }) => {
    let fileExtension = '';
    let fileNameOnly = '';
    const lastIndex = meta.name.lastIndexOf('.');
    if (lastIndex < 1) {
      fileExtension = '';
      fileNameOnly = meta.name;
    } else {
      fileExtension = meta.name.substr(lastIndex + 1);
      fileNameOnly = meta.name.substr(0, lastIndex);
    }
    const url = uploadApiUrl;

    const data = new FormData();
    data.append('data', file);
    data.append('filename', fileNameOnly);
    data.append('clientCode', context.state.selectedClient.code);
    data.append('extension', fileExtension);
    data.append('template', 'GFI');

    return ({
      url,
      body: data,
      method: 'POST',
      headers: {
        authorization: `Bearer ${Auth0SessionInfo.GetSessionInfo().accessToken}`,
      },
    });
  };
  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => {
  //   console.log(status, meta, file);
  // };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const yes = () => (
    <Container>
      <div>
        <ImportFileHeader>How to Import a File</ImportFileHeader>
        <p>
          {'Start with DFIN\'s '}
          <TemplateLink href={getTemplate()} download>
            Global Filing Template
          </TemplateLink>
          .  Fill it out and drag and drop below!
        </p>
      </div>
      <Dropzone
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
        getUploadParams={getUploadParams}
        inputContent={`Drag Files or Click to add to ${context.state.selectedClient.name}`}
        // onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        submitButtonContent="Clear Succesful Imports"
        styles={{
          dropzone: {
            minHeight: 300,
            maxHeight: 600,
            backgroundColor: 'rgb(255, 255, 255)',
            overflow: 'hidden',
            borderRadius: '10px',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 15px',
            marginBottom: '20px',
            borderWidth: '0px',
          },
          dropzoneActive: {
            borderColor: '#2EC4B6',
  
          },
          dropzoneReject: {
            borderColor: '#EE4266',
          },
          inputLabel: {
            userSelect: 'none', /* Standard */
            color: '#6F08CD',
            textColor: '#F2F1F6',
            fontFamily: 'Fira Sans',
          },
          inputLabelWithFiles: {
            color: '#6F08CD',
          },
          submitButton: {
            backgroundColor: '#6F08CD',
            color: '#F2F1F6',
            fontFamily: 'Fira Sans',
          },
        }}
      />
    </Container>
  );
  return renderWithPermissions(yes);
};

export default LoadFile;
