import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import GrpButton from '../../Library/GrpButton/GrpButton';
import GrpButtonGroup from '../../Library/GrpButton/GrpButtonGroup/GrpButtonGroup';
import GrpError from '../../Components/GrpError/GrpError';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpDropFile from '../../Library/GrpDropFile/GrpDropFile';

const activeClassName = 'active';

export const DashboardView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DashboardBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(GrpButton)`
  // margin-left: 7px;
`;

export const ButtonGroup = styled(GrpButtonGroup)`
`;

export const Error = styled(GrpError)``;

export const Spinner = styled(GrpSpinner)``;

export const Toolbar = styled(GrpToolbar)``;

export const Grid = styled(GrpGrid)``;

export const DropFile = styled(GrpDropFile)``;

export const LevelButton = styled(NavLink).attrs({
  activeClassName,
})`
  &.${activeClassName} {
    font-weight: 700; //"Semi-Bold"
    background-color: #e9e8f0;
    &:hover {
      background-color: #e9e8f0 !important;
    }
  }
  justify-content: center;
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 6px;
  font-size: 14px;
  margin-left: 7px;
  padding: 0 16px;
  transition: all 300ms ease-out;
  :link, :active, :hover, :visited {
    text-decoration: none;
    color: #6F08CD;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const SubLevelButton = styled(NavLink).attrs({
  activeClassName,
})`
  border-bottom-width: 2px;
  &.${activeClassName} {
    font-weight: 700;
    border-bottom-style: solid;
    &:hover {:
      color: #fff !important;
      background-color: #e9e8f0 !important;
    }
  }
  display: flex;
  letter-spacing: -0.25px;
  padding: 7px 0;
  font-size: 11px;
  font-weight: 500;

  justify-content: center;
  text-align: center;
  align-items: center;
  transition: all 300ms ease-out;
  :hover {
    border-bottom-style: solid;
  }
  :link, :active, :hover, :visited {
    text-decoration: none;
    color: #6F08CD;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;
