/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import CDATA from 'Services/CDATA';
import AppContext from '../../AppContext';
import 'Styles/SCSS/ag-theme-material.scss';
import BlobLinkButton from 'Library/GrpGrid/BlobLinkButton/BlobLinkButton';
import {
  Button, Error, Grid, GridBody, GridWrapper, Spinner, Toolbar,
} from './FileImportStatus.styles';
import statusCell from './GridComponents/statusCell';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';
import Select from 'react-select';

const curatedFileName = (data) => ((data && data.curationfile) && data.curationfile);
const curatedFileURL = (data) => ((data && data.curationfileurl) && data.curationfileurl);
const originalFileName = (data) => ((data && data.originalfilename) && data.originalfilename);
const originalFileURL = (data) => ((data && data.originalfileurl) && data.originalfileurl);

class FileImportStatus extends Component {
  frameworkComponents={
    statusCell,
  }

  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_app_File_Import_Status',
      failed: false,
      loading: true,
      totalRowCount: 0,
      toolbarButtons: [
        <Button
          onClick={() => { this.refreshData(); }}
          icon={['fas', 'sync']}
          iconSide="Round"
          size="Small"
        />
      ],
      displayedRowCount: 0,
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );

      const columnDefs = this.mapColumns(columns.value);
      columnDefs.unshift({
        cellRendererFramework: (props) => (
          <BlobLinkButton
            {...props}
            functionToGenerateContent={originalFileName}
            functionToGenerateURL={originalFileURL}
          />
        ),
        cellClass: 'ag-cell',
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
        field: 'Original File',
        headerName: 'Original File',
        maxWidth: 800,
        sortable: true,
        valueGetter: (props) => originalFileName(props.data),
      });

      columnDefs.unshift({
        cellRendererFramework: (props) => (
          <BlobLinkButton
            {...props}
            functionToGenerateContent={curatedFileName}
            functionToGenerateURL={curatedFileURL}
          />
        ),
        cellClass: 'ag-cell',
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
        field: 'Curated File',
        headerName: 'Curated File',
        maxWidth: 800,
        sortable: true,
        valueGetter: (props) => curatedFileName(props.data),
      });

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        { ClientCode: this.context.state.selectedClient.code },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ data: data.value, loading: false, totalRowCount });
      if (this.state.autoRefreshEnabled) { 
        const autoRefreshEnabled = this.state.autoRefreshEnabled; 
        this.setState( autoRefreshEnabled ); 
      }
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => {
    if((x.dv_column === 'validationerrorsexist') || (x.dv_column === 'rowlevelerrorsexist')) {
      return ({
        cellRenderer: 'statusCell',
        cellStyle: {
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        },
        editable: x.editable,
        field: x.dv_column,
        headerName: x.display_name,
        hide: x.is_hidden,
        maxWidth: 200,
      });
    }

    return ({
      comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
      editable: x.editable,
      field: x.dv_column,
      headerName: x.display_name,
      hide: x.is_hidden,
      // this is if you want to do a default sort for a specific column
      sort: x.dv_column.toLowerCase() === 'ingestiontimestamp' ? {
        direction: 'desc',
        priority: 0,
      } : null,
    })
  });

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  handleAutoRefresh = async (selectedValue) => {
    if (selectedValue.value === true) {
      this.updateTimer = setInterval(() => this.getData(), 30000);
    }
    if (selectedValue.value === false) {
      clearInterval(this.updateTimer);
    }
    this.setState({ autoRefreshEnabled: selectedValue });
  }

  render() {
    const yes = () => (
      <GridWrapper>
        <GridBody>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        controls={this.state.toolbarButtons}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        deleter={this.deletePrompt}
                        dropdown={(
                          <Select
                            defaultValue={{ label: 'Auto-Refresh Off', value: false }}
                            ignoreAccents={false}
                            onChange={this.handleAutoRefresh}
                            options={[
                              { label: 'Auto-Refresh Off', value: false },
                              { label: 'Auto-Refresh On', value: true },
                            ]}
                            value={this.state.autoRefreshEnabled}
                          />
                        )}
                        exportName={this.state.api_name}
                      />
                    )
                  )
                }
                <Grid
                  apiName={this.state.api_name}
                  columnDefs={this.state.columnDefs}
                  frameworkComponents={this.frameworkComponents}
                  onGridReady={this.onGridReady}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                  rawColumnData={this.state.rawColumns}
                />
              </>
            )
          }
        </GridBody>
      </GridWrapper>
    );
    return renderWithPermissions(yes);
  }
}

FileImportStatus.contextType = AppContext;

export default FileImportStatus;
