import React, { useContext } from 'react';
import AppContext from '../../../AppContext';
import GrpButton from '../../../Library/GrpButton/GrpButton';

const ClientButton = () => {
  const { state, handlers } = useContext(AppContext);

  return (
    <GrpButton
      type="Primary"
      size="Medium"
      text={`Change from ${state.selectedClient.name} to another client`}
      onClick={() => handlers.toggleClientSelection()}
    />
  );
};

export default ClientButton;
