import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Select = styled.div`
  height: 32px;
  //width: 100%;
  border: 1px hidden;
  border-radius: 100px;
  ${(props) => (props.disabled
    ? 'pointer-events: none;' : 'pointer-events:all;'
  )}
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  ${(props) => (props.sublevel
    ? 'background-color: #e9e8f0;' : 'background-color: #e9e8f0;'
  )}
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  :focus { outline: none; };
`;

export const Caret = styled(FontAwesomeIcon)`
  color: #6f08cd;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-right: 5px;
  margin-top: -6px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const Display = styled.div`
  height: 100%;
  display: flex;
  color: #6f08cd;
  align-items: center;
  padding: 0 20px;
  ${(props) => (props.sublevel
    && 'text-decoration: none;'
  )}
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const SelectList = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  background-color: #e9e8f0;
  border: $std-border;
  border-radius: 3px;
  padding: 15px 0;
  width: 175%;
  box-sizing: border-box;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 9999;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  ${(props) => (props.openState === 'open' && 'display: block;')}
  ${(props) => (props.sublevel === true
    ? 'background-color: #e9e8f0;' : 'background-color: #e9e8f0;'
  )}
`;

export const Item = styled.div`
  //height: 30px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #d9d9d9;
  font-size: 14px;
  transition: all 200ms ease-out;
  box-sizing: border-box;
    &:hover {
      background-color: #ffffff;
      color: #d9d9d9;
    }
  }
  // :focus {
  //   outline:none;
  // }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  ${(props) => (props.sublevel
    ? 'background-color: #e9e8f0;' : 'background-color: #e9e8f0;'
  )}
`;
