import React from 'react';

const translationStatusRenderer = (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <div>
      {cellValue}
    </div>
  );
};

export default translationStatusRenderer;
