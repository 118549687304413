/* eslint-disable class-methods-use-this */
import axios from 'axios';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import config from '../config';
import CDATA from './CDATA';

const { encKey } = config.sessionStorage;
const baseURLvar = config.apim.url;

class APIM {
  async makeRequest(api, body, errorMessage, authenticationToken) {
    let apimURL = '';
    let header = {};
    let method = '';
    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(
      CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc),
    );

    const clientCode = appState?.selectedClient?.code ?? appState.availableClientCodeAndNames[0].code;

    // eslint-disable-next-line default-case
    switch (api) {
      case 'birtPdfDelete':
          apimURL = baseURLvar + '/pdf/birtdelete';
          header = {
            'Content-Type': 'application/json',
            authorization: `Bearer ` + authenticationToken,
          };
          method = 'POST';
          break;
      case 'publishToTest':
        apimURL = baseURLvar + '/publish/publishtest';
        header = {
          'Content-Type': 'text/plain',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'publishToLive':
        apimURL = baseURLvar + '/publish/publishlive';
        header = {
          'Content-Type': 'text/plain',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'deleteFromTest':
        apimURL = baseURLvar + '/publish/deletetest';
        header = {
          'Content-Type': 'text/plain',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'RequestArrivalPricing':
        apimURL = baseURLvar + '/pricing/RequestArrivalPricing';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'generateExtract':
        apimURL = baseURLvar + '/extract/generateextract';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'generateReport':
          apimURL = baseURLvar + '/extract/generatereport';
          header = {
            'Content-Type': 'application/json',
            authorization: `Bearer ` + authenticationToken,
          };
          method = 'POST';
          break;
      case 'calculateRiskAndPerformance':
        apimURL = baseURLvar + '/extract/calculateriskandperf';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'transaction':
        apimURL = baseURLvar + '/extract/transaction';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;

      case 'simulationproducer':        
        apimURL = baseURLvar + '/simulation/simulationproducer';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
          client_code: clientCode
        };
        method = 'POST';
        break;
      case 'RegisterFtp':
        apimURL = baseURLvar + '/distribution/RegisterFtp';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
        };
        method = 'POST';
        break;
      case 'requestPdfGeneration':
        apimURL = baseURLvar + '/pdf/RequestPdfGeneration';
        header = {
          'Content-Type': 'application/json',
          authorization: `Bearer ` + authenticationToken,
          client_code: appState.selectedClient.code,
        };
        method = 'POST';
        break;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const API = axios.create({
          baseURL: apimURL,
          timeout: 900000,
          headers: header,
        });

        const response = await API.request('', { method, data: body });
        if (response.data.value) {
          resolve(response.data);
        } else if (response.status === 200 || response.status === 201 || response.status === 202) {
          resolve(response.data);
        } else {
          try {
            const errorLogResponse = await CDATA.makeRequest(
              'POST',
              'PRIIPS_logging_app_error',
              null,
              {
                ClientCode_bk: appState.selectedClient.code !== null ? appState.selectedClient.code : 'UNK',
                mail: appState.userEmail,
                app_location: window.location.pathname,
                api_action: api,
                url: `POST {method} {apimURL}`,
                action_parameters: JSON.stringify(body),
                error_message: `No valid response from request to ${apimURL} with (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
                additional_info: errorMessage,
              },
            );
            reject({
              human: errorMessage,
              errorID: errorLogResponse.data.RECORD_ID,
              machine: `No valid response from request to ${apimURL} with (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
            });
          } catch {
            reject({
              human: errorMessage,
              machine: `No valid response from request to ${apimURL} with (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
            });
          }
        }
      } catch (err) {
        try {
          const errorLogResponse = await CDATA.makeRequest(
            'POST',
            'PRIIPS_logging_app_error',
            null,
            {
              ClientCode_bk: appState.selectedClient.code !== null ? appState.selectedClient.code : 'UNK',
              mail: appState.userEmail,
              app_location: window.location.pathname,
              api_action: api,
              url: 'POST {method} {apimURL}',
              action_parameters: JSON.stringify(body),
              error_message: `No valid response from request to ${apimURL} with (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
              additional_info: errorMessage,
            },
          );
          reject({
            human: errorMessage,
            errorID: errorLogResponse.data.RECORD_ID,
            machine: err,
          });
        } catch {
          reject({
            human: errorMessage,
            machine: err,
          });
        }
      }
    });
  }
}

export default new APIM();
