/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import CDATA from 'Services/CDATA';
import { ComparisonView, ComparisonBody } from './PeriodComparison.styles';
import AppContext from '../../AppContext';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import 'Styles/SCSS/ag-theme-material.scss';

// this exists just to speed up the select dropdown by disabling mouseover events, so now it's merely shitty instead of completely unusable
// eslint-disable-next-line react/prefer-stateless-function

class PeriodComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: this.props.api_name,
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );

      this.setState({
        columnDefs: this.mapColumns(columns.value),
        rawColumns: columns.value,
      });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    this.setState({ loading: true });
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        { ClientCode_bk: this.context.state.selectedClient.code, Reference_Date: this.context.state.selectedReportingDate.code, Language: 'ENG' },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
    cellStyle(params) {
      if (params.node.data) {
        let background = '';
        if (params.node.data.columnmatch === 'TRUE') {
          background = 'rgba(255,255,0, 0.1) !important';
        } else {
          background = 'rgba(255, 0, 0, 0.1) !important';
        }
        return {
          background,
        };
      }
      return null;
    },
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  externalFilterChanged = (filterVal) => {
    this.setState({ filterVal },
      () => { this.gridApi.onFilterChanged(); });
  }

  doesExternalFilterPass = (node) => {
    switch (this.state.filterVal) {
      case 'TRUE': return node.data.columnmatch === this.state.filterVal;
      case 'FALSE': return node.data.columnmatch === this.state.filterVal;
      case 'All': return true;
      default: return true;
    }
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  render() {
    return (
      <ComparisonView>
        <ComparisonBody>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        controls={this.state.toolbarButtons}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        externalFilterDropdown={{
                          title: 'ColumnMatch:',
                          options: [
                            { label: 'All', value: 'ALL' },
                            { label: 'Same Values', value: 'TRUE' },
                            { label: 'Changed Values', value: 'FALSE' },
                          ],
                          defaultValue: { label: 'All', value: 'ALL' },
                        }}
                        externalFilterChanged={this.externalFilterChanged}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  externalFilter={this.doesExternalFilterPass}
                  pivotable
                />
              </>
            )
          }
        </ComparisonBody>
      </ComparisonView>
    );
  }
}

PeriodComparison.contextType = AppContext;

export default PeriodComparison;
