import styled from 'styled-components';
import GrpError from 'Components/GrpError/GrpError';
import GrpModal from 'Components/GrpModal/GrpModal';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpToaster from 'Library/GrpToaster/GrpToaster';

export const AppError = styled(GrpError)``;

export const AppModal = styled(GrpModal)``;

export const ClientReportingDrawer = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100%;
  justify-content: space-around;
  position: relative;
  width: 100%;
`;

export const HomeScreenHeader = styled.h1`
  font-weight: 200;
  color: rgb(45, 35, 90);
  lineHeight: 65px;
  font-size: 52px;
`;

export const LoadingSpinner = styled(GrpSpinner)``;

export const LogoutButton = styled(GrpButton)``;

export const Toaster = styled(GrpToaster)``;
