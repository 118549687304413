import React from 'react';
import ClientSelection from 'Components/HomeDrawer/DrawerSelection/ClientSelection';
import classes from './ModalClientSelection.module.scss';
import Logo from 'Images/logo_white.svg';
import Checkers from 'Images/checkers.svg';

const ModalClientSelection = () => (
  <div
    style={{
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      background: `url(${Checkers})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowX: 'hidden',
    }}
  >
    <div className={classes.Modal}>
      <img alt="DFIN" src={Logo} />
      <div className={classes.Title}>
        ArcRegulatory
      </div>
      <div className={classes.ModalPanel}>
        <ClientSelection
          panelHeight="400px"
        />
      </div>
    </div>
  </div>
);

export default ModalClientSelection;
