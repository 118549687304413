import React from 'react';
import PropTypes from 'prop-types';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import {
  Toolbar, Spinner, Left, Controls, DeleteButton, ExportControl, Counter,
} from './GrpToolbar.styles';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';

const GrpToolbar = ({
  api, controls, count, deleter, dropdown, exportName,
  externalFilterChanged, externalFilterDropdown, leftControls,
  outputColumns, outputSeparator, processCellCallback, working,
}) => {
  const isCount = count && count.showing > 0 && count.total > 0;
  // eslint-disable-next-line no-param-reassign
  count = isCount ? count : { showing: 0, total: 0 };
  const counter = isCount && <Counter count={count} />;
  const exportGrid = (format) => {
    if (format === 'csv' && !outputColumns) {
      let data = api.getDataAsCsv({
        columnSeparator: outputSeparator,
        processCellCallback,
      });
      exportCSV(data, exportName);  
    } else if (format === 'csv' && outputColumns) {
      let data = api.getDataAsCsv({
        columnSeparator: outputSeparator,
        columnKeys: outputColumns,
        processCellCallback,
      });
      exportCSV(data, exportName);
      
    } else if (format === 'excel') {
      api.exportDataAsExcel({ 
        columnKeys: outputColumns,
        fileName: exportName,
        processCellCallback,
      });
    }
  };

  const onFilterChange = (filterVal) => {
    externalFilterChanged(filterVal);
  };

  const exportCSV = (data, exportName) => {
    let csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
      //Internet Explorer
      if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(csvData, exportName);
      } else {
          //Other browsers
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(csvData);
          link.setAttribute('download', exportName);
          link.style.display = "none";
          document.body.appendChild(link);    
          link.click();
          document.body.removeChild(link);    
      };
  }

  return (
    <Toolbar>
      <Left>
        {
          working && (
            <Spinner icon={faCircleNotch} />
          )
        }
        {
          dropdown && (
            <div style={{ width: 250 }}>{dropdown}</div>
          )
        }
        {
          externalFilterDropdown && (
            <div style={{ width: 250 }}>
              <Select
                styles={{
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? '#6F08CD' : '',
                  }),
                }}
                isSearchable={false}
                options={externalFilterDropdown.options}
                defaultValue={externalFilterDropdown.defaultValue}
                onChange={(event) => onFilterChange(event.value)}
              />
            </div>
          )
        }
        {leftControls && leftControls.map((x) => x)}
      </Left>
      <Controls>
        {controls && controls.map((x) => x)}
        {
          !!deleter && renderWithPermissions(() => (
            <DeleteButton
              onClick={deleter}
              size="Medium"
              type="Primary"
              text="Delete"
            />
            ),
            'edit',
            () => <></>
          )
        }
        {
          !!exportName && (
            <ExportControl
              exports={{
                types: count.showing < 50001 ? ['csv', 'excel'] : ['csv'],
                onClick: exportGrid,
              }}
            />
          )
        }
        {counter}
      </Controls>
    </Toolbar>
  );
};


export default GrpToolbar;

GrpToolbar.defaultProps = {
  api: {
    exportDataAsCsv: null,
    exportDataAsExcel: null,
  },
  count: null,
  leftControls: null,
  controls: null,
  deleter: null,
  dropdown: null,
  exportName: '',
  externalFilterChanged: null,
  externalFilterDropdown: null,
  working: false,
  outputSeparator: ',',
};

GrpToolbar.propTypes = {
  api: PropTypes.shape({
    exportDataAsCsv: PropTypes.func,
    exportDataAsExcel: PropTypes.func,
  }),
  leftControls: PropTypes.array,
  controls: PropTypes.array,
  count: PropTypes.shape({
    showing: PropTypes.number,
    total: PropTypes.number,
  }),
  deleter: PropTypes.func,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({

    })
  ]),
  exportName: PropTypes.string,
  externalFilterChanged: PropTypes.func,
  externalFilterDropdown: PropTypes.shape({
    options: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  working: PropTypes.bool,
  outputSeparator: PropTypes.string,
  outputColumns: PropTypes.array,
};
