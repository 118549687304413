import React, { useContext } from 'react';
import styled from 'styled-components';
import AppContext from '../../AppContext';
import ArpMessage from '../../Library/ArpMessage/ArpMessage';

export const HomeContentView = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SettingsInject = styled.span`
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  margin-left: 15px;
  &:focus { 
    outline:none;
  }
`;

export const ReportingMessage = () => {
  const context = useContext(AppContext);
  return (
    <ArpMessage
      content={(
        <>
          You must select a reporting period to continue.
          <SettingsInject
            role="menuitem"
            tabIndex={0}
            onClick={() => context.handlers.injectClientReportingIntoDrawer()}
            onKeyPress={() => context.handlers.injectClientReportingIntoDrawer()}
          >
            Open settings pane
          </SettingsInject>
        </>
      )}
    />
  );
};
