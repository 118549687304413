import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import CryptoEnc from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
import CDATA from 'Services/CDATA';
import AppContext from '../../AppContext';
import TransactionCostPreview from './Sublevels/TransactionCostPreview';
import TransactionCostMonthPreview from './Sublevels/TransactionCostMonthPreview';
import TransactionCostPeriodPreview from './Sublevels/TransactionCostPeriodPreview';
import TransactionCostYearPreview from './Sublevels/TransactionCostYearPreview';
import TransactionCostFinalPreview from './Sublevels/TransactionCostFinalPreview';
import TransactionCostLookthroughPreview from './Sublevels/TransactionCostLookthroughPreview';
import TransactionCostAnnualizedPreview from './Sublevels/TransactionCostAnnualizedPreview';
import TransactionCostActualsAnnualizedPreview from './Sublevels/TransactionCostActualsAnnualizedPreview';
import TransactionCostAssetClassPreview from './Sublevels/TransactionCostAssetClassPreview';
import TransactionCostCTICalculationPreview from './Sublevels/TransactionCostCTICalculationPreview';
import config from '../../config';
import GrpHeader from 'Library/GrpHeader/GrpHeader';
import GrpButton from 'Library/GrpButton/GrpButton';
import { LevelButton, SubLevelButton, LevelBlobButton } from './TransactionCost.styles';

const { encKey } = config.sessionStorage;

class TransactionCostRouter extends Component {
  constructor(props) {
    super(props);
    let sessionState = sessionStorage.getItem('PageStateStorage');
    let propsID = this.props.location.fundcode_bk;
    if (propsID) {
      this.state = {
        page: window.location.pathname,
        api_name:
          this.props.location.regulation_name === 'DCPT' ? 'PRIIPS_app_Transaction_Costs_DCPT_Annualized_Update' :
          this.props.location.regulation_name === 'CTI' ? 'PRIIPS_app_Transaction_Costs_CTI_AssetClass_Update' :
          'PRIIPS_app_Transaction_Costs_Per_Period_Year_3Years_Update',
        api_name_transactions: 'PRIIPS_app_Transaction_Costs_Per_Transaction_Update',
        approvable: this.props.location.approvable,
        Fund_Name: this.props.location.fund_name,
        Fund_Code: this.props.location.fundcode_bk,
        regulation_name: this.props.location.regulation_name,
        redirect: false,
      };
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    }
    else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page.substring(0, 21) === '/transactions/preview') {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    }
    else {
      this.state = { redirect: true };
    }
  }

  RollUpCalculation = (onlyNew) => {
    CDATA.makeRequest(
      'POST',
      this.state.api_name,
      '',
      {
        Fund_Codes: this.state.Fund_Code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `${this.state.regulation_name} Transaction Cost Calculation`,
        Class_Code: '',
        OnlyNew: onlyNew,
      },
      'Approve failed.')
      .then(() => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully ran the all the roll up calculations for '}
              <span>{this.state.Fund_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the calculation for '}
              <span>{this.state.Fund_Code}</span>
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  approve = () => {
      const modalContent = (
        <div>
          <p>
          {`Are you sure you want to run ${this.state.regulation_name} roll up transactions for ${this.state.Fund_Code} ?`}
          </p>
          <GrpButton
            onClick={() => ( this.RollUpCalculation(false))}
            size="Medium"
            type="Primary"
            text=" Ok "
          />
          <GrpButton
            onClick={() => this.context.handlers.toggleModal()}
            size="Medium"
            type="Primary"
            text="Cancel"
          />
        </div>
      );
      this.context.handlers.setModal('', modalContent, null, null, false);
      this.context.handlers.toggleModal();
  }

  RollUpCalculationDCPTActualsAnnualized = (onlyNew) => {
    CDATA.makeRequest('POST',
      'PRIIPS_app_Transaction_Costs_DCPT_Actuals_Annualized_Update',
      '',
      {
        Fund_Codes: this.state.Fund_Code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `Actuals Annualized Transaction Cost Calculation`,
        Class_Code: '',
        OnlyNew: onlyNew,
      },
      'Approve failed.')
      .then(() => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully ran the all the roll up calculations for '}
              <span>{this.state.Fund_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the calculation for '}
              <span>{this.state.Fund_Code}</span>
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  approve2 = () => {
    const modalContent = (
      <div>
        <p>
        {`Are you sure you want to run Actuals Annualized roll up transactions for ${this.state.Fund_Code} ?`}
        </p>
        <GrpButton
          onClick={() => ( this.RollUpCalculationDCPTActualsAnnualized(false))}
          size="Medium"
          type="Primary"
          text=" Ok "
        />
        <GrpButton
          onClick={() => this.context.handlers.toggleModal()}
          size="Medium"
          type="Primary"
          text="Cancel"
        />
      </div>
    );
    this.context.handlers.setModal('', modalContent, null, null, false);
    this.context.handlers.toggleModal();
  }

  TransactionsCalculation = (onlyNew) => {
    CDATA.makeRequest('POST',
      this.state.api_name_transactions,
      '',
      {
        Fund_Codes: this.state.Fund_Code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `Underlying Transaction Cost Calculation`,
        Class_Code: '',
        OnlyNew: onlyNew,
      },
      'Approve failed.')
      .then(() => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully ran the underlying transaction calculation for '}
              <span>{this.state.Fund_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the calculation for '}
              <span>{this.state.Fund_Code}</span>
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  approveTransactions = () => {
    const modalContent = (
      <div>
        <p>
          {`Are you sure you want to run the underlying transactions for ${this.state.Fund_Code} ?`}
        </p>
        <GrpButton
          onClick={() => ( this.TransactionsCalculation(false))}
          size="Medium"
          type="Primary"
          text=" Ok "
        />
        <GrpButton
          onClick={() => this.context.handlers.toggleModal()}
          size="Medium"
          type="Primary"
          text="Cancel"
        />
      </div>
    );
    this.context.handlers.setModal('', modalContent, null, null, false);
    this.context.handlers.toggleModal();
  }

  render() {
    return (
      <>
        {
          this.state.regulation_name === 'DCPT' ? (
            <GrpHeader
              title="Transactions"
              headerItems={[
                <LevelButton
                  key={0}
                  to={{ pathname: '/transactions/dashboard/dcpt' }}
                >
                  Actuals and Annualized Dashboard
                </LevelButton>,
              ]}
              subHeaderItems={[
                <SubLevelButton
                  key={0}
                  to={{ pathname: '/transactions/preview/all-transactions' }}
                >
                  All Transactions
                </SubLevelButton>,
                <SubLevelButton
                  key={1}
                  to={{ pathname: '/transactions/preview/monthly-output' }}
                >
                  Per Month
                </SubLevelButton>,
                <SubLevelButton
                  key={2}
                  to={{ pathname: '/transactions/preview/dcpt-annualized-output' }}
                >
                  DCPT
                </SubLevelButton>,
                <SubLevelButton
                  key={3}
                  to={{ pathname: '/transactions/preview/actuals-annualized-output' }}
                >
                  Actuals and Annualized
                </SubLevelButton>,
                <SubLevelButton
                  key={4}
                  to={{ pathname: '/transactions/preview/lookthrough-dcpt-output' }}
                >
                  Lookthrough
                </SubLevelButton>,
              ]}
              fileName={(
                <LevelBlobButton
                  actionType=""
                  size="Small"
                  text={this.state.Fund_Name}
                  onClick={() => console.log('LONG LIVE HARAMBE!!!')}
                />
              )}
            />
          ) : 
          this.state.regulation_name === 'CTI' ? (
            <GrpHeader
              title="Transactions"
              headerItems={[
                <LevelButton
                  key={0}
                  to={{ pathname: '/transactions/dashboard/cti' }}
                >
                  CTI Dashboard
                </LevelButton>,
              ]}
              subHeaderItems={[
                <SubLevelButton
                  key={0}
                  to={{ pathname: '/transactions/preview/all-transactions' }}
                >
                  All Transactions
                </SubLevelButton>,
                <SubLevelButton
                  key={1}
                  to={{ pathname: '/transactions/preview/cti-assetclass' }}
                >
                  Asset Class Breakdown
                </SubLevelButton>,
                <SubLevelButton
                  key={2}
                  to={{ pathname: '/transactions/preview/cti-calculation' }}
                >
                  CTI Calculation Preview
                </SubLevelButton>,
              ]}
              fileName={(
                <LevelBlobButton
                  actionType=""
                  size="Small"
                  text={this.state.Fund_Name}
                  onClick={() => console.log('LONG LIVE HARAMBE!!!')}
                />
              )}
            />
          ) :
          (
            // default to PRIIPS/MIFID previews
            <GrpHeader
              title="Transactions"
              headerItems={[
                <LevelButton
                  key={0}
                  to={{ pathname: '/transactions/dashboard/priips' }}
                >
                  PRIIPS Methodology Dashboard
                </LevelButton>,
              ]}
              subHeaderItems={[
                <SubLevelButton
                  key={0}
                  to={{ pathname: '/transactions/preview/all-transactions' }}
                >
                  All Transactions
                </SubLevelButton>,
                <SubLevelButton
                  key={1}
                  to={{ pathname: '/transactions/preview/monthly-output' }}
                >
                  Per Month
                </SubLevelButton>,
                <SubLevelButton
                  key={2}
                  to={{ pathname: '/transactions/preview/period-output' }}
                >
                  Per Period
                </SubLevelButton>,
                <SubLevelButton
                  key={3}
                  to={{ pathname: '/transactions/preview/yearly-output' }}
                >
                  Per Year
                </SubLevelButton>,
                <SubLevelButton
                  key={4}
                  to={{ pathname: '/transactions/preview/final-output' }}
                >
                  Per 3 Year
                </SubLevelButton>,
                <SubLevelButton
                  key={5}
                  to={{ pathname: '/transactions/preview/lookthrough-output' }}
                >
                  Lookthrough
                </SubLevelButton>,
              ]}
              fileName={(
                <LevelBlobButton
                  actionType=""
                  size="Small"
                  text={this.state.Fund_Name}
                  onClick={() => console.log(this.state.Fund_Name)}
                />
              )}
            />
          )
        }
        {
          <Switch>
            <Route
              exact
              path="/transactions/preview/all-transactions"
              render={() => (
                <TransactionCostPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/monthly-output"
              render={() => (
                <TransactionCostMonthPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/period-output"
              render={() => (
                <TransactionCostPeriodPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/yearly-output"
              render={() => (
                <TransactionCostYearPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/final-output"
              render={() => (
                <TransactionCostFinalPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/lookthrough-output"
              render={() => (
                <TransactionCostLookthroughPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/dcpt-annualized-output"
              render={() => (
                <TransactionCostAnnualizedPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/actuals-annualized-output"
              render={() => (
                <TransactionCostActualsAnnualizedPreview
                  {...this.state}
                  approve={this.approve2.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/lookthrough-dcpt-output"
              render={() => (
                <TransactionCostLookthroughPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/cti-assetclass"
              render={() => (
                <TransactionCostAssetClassPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/transactions/preview/cti-calculation"
              render={() => (
                <TransactionCostCTICalculationPreview
                  {...this.state}
                  approve={this.approve.bind(this)}
                  approveTransactions={this.approveTransactions.bind(this)}
                />
              )}
            />
          </Switch>
        }
      </>
    );
  }
}

TransactionCostRouter.contextType = AppContext;

export default TransactionCostRouter;
