/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import { Route, Switch, withRouter } from 'react-router-dom';
import config from './config';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import CDATA from 'Services/CDATA';
import permissionsApi from 'Services/permissionsApi';
import ModalClientSelection from 'Layouts/ModalClientSelection';
import Outbound from 'Layouts/Routing/Outbound';
import Inbound from 'Layouts/Routing/Inbound';
import AppContext from './AppContext';
import GrpNavItem from './Library/GrpNavItem/GrpNavItem';
import classes from './App.module.scss';
import HomeAside from './Components/HomeAside/HomeAside';
import HomeDrawer from './Components/HomeDrawer/HomeDrawer';
import { DrawerDateSelect } from './Components/HomeDrawer/HomeDrawer.styles';
import {
  AppError, AppModal, ClientReportingDrawer, HomeScreenHeader, LoadingSpinner, LogoutButton,
  Toaster,
} from './App.styles';
import ClientButton from './Components/HomeDrawer/DrawerSelection/ClientButton';
// import HomeContextPanel from './Components/HomeContextPanel/HomeContextPanel';
import HomeContent from './Components/HomeContent/HomeContent';
import Callback from './Views/Auth0/Callback';
import Auth0IdleTimer from './Components/Auth0/Auth0IdleTimer';

const { encKey } = config.sessionStorage;

class App extends Component {
  authClient

  interval

  constructor(props) {
    super(props);

    library.add(fal, fas, far);
    this.authClient = props.authClient;
    this.interval = props.interval;
    let callbackURL = window.localStorage.getItem("callbackURL") || "/";
    window.localStorage.removeItem("callbackURL");

    const sessionAppState = sessionStorage.getItem('AppStateStorage');
    const { user } = Auth0SessionInfo.GetSessionInfo();
    if (sessionAppState) {
      this.state = JSON.parse(
        CryptoAES.decrypt(sessionAppState.toString(), encKey).toString(CryptoEnc),
      );
      this.state.drawerContent = null;
      this.state.drawerOpen = false;
      this.state.toast = null;
      this.state.modalContent = null;
      this.state.modalFooter = null;
      this.state.modalOpen = false;
      this.state.loading = false;
      this.state.failed = false;
      this.state.failMessage = null;
      this.state.user = user;
      this.state.userEmail = user.email;
      this.state.callbackURL = callbackURL;
      this.state.calledBack = false;
    } else {
      this.state = {
        loading: true,
        failed: false,
        failMessage: null,
        showClientSelection: false,

        userAzureID: null,
        userDisplayName: null,
        userGivenName: null,
        userSurname: null,
        userEmail: user.email,

        availableClientCodeAndNames: null,
        availableReportingDates: null,
        availableLayout: null,
        availableRoutes: null,
        availableModules: null,
        availableLevels: null,
        availableSubLevels: null,
        availableFunds: null,
        availableClasses: null,

        selectedClient: null, // code, name, type
        selectedReportingDate: null,
        selectedLayout: null,

        asideOpen: false,
        contextPanelOpen: false,
        drawerOpen: false,
        drawerContent: null,
        toast: null,

        modalOpen: false,
        modalTitle: null,
        modalContent: null,
        modalContentOverflow: null,
        modalFooter: null,
        modalSpinner: false,
        callbackURL: callbackURL,
        calledBack: false,
      };
    }
    this.handlers = {
      handleClientSelect: this.handleClientSelect,
      handleDateSelect: this.handleDateSelect,
      // toggleAsideState: this.toggleAsideState,
      // toggleContextPanel: this.toggleContextPanel,
      toggleDrawer: this.toggleDrawer,
      // toggleSubPanel: this.toggleSubPanel,
      // getCards: this.getCards,

      setSelectedClient: this.setSelectedClient,
      setSelectedReportingDate: this.setSelectedReportingDate,
      setAvailableLevels: this.setAvailableLevels,
      setAvailableSubLevels: this.setAvailableSubLevels,

      toggleAside: this.toggleAside,
      setDrawerContent: this.setDrawerContent,

      setToast: this.setToast,
      closeToast: this.closeToast,
      setModal: this.setModal,
      toggleModal: this.toggleModal,
      injectClientReportingIntoDrawer: this.injectClientReportingIntoDrawer,
      closeModal: this.closeModal,
      openModal: this.openModal,
      toggleClientSelection: this.toggleClientSelection,
    };
  }

  componentDidMount() {
    this.getUserClientData().then(async () => {
      this.setUserAzureID();
      if (this.state.availableClientCodeAndNames.length === 1) {
        await this.setSelectedClient(this.state.availableClientCodeAndNames[0]);
      }
      await this.setStateAndSession({ loading: false });
    })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  async setUserAzureID() {
    try {
      // the get user function fails if the token has expired so we get the token just in case
      const { user } = Auth0SessionInfo.GetSessionInfo();
      // Check if the Auth0 externalId is present in the users table.
      let data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_global_users',
        CDATA.addFilter(`externalId eq '${user.sub}'`),
        {},
        'Error looking up user ID. Please contact your administrator.',
      );

      if (!data.value.length) {
        // Set the Auth0 externalId for the record matching this email address.
        data = await CDATA.makeRequest(
          'POST',
          'PRIIPS_global_UPDATE_USER_EXTERNAL_ID',
          '',
          {
            mail: user.email,
            externalId: user.sub,
          },
          'Error looking up user ID. Please contact your administrator.',
        );

        // Re-poll to get user record.
        data = await CDATA.makeRequest(
          'GET',
          'PRIIPS_global_users',
          CDATA.addFilter(`externalId eq '${user.sub}'`),
          {},
          'Error looking up user ID. Please contact your administrator.',
        );
      }
      // Capture the AzureID to state.
      this.setStateAndSession({
        userAzureID: data.value[0].azure_id,
        userGivenName: data.value[0].FirstName,
        userSurname: data.value[0].LastName,
        userId: data.value[0].RECORD_ID,
      });
    } catch (err) {
      this.setStateAndSession({
        loading: false,
        failed: true,
        failMessage: err.human,
      });
    }
  }

  async getLayouts() {
    try {
      const data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_metadata_route_display',
        `${CDATA.addFilter('is_active eq \'Y\'')}&${CDATA.addOrderBy('display_order asc')}`,
        {},
        'Error loading app layout. Please contact your administrator.',
      );
      this.setStateAndSession({
        availableLayout: data.value.filter((item) => item.return_value !== '0'),
      });
    } catch (err) {
      this.setStateAndSession({
        loading: false,
        failed: true,
        failMessage: err.human,
      });
    }
  }

  async getUserClientData() {
    try {
      const auth0Session = Auth0SessionInfo.GetSessionInfo();
      const { accessToken } = auth0Session;
      const { user } = auth0Session;
      const data = await permissionsApi.getUserClients(user.email, accessToken);

      const filteredData = data.clients
        .filter((item) => item.id !== '0')
        .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

      const clientDropdownObject = filteredData.map((x) => ({
        code: x.location,
        name: x.name,
        type: x.cli_type,
      }));

      this.setStateAndSession({
        availableClientCodeAndNames: _.uniqBy(clientDropdownObject, 'code'),
      });
    } catch (err) {
      this.setStateAndSession({
        loading: false,
        failed: true,
        failMessage: err.human || err.message,
      });
      throw err;
    }
  }

  setSelectedClient = async (clientSelectionArray) => {
    if (!this.state.selectedClient || clientSelectionArray.code !== this.state.selectedClient.code) { // if no selected client or selection doesn't equal
      await this.setStateAndSession(
        (prevState) => ({
          selectedClient: {
            code: clientSelectionArray.code,
            name: clientSelectionArray.name,
            type: clientSelectionArray.type,
          },
          showClientSelection: false,
          selectedReportingDate: null,
          availableFunds: null,
          availableClasses: null,
        }),
      )
      .then(async () => {
        this.setStateAndSession({
          loading: true,
        });
        await this.getLayouts();
        this.setStateAndSession({
          availableModules: _.uniqBy(
            this.state.availableLayout
              .filter((item) => item.cli_type === clientSelectionArray.type)
              .map((x) => ({
                module: x.module,
                moduleDisplayName: x.module_display_name,
                icon: x.module_icon,
              })),
            'module',
          ),
          availableRoutes: _.uniqBy(
            this.state.availableLayout
              .filter((item) => item.cli_type === clientSelectionArray.type)
              .map((x) => ({
                full_path: `/${x.module}${x.level !== null ? `/${x.level}` : ''}${x.sublevel !== null ? `/${x.sublevel}` : ''}`,
                component: x.component,
                api_name: x.api_name,
                pk: x.pk,
                exact: x.exact,
                editable: x.editable,
                force_reportingdate: x.force_reportingdate,
              })),
            'full_path',
          ),
          selectedLayout: this.state.availableLayout
            .filter((item) => (item.cli_type === clientSelectionArray.type)
          ),
        });
        this.setStateAndSession({
          loading: false,
        });
        
        // Set reporting dates for this client.
        const reportingDates = await CDATA.makeRequest(
          'GET',
          'PRIIPS_app_client_reporting_period',
          `${CDATA.addFilter(`ClientCode_bk eq '${clientSelectionArray.code}'`)}&${CDATA.addOrderBy('Reporting_Period DESC')}`,
          {},
          'Unable to load client reporting periods.',
        );
        const availableReportingDates = reportingDates.value.map((x) => ({
          code: x.Reporting_Period ? x.Reporting_Period.substring(0, 10) : null,
          name: x.Reporting_Period ? x.Reporting_Period.substring(0, 10) : null,
          selected: false,
        }));
        this.setStateAndSession({
          availableReportingDates,
        });

        const auth0Session = Auth0SessionInfo.GetSessionInfo();
        const { accessToken } = auth0Session;
        const { user } = auth0Session;

        // Set permissions for this user and client.
        const permissionsData = await permissionsApi.getUserAppPermissions(
          user.email,
          this.state.selectedClient.code, accessToken,
        );
        const permissions = permissionsData.map((x) => x.appPermissionName);
        this.setStateAndSession({ permissions });
        if (window.location.search.includes("callback")) {
          this.setStateAndSession({ calledBack: true });
        }
        // get and store all the funds for this client if it's not insurance
        if (clientSelectionArray.type === 'AM') {
          try {
            const funds = await CDATA.makeRequest(
              'GET',
              'PRIIPS_app_fund',
              `${CDATA.addSelect('ClientCode_bk,FundCode_bk,Fund_Name')}&${CDATA.addFilter(`ClientCode_bk eq '${clientSelectionArray.code}'`)}`,
              {},
              'Error retrieving list of funds for the selected client',
            );
            const filteredFunds = funds.value.filter((item) => item.return_value !== '0');
            const availableFunds = filteredFunds.map((x) => (
              {
                value: x.FundCode_bk,
                label: `${x.Fund_Name} - ${x.FundCode_bk}`,
              }
            ));
            this.setStateAndSession({ availableFunds });

            const cliClasses = await CDATA.makeRequest(
              'GET',
              'PRIIPS_app_class',
              `${CDATA.addSelect('ClientCode_bk,ClassCode_bk,OF_Full_Share_Class_Name')}&${CDATA.addFilter(`ClientCode_bk eq '${clientSelectionArray.code}'`)}`,
              {},
              'Error retrieving list of classes for the selected client',
            );
            const filteredClasses = cliClasses.value.filter((item) => item.return_value !== '0');
            const availableClasses = filteredClasses.map((x) => ({
              value: x.ClassCode_bk,
              label: `${x.OF_Full_Share_Class_Name} - ${x.ClassCode_bk}`,
            }));
            this.setStateAndSession({ availableClasses });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err); // console.log(err.human)
          }
        }
      });
    } else {
      this.setStateAndSession({ showClientSelection: false });
    }
  }

  setSelectedReportingDate = (reportingDate) => {
    this.setStateAndSession({ selectedReportingDate: reportingDate });
    this.closeDrawer();
  }

  setAvailableLevels = (moduleName) => {
    const interimLevels = this.state.selectedLayout
      .filter((item) => (item.module === moduleName && item.level !== null))
      .map((x) => ({
        path: x.level,
        display_name: x.level_display_name,
        display_order: x.display_order,
      }));
    const levels = _.uniqBy(interimLevels, 'path');
    this.setStateAndSession({ availableLevels: levels });
  }

  setAvailableSubLevels = (moduleName, level) => {
    const interimSubLevels = this.state.selectedLayout
      .filter((item) => (
        item.module === moduleName && item.level === level && item.sublevel !== null
      ))
      .map((x) => ({
        path: x.sublevel,
        display_name: x.sublevel_display_name,
        api_name: x.api_name,
        pk: x.pk,
        display_order: x.display_order,
        parent: x.level,
        component: x.component,
      }));
    const subLevels = _.uniqBy(interimSubLevels, 'path');
    this.setStateAndSession({ availableSubLevels: subLevels });
  }

  toggleClientSelection = () => {
    this.setStateAndSession((prevState) => ({
      showClientSelection: !prevState.showClientSelection,
    }));
  };

  toggleAside = () => {
    this.setStateAndSession((prevState) => ({ asideOpen: !prevState.asideOpen }));
  };

  closeDrawer = () => {
    this.setStateAndSession({
      drawerContent: null,
      drawerOpen: false,
    });
  };

  toggleDrawer = () => {
    this.setStateAndSession((prevState) => ({
      drawerOpen: !prevState.drawerOpen,
      // if drawer was open then clear it out, otherwise let the content live
      drawerContent: prevState.drawerOpen === true ? null : prevState.drawerContent,
    }));
  };

  setToast = (toast) => {
    this.setStateAndSession({ toast });
  };

  closeToast = () => {
    const toast = { ...this.state.toast };
    toast.body = null;
    this.setStateAndSession({ toast });
  };

  setModal = (
    modalTitle, modalContent, modalContentOverflow, modalFooter, modalSpinner, modalWidth
  ) => {
    // eslint-disable-next-line no-param-reassign
    if (modalWidth === null) modalWidth = '400px';
    this.setStateAndSession({
      modalTitle, modalContent, modalContentOverflow, modalFooter, modalSpinner, modalWidth,
    });
  };

  toggleModal = () => {
    this.setStateAndSession(
      (prevState) => ({ modalOpen: !prevState.modalOpen }),
    );
  };

  closeModal = () => {
    this.setStateAndSession({ modalOpen: false });
  };

  openModal = () => {
    this.setStateAndSession({ modalOpen: true });
  };

  handleLogout = () => {
    sessionStorage.clear();
    window.localStorage.removeItem("callbackURL");
    this.authClient.logout({ redirect_uri: config.auth0.logoutRedirectUrl });
  }

  setDrawerContent = (drawerTitle, drawerContent) => {
    // Default to Client Reporting Date for drawer
    const clientDrawerTitle = 'Application Settings';

    const clientReportingDrawerContent = (
      <ClientReportingDrawer>
        {/* <DrawerClientSelect panelHeight="300px" /> */}
        <ClientButton />
        <DrawerDateSelect />
        <LogoutButton
          onClick={this.handleLogout}
          size="Medium"
          text="Logout"
          type="Primary"
        />
      </ClientReportingDrawer>
    );

    if (!drawerContent) {
      drawerContent = clientReportingDrawerContent;
      drawerTitle = clientDrawerTitle;
    }

    this.setStateAndSession({ drawerContent, drawerTitle, drawerOpen: true });
  }

  injectClientReportingIntoDrawer = () => {
    const drawerTitle = 'Application Settings';
    const drawerContent = (
      <ClientReportingDrawer>
        {/* <DrawerClientSelect panelHeight="300px" /> */}
        <ClientButton />
        <DrawerDateSelect />
        <LogoutButton
          onClick={this.handleLogout}
          size="Medium"
          text="Logout"
          type="Primary"
        />
      </ClientReportingDrawer>
    );
    // this.setDrawerContent(drawerContent);
    this.setStateAndSession({ drawerTitle, drawerContent, drawerOpen: true });
  };

  setStateAndSession = async (newStateKeys) => {
    this.setState(newStateKeys, () => {
      sessionStorage.setItem('AppStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          handlers: this.handlers,
        }}
      >
        <Auth0IdleTimer AuthClient={this.authClient} intervalFunction={this.interval} />
        {
          this.state.loading ? (<LoadingSpinner text="Loading app..." />) : (
            this.state.failed ? (<AppError text={this.state.failMessage} />) : (
              (this.state.selectedClient && !this.state.showClientSelection || window.location.pathname.includes("inbound")) ? (
                // formerly this was all in home layout
                <div className={classes.HomeLayout}>
                  <HomeAside
                    asideOpen={this.state.asideOpen}
                    toggleAside={this.handlers.toggleAside}
                  >
                    {
                      this.state.availableModules && (
                        <>
                          {
                            this.state.availableModules.map((x) => {
                              const menuItems = _.uniqBy(this.state.selectedLayout
                                .filter((item) => (
                                  item.module === x.module
                                    && item.level_display_name !== null
                                ))
                                .map((y) => ({
                                  path: `/${y.module}/${y.level}`,
                                  display: y.level_display_name,
                                })),
                                'path'
                              );

                              return (
                                <GrpNavItem
                                  activeClassName={classes.NavActive}
                                  key={x.module}
                                  icon={['fal', x.icon]}
                                  label={x.moduleDisplayName}
                                  state={this.state.asideOpen}
                                  items={menuItems}
                                  path={`/${x.module}`}
                                  visibility
                                />
                              );
                            })
                          }
                        </>
                      )
                    }
                  </HomeAside>

                  <div className={`${classes.HomeLayoutContent} ${this.state.asideOpen && classes.Open}`}>
                    <Switch>
                      <Route exact path="/">
                        <div
                          style={
                            { display: 'flex', flexGrow: '1' }
                          }
                        >
                          <div style={{ margin: 'auto', width: '75%', textAlign: 'center' }}>
                            <div>
                              <div>
                                <HomeScreenHeader>
                                  {'Welcome to ArcRegulatory'}
                                  {this.state.userGivenName && (`, ${this.state.userGivenName}`)}
                                </HomeScreenHeader>
                              </div>
                              <div>
                                <h3>Version 1.1</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Route>
                      {
                        this.state.availableModules && this.state.availableModules.map((x) => (
                          <Route key={x.module} path={`/${x.module}`}>
                            <HomeContent
                              moduleName={x.module}
                              displayName={x.moduleDisplayName}
                              availableRoutes={this.state.availableRoutes}
                              selectedReportingDate={this.state.selectedReportingDate}
                            />
                          </Route>
                        ))
                      }
                      <Route path="/callback" component={() => <Callback redirectURL={this.state.callbackURL + "?callback"} />} />
                      <Route path="/outbound" component={() => <Outbound />} />
                      <Route path="/inbound" component={() => <Inbound/>} />
                    </Switch>
                  </div>

                  <HomeDrawer
                    title={this.state.drawerTitle}
                    visible={this.state.drawerOpen}
                    toggleDrawer={this.toggleDrawer}
                  >
                    {this.state.drawerContent && this.state.drawerContent}
                  </HomeDrawer>

                  <Toaster
                    toast={this.state.toast}
                    handler={this.closeToast}
                  />
                  {
                    this.state.modalOpen && (
                      <AppModal
                        body={this.state.modalContent}
                        bodyOverflow={this.state.modalContentOverflow}
                        footer={this.state.modalFooter}
                        handler={this.toggleModal}
                        spinner={this.state.modalSpinner}
                        title={this.state.modalTitle}
                        width={this.state.modalWidth}
                      />
                    )
                  }
                </div>
              ) : <ModalClientSelection />
            )
          )
        }
      </AppContext.Provider>
    );
  }
}

export default withRouter(App);
