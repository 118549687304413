import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Exporter = styled.div`
  align-items: center;
  border-radius: 3px;
  background-color: #E9E8F0;
  border: 1px solid #e0e0e0;
  color: #2d235b;
  display: flex;
  margin-left: 7px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  height: 32px;
  padding: 0 10px;
`;

export const ExporterTitle = styled.div`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const ExporterIcon = styled(FontAwesomeIcon)`
  width: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 7px;
  color: #6F08CD;
`;
