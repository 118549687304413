import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import GrpButton from '../../Library/GrpButton/GrpButton';
import GrpSelect from '../../Library/GrpSelect/GrpSelect';

const activeClassName = 'active';
export const LevelButton = styled(NavLink).attrs({
  activeClassName,
})`
  &.${activeClassName} {
    font-weight: 700; //"Semi-Bold"
    background-color: #e9e8f0;
    &:hover {
      background-color: #e9e8f0 !important;
    }
  }
  justify-content: center;
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 6px;
  font-size: 14px;
  margin-left: 7px;
  padding: 0 16px;
  transition: all 300ms ease-out;
  :link, :active, :hover, :visited {
    text-decoration: none;
    color: #6F08CD;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const SubLevelButton = styled(NavLink).attrs({
  activeClassName,
})`
  border-bottom-width: 2px;
  &.${activeClassName} {
    font-weight: 700;
    border-bottom-style: solid;
    &:hover {:
      color: #fff !important;
      background-color: #e9e8f0 !important;
    }
  }
  display: flex;
  letter-spacing: -0.25px;
  padding: 7px 0;
  font-size: 11px;
  font-weight: 500;

  justify-content: center;
  text-align: center;
  align-items: center;
  transition: all 300ms ease-out;
  :hover {
    border-bottom-style: solid;
  }
  :link, :active, :hover, :visited {
    text-decoration: none;
    color: #6F08CD;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const SubLevelSelect = styled(GrpSelect)`
  text-decoration: none;
  background-color: red;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const ClientAndReportingDate = styled(GrpButton)`
  justify-content: right !important;
  padding-left: 0;
  display:flex;
  flex-wrap: wrap;
  margin-left: 15px;
  background-color: #fff !important;
  :hover {
    background-color: #fff !important;
    text-decoration: underline;
  };
  white-space: pre-wrap;
  text-align: right;
`;

export const Client = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #6F08CD;
  justify-content: right;
  text-align: right;
`;

export const ReportingDate = styled.div`
  page-break-before: always;
  text-align: right;
`;
