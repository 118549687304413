import React, { useContext } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Table } from 'react-bootstrap';
import GrpButton from '../../../../Library/GrpButton/GrpButton';
import AppContext from '../../../../AppContext';
import { ModalTableDiv } from '../ReportingOutput.styles';

const AuditLogModal = ({ data }) => {
    const context = useContext(AppContext);
    return (
        <div>
          {
            data.length > 0 ? (
              <ModalTableDiv>
                <div> {data.length} Result{data.length > 1 ? 's' : '' }</div>           
    
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Email Address</th>
                      <th>Date (Time)</th>
                      <th>Action</th>
                      <th>Field (Instance)</th>
                      <th>Change From</th>
                      <th>Change To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.filter((item) => item.UserId !== '0').map((x) => (
                        <tr>
                          <td>{x.UserEmailAddress}</td>
                          <td><Moment format="MM-DD-YYYY HH:mm:ss">{x.LogDateTime}</Moment></td>
                          <td>{x.ActionNm}</td>
                          <td>{x.UpdatedFieldNm}</td>
                          <td>{x.OldFieldValue}</td>
                          <td>{x.NewFieldValue}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                </ModalTableDiv>
            ) : (
              <div>
                <p>No Audit Log</p>
              </div>
            )
          }
          <div>
            <GrpButton
              text="Close"
              onClick={() => { context.handlers.closeModal(); }}
              size="Medium"
              type="Primary"
            />
          </div>
        </div>
      );
};

export default AuditLogModal;