import React from 'react';
import PropType from 'prop-types';
import {
  Header, MainHeader, SubHeader,
  HeaderLeft, HeaderTitle, HeaderCaret, HeaderRight,
} from './GrpHeader.styles';

const GrpHeader = ({
  title, headerItems, headerControls, subHeaderItems, subRoutes, fileName,
}) => (
  <Header>
    <MainHeader>
      <HeaderLeft>
        <HeaderTitle>{title}</HeaderTitle>
        {
          headerItems && headerItems.length > 0 && (
            <HeaderCaret icon={['fal', 'angle-right']} />
          )
        }
        {headerItems}
        {
          subRoutes && subRoutes.length > 0 && (
            <HeaderCaret icon={['fal', 'angle-right']} />
          )
        }
        {subRoutes}
        {
          fileName && (
            <HeaderCaret icon={['fal', 'angle-right']} />
          )
        }
        {fileName}
      </HeaderLeft>
      <HeaderRight>
        {headerControls}
      </HeaderRight>
    </MainHeader>
    {
      (subHeaderItems && subHeaderItems.length !== 0) && (
        <SubHeader>
          {subHeaderItems}
        </SubHeader>
      )
    }
  </Header>
);

GrpHeader.defaultProps = {
  title: '',
  fileName: '',
  headerItems: [],
  headerControls: <></>,
  subHeaderItems: [],
  subRoutes: [],
};

GrpHeader.propTypes = {
  title: PropType.string,
  fileName: PropType.oneOfType([
    PropType.string,
    PropType.object,
  ]),
  headerControls: PropType.oneOfType([
    PropType.element,
    PropType.arrayOf(PropType.element),
  ]),
  headerItems: PropType.arrayOf(PropType.element),
  subHeaderItems: PropType.arrayOf(PropType.element),
  subRoutes: PropType.arrayOf(PropType.element),
};

export default GrpHeader;
