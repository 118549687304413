import config from '../../config';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import React from 'react';
import propTypes from 'prop-types';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import sasTokenApi from '../../Services/sasTokenApi';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';

const GrpDropzone = ({ uploadURL, inputContent, clientCode }) => {
  // specify upload params and url for your files

  const getUploadParams = async ({ meta, file }) => {
    const { encKey } = config.sessionStorage;
    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));
    
    const url = uploadURL;
    //console.log(meta);
    //console.log(meta.name);
    //console.log(url);

    var storage = sasTokenApi.splitStorageUrl(uploadURL);
    const sasToken = await sasTokenApi.get(storage.account, storage.container, null, Auth0SessionInfo.GetSessionInfo().accessToken);

    return ({
      body: file,
      url: (clientCode ? `${url}/${clientCode}_${meta.name}` : `${url}/${meta.name}`) + '?' + sasToken,
      method: 'PUT',
      headers: {
        'x-ms-version': '2018-03-28',
        'x-ms-blob-type': 'BlockBlob',
        // 'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
      },
      // meta: {
      //   fileUrl: `${url}/${meta.name}`,
      // },
    });
  };

  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => {
  //   console.log(status, meta, file);
  // };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    // console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  return (
    <Dropzone
      // accept="application/*, text/*, image/*"
      inputContent={inputContent}
      getUploadParams={getUploadParams}
      // onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      styles={{
        dropzone: {
          minHeight: 150,
          maxHeight: 800,
          backgroundColor: 'rgb(255, 255, 255)',
          overflow: 'hidden',
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 15px',
          marginBottom: '20px',
          borderWidth: '0px',
        },
        dropzoneActive: {
          borderColor: '#2EC4B6',

        },
        dropzoneReject: {
          borderColor: '#EE4266',
        },
        inputLabel: {
          userSelect: 'none', /* Standard */
          color: '#6F08CD',
          textColor: '#F2F1F6',
          fontFamily: 'Fira Sans',
        },
        inputLabelWithFiles: {
          color: '#6F08CD',
        },
        preview: {
          div: {
            color: 'green',
            backgroundColor: 'green',
          },
        },
        submitButton: {
          backgroundColor: '#6F08CD',
          color: '#F2F1F6',
        },
      }}
    />
  );
};

GrpDropzone.defaultProps = {
  uploadURL: 'https://httpbin.org/post',
};

GrpDropzone.propTypes = {
  uploadURL: propTypes.string,
};

export default GrpDropzone;
