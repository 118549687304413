import React, { useContext } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Table } from 'react-bootstrap';
import GrpButton from '../../../../Library/GrpButton/GrpButton';
import AppContext from '../../../../AppContext';
import { ModalTableDiv } from '../ReportingOutput.styles';


const VersionHistoryModal = ({ data }) => {
    const context = useContext(AppContext);
    return (
        <div>
          {
            data.length > 0 ? (
              <ModalTableDiv>
                <div> {data.length} Result{data.length > 1 ? 's' : '' }</div>   
    
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Date (Time)</th>
                      <th>Location</th>
                      <th>File Name</th>
                      <th>Status Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.filter((item) => item.DocumentId !== '0').map((x) => (
                        <tr>
                          <td><Moment format="MM-DD-YYYY HH:mm:ss">{x.CreatedDateTime}</Moment></td>
                          <td>{x.Location}</td>
                          <td>{x.ArtifactFileName}</td>
                          <td>{x.StatusMessage === null ? "No Data" : x.StatusMessage}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </ModalTableDiv>
            ) : (
              <div>
                <p>No Version History Found</p>
              </div>
            )
          }
          <div>
            <GrpButton
              text="Close"
              onClick={() => { context.handlers.closeModal(); }}
              size="Medium"
              type="Primary"
            />
          </div>
        </div>
      );
};

export default VersionHistoryModal;