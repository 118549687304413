import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import range from 'lodash/range';
import GrpButton from 'Library/GrpButton/GrpButton';
import AppContext from 'AppContext';
import APIM from 'Services/APIM';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import 'react-datepicker/dist/react-datepicker.css';

class MarketDataModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleFromChangedate = this.handleFromChangedate.bind(this);
    this.handleToChangedate = this.handleToChangedate.bind(this);
  }

  componentDidMount() {
    let selectedReportingPeriod = new Date(this.context.state.selectedReportingDate.code);
    selectedReportingPeriod = new Date(
      selectedReportingPeriod.getTime() - selectedReportingPeriod.getTimezoneOffset() * -60000,
    );
    this.setState({
      fromDate: this.findPriorReportingPeriod(),
      toDate: selectedReportingPeriod,
    });
  }

  handleFromChangedate(date) {
    this.setState({ fromDate: date });
  }

  handleToChangedate(date) {
    this.setState({ toDate: date });
  }

  async requestArrivalPricing() {
    const listOfRows = this.props.rows;// this.gridApi.getSelectedRows();
    const listOfFunds = [];
    const modalContent = <div><p>{`Sending market data request for ${await listOfRows.length} row${await listOfRows.length === 1 ? '' : 's'}...`}</p></div>;
    this.context.handlers.setModal('Processing ...', modalContent, null, null, true);
    listOfRows.forEach((row) => {
      listOfFunds.push(row.fundcode_bk);
    });

    if (listOfRows.length > 0) {
      const apimData = {
        ClientCode: this.context.state.selectedClient.code,
        FundCodes: listOfFunds,
        StartDate: this.state.fromDate.toLocaleString('en-us', { month: 'numeric', day: 'numeric', year: 'numeric' }),
        EndDate: this.state.toDate.toLocaleString('en-us', { month: 'numeric', day: 'numeric', year: 'numeric' }),
        UserEmailAddress: this.context.state.userEmail,
        User: this.context.state.userAzureID,
      };

      APIM.makeRequest(
        'RequestArrivalPricing',
        JSON.stringify(apimData),
        'Error Publishing PDF To Live Site',
        Auth0SessionInfo.GetSessionInfo().accessToken,
      )
        .then(() => { // res
          this.context.handlers.closeModal();
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully submitted a market data pricing request for '}
                <span>{listOfRows.length}</span>
                {' fund'}
                {listOfRows.length > 1 ? 's' : ''}
                .
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        })
        .catch(() => { // error
          this.context.handlers.closeModal();
          const toast = {
            type: 'error',
            body: (
              <>
                {'Error - something went wrong and we were not able to send the pricing request for '}
                <span>{listOfRows.length}</span>
                {' row'}
                {listOfRows.length > 1 ? 's' : ''}
                {' you had selected.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });
    } else {
      const toast = {
        type: 'error',
        body: (<>No funds were selected.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  findPriorReportingPeriod() {
    let selectedReportingPeriod = new Date(this.context.state.selectedReportingDate.code);
    selectedReportingPeriod = new Date(
      selectedReportingPeriod.getTime() - selectedReportingPeriod.getTimezoneOffset() * -60000
    );
    const availableDates = this.context.state.availableReportingDates.map((x) => (
      new Date(x.code)
    )).map((x) => new Date(x.getTime() - x.getTimezoneOffset() * -60000));

    const beforeDates = availableDates.filter((d) => d - selectedReportingPeriod < 0);

    // If earlier date exhists select prior reporting period
    if (beforeDates.length !== 0) return beforeDates[0];
    return selectedReportingPeriod;
  }

  render() {
    return (
      <div>
        <div>
          {'Date From : '}
          <DatePicker
            renderCustomHeader={
              ({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'left',
                  }}
                >
                  <select
                    value={date.toLocaleString('en-us', { month: 'long' })}
                    onChange={({ target: { selectedIndex } }) => { changeMonth(selectedIndex); }}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <select
                    style={{ marginLeft: 15 }}
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {
                      range(2010, date.getFullYear() + 4, 1).map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))
                    }
                  </select>
                </div>
              )
            }
            selected={this.state.fromDate}
            onChange={this.handleFromChangedate}
          />
        </div>
        <div style={{ marginTop: 10 }} />
        <div style={{ marginLeft: 18 }}>
          {'Date To : '}
          <DatePicker
            renderCustomHeader={
              ({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'left',
                  }}
                >
                  <select
                    value={date.toLocaleString('en-us', { month: 'long' })}
                    onChange={({ target: { selectedIndex } }) => { changeMonth(selectedIndex); }}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <select
                    style={{ marginLeft: 15 }}
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {
                      range(2016, date.getFullYear() + 4, 1).map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))
                    }
                  </select>
                </div>
              )
            }
            selected={this.state.toDate}
            onChange={this.handleToChangedate}
          />
        </div>
        <div style={{ marginBottom: 10 }} />
        <GrpButton
          onClick={() => this.requestArrivalPricing()}
          size="Medium"
          type="Primary"
          text=" Ok "
        />
        <GrpButton
          onClick={() => this.context.handlers.closeModal()}
          size="Medium"
          type="Primary"
          text="Cancel"
        />
      </div>
    );
  }
}

MarketDataModalContent.contextType = AppContext;

export default MarketDataModalContent;
