/* eslint-disable prefer-template */
/* eslint-disable react/jsx-boolean-value */
import React, { Component } from 'react';
import AppContext from 'AppContext';
import CDATA from 'Services/CDATA';
import APIM from 'Services/APIM';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import {
  View, Body, Grid, Button, RegSelect, Error, Spinner, Toolbar, ApprovalControls,
} from './ReportingOutput.styles';
import AuditLogModal from './AuditLogModal/AuditLogModal';
import VersionHistoryModal from './VersionHistoryModal/VersionHistoryModal';
import approvalCellEditor from 'Library/GrpGrid/ApprovalCell/approvalCellEditor';
import approvalCellRenderer from 'Library/GrpGrid/ApprovalCell/approvalCellRenderer';
import stateCell from './GridComponents/stateCell';
import statusCell from './GridComponents/statusCell';
import translationStatusRenderer from './GridComponents/translationStatusRenderer';

// const approvalMappings= {
//   10: 'New',
//   90: 'Rejected',
//   100: 'Approved',
// };

const statusMappings = {
  10: 'New',
  20: 'In progress',
  100: 'Translated',
};

function extractValues(mappings) {
  return Object.keys(mappings);
}

function lookupValue(mappings, key) {
  return mappings[key];
}

// eslint-disable-next-line consistent-return
function lookupKey(mappings, name) {
  const keys = Object.keys(mappings);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (mappings[key] === name) {
      return key;
    }
  }
}

const translationStatuses = extractValues(statusMappings);

/**
 * Reporting Output View for the Asset manager side.
 */
class ReportingOutput extends Component {
  frameworkComponents={
    approvalCellEditor,
    approvalCellRenderer,
    stateCell,
    statusCell,
    translationStatusRenderer,
  }

  constructor(props) {
    super(props);

    this.state = {
      apiName: this.props.api_name,
      displayedRowCount: 0,
      failed: false,
      loading: true,
      reportType: 'EPT',
      selectedReport: 'PRIIPS KID',
      totalRowCount: 0,
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.apiName,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);

      columnDefs.unshift({
        checkboxSelection: true,
        field: 'SelectRow',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: '',
        sortable: false,
        maxWidth: 45,
        resizable: false,
      });

      columnDefs.push({
        cellClass: 'ag-cell',
        cellRendererFramework: () => (
          <Button
            size="Small"
            icon={['fas', 'ellipsis-v']}
            iconSide="Round"
          />
        ),
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
        field: 'Action',
        headerName: 'Action',
        maxWidth: 130,
        minWidth: 70,
        showRowGroup: false,
        sortable: false,
        width: 90,
      });
      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
        loading: false,
      });
    }
  }

  async getData() {
    try {
      const payload = {
        ClientCode: this.context.state.selectedClient.code,
        ReportingPeriod: this.context.state.selectedReportingDate.code,
        ReportType: this.state.reportType,
      };
      const data = await CDATA.makeRequest(
        'POST',
        this.state.apiName,
        '',
        payload,
        'Error retrieving data.',
      );

      const totalRowCount = data && data.length !== 0 ? data.length : 0;
      // this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.setRowData(data);
      // this.gridApi.onFilterChanged();

      this.setState({ loading: false, totalRowCount });
      this.onSelectionChanged();
    } catch (err) {
      this.setState({
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
        loading: false,
      });
    }
  }

  /**
   * mapColumns
   *
   * Takes the colums that need custom configuration and passes in their custom
   * components/parameters.
   *
   * @param {*} rawColumns column data received from the getMetadata method.
   * @returns processed column data.
   */
  mapColumns = (rawColumns) => rawColumns.map((x) => {
    if ((x.dv_column === 'ReportApproved') || (x.dv_column === 'HasReportNarratives')) {
      return ({
        cellRenderer: 'statusCell',
        cellStyle: {
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        },
        comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
        editable: false,
        field: x.dv_column,
        headerName: x.display_name,
        hide: x.is_hidden,
        maxWidth: 200,
      });
    }

    if (x.dv_column === 'TranslationStatusId') {
      return ({
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: translationStatuses,
        },
        cellEditorPopup: true,
        editable: true,
        field: x.dv_column,
        headerName: x.display_name,
        hide: x.is_hidden,
        minWidth: 50,
        valueFormatter: (params) => (lookupValue(statusMappings, params.value)),
        valueParser: (params) => (lookupKey(statusMappings, params.newValue)),
      });
    }

    if (x.dv_column === 'OutputState') {
      return ({
        cellRenderer: 'stateCell',
        cellRendererParams: {
          surpressCount: true,
          surpressPadding: true,
        },
        comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
        field: x.dv_column,
        headerName: x.display_name,
        hide: x.is_hidden,
        keyCreator: (status) => (status.value),
        minWidth: 60,
      });
    }

    if (x.dv_column === 'DocumentApprovedId') {
      return ({
        cellRenderer: 'approvalCellRenderer',
        cellRendererParams: {
          surpressCount: true,
          surpressPadding: true,
        },
        cellEditor: 'approvalCellEditor',
        editable: true,
        field: x.dv_column,
        headerName: x.display_name,
        hide: x.is_hidden,
        minWidth: 50,
        maxWidth: 190,
        sortable: false,
      });
    }

    return ({
      comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
      editable: false,
      field: x.dv_column,
      headerName: x.display_name,
      hide: x.is_hidden,
    });
  });

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const toolbarButtons = [
      <ApprovalControls disabled={selectedRows.length === 0} type="Primary" size="Medium">
        <Button
          key={0}
          onClick={selectedRows.length === 0 ? () => {} : () => { this.bulkApproval(); }}
          text="Approve"
        />
        <Button
          key={1}
          onClick={selectedRows.length === 0 ? () => {} : () => { this.bulkRejection(); }}
          text="Reject"
        />
      </ApprovalControls>,
      <Button
        disabled={selectedRows.length === 0}
        key={2}
        onClick={selectedRows.length === 0 ? () => {} : () => { this.generateOutput(); }}
        size="Medium"
        text="Generate Output"
        type="Secondary"
      />,
      <Button
        disabled={selectedRows.length === 0}
        key={3}
        onClick={() => {}}
        size="Medium"
        text="Download"
        type="Primary"
      />,
      <Button
        onClick={() => { this.refresh(); }}
        icon={['fas', 'sync']}
        iconSide="Round"
        size="Small"
      />,
    ];

    this.setState({ toolbarButtons });
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  };

  handleRegulationSelect = (selectedValue) => {
    console.log(selectedValue);
  };

  onCellClicked = (params) => {
    if (params && params.column.colId === 'Action') {
      params.api.contextMenuFactory.showMenu(
        params.node, params.column, params.value, params.event,
      );
    }
  };

  onCellValueChanged = (params) => {
    const colId = params.column.getId();
    if (colId === 'TranslationStatusId') {
      CDATA.makeRequest(
        'POST',
        'PRIIPS_doc_SetNewPdfTranslationStatus',
        '',
        {
          DocumentId: params.data.DocumentId,
          NewStatusId: parseInt(params.value, 10),
          UserEmailAddress: this.context.state.userEmail,
        },
        'Translation Status Editing Failed',
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          const toast = {
            type: 'error',
            body: (
              <>
                {error}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });
    }

    if (colId === 'DocumentApprovedId') {
      CDATA.makeRequest(
        'POST',
        'PRIIPS_doc_SetNewPdfApprovalStatus',
        '',
        {
          ItemsToUpdate: [params.data],
          NewApprovalStatusId: params.value,
          UserEmailAddress: this.context.state.userEmail,
        },
        'Request to change PDF status has failed.',
      )
        .then(() => {
          const toast = {
            type: 'success',
            body: (
              <>
                Document Approval Status Changed
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        })
        .catch((error) => {
          const toast = {
            type: 'error',
            body: (
              <>
                {error}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });
    }
  };

  bulkApproval = () => {
    const listOfRows = this.gridApi.getSelectedRows();

    // get number of selected rows that can't be approved
    if (listOfRows.length > 0) {
      const ungeneratedDocuments = listOfRows.filter((document) => (
        document.PDFLink === null
      ));

      if (ungeneratedDocuments.length > 0) {
        const modalContent = (
          <div>
            <p>You have {ungeneratedDocuments.length} ungenerated document{ungeneratedDocuments.length > 1 ? 's' : ''} in your selection. {ungeneratedDocuments.length > 1 ? 'They' : 'It'} will not be approved until generated.</p>
            <Button
              onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Ok"
            />
          </div>
        );
        this.context.handlers.setModal('Ungenerated Documents', modalContent, null, null, false);
        this.context.handlers.toggleModal();
      } else {
        CDATA.makeRequest(
          'POST',
          'PRIIPS_doc_SetNewPdfApprovalStatus',
          '',
          {
            ItemsToUpdate: listOfRows,
            NewApprovalStatusId: 100, // Approved
            UserEmailAddress: this.context.state.userEmail,
          },
          'Bulk PDF Approval Failed.',
        )
          .then((response) => {
            // for()
            this.setState({ loading: true });
            this.getMetadata();
            this.getData();
            const toast = {
              type: 'success',
              body: (
                <> Operation Successful </>
              ),
            };
            this.context.handlers.setToast(toast);
          })
          .catch((error) => {
            const toast = {
              type: 'error',
              body: (
                <>
                  {error}
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          });
      }
    }
  };

  bulkRejection = () => {
    const listOfRows = this.gridApi.getSelectedRows();

    // get number of selected rows that can't be rejected
    if (listOfRows.length > 0) {
      const ungeneratedDocuments = listOfRows.filter((document) => (
        document.PDFLink === null
      ));

      if (ungeneratedDocuments.length > 0) {
        const modalContent = (
          <div>
            <p>You have {ungeneratedDocuments.length} ungenerated document{ungeneratedDocuments.length > 1 ? 's' : ''} in your selection. {ungeneratedDocuments.length > 1 ? 'They' : 'It'} will not be rejected until generated.</p>
            <Button
              onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Primary" text="Ok"
            />
          </div>
        );
        this.context.handlers.setModal('Ungenerated Documents', modalContent, null, null, false);
        this.context.handlers.toggleModal();
      } else {
        CDATA.makeRequest(
          'POST',
          'PRIIPS_doc_SetNewPdfApprovalStatus',
          '',
          {
            UserEmailAddress: this.context.state.userEmail,
            ItemsToUpdate: listOfRows,
            NewApprovalStatusId: 90, // Rejected
          },
          'Bulk PDF Approval Failed.',
        )
          .then(() => {
            this.setState({ loading: true });
            this.getMetadata();
            this.getData();
            const toast = {
              type: 'success',
              body: (
                <> Rejection Successful </>
              ),
            };
            this.context.handlers.setToast(toast);
          })
          .catch((error) => {
            this.context.handlers.closeModal();
            const toast = {
              type: 'error',
              body: (
                <>
                  {error}
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          });
      }
    }
  };

  getContextMenuItems = (params) => {
    const cellDetail = params.api.getFocusedCell();
    if (cellDetail !== null) {
      if (cellDetail.column.colId !== 'Action') {
        return [
          'copy',
          'copyWithHeaders',
        ];
      }

      const rowNode = params.api.getDisplayedRowAtIndex(cellDetail.rowIndex);
      const menuItems = [];
      const auditLogMenu = {
        name: 'Get Audit Log',
        action: async () => {
          // modal saying approving data
          const modalContent = (
            <div>
              <p>Loading Audit Log ...</p>
            </div>
          );

          this.context.handlers.setModal('Loading ...', modalContent, null, null, true);
          this.context.handlers.toggleModal();
          try {
            const result = await CDATA.makeRequest(
              'POST',
              'PRIIPS_doc_GetAuditLogs',
              '',
              {
                DocumentId: rowNode.data.DocumentId,
                userEmailAddress: this.context.state.userEmail,
              },
              'Failed to retrieve audit log.',
            );
            this.context.handlers.setModal(
              'Audit Log For ' + rowNode.data.FundName,
              <AuditLogModal
                data={result.Content}
              />,
              null,
              null,
              false,
              '800px',
            );
          } catch (err) {
            this.context.handlers.closeModal();
            const toast = {
              type: 'error',
              body: (
                <>
                  Error - something went wrong and we were not able to
                  retrieve the audit log for this report.
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          }
        },
      };

      const versionHistoryMenu = {
        name: 'Get Version History',
        action: async () => {
          const modalContent = (
            <div>
              <p>Loading Version History ...</p>
            </div>
          );

          this.context.handlers.setModal('Loading ...', modalContent, null, null, true);
          this.context.handlers.toggleModal();
          try {
            const result = await CDATA.makeRequest(
              'POST',
              'PRIIPS_doc_GetVersionHistory',
              '',
              {
                DocumentId: rowNode.data.DocumentId,
                userEmailAddress: this.context.state.userEmail,
              },
              'Error: We were not able to retrieve the version history for this report.',
            );

            this.context.handlers.setModal(
              'Version History For ' + rowNode.data.FundName,
              <VersionHistoryModal
                data={result.Content}
              />,
              null,
              null,
              false,
              '800px',
            );
          } catch (error) {
            this.context.handlers.closeModal();
            const toast = {
              type: 'error',
              body: (
                <>
                  {error}
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          }
        },
      };

      menuItems.push(auditLogMenu);
      menuItems.push(versionHistoryMenu);
      return menuItems;
    }
    return null;
  };

  async generateOutput() {
    try {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      if (selectedData.some(node => node.DocumentApproved == "Approved")) {
        const toast = {
          type: 'error',
          body: (
          <>Can not regenerate PDF. You have selected one or more share classes that are in an approved state.</>
          ),
        };
        this.context.handlers.setToast(toast);
      }
      else{
        const documentsMsg = {
          type: 'success',
          body: (
          <>Generating {selectedData.length} documents, this may take awhile. We'll let you know once the action has been completed.</>
          ),
        };
        this.context.handlers.setToast(documentsMsg);

        setTimeout(() => {
          for (const row of selectedData) {
            const payload = {
              UserEmailAddress: this.context.state.userEmail,
              DocumentId: row.DocumentId,
            };
            APIM.makeRequest(
              'requestPdfGeneration',
              JSON.stringify(payload),
              'Error. Pdf Request failed.',
              Auth0SessionInfo.GetSessionInfo().accessToken,
            )
              .then(() => {
                const toast = {
                  type: 'success',
                  body: (
                  <> Operation Successful </>
                  ),
                };
                this.context.handlers.setToast(toast);
              })
              .catch((error) => {
                const toast = {
                  type: 'error',
                  body: (
                  <>{error}</>
                  ),
                };
                this.context.handlers.setToast(toast);
              });
          }
        },1000);
       
        this.setState({ loading: true });
        this.getMetadata();
        await this.getData();        
      }
    } catch (err) {
      this.setState({
        failed: true,
        failMessage: 'Operation Failed.',
        loading: false,
      });
    }
  }

  async refresh() {
    this.setState({ loading: true });
    this.getMetadata();
    await await this.getData();
  }

  render() {
    return (
      <View>
        <Body>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data... " /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        controls={this.state.toolbarButtons}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        // dropdown={(
                        //   <RegSelect
                        //     defaultValue={this.state.selectedReport}
                        //     isSearchable
                        //     options={[
                        //       { label: 'PRIIPS KID', value: 'PRIIPS KID' },
                        //     ]}
                        //     value={this.state.selectedValue}
                        //     onChange={this.handleRegulationSelect}
                        //   />
                        // )}
                        exportName={this.state.apiName}
                        working={this.state.toolbarWorking}
                      />
                    )
                  )
                }
                <Grid
                  apiName={this.state.apiName}
                  columnDefs={this.state.columnDefs}
                  frameworkComponents={this.frameworkComponents}
                  getContextMenuItems={this.getContextMenuItems}
                  groupSelectsChildren
                  onCellClicked={this.onCellClicked}
                  onCellValueChanged={this.onCellValueChanged}
                  onGridReady={this.onGridReady}
                  onModelUpdated={this.onModelUpdated}
                  onSelectionChanged={this.onSelectionChanged}
                  pivotable
                  rawColumnData={this.state.rawColumns}
                  singleClickEdit={true}
                />
              </>
            )
          }
        </Body>
      </View>
    );
  }
}

ReportingOutput.contextType = AppContext;

export default ReportingOutput;
