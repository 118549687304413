import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SelectBoxItem = styled.div`
  height: 40px;
  background-color: ${(props) => (props.active ? '#6F08CD' : '#fff')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border: 2px solid #ebebeb;
  transition: all 150ms ease-out;
  overflow: hidden;
  color: ${(props) => (props.active ? '#fff' : '#6F08CD')};
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:focus { 
    outline:none;
  }
  &:hover {
    transform: translate(0, -3px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-color: #cecece;
  }
`;

const GrpSelectBoxItem = ({ item, onClick }) => (
  <SelectBoxItem
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex={0}
    role="menuitem"
    active={item.selected}
  >
    {item.name}
  </SelectBoxItem>
);

export default GrpSelectBoxItem;

GrpSelectBoxItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
  }).isRequired,
};
