import React, { Component } from 'react';
import Viewer from '../Viewer/Viewer';
import AppContext from '../../AppContext';


class ShareOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ post_data: { ClientCode_bk: this.context.state.selectedClient.code } });
  }

  render() {
    return (
      this.state.post_data ? <Viewer {...this.props} post_data={this.state.post_data} /> : <></>
    );
  }
}

ShareOverview.contextType = AppContext;

export default ShareOverview;
