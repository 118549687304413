import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  align-items: center;
  display: flex;
  // flex-direction: row;
  justify-content: space-around;
  // background-color: red;
  position: relative;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  height: 100%;
  // margin: 5px;
`;

export const ToggleButton = styled.div`
  color: #57576B;
  font-size: 18px;
  cursor: pointer;
  margin: 1rem;
`;

export const ApproveToggle = styled(ToggleButton)`
  color: ${(props) => (props.active ? '#2EC4B6 ' : '#57576B')};
  &:hover {
    color: #29939b;
  }
`;

export const RejectToggle = styled(ToggleButton)`
  color: ${(props) => (props.active ? '#EE4266' : '#57576B')};
  &:hover {
    color: #b43562;
  }
`;

class approvalCellEditor extends Component {
  constructor(props) {
    super(props);

    this.onApprovalClick = this.onApprovalClick.bind(this);
    this.onRejectionClick = this.onRejectionClick.bind(this);

    this.state = {
      approval: this.props.value,
    };
  }

  componentWillMount() {
    // disallows empty cells to be edited
    if (this.props.data.PDFLink === null) this.props.api.stopEditing();

    this.setApproval(this.props.value);
  }

  componentDidUpdate() {
    // this.focus();
    this.props.api.stopEditing();
  }

  onApprovalClick() {
    if (this.state.approval === 100) this.setApproval(10);
    else this.setApproval(100);
  }

  onRejectionClick() {
    if (this.state.approval === 90) this.setApproval(10);
    else this.setApproval(90);
  }

  getValue() {
    return this.state.approval;
  }

  setApproval(status) {
    this.setState({
      approval: status,
    });
  }

  valueChanged = (e) => {
    this.setState({ value: e.target.value });
  };

  render() {
    return (
      this.props.data.PDFLink && (
        <Container>
          <ApproveToggle
            active={this.state.approval === 100}
            onClick={this.onApprovalClick}
          >
            <FontAwesomeIcon icon={['fas', 'file-check']} />
          </ApproveToggle>
          <RejectToggle
            active={this.state.approval === 90}
            onClick={this.onRejectionClick}
          >
            <FontAwesomeIcon icon={['fas', 'file-times']} />
          </RejectToggle>
        </Container>
      )
    );
  }
}

export default approvalCellEditor;
