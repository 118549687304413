import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import range from 'lodash/range';
import AppContext from 'AppContext';
import GrpButton from 'Library/GrpButton/GrpButton';
import 'Styles/SCSS/ag-theme-material.scss';
import 'react-datepicker/dist/react-datepicker.css';
import APIM from 'Services/APIM';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';

class DateModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      // dateFrom: new Date(),
      // dateTo: new Date(),
    };
    this.handleFromChangeDate = this.handleFromChangeDate.bind(this);
    this.handleToChangeDate = this.handleToChangeDate.bind(this);
  }

  handleFromChangeDate(date) {
    this.setState({ fromDate: date });
  }

  handleToChangeDate(date) {
    this.setState({ toDate: date });
  }

  async createAllTransactionsExtract(startDate, endDate){
    const data = {
      procedure: 'Transaction_Costs_Output_All_Transactions',
      ClientCode: this.context.state.selectedClient.code,
      FileType: 'Excel',
      BaseFileName: 'All_Transaction_Results',
      parameters: {
        Client_Code: this.context.state.selectedClient.code,
        StartDate: startDate,
        EndDate: endDate
      },
      ColumnFilters: {
        "All Transactions":       "EXTRACT_TC_All_Transactions",    
      }
    };

  if (startDate > endDate) {
    const toast = {
      type: 'error',
      body: (
        <>
          {'Error - End Date cannot be earlier than the start Date '}
        </>
      ),
    };
    this.context.handlers.setToast(toast);
  } else {
    this.context.handlers.closeToast();
    APIM.makeRequest('generateReport', JSON.stringify(data), 'Error Generating All Transaction Results Extract', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
          <>
             {`You have queued a request to extract All Transactions. It will appear on the Data Management > Exports page when the extract has completed.`}.
          </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'All Transaction Results Extract Generation' });
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
             Error - something went wrong and we were not able to generate the
             extract you requested.
           </>
          ),
        };
        this.context.handlers.setToast(toast);
      }); 
  }
}

  render() {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flexShrink: 0,
              marginRight: '8px',
            }}
          >
            Date From :
          </div>
          <DatePicker
            isClearable={true}
            renderCustomHeader={
              ({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <select
                    value={date.toLocaleString('en-us', { month: 'long' })}
                    onChange={({ target: { selectedIndex } }) => { changeMonth(selectedIndex); }}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <select
                    style={{ minWidth: 52 }}
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {
                      range(
                        this.state.toDate ? this.state.toDate.getFullYear()
                          : this.state.today.getFullYear() + 1,
                        2010,
                        -1,
                      ).map((year) => (<option key={year} value={year}>{year}</option>))
                    }
                  </select>
                </div>
              )
            }
            selected={this.state.fromDate}
            onChange={this.handleFromChangeDate}
            placeholderText="mm/dd/yyyy"
          />
        </div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div
            style={{
              flexShrink: 0,
              marginRight: '8px',
            }}
          >
            Date To :
          </div>
          <DatePicker
            isClearable={true}
            placeholderText="mm/dd/yyyy"
            renderCustomHeader={
              ({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <select
                    value={date.toLocaleString('en-us', { month: 'long' })}
                    onChange={({ target: { selectedIndex } }) => { changeMonth(selectedIndex); }}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <select
                    style={{ minWidth: 52 }}
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {
                      range(
                        this.state.fromDate ? this.state.fromDate.getFullYear() : 2010,
                        new Date().getFullYear() + 3,
                      ).map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))
                    }
                  </select>
                </div>
              )
            }
            selected={this.state.toDate}
            onChange={this.handleToChangeDate}
          />
        </div>
        <div style={{ marginBottom: 10 }} />
        <>
          <GrpButton
            onClick={() => this.createAllTransactionsExtract(
              new Date(this.state.fromDate.getTime() - (this.state.fromDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
              new Date(this.state.toDate.getTime() - (this.state.toDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
            )}
            size="Medium"
            type="Primary"
            text=" Ok "
          />
          <GrpButton
            onClick={() => this.context.handlers.closeModal()}
            size="Medium"
            type="Primary"
            text="Cancel"
          />
        </>
      </div>
    );
  }
}

DateModalContent.contextType = AppContext;

export default DateModalContent;
