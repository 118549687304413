import styled from 'styled-components';
// import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const rotator = keyframes`
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(270deg); }
// `;

// const colors = keyframes`
//   0% { stroke: #c0c0c0; }
//   50% { stroke: rgb(95, 42, 163); }
// `;

// const dash = keyframes`
//   0% { stroke-dashoffset: $offset; }
//   50% {
//     stroke-dashoffset: $offset/4;
//     transform:rotate(135deg);
//   }
//   100% {
//     stroke-dashoffset: $offset;
//     transform:rotate(450deg);
//   }
// `;

export const ErrorWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Skull = styled(FontAwesomeIcon)`

`;

export const Name = styled.div`
  width: 50%;
  text-align: center;
`;
