/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import {
  Col, Row, Nav, Navbar, Form, Button, ButtonToolbar, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { RegEditView, RegEditBody, Toolbar, GButton, Spinner} from './RegulationEditor.styles';
import AppContext from '../../AppContext';
import GrpButton from '../../Library/GrpButton/GrpButton';
import '../../Styles/SCSS/ag-theme-material.scss';
import config from '../../config';
import CDATA from '../../Services/CDATA';

// -------------------------------------------------------------------
// TEMPORARY EDITOR PAGE --- COPIED FROM DCPT BUT WILL BE CHANGED SOON
// -------------------------------------------------------------------
const { encKey } = config.sessionStorage;

class CTIeditor extends Component {
  constructor(props) {
    super(props);

    let sessionState = sessionStorage.getItem('PageStateStorage');
    let propsID = this.props.location.fundcode_bk;
    // console.log(propsID)
    if (propsID) {
      this.state = {
        page: window.location.pathname,
        raw_api: this.props.raw_api,
        api_name: this.props.location.api_name,
        loading: false,
        failed: false,
        Class_Code: this.props.location.classcode_bk,
        Fund_Code: this.props.location.fundcode_bk,
        eventKey: 'Account information',
        action_type: this.props.location.action_type,
        toolbarButtons: [
          'Placeholder' === 'Edit' ? (
            <GrpButton key={0} onClick={() => { this.handleApprove(); }} size="Medium" type="Primary" text="Approve" />
          ) : (
            <GrpButton key={0} onClick={() => { this.handleReject(); }} size="Medium" type="Primary" text="Re-Open" />
          ),
        ],
        validated: false,
        loaded: false,
        redirect: false,
      };
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page === window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async componentDidMount() {
    const urlParameters = CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and Reference_Date eq '${this.context.state.selectedReportingDate.code}' and x_00010_CTI_Portfolio_Identifying_Data eq '${this.state.Class_Code}'`);
    try {
      const data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_app_CTI_OUTPUT',
        urlParameters,
        {},
        'Error retrieving data.',
      );

      let ctiFields = data.value[0];
      console.log(data);

      this.setState(ctiFields);
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  async saveCTI(ctiSection) {
    if (ctiSection === 'Account information') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio: this.state.Class_Code,
            x_00030_CTI_Portfolio_Name: this.state.x_00030_CTI_Portfolio_Name,
            x_00040_CTI_Portfolio_Issuer_Name: this.state.x_00040_CTI_Portfolio_Issuer_Name,
            x_00050_CTI_Start_Of_Period: this.state.x_00050_CTI_Start_Of_Period,
            x_00060_CTI_End_Of_Period: this.state.x_00060_CTI_End_Of_Period,
            Reference_date: this.state.x_00000_Reporting_Period,
            x_00070_CTI_Currency_Of_Report: this.state.x_00070_CTI_Currency_Of_Report,
            x_00080_CTI_Fund_Or_Mandate: this.state.x_00080_CTI_Fund_Or_Mandate,
            x_00090_CTI_Client_Only_Report: this.state.x_00090_CTI_Client_Only_Report,
            x_00100_CTI_Underlying_Investment_Vehicles_Used: this.state.x_00100_CTI_Underlying_Investment_Vehicles_Used,
            x_00110_CTI_Performance_Fee_Applicable: this.state.x_00110_CTI_Performance_Fee_Applicable,
            x_00120_CTI_Securities_Lending_Applicable: this.state.x_00120_CTI_Securities_Lending_Applicable,
            x_00130_CTI_Securities_Lending_Agent_Name: this.state.x_00130_CTI_Securities_Lending_Agent_Name,
            x_00140_CTI_Direct_Property_Exposure: this.state.x_00140_CTI_Direct_Property_Exposure,
            x_00150_CTI_Asset_Class_Breakdown_Provided: this.state.x_00150_CTI_Asset_Class_Breakdown_Provided,
            // x_: this.state.x_,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    } else if (ctiSection === 'Client Specific Information') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_02010_CTI_Client_Name: this.state.x_02010_CTI_Client_Name,
            x_02020_CTI_Client_Average_Value: this.state.x_02020_CTI_Client_Average_Value,
            x_02030_CTI_Entry_Costs: this.state.x_02030_CTI_Entry_Costs,
            x_02040_CTI_Exit_Costs: this.state.x_02040_CTI_Exit_Costs,
            x_02050_CTI_Client_FX_Costs: this.state.x_02050_CTI_Client_FX_Costs,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    } else if (ctiSection === 'Portfolio Transaction Cost') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_03010_CTI_Total_Transaction_Costs: this.state.x_03010_CTI_Total_Transaction_Costs,
            x_03020_CTI_Total_Explicit_Costs: this.state.x_03020_CTI_Total_Explicit_Costs,
            x_03030_CTI_Explicit_Transaction_Taxes: this.state.x_03030_CTI_Explicit_Transaction_Taxes,
            x_03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees: this.state.x_03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees,
            x_03050_CTI_Explicit_Transaction_Related_Services: this.state.x_03050_CTI_Explicit_Transaction_Related_Services,
            x_03060_CTI_Explicit_Other_Charges: this.state.x_03060_CTI_Explicit_Other_Charges,
            x_03070_CTI_Implicit_Costs: this.state.x_03070_CTI_Implicit_Costs,
            x_03080_CTI_Indirect_Transaction_Costs: this.state.x_03080_CTI_Indirect_Transaction_Costs,
            x_03090_CTI_Anti_Dilution_Offset: this.state.x_03090_CTI_Anti_Dilution_Offset,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    } else if (ctiSection === 'Ongoing charges') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_App_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_04010_CTI_Ongoing_Charges: this.state.x_04010_CTI_Ongoing_Charges,
            x_04100_CTI_Fund_And_Management_Charges: this.state.x_04100_CTI_Fund_And_Management_Charges,
            x_04110_CTI_Invoiced_Fees: this.state.x_04110_CTI_Invoiced_Fees,
            x_04120_CTI_Fees_And_Charges_From_NAV: this.state.x_04120_CTI_Fees_And_Charges_From_NAV,
            x_04130_CTI_Investment_Advisors_Fees_From_NAV: this.state.x_04130_CTI_Investment_Advisors_Fees_From_NAV,
            x_04140_CTI_Research_Payments_From_NAV: this.state.x_04140_CTI_Research_Payments_From_NAV,
            x_04150_CTI_Management_Fee_Rebates: this.state.x_04150_CTI_Management_Fee_Rebates,
            x_04160_CTI_Indirect_Fees_And_Charges: this.state.x_04160_CTI_Indirect_Fees_And_Charges,
            x_04200_CTI_Administration_Charges: this.state.x_04200_CTI_Administration_Charges,
            x_04210_CTI_Investment_Administration_Fees: this.state.x_04210_CTI_Investment_Administration_Fees,
            x_04220_CTI_Custody_And_Depositary_Fees: this.state.x_04220_CTI_Custody_And_Depositary_Fees,
            x_04230_CTI_Research_Payments: this.state.x_04230_CTI_Research_Payments,
            x_04240_CTI_Collateral_Management_Fees: this.state.x_04240_CTI_Collateral_Management_Fees,
            x_04250_CTI_Facility_Fees: this.state.x_04250_CTI_Facility_Fees,
            x_04290_CTI_Other_Administration_Charges: this.state.x_04290_CTI_Other_Administration_Charges,
            x_04300_CTI_Governance_Regulation_Compliance_Charges: this.state.x_04300_CTI_Governance_Regulation_Compliance_Charges,
            x_04310_CTI_Audit_Costs: this.state.x_04310_CTI_Audit_Costs,
            x_04320_CTI_Legal_And_Professional_Fees: this.state.x_04320_CTI_Legal_And_Professional_Fees,
            x_04330_CTI_Engagement_And_Voting_Fees: this.state.x_04330_CTI_Engagement_And_Voting_Fees,
            x_04340_CTI_Performance_Measurement: this.state.x_04340_CTI_Performance_Measurement,
            x_04350_CTI_Risk_Monitoring: this.state.x_04350_CTI_Risk_Monitoring,
            x_04360_CTI_Tax_Advice_And_Structuring_Costs: this.state.x_04360_CTI_Tax_Advice_And_Structuring_Costs,
            x_04390_CTI_Other_Governance_Regulation_Compliance_Charges: this.state.x_04390_CTI_Other_Governance_Regulation_Compliance_Charges,
            x_04400_CTI_Distribution_Communication_Client_Service_Charges: this.state.x_04400_CTI_Distribution_Communication_Client_Service_Charges,
            x_04410_CTI_Distribution_Costs: this.state.x_04410_CTI_Distribution_Costs,
            x_04420_CTI_Communication_Material: this.state.x_04420_CTI_Communication_Material,
            x_04490_CTI_Other_Distribution_Communication_Client_Service_Charges: this.state.x_04490_CTI_Other_Distribution_Communication_Client_Service_Charges,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Incidental costs') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_05010_CTI_Incidental_Costs: this.state.x_05010_CTI_Incidental_Costs,
            x_05020_CTI_Performance_Fees_Invoiced: this.state.x_05020_CTI_Performance_Fees_Invoiced,
            x_05030_CTI_Performance_Fees_From_NAV: this.state.x_05030_CTI_Performance_Fees_From_NAV,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Lending and borrowing costs') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_06010_CTI_Lending_And_Borrowing_Costs: this.state.x_06010_CTI_Lending_And_Borrowing_Costs,
            x_06020_CTI_Securities_Lending_Value_On_Loan: this.state.x_06020_CTI_Securities_Lending_Value_On_Loan,
            x_06030_CTI_Gross_Securities_Lending_Revenue: this.state.x_06030_CTI_Gross_Securities_Lending_Revenue,
            x_06040_CTI_Securities_Lending_Revenue_Portfolio_Share: this.state.x_06040_CTI_Securities_Lending_Revenue_Portfolio_Share,
            x_06050_CTI_Securities_Lending_Fees_Custodian_Share: this.state.x_06050_CTI_Securities_Lending_Fees_Custodian_Share,
            x_06060_CTI_Securities_Lending_Fees_Manager_Share: this.state.x_06060_CTI_Securities_Lending_Fees_Manager_Share,
            x_06070_CTI_Securities_Lending_Fees_Agent_Share: this.state.x_06070_CTI_Securities_Lending_Fees_Agent_Share,
            x_06080_CTI_Securities_Borrowing_Fees: this.state.x_06080_CTI_Securities_Borrowing_Fees,
            x_06100_CTI_Interest_On_Borrowing: this.state.x_06100_CTI_Interest_On_Borrowing,
            x_06110_CTI_Borrowing_And_Arrangement_Fees: this.state.x_06110_CTI_Borrowing_And_Arrangement_Fees,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Property Expenses') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_07010_CTI_Property_Expenses: this.state.x_07010_CTI_Property_Expenses,
            x_07020_CTI_Property_Management_Fees: this.state.x_07020_CTI_Property_Management_Fees,
            x_07030_CTI_Property_Leasing_Costs: this.state.x_07030_CTI_Property_Leasing_Costs,
            x_07040_CTI_Property_Maintenance_And_Repairs: this.state.x_07040_CTI_Property_Maintenance_And_Repairs,
            x_07050_CTI_Property_Utilities_And_Service_Charges: this.state.x_07050_CTI_Property_Utilities_And_Service_Charges,
            x_07060_CTI_Property_Void_Costs: this.state.x_07060_CTI_Property_Void_Costs,
            x_07070_CTI_Property_Insurance_Costs: this.state.x_07070_CTI_Property_Insurance_Costs,
            x_07080_CTI_Property_Valuation_Fees: this.state.x_07080_CTI_Property_Valuation_Fees,
            x_07090_CTI_Property_Failed_Transaction_Costs: this.state.x_07090_CTI_Property_Failed_Transaction_Costs,
            x_07100_CTI_Indirect_Property_Expenses: this.state.x_07100_CTI_Indirect_Property_Expenses,
            x_07110_CTI_Other_Property_Expenses: this.state.x_07110_CTI_Other_Property_Expenses,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Other relevant information') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_08010_CTI_Gross_Return: this.state.x_08010_CTI_Gross_Return,
            x_08020_CTI_Net_Return: this.state.x_08020_CTI_Net_Return,
            x_08110_CTI_Start_Asset_Value: this.state.x_08110_CTI_Start_Asset_Value,
            x_08120_CTI_End_Asset_Value: this.state.x_08120_CTI_End_Asset_Value,
            x_08130_CTI_Portfolio_Purchases: this.state.x_08130_CTI_Portfolio_Purchases,
            x_08140_CTI_Portfolio_Sales: this.state.x_08140_CTI_Portfolio_Sales,
            x_08150_CTI_Fund_Inflows: this.state.x_08150_CTI_Fund_Inflows,
            x_08160_CTI_Fund_Outflows: this.state.x_08160_CTI_Fund_Outflows,
            x_08210_CTI_LDI_PV01_Net_Traded: this.state.x_08210_CTI_LDI_PV01_Net_Traded,
            x_08220_CTI_LDI_IE01_Net_Traded: this.state.x_08220_CTI_LDI_IE01_Net_Traded,
            x_08230_CTI_LDI_PV01_Transaction_Cost: this.state.x_08230_CTI_LDI_PV01_Transaction_Cost,
            x_08240_CTI_LDI_IE01_Transaction_Cost: this.state.x_08240_CTI_LDI_IE01_Transaction_Cost,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Narrative information') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_09010_CTI_Transaction_Costs_Methodology: this.state.x_09010_CTI_Transaction_Costs_Methodology,
            x_09020_CTI_Incidental_Costs_Methodology: this.state.x_09020_CTI_Incidental_Costs_Methodology,
            x_09030_CTI_Return_Methodology: this.state.x_09030_CTI_Return_Methodology,
            x_09040_CTI_Additional_Notes: this.state.x_09040_CTI_Additional_Notes,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Asset class portfolio transaction costs') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_10000_CTI_Equities_Used: this.state.x_10000_CTI_Equities_Used,
            x_10030_CTI_Explicit_Transaction_Taxes_Equities: this.state.x_10030_CTI_Explicit_Transaction_Taxes_Equities,
            x_10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities: this.state.x_10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities,
            x_10050_CTI_Explicit_Transaction_Related_Services_Equities: this.state.x_10050_CTI_Explicit_Transaction_Related_Services_Equities,
            x_10060_CTI_Explicit_Other_Charges_Equities: this.state.x_10060_CTI_Explicit_Other_Charges_Equities,
            x_10070_CTI_Implicit_Costs_Equities: this.state.x_10070_CTI_Implicit_Costs_Equities,
            x_10080_CTI_Indirect_Transaction_Costs_Equities: this.state.x_10080_CTI_Indirect_Transaction_Costs_Equities,
            x_10100_CTI_Bonds_Used: this.state.x_10100_CTI_Bonds_Used,
            x_10130_CTI_Explicit_Transaction_Taxes_Bonds: this.state.x_10130_CTI_Explicit_Transaction_Taxes_Bonds,
            x_10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds: this.state.x_10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds,
            x_10150_CTI_Explicit_Transaction_Related_Services_Bonds: this.state.x_10150_CTI_Explicit_Transaction_Related_Services_Bonds,
            x_10160_CTI_Explicit_Other_Charges_Bonds: this.state.x_10160_CTI_Explicit_Other_Charges_Bonds,
            x_10170_CTI_Implicit_Costs_Bonds: this.state.x_10170_CTI_Implicit_Costs_Bonds,
            x_10180_CTI_Indirect_Transaction_Costs_Bonds: this.state.x_10180_CTI_Indirect_Transaction_Costs_Bonds,
            x_10200_CTI_Pooled_Vehicle_Used: this.state.x_10200_CTI_Pooled_Vehicle_Used,
            x_10230_CTI_Explicit_Transaction_Taxes_Pooled: this.state.x_10230_CTI_Explicit_Transaction_Taxes_Pooled,
            x_10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled: this.state.x_10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled,
            x_10250_CTI_Explicit_Transaction_Related_Services_Pooled: this.state.x_10250_CTI_Explicit_Transaction_Related_Services_Pooled,
            x_10260_CTI_Explicit_Other_Charges_Pooled: this.state.x_10260_CTI_Explicit_Other_Charges_Pooled,
            x_10270_CTI_Implicit_Costs_Pooled: this.state.x_10270_CTI_Implicit_Costs_Pooled,
            x_10280_CTI_Indirect_Transaction_Costs_Pooled: this.state.x_10280_CTI_Indirect_Transaction_Costs_Pooled,
            x_10300_CTI_ETD_Used: this.state.x_10300_CTI_ETD_Used,
            x_10330_CTI_Explicit_Transaction_Taxes_ETD: this.state.x_10330_CTI_Explicit_Transaction_Taxes_ETD,
            x_10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD: this.state.x_10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD,
            x_10350_CTI_Explicit_Transaction_Related_Services_ETD: this.state.x_10350_CTI_Explicit_Transaction_Related_Services_ETD,
            x_10360_CTI_Explicit_Other_Charges_ETD: this.state.x_10360_CTI_Explicit_Other_Charges_ETD,
            x_10370_CTI_Implicit_Costs_ETD: this.state.x_10370_CTI_Implicit_Costs_ETD,
            x_10380_CTI_Indirect_Transaction_Costs_ETD: this.state.x_10380_CTI_Indirect_Transaction_Costs_ETD,
            x_10400_CTI_OTCD_Used: this.state.x_10400_CTI_OTCD_Used,
            x_10430_CTI_Explicit_Transaction_Taxes_OTCD: this.state.x_10430_CTI_Explicit_Transaction_Taxes_OTCD,
            x_10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD: this.state.x_10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD,
            x_10450_CTI_Explicit_Transaction_Related_Services_OTCD: this.state.x_10450_CTI_Explicit_Transaction_Related_Services_OTCD,
            x_10460_CTI_Explicit_Other_Charges_OTCD: this.state.x_10460_CTI_Explicit_Other_Charges_OTCD,
            x_10470_CTI_Implicit_Costs_OTCD: this.state.x_10470_CTI_Implicit_Costs_OTCD,
            x_10480_CTI_Indirect_Transaction_Costs_OTCD: this.state.x_10480_CTI_Indirect_Transaction_Costs_OTCD,
            x_10500_CTI_FX_Used: this.state.x_10500_CTI_FX_Used,
            x_10530_CTI_Explicit_Transaction_Taxes_FX: this.state.x_10530_CTI_Explicit_Transaction_Taxes_FX,
            x_10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX: this.state.x_10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX,
            x_10550_CTI_Explicit_Transaction_Related_Services_FX: this.state.x_10550_CTI_Explicit_Transaction_Related_Services_FX,
            x_10560_CTI_Explicit_Other_Charges_FX: this.state.x_10560_CTI_Explicit_Other_Charges_FX,
            x_10570_CTI_Implicit_Costs_FX: this.state.x_10570_CTI_Implicit_Costs_FX,
            x_10580_CTI_Indirect_Transaction_Costs_FX: this.state.x_10580_CTI_Indirect_Transaction_Costs_FX,
            x_10600_CTI_MMI_Used: this.state.x_10600_CTI_MMI_Used,
            x_10630_CTI_Explicit_Transaction_Taxes_MMI: this.state.x_10630_CTI_Explicit_Transaction_Taxes_MMI,
            x_10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI: this.state.x_10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI,
            x_10650_CTI_Explicit_Transaction_Related_Services_MMI: this.state.x_10650_CTI_Explicit_Transaction_Related_Services_MMI,
            x_10660_CTI_Explicit_Other_Charges_MMI: this.state.x_10660_CTI_Explicit_Other_Charges_MMI,
            x_10670_CTI_Implicit_Costs_MMI: this.state.x_10670_CTI_Implicit_Costs_MMI,
            x_10680_CTI_Indirect_Transaction_Costs_MMI: this.state.x_10680_CTI_Indirect_Transaction_Costs_MMI,
            x_10700_CTI_Physical_Assets_Used: this.state.x_10700_CTI_Physical_Assets_Used,
            x_10730_CTI_Explicit_Transaction_Taxes_Physical: this.state.x_10730_CTI_Explicit_Transaction_Taxes_Physical,
            x_10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical: this.state.x_10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical,
            x_10750_CTI_Explicit_Transaction_Related_Services_Physical: this.state.x_10750_CTI_Explicit_Transaction_Related_Services_Physical,
            x_10760_CTI_Explicit_Other_Charges_Physical: this.state.x_10760_CTI_Explicit_Other_Charges_Physical,
            x_10770_CTI_Implicit_Costs_Physical: this.state.x_10770_CTI_Implicit_Costs_Physical,
            x_10780_CTI_Indirect_Transaction_Costs_Physical: this.state.x_10780_CTI_Indirect_Transaction_Costs_Physical,
            x_10800_CTI_Private_Assets_Used: this.state.x_10800_CTI_Private_Assets_Used,
            x_10830_CTI_Explicit_Transaction_Taxes_Private: this.state.x_10830_CTI_Explicit_Transaction_Taxes_Private,
            x_10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private: this.state.x_10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private,
            x_10850_CTI_Explicit_Transaction_Related_Services_Private: this.state.x_10850_CTI_Explicit_Transaction_Related_Services_Private,
            x_10860_CTI_Explicit_Other_Charges_Private: this.state.x_10860_CTI_Explicit_Other_Charges_Private,
            x_10870_CTI_Implicit_Costs_Private: this.state.x_10870_CTI_Implicit_Costs_Private,
            x_10880_CTI_Indirect_Transaction_Costs_Private: this.state.x_10880_CTI_Indirect_Transaction_Costs_Private,
            x_10900_CTI_Other_Assets_Used: this.state.x_10900_CTI_Other_Assets_Used,
            x_10930_CTI_Explicit_Transaction_Taxes_Other: this.state.x_10930_CTI_Explicit_Transaction_Taxes_Other,
            x_10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other: this.state.x_10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other,
            x_10950_CTI_Explicit_Transaction_Related_Services_Other: this.state.x_10950_CTI_Explicit_Transaction_Related_Services_Other,
            x_10960_CTI_Explicit_Other_Charges_Other: this.state.x_10960_CTI_Explicit_Other_Charges_Other,
            x_10970_CTI_Implicit_Costs_Other: this.state.x_10970_CTI_Implicit_Costs_Other,
            x_10980_CTI_Indirect_Transaction_Costs_Other: this.state.x_10980_CTI_Indirect_Transaction_Costs_Other,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
    else if (ctiSection === 'Asset class other relevant information') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_CTI(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            x_00010_CTI_Portfolio_Identifying_Data: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            x_12010_CTI_Start_Asset_Value_Equities: this.state.x_12010_CTI_Start_Asset_Value_Equities,
            x_12020_CTI_End_Asset_Value_Equities: this.state.x_12020_CTI_End_Asset_Value_Equities,
            x_12030_CTI_Purchases_Equities: this.state.x_12030_CTI_Purchases_Equities,
            x_12040_CTI_Sales_Equities: this.state.x_12040_CTI_Sales_Equities,
            x_12110_CTI_Start_Asset_Value_Bonds: this.state.x_12110_CTI_Start_Asset_Value_Bonds,
            x_12120_CTI_End_Asset_Value_Bonds: this.state.x_12120_CTI_End_Asset_Value_Bonds,
            x_12130_CTI_Purchases_Bonds: this.state.x_12130_CTI_Purchases_Bonds,
            x_12140_CTI_Sales_Bonds: this.state.x_12140_CTI_Sales_Bonds,
            x_12210_CTI_Start_Asset_Value_Pooled: this.state.x_12210_CTI_Start_Asset_Value_Pooled,
            x_12220_CTI_End_Asset_Value_Pooled: this.state.x_12220_CTI_End_Asset_Value_Pooled,
            x_12230_CTI_Purchases_Pooled: this.state.x_12230_CTI_Purchases_Pooled,
            x_12240_CTI_Sales_Pooled: this.state.x_12240_CTI_Sales_Pooled,
            x_12310_CTI_Start_Asset_Value_ETD: this.state.x_12310_CTI_Start_Asset_Value_ETD,
            x_12320_CTI_End_Asset_Value_ETD: this.state.x_12320_CTI_End_Asset_Value_ETD,
            x_12410_CTI_Start_Asset_Value_OTCD: this.state.x_12410_CTI_Start_Asset_Value_OTCD,
            x_12420_CTI_End_Asset_Value_OTCD: this.state.x_12420_CTI_End_Asset_Value_OTCD,
            x_12510_CTI_Start_Asset_Value_FX: this.state.x_12510_CTI_Start_Asset_Value_FX,
            x_12520_CTI_End_Asset_Value_FX: this.state.x_12520_CTI_End_Asset_Value_FX,
            x_12610_CTI_Start_Asset_Value_MMI: this.state.x_12610_CTI_Start_Asset_Value_MMI,
            x_12620_CTI_End_Asset_Value_MMI: this.state.x_12620_CTI_End_Asset_Value_MMI,
            x_12630_CTI_Purchases_MMI: this.state.x_12630_CTI_Purchases_MMI,
            x_12640_CTI_Sales_MMI: this.state.x_12640_CTI_Sales_MMI,
            x_12710_CTI_Start_Asset_Value_Physical: this.state.x_12710_CTI_Start_Asset_Value_Physical,
            x_12720_CTI_End_Asset_Value_Physical: this.state.x_12720_CTI_End_Asset_Value_Physical,
            x_12730_CTI_Purchases_Physical: this.state.x_12730_CTI_Purchases_Physical,
            x_12740_CTI_Sales_Physical: this.state.x_12740_CTI_Sales_Physical,
            x_12810_CTI_Start_Asset_Value_Private: this.state.x_12810_CTI_Start_Asset_Value_Private,
            x_12820_CTI_End_Asset_Value_Private: this.state.x_12820_CTI_End_Asset_Value_Private,
            x_12830_CTI_Purchases_Private: this.state.x_12830_CTI_Purchases_Private,
            x_12840_CTI_Sales_Private: this.state.x_12840_CTI_Sales_Private,
            x_12910_CTI_Start_Asset_Value_Other: this.state.x_12910_CTI_Start_Asset_Value_Other,
            x_12920_CTI_End_Asset_Value_Other: this.state.x_12920_CTI_End_Asset_Value_Other,
            x_12930_CTI_Purchases_Other: this.state.x_12930_CTI_Purchases_Other,
            x_12940_CTI_Sales_Other: this.state.x_12940_CTI_Sales_Other,
          },
          'CTI saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `CTI Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }

  }

  handleSubmit(event, ctiSection) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true, Status: 'Ready for Review' });
      this.saveCTI(ctiSection);
      event.preventDefault();
      event.stopPropagation();
      // console.log('here')
    } else {
      event.preventDefault();
      this.setState({ validated: true, Status: 'Ready for Review' });
      this.saveCTI(ctiSection);
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    // console.log(target.name);

    this.setState({
      [name]: value,
    });
    sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
  }

  handleSelectChange(selectedItems) {
    this.setState({ selectedItems });
  }

  disableValidation() {
    this.setState({ validated: false });
  }

  handleApprove(event) {
    const modalContent = <div><p>Approving {this.state.Class_Code} CTI...</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);

    CDATA.makeRequest(
      'POST',
      'PRIIPS_App_APPROVE_CTI_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        RecordIDList: this.state.RECORD_ID.toString(),
        UserName: this.context.state.userEmail,
      },
      'Approve failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved the CTI for Portfolio: '}
              <span>{this.state.Class_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ action_type: 'View', status: 'Approved' });
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to approve the CTI for the Portfolio:'}
              <span> {this.state.Class_Code} </span>
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  handleReject(event) {
    const modalContent = <div><p>Re Opening {this.state.Class_Code} CTI...</p></div>;
    this.context.handlers.setModal('Re Opening ...', modalContent, null, null, true);

    CDATA.makeRequest(
      'POST',
      'PRIIPS_app_REOPEN_CTI_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        RecordIDList: this.state.RECORD_ID.toString(),
        UserName: this.context.state.userEmail,
      },
      'Re-Open failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'CTI re-opened successfully for the Portfolio: '}
              <span>{this.state.Class_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ action_type: 'Edit', status: 'Ready for review' });
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to re-open the CTI for the Portfolio'}
              <span> {this.state.Class_Code} </span>
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  refreshData() {
    this.setState({ loading: false }, () => this.saveCTI());
  }

  render() {
    return (
      this.state.redirect === true ? <Redirect to="/regulatory-reporting/cti/dashboard" /> : (
        <RegEditView>
          <RegEditBody>
            {
            // this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                <Navbar style={{ backgroundColor: '#e9e8f0' }}>
                  <Nav
                    class="nav nav-tabs"
                    style={{ backgroundColor: '#e9e8f0', marginLeft: -15 }}
                    onSelect={(selectedKey) => this.setState({ eventKey: selectedKey })}
                    onClick={() => { this.disableValidation(); }}
                  >
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Account information">Account information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Client Specific Information">Client Specific Information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Portfolio Transaction Cost">Portfolio Transaction Cost</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Ongoing charges">Ongoing charges</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Incidental costs">Incidental costs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Lending and borrowing costs">Lending and borrowing costs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Property Expenses">Property Expenses</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Other relevant information">Other relevant information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Narrative information">Narrative information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Asset class portfolio transaction costs">Asset class portfolio transaction costs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Asset class other relevant information">Asset class other relevant information</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Nav className="ml-auto" style={{ marginRight: '40px' }}>
                    {
                      this.state.action_type === 'Edit' ? (
                        <GButton
                          text="Approve"
                          type="Primary"
                          size="Medium"
                          onClick={() => this.handleApprove()}
                        />
                      ) : (
                        <GButton
                          text="Re-Open"
                          type="Primary"
                          size="Medium"
                          onClick={() => this.handleReject()}
                        />
                      )
                    }
                  </Nav>
                  <Nav>
                    <Navbar.Collapse className="justify-content-end">
                      <Navbar.Text>
                        {`${this.state.Class_Code} - ` }
                        <a style={{ color: '#6F08CD' }}>{this.state.eventKey}</a>
                      </Navbar.Text>
                    </Navbar.Collapse>
                    {
                      this.state.loading ? <Spinner text="Loading data..." /> : (
                        this.gridApi && (
                          <Toolbar
                            api={this.gridApi}
                            working={this.state.toolbarWorking}
                            exportName={this.state.api_name}
                            controls={this.state.toolbarButtons}
                            count={{
                              total: this.state.totalRowCount,
                              showing: this.state.displayedRowCount,
                            }}
                          />
                        )
                      )
                  }
                  </Nav>
                </Navbar>
                <Row style={{ display: 'flex', flexDirection: '1', flexGrow: '1' }} className="no-padding">
                  {
                    this.state.eventKey === 'Account information' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Account information')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00010_CTI_Portfolio_Identifying_Data'}
                                  >
                                    Identification of the fund or share class or segregated account
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00010_CTI_Portfolio_Identifying_Data.controlID">
                                  <Form.Label>00010_CTI_Portfolio_Identifying_Data</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00010_CTI_Portfolio_Identifying_Data"
                                    value={this.state.x_00010_CTI_Portfolio_Identifying_Data}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    One of the following options is required in this field: ISIN, CUSIP, SEDOL, WKN , Bloomberg Ticker, BBGID, Reuters RIC, FIGI, LEI, Other code by members of the Association of National Numbering Agencies, Code attributed by the undertaking
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio'}>
                                    Codification chosen to identify the share of the CIS
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio.controlID">
                                  <Form.Label>00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio"
                                    value={this.state.x_00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: 1, 2, 3, 4, 5, 6, 7, 8, 9, 99.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00030_Portfolio_Name'}>
                                    Name of the Portfolio or name of the CIS
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00030_CTI_Portfolio_Name.controlID">
                                  <Form.Label>00030_CTI_Portfolio_Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00030_CTI_Portfolio_Name"
                                    value={this.state.x_00030_Portfolio_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00040_Portfolio_Issuer_Name'}>
                                    Name of issuer of the fund or share class or segregated account manager
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00040_CTI_Portfolio_Issuer_Name.controlID">
                                  <Form.Label>00040_CTI_Portfolio_Issuer_Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00040_CTI_Portfolio_Issuer_Name"
                                    value={this.state.x_00040_CTI_Portfolio_Issuer_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00050_CTI_Start_Of_Period'}>
                                    Date of the first day to which the data refers
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00050_CTI_Start_Of_Period.controlID">
                                  <Form.Label>00050_CTI_Start_Of_Period</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00050_CTI_Start_Of_Period"
                                    value={this.state.x_00050_Start_Of_Period}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00060_CTI_End_Of_Period'}>
                                    Date of the last day to which the data refers
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00060_CTI_End_Of_Period.controlID">
                                  <Form.Label>00060_CTI_End_Of_Period</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00060_CTI_End_Of_Period"
                                    value={this.state.x_00060_End_Of_Period}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00070_CTI_Currency_Of_Report'}
                                  >
                                    Currency in which monetary cost data is denominated in this report
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00070_CTI_Currency_Of_Report.controlID">
                                  <Form.Label>00070_CTI_Currency_Of_Report</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00070_CTI_Currency_Of_Report"
                                    value={this.state.x_00070_CTI_Currency_Of_Report}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="ISO 4217 Currency Code"
                                    pattern="(AED|AFN|ALL|AMD|ANG|AOA|ARS|AUD|AWG|AZN|BAM|BBD|BDT|BGN|BHD|BIF|BMD|BND|BOB|BOV|BRL|BSD|BTN|BWP|BYN|BZD|CAD|CDF|CHE|CHF|CHW|CLF|CLP|CNY|COP|COU|CRC|CUC|CUP|CVE|CZK|DJF|DKK|DOP|DZD|EGP|ERN|ETB|EUR|FJD|FKP|GBP|GEL|GHS|GIP|GMD|GNF|GTQ|GYD|HKD|HNL|HRK|HTG|HUF|IDR|ILS|INR|IQD|IRR|ISK|JMD|JOD|JPY|KES|KGS|KHR|KMF|KPW|KRW|KWD|KYD|KZT|LAK|LBP|LKR|LRD|LSL|LYD|MAD|MDL|MGA|MKD|MMK|MNT|MOP|MRU[13]|MUR|MVR|MWK|MXN|MXV|MYR|MZN|NAD|NGN|NIO|NOK|NPR|NZD|OMR|PAB|PEN|PGK|PHP|PKR|PLN|PYG|QAR|RON|RSD|RUB|RWF|SAR|SBD|SCR|SDG|SEK|SGD|SHP|SLL|SOS|SRD|SSP|STN[15]|SVC|SYP|SZL|THB|TJS|TMT|TND|TOP|TRY|TTD|TWD|TZS|UAH|UGX|USD|USN|UYI|UYU|UYW|UZS|VES|VND|VUV|WST|XAF|XAG|XAU|XBA|XBB|XBC|XBD|XCD|XDR|XOF|XPD|XPF|XPT|XSU|XTS|XUA|XXX|YER|ZAR|ZMW|ZWL)"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Invalid pattern. Must be a three character code. See Code ISO 4217
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00080_CTI_Fund_Or_Mandate'}
                                  >
                                    Indicator to show whether report relates to a pooled fund (F) or a segregated mandate (M)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00080_CTI_Fund_Or_Mandate.controlID">
                                  <Form.Label>00080_CTI_Fund_Or_Mandate</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00080_CTI_Fund_Or_Mandate"
                                    value={this.state.x_00080_CTI_Fund_Or_Mandate}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="F/M"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Must be one of the following F/M
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              </Col>
                              <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-Reporting_Period'}
                                  >
                                    Identification of the Reporting Period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="Reporting_Period.controlID">
                                  <Form.Label>Reporting_Period</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_Reference_Date"
                                    value={this.state.x_Reference_Date}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  This field is required and must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00090_CTI_Client_Only_Report'}
                                  >
                                    Affirms the report contains client specific data
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00090_CTI_Client_Only_Report.controlID">
                                  <Form.Label>00090_CTI_Client_Only_Report</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00090_CTI_Client_Only_Report"
                                    value={this.state.x_00090_CTI_Client_Only_Report}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00100_CTI_Underlying_Investment_Vehicles_Used'}
                                  >
                                    Affirms that investments are made in underlying investment trusts or pooled funds
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00100_CTI_Underlying_Investment_Vehicles_Used.controlID">
                                  <Form.Label>00100_CTI_Underlying_Investment_Vehicles_Used</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00100_CTI_Underlying_Investment_Vehicles_Used"
                                    value={this.state.x_00100_CTI_Underlying_Investment_Vehicles_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00110_CTI_Performance_Fee_Applicable'}
                                  >
                                    Affirms that a performance fee arrangement exists
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00110_CTI_Performance_Fee_Applicable.controlID">
                                  <Form.Label>00110_CTI_Performance_Fee_Applicable</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00110_CTI_Performance_Fee_Applicable"
                                    value={this.state.x_00110_CTI_Performance_Fee_Applicable}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00120_CTI_Securities_Lending_Applicable'}
                                  >
                                    Affirms that securities lending is permitted
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00120_CTI_Securities_Lending_Applicable.controlID">
                                  <Form.Label>00120_CTI_Securities_Lending_Applicable</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00120_CTI_Securities_Lending_Applicable"
                                    value={this.state.x_00120_CTI_Securities_Lending_Applicable}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00130_CTI_Securities_Lending_Agent_Name'}
                                  >
                                    Name of any third party securities lending agent that is neither the manager nor the custodian
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00130_CTI_Securities_Lending_Agent_Name.controlID">
                                  <Form.Label>00130_CTI_Securities_Lending_Agent_Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00130_CTI_Securities_Lending_Agent_Name"
                                    value={this.state.x_00130_CTI_Securities_Lending_Agent_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00140_CTI_Direct_Property_Exposure'}
                                  >
                                    Affirms an exposure to direct property holdings including that obtained via pooled vehicles
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00140_CTI_Direct_Property_Exposure.controlID">
                                  <Form.Label>00140_CTI_Direct_Property_Exposure</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00140_CTI_Direct_Property_Exposure"
                                    value={this.state.x_00140_CTI_Direct_Property_Exposure}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00150_CTI_Asset_Class_Breakdown_Provided'}
                                  >
                                    Affirms that the report includes an analysis of transaction costs for each asset class
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00150_CTI_Asset_Class_Breakdown_Provided.controlID">
                                  <Form.Label>00150_CTI_Asset_Class_Breakdown_Provided</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00150_CTI_Asset_Class_Breakdown_Provided"
                                    value={this.state.x_00150_CTI_Asset_Class_Breakdown_Provided}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                      variant="primary" type="submit">Save</Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Client Specific Information' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Client Specific Information')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02010_CTI_Client_Name'}>
                                    Name of the client to which the report relates
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02010_CTI_Client_Name.controlID">
                                  <Form.Label>02010_CTI_Client_Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02010_CTI_Client_Name"
                                    value={this.state.x_01010_Percentage_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02020_CTI_Client_Average_Value'}>
                                    Average value of the client's holding in a pooled vehicle or average total net asset value of a segregated mandate
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02020_CTI_Client_Average_Value.controlID">
                                  <Form.Label>02020_CTI_Client_Average_Value</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02020_CTI_Client_Average_Value"
                                    value={this.state.x_01020_Categories_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02030_CTI_Entry_Costs'}>
                                    All costs and charges paid to the investment firm at the beginning of the provided investment service(s)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02030_CTI_Entry_Costs.controlID">
                                  <Form.Label>01030_Transaction_Costs_Methodology</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02030_CTI_Entry_Costs"
                                    value={this.state.x_02030_CTI_Entry_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              </Col>
                              <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02040_CTI_Exit_Costs'}>
                                    All costs and charges paid to the investment firm at the end of the provided investment service(s)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02040_CTI_Exit_Costs.controlID">
                                  <Form.Label>02040_CTI_Exit_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02040_CTI_Exit_Costs"
                                    value={this.state.x_02040_CTI_Exit_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02050_CTI_Client_FX_Costs'}>
                                    Transaction costs for converting client flows to the currency of the fund or share class
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02050_CTI_Client_FX_Costs.controlID">
                                  <Form.Label>02040_CTI_Exit_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02050_CTI_Client_FX_Costs"
                                    value={this.state.x_02050_CTI_Client_FX_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                      variant="primary" type="submit"
                                    >Save</Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary" onClick={() => { this.setState({ redirect: true }); }}>
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Portfolio Transaction Cost' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Portfolio Transaction Cost')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03010_CTI_Total_Transaction_Costs'}>
                                    TOTAL: 03020 + 03070 + 03080 - 03090
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03010_CTI_Total_Transaction_Costs.controlID">
                                  <Form.Label>03010_CTI_Total_Transaction_Costs</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_03010_CTI_Total_Transaction_Costs"
                                    value={this.state.x_03010_CTI_Total_Transaction_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03020_CTI_Total_Explicit_Costs'}>
                                    TOTAL: SUM(03030:03060)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03020_CTI_Total_Explicit_Costs.controlID">
                                  <Form.Label>03020_CTI_Total_Explicit_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03020_CTI_Total_Explicit_Costs"
                                    value={this.state.x_03020_CTI_Total_Explicit_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03030_CTI_Explicit_Transaction_Taxes'}>
                                    Transaction taxes such as stamp duty and other financial transaction taxes
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03030_CTI_Explicit_Transaction_Taxes.controlID">
                                  <Form.Label>03030_CTI_Explicit_Transaction_Taxes</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03030_CTI_Explicit_Transaction_Taxes"
                                    value={this.state.x_03030_CTI_Explicit_Transaction_Taxes}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees'}>
                                    Fees and commissions paid to brokers or equivalent (eg. agent's fees)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees.controlID">
                                  <Form.Label>03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees"
                                    value={this.state.x_03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03050_CTI_Explicit_Transaction_Related_Services'}>
                                    Transaction service costs such as appraisals, surveys and studies, searches, due diligence, legal and notary services
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03050_CTI_Explicit_Transaction_Related_Services.controlID">
                                  <Form.Label>03050_CTI_Explicit_Transaction_Related_Services</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03050_CTI_Explicit_Transaction_Related_Services"
                                    value={this.state.x_03050_CTI_Explicit_Transaction_Related_Services}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03060_CTI_Explicit_Other_Charges'}>
                                    Other explicit transaction cost not included in 03030, 03040 or 03050. Includes any entry or exit charges for investing in underlying pooled funds
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03060_CTI_Explicit_Other_Charges.controlID">
                                  <Form.Label>03060_CTI_Explicit_Other_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03060_CTI_Explicit_Other_Charges"
                                    value={this.state.x_03060_CTI_Explicit_Other_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03070_CTI_Implicit_Costs'}>
                                    Implicit transaction costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03070_CTI_Implicit_Costs.controlID">
                                  <Form.Label>03070_CTI_Implicit_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03070_CTI_Implicit_Costs"
                                    value={this.state.x_03070_CTI_Implicit_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03080_CTI_Indirect_Transaction_Costs'}>
                                    Transaction costs incurred in an underlying investment vehicle
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03080_CTI_Indirect_Transaction_Costs.controlID">
                                  <Form.Label>03080_CTI_Indirect_Transaction_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03080_CTI_Indirect_Transaction_Costs"
                                    value={this.state.x_03080_CTI_Indirect_Transaction_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03090_CTI_Anti_Dilution_Offset'}>
                                    Anti-dilution amount collected as part of the price of creations or cancellations of units
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03090_CTI_Anti_Dilution_Offset.controlID">
                                  <Form.Label>03090_CTI_Anti_Dilution_Offset</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03090_CTI_Anti_Dilution_Offset"
                                    value={this.state.x_03090_CTI_Anti_Dilution_Offset}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                           </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Ongoing charges' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Ongoing charges')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04010_CTI_Ongoing_Charges'}>
                                    TOTAL: 04100 + 04200 + 04300 + 04400 + 06010
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04010_CTI_Ongoing_Charges.controlID">
                                  <Form.Label>04010_CTI_Ongoing_Charges</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_04010_CTI_Ongoing_Charges"
                                    value={this.state.x_04010_CTI_Ongoing_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04100_CTI_Fund_And_Management_Charges'}>
                                    TOTAL: 04110 + 04120 + 04130 + 04140 - 04150 + 04160
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04100_CTI_Fund_And_Management_Charges.controlID">
                                  <Form.Label>04100_CTI_Fund_And_Management_Charges</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_04100_CTI_Fund_And_Management_Charges"
                                    value={this.state.x_04100_CTI_Fund_And_Management_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04110_CTI_Invoiced_Fees'}>
                                    Invoiced management fees including irrecoverable VAT
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04110_CTI_Invoiced_Fees.controlID">
                                  <Form.Label>04110_CTI_Invoiced_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04110_CTI_Invoiced_Fees"
                                    value={this.state.x_04110_CTI_Invoiced_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04120_CTI_Fees_And_Charges_From_NAV'}>
                                    Ongoing charges of the pooled vehicle excluding any investment advisors fees, research payments and indirect fees and charges
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04120_CTI_Fees_And_Charges_From_NAV.controlID">
                                  <Form.Label>04120_CTI_Fees_And_Charges_From_NAV</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04120_CTI_Fees_And_Charges_From_NAV"
                                    value={this.state.x_04120_CTI_Fees_And_Charges_From_NAV}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04130_CTI_Investment_Advisors_Fees_From_NAV'}>
                                    Third party investment management fees
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04130_CTI_Investment_Advisors_Fees_From_NAV.controlID">
                                  <Form.Label>04130_CTI_Investment_Advisors_Fees_From_NAV</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04130_CTI_Investment_Advisors_Fees_From_NAV"
                                    value={this.state.x_04130_CTI_Investment_Advisors_Fees_From_NAV}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04140_CTI_Research_Payments_From_NAV'}>
                                    Research charge to the fund in order to fund an RPA
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04140_CTI_Research_Payments_From_NAV.controlID">
                                  <Form.Label>04140_CTI_Research_Payments_From_NAV</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04140_CTI_Research_Payments_From_NAV"
                                    value={this.state.x_04140_CTI_Research_Payments_From_NAV}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04150_CTI_Management_Fee_Rebates'}>
                                    Rebates of fees taken from NAV paid to the client
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04150_CTI_Management_Fee_Rebates.controlID">
                                  <Form.Label>04150_CTI_Management_Fee_Rebates</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04150_CTI_Management_Fee_Rebates"
                                    value={this.state.x_04150_CTI_Management_Fee_Rebates}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04160_CTI_Indirect_Fees_And_Charges'}>
                                    Fees and charges incurred in an underlying investment vehicle (both pooled funds and investment trusts)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04160_CTI_Indirect_Fees_And_Charges.controlID">
                                  <Form.Label>04160_CTI_Indirect_Fees_And_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04160_CTI_Indirect_Fees_And_Charges"
                                    value={this.state.x_04160_CTI_Indirect_Fees_And_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04200_CTI_Administration_Charges'}>
                                    Charges in relation to administrative services provided to the client
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04200_CTI_Administration_Charges.controlID">
                                  <Form.Label>04200_CTI_Administration_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04200_CTI_Administration_Charges"
                                    value={this.state.x_04200_CTI_Administration_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04210_CTI_Investment_Administration_Fees'}>
                                    Investment Administration Fees
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04210_CTI_Investment_Administration_Fees.controlID">
                                  <Form.Label>04210_CTI_Investment_Administration_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04210_CTI_Investment_Administration_Fees"
                                    value={this.state.x_04210_CTI_Investment_Administration_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04220_CTI_Custody_And_Depositary_Fees'}>
                                    Custody and Depositary Fees
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04220_CTI_Custody_And_Depositary_Fees.controlID">
                                  <Form.Label>04220_CTI_Custody_And_Depositary_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04220_CTI_Custody_And_Depositary_Fees"
                                    value={this.state.x_04220_CTI_Custody_And_Depositary_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04230_CTI_Research_Payments'}>
                                    Research charge to the client in order to fund an RPA
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04230_CTI_Research_Payments.controlID">
                                  <Form.Label>04230_CTI_Research_Payments</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04230_CTI_Research_Payments"
                                    value={this.state.x_04230_CTI_Research_Payments}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04240_CTI_Collateral_Management_Fees'}>
                                    Collateral management fees
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04240_CTI_Collateral_Management_Fees.controlID">
                                  <Form.Label>04240_CTI_Collateral_Management_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04240_CTI_Collateral_Management_Fees"
                                    value={this.state.x_04240_CTI_Collateral_Management_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04250_CTI_Facility_Fees'}>
                                    Facility fees
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04250_CTI_Facility_Fees.controlID">
                                  <Form.Label>04250_CTI_Facility_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04250_CTI_Facility_Fees"
                                    value={this.state.x_04250_CTI_Facility_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04290_CTI_Other_Administration_Charges'}>
                                    Other administration charges
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04290_CTI_Other_Administration_Charges.controlID">
                                  <Form.Label>04290_CTI_Other_Administration_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04290_CTI_Other_Administration_Charges"
                                    value={this.state.x_04290_CTI_Other_Administration_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04300_CTI_Governance_Regulation_Compliance_Charges'}>
                                    Charges in relation to governance, regulatory and compliance services provided to the client
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04300_CTI_Governance_Regulation_Compliance_Charges.controlID">
                                  <Form.Label>04300_CTI_Governance_Regulation_Compliance_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04300_CTI_Governance_Regulation_Compliance_Charges"
                                    value={this.state.x_04300_CTI_Governance_Regulation_Compliance_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04310_CTI_Audit_Costs'}>
                                    Audit costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04310_CTI_Audit_Costs.controlID">
                                  <Form.Label>04310_CTI_Audit_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04310_CTI_Audit_Costs"
                                    value={this.state.x_04310_CTI_Audit_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04320_CTI_Legal_And_Professional_Fees'}>
                                    Legal and professional fees
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04320_CTI_Legal_And_Professional_Fees.controlID">
                                  <Form.Label>04320_CTI_Legal_And_Professional_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04320_CTI_Legal_And_Professional_Fees"
                                    value={this.state.x_04320_CTI_Legal_And_Professional_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04330_CTI_Engagement_And_Voting_Fees'}>
                                    Engagement and voting 04120_CTI_Fees_And_Charges_From_NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04330_CTI_Engagement_And_Voting_Fees.controlID">
                                  <Form.Label>04330_CTI_Engagement_And_Voting_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04330_CTI_Engagement_And_Voting_Fees"
                                    value={this.state.x_04330_CTI_Engagement_And_Voting_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04340_CTI_Performance_Measurement'}>
                                   Performance Measurement
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04340_CTI_Performance_Measurement.controlID">
                                  <Form.Label>04340_CTI_Performance_Measurement</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04340_CTI_Performance_Measurement"
                                    value={this.state.x_04340_CTI_Performance_Measurement}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04350_CTI_Risk_Monitoring'}>
                                   Risk monitoring
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04350_CTI_Risk_Monitoring.controlID">
                                  <Form.Label>04350_CTI_Risk_Monitoring</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04350_CTI_Risk_Monitoring"
                                    value={this.state.x_04350_CTI_Risk_Monitoring}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04360_CTI_Tax_Advice_And_Structuring_Costs'}>
                                   Tax advice and structuring costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04360_CTI_Tax_Advice_And_Structuring_Costs.controlID">
                                  <Form.Label>04360_CTI_Tax_Advice_And_Structuring_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04360_CTI_Tax_Advice_And_Structuring_Costs"
                                    value={this.state.x_04360_CTI_Tax_Advice_And_Structuring_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04390_CTI_Other_Governance_Regulation_Compliance_Charges'}>
                                   Other governance regulation and compliance charges
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04390_CTI_Other_Governance_Regulation_Compliance_Charges.controlID">
                                  <Form.Label>04390_CTI_Other_Governance_Regulation_Compliance_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04390_CTI_Other_Governance_Regulation_Compliance_Charges"
                                    value={this.state.x_04390_CTI_Other_Governance_Regulation_Compliance_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04400_CTI_Distribution_Communication_Client_Service_Charges'}>
                                   Charges in relation to distribution, communication and client services provided to the client
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04400_CTI_Distribution_Communication_Client_Service_Charges.controlID">
                                  <Form.Label>04400_CTI_Distribution_Communication_Client_Service_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04400_CTI_Distribution_Communication_Client_Service_Charges"
                                    value={this.state.x_04400_CTI_Distribution_Communication_Client_Service_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04410_CTI_Distribution_Costs'}>
                                    Distribution Costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04410_CTI_Distribution_Costs.controlID">
                                  <Form.Label>04410_CTI_Distribution_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04410_CTI_Distribution_Costs"
                                    value={this.state.x_04410_CTI_Distribution_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04420_CTI_Communication_Material'}>
                                   Communication Material
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04420_CTI_Communication_Material.controlID">
                                  <Form.Label>04420_CTI_Communication_Material</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04420_CTI_Communication_Material"
                                    value={this.state.x_04420_CTI_Communication_Material}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04490_CTI_Other_Distribution_Communication_Client_Service_Charges'}>

                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04490_CTI_Other_Distribution_Communication_Client_Service_Charges.controlID">
                                  <Form.Label>04490_CTI_Other_Distribution_Communication_Client_Service_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04490_CTI_Other_Distribution_Communication_Client_Service_Charges"
                                    value={this.state.x_04490_CTI_Other_Distribution_Communication_Client_Service_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Incidental costs' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Incidental costs')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-05010_CTI_Incidental_Costs'}>
                                    TOTAL: 05020 + 05030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05010_CTI_Incidental_Costs.controlID">
                                  <Form.Label>05010_CTI_Incidental_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05010_CTI_Incidental_Costs"
                                    value={this.state.x_01010_Percentage_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-05020_CTI_Performance_Fees_Invoiced'}>
                                    Invoiced performance related fees including irrecoverable VAT
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05020_CTI_Performance_Fees_Invoiced.controlID">
                                  <Form.Label>05020_CTI_Performance_Fees_Invoiced</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05020_CTI_Performance_Fees_Invoiced"
                                    value={this.state.x_05020_CTI_Performance_Fees_Invoiced}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-05030_CTI_Performance_Fees_From_NAV'}>
                                    Performance fee and carried interest charges for the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="05030_CTI_Performance_Fees_From_NAV.controlID">
                                  <Form.Label>05030_CTI_Performance_Fees_From_NAV</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_05030_CTI_Performance_Fees_From_NAV"
                                    value={this.state.x_05030_CTI_Performance_Fees_From_NAV}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="secondary" onClick={() => { this.setState({ redirect: true }); }}>
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Lending and borrowing costs' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Lending and borrowing costs')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06010_CTI_Lending_And_Borrowing_Costs'}>
                                    TOTAL: SUM(06050:06110)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06010_CTI_Lending_And_Borrowing_Costs.controlID">
                                  <Form.Label>06010_CTI_Lending_And_Borrowing_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_006010_CTI_Lending_And_Borrowing_Costs"
                                    value={this.state.x_006010_CTI_Lending_And_Borrowing_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06020_CTI_Securities_Lending_Value_On_Loan'}>
                                    Value of assets on loan at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06020_CTI_Securities_Lending_Value_On_Loan.controlID">
                                  <Form.Label>06020_CTI_Securities_Lending_Value_On_Loan</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06020_CTI_Securities_Lending_Value_On_Loan"
                                    value={this.state.x_06020_CTI_Securities_Lending_Value_On_Loan}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06030_CTI_Gross_Securities_Lending_Revenue'}>
                                    The amounts paid by the ultimate borrowers in respect of securities lent during the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06030_CTI_Gross_Securities_Lending_Revenue.controlID">
                                  <Form.Label>06030_CTI_Gross_Securities_Lending_Revenue</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06030_CTI_Gross_Securities_Lending_Revenue"
                                    value={this.state.x_06030_CTI_Gross_Securities_Lending_Revenue}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06040_CTI_Securities_Lending_Revenue_Portfolio_Share'}>
                                    "TOTAL: 06030 - 06050 - 06060 - 06070. The amount of income to the client or fund from securities lending activities"
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06040_CTI_Securities_Lending_Revenue_Portfolio_Share.controlID">
                                  <Form.Label>06040_CTI_Securities_Lending_Revenue_Portfolio_Share</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06040_CTI_Securities_Lending_Revenue_Portfolio_Share"
                                    value={this.state.x_06040_CTI_Securities_Lending_Revenue_Portfolio_Share}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06050_CTI_Securities_Lending_Fees_Custodian_Share'}>
                                    The amount of securities lending revenue retained by the custodian
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06050_CTI_Securities_Lending_Fees_Custodian_Share.controlID">
                                  <Form.Label>06050_CTI_Securities_Lending_Fees_Custodian_Share</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06050_CTI_Securities_Lending_Fees_Custodian_Share"
                                    value={this.state.x_06050_CTI_Securities_Lending_Fees_Custodian_Share}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06060_CTI_Securities_Lending_Fees_Manager_Share'}>
                                    The amount of securities lending revenue retained by the manager
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06060_CTI_Securities_Lending_Fees_Manager_Share.controlID">
                                  <Form.Label>06060_CTI_Securities_Lending_Fees_Manager_Share</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06060_CTI_Securities_Lending_Fees_Manager_Share"
                                    value={this.state.x_06060_CTI_Securities_Lending_Fees_Manager_Share}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06070_CTI_Securities_Lending_Fees_Agent_Share'}>
                                    The amount of securities lending revenue retained by the lending agent named in 00130
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06070_CTI_Securities_Lending_Fees_Agent_Share.controlID">
                                  <Form.Label>06070_CTI_Securities_Lending_Fees_Agent_Share</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06070_CTI_Securities_Lending_Fees_Agent_Share"
                                    value={this.state.x_06070_CTI_Securities_Lending_Fees_Agent_Share}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06080_CTI_Securities_Borrowing_Fees'}>
                                    The total amount paid for borrowing securities
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06080_CTI_Securities_Borrowing_Fees.controlID">
                                  <Form.Label>06080_CTI_Securities_Borrowing_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06080_CTI_Securities_Borrowing_Fees"
                                    value={this.state.x_06080_CTI_Securities_Borrowing_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06100_CTI_Interest_On_Borrowing'}>
                                    The total interest paid on borrowings
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06100_CTI_Interest_On_Borrowing.controlID">
                                  <Form.Label>06100_CTI_Interest_On_Borrowing</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06100_CTI_Interest_On_Borrowing"
                                    value={this.state.x_06100_CTI_Interest_On_Borrowing}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-06110_CTI_Borrowing_And_Arrangement_Fees'}>
                                    Fees for setting up debt facility
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="06110_CTI_Borrowing_And_Arrangement_Fees.controlID">
                                  <Form.Label>06110_CTI_Borrowing_And_Arrangement_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_06110_CTI_Borrowing_And_Arrangement_Fees"
                                    value={this.state.x_06110_CTI_Borrowing_And_Arrangement_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="secondary" onClick={() => { this.setState({ redirect: true }); }}>
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                   {
                    this.state.eventKey === 'Property Expenses' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Property Expenses')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07010_CTI_Property_Expenses'}>
                                    Property Expenses
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07010_CTI_Property_Expenses.controlID">
                                  <Form.Label>07010_CTI_Property_Expenses</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07010_CTI_Property_Expenses"
                                    value={this.state.x_07010_CTI_Property_Expenses}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07020_CTI_Property_Management_Fees'}>
                                    Fees and charges for management of leases and services
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07020_CTI_Property_Management_Fees.controlID">
                                  <Form.Label>07020_CTI_Property_Management_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07020_CTI_Property_Management_Fees"
                                    value={this.state.x_07020_CTI_Property_Management_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07030_CTI_Property_Leasing_Costs'}>
                                    Lease negotiation and renewals, letting costs, marketing vacant space, rent reviews, associated legal costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07030_CTI_Property_Leasing_Costs.controlID">
                                  <Form.Label>07030_CTI_Property_Leasing_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07030_CTI_Property_Leasing_Costs"
                                    value={this.state.x_07030_CTI_Property_Leasing_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07040_CTI_Property_Maintenance_And_Repairs'}>
                                    Maintenance and repairs, excluding improvements
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07040_CTI_Property_Maintenance_And_Repairs.controlID">
                                  <Form.Label>07040_CTI_Property_Maintenance_And_Repairs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07040_CTI_Property_Maintenance_And_Repairs"
                                    value={this.state.x_07040_CTI_Property_Maintenance_And_Repairs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07050_CTI_Property_Utilities_And_Service_Charges'}>
                                    Costs of services supplied to the property to the extent not recoverable from tenants. Excludes void costs which are reported in 07060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07050_CTI_Property_Utilities_And_Service_Charges.controlID">
                                  <Form.Label>07050_CTI_Property_Utilities_And_Service_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07050_CTI_Property_Utilities_And_Service_Charges"
                                    value={this.state.x_07050_CTI_Property_Utilities_And_Service_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07060_CTI_Property_Void_Costs'}>
                                    Costs associated with unoccupied space
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07060_CTI_Property_Void_Costs.controlID">
                                  <Form.Label>07060_CTI_Property_Void_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07060_CTI_Property_Void_Costs"
                                    value={this.state.x_07060_CTI_Property_Void_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07070_CTI_Property_Insurance_Costs'}>
                                    Property insurance costs including rebates
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07070_CTI_Property_Insurance_Costs.controlID">
                                  <Form.Label>07070_CTI_Property_Insurance_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07070_CTI_Property_Insurance_Costs"
                                    value={this.state.x_07070_CTI_Property_Insurance_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07080_CTI_Property_Valuation_Fees'}>
                                    Fees for independent valuation of property assets
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07080_CTI_Property_Valuation_Fees.controlID">
                                  <Form.Label>07080_CTI_Property_Valuation_Fees</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07080_CTI_Property_Valuation_Fees"
                                    value={this.state.x_07080_CTI_Property_Valuation_Fees}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07090_CTI_Property_Failed_Transaction_Costs'}>
                                    Costs associated with failed and aborted transactions
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07090_CTI_Property_Failed_Transaction_Costs.controlID">
                                  <Form.Label>07090_CTI_Property_Failed_Transaction_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07090_CTI_Property_Failed_Transaction_Costs"
                                    value={this.state.x_07090_CTI_Property_Failed_Transaction_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07100_CTI_Indirect_Property_Expenses'}>
                                    Property expenses incurred in an underlying investment vehicle (both pooled funds and investment trusts)
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07100_CTI_Indirect_Property_Expenses.controlID">
                                  <Form.Label>07100_CTI_Indirect_Property_Expenses</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07100_CTI_Indirect_Property_Expenses"
                                    value={this.state.x_07100_CTI_Indirect_Property_Expenses}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-07110_CTI_Other_Property_Expenses'}>
                                    Other expenses associated with directly held property assets
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="07110_CTI_Other_Property_Expenses.controlID">
                                  <Form.Label>07110_CTI_Other_Property_Expenses</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_07110_CTI_Other_Property_Expenses"
                                    value={this.state.x_07110_CTI_Other_Property_Expenses}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="secondary" onClick={() => { this.setState({ redirect: true }); }}>
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                   {
                    this.state.eventKey === 'Other relevant information' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Other relevant information')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08010_CTI_Gross_Return'}>
                                    Gross investment return for the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08010_CTI_Gross_Return.controlID">
                                  <Form.Label>08010_CTI_Gross_Return</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08010_CTI_Gross_Return"
                                    value={this.state.x_01010_Percentage_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08020_CTI_Net_Return'}>
                                    Net investment return for the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08020_CTI_Net_Return.controlID">
                                  <Form.Label>08020_CTI_Net_Return</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08020_CTI_Net_Return"
                                    value={this.state.x_08020_CTI_Net_Return}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08110_CTI_Start_Asset_Value'}>
                                    Net asset value at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08110_CTI_Start_Asset_Value.controlID">
                                  <Form.Label>08110_CTI_Start_Asset_Value</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_08110_CTI_Start_Asset_Value"
                                    value={this.state.x_08110_CTI_Start_Asset_Value}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08120_CTI_End_Asset_Value'}>
                                    Net asset value at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08120_CTI_End_Asset_Value.controlID">
                                  <Form.Label>08120_CTI_End_Asset_Value</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_08120_CTI_End_Asset_Value"
                                    value={this.state.x_08120_CTI_End_Asset_Value}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08130_CTI_Portfolio_Purchases'}>
                                    Total consideration paid for the purchase of assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08130_CTI_Portfolio_Purchases.controlID">
                                  <Form.Label>08130_CTI_Portfolio_Purchases</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_08130_CTI_Portfolio_Purchases"
                                    value={this.state.x_08130_CTI_Portfolio_Purchases}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08140_CTI_Portfolio_Sales'}>
                                    Total consideration received from the sale of assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08140_CTI_Portfolio_Sales.controlID">
                                  <Form.Label>08140_CTI_Portfolio_Sales</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor:'#F8F8FF' }}
                                    name="x_08140_CTI_Portfolio_Sales"
                                    value={this.state.x_08140_CTI_Portfolio_Sales}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08150_CTI_Fund_Inflows'}>
                                    Net inflow of new money to the fund during the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08150_CTI_Fund_Inflows.controlID">
                                  <Form.Label>08150_CTI_Fund_Inflows</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08150_CTI_Fund_Inflows"
                                    value={this.state.x_08150_CTI_Fund_Inflows}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08160_CTI_Fund_Outflows'}>
                                    Net outflow of money from the fund during the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08160_CTI_Fund_Outflows.controlID">
                                  <Form.Label>08160_CTI_Fund_Outflows</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08160_CTI_Fund_Outflows"
                                    value={this.state.x_08160_CTI_Fund_Outflows}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08210_CTI_LDI_PV01_Net_Traded'}>
                                    Total consideration of assets (including OTC) traded during the reporting period in terms of PV01
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08210_CTI_LDI_PV01_Net_Traded.controlID">
                                  <Form.Label>08210_CTI_LDI_PV01_Net_Traded</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08210_CTI_LDI_PV01_Net_Traded"
                                    value={this.state.x_08210_CTI_LDI_PV01_Net_Traded}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08220_CTI_LDI_IE01_Net_Traded'}>
                                    Total consideration of assets (including OTC) traded during the reporting period in terms of IE01
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08220_CTI_LDI_IE01_Net_Traded.controlID">
                                  <Form.Label>08220_CTI_LDI_IE01_Net_Traded</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08220_CTI_LDI_IE01_Net_Traded"
                                    value={this.state.x_08220_CTI_LDI_IE01_Net_Traded}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08230_CTI_LDI_PV01_Transaction_Cost'}>
                                    Total Transaction cost of instruments traded using PV01
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08230_CTI_LDI_PV01_Transaction_Cost.controlID">
                                  <Form.Label>08230_CTI_LDI_PV01_Transaction_Cost</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08230_CTI_LDI_PV01_Transaction_Cost"
                                    value={this.state.x_08230_CTI_LDI_PV01_Transaction_Cost}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-08240_CTI_LDI_IE01_Transaction_Cost'}>
                                    Total Transaction cost of instruments traded using IE01
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="08240_CTI_LDI_IE01_Transaction_Cost.controlID">
                                  <Form.Label>08240_CTI_LDI_IE01_Transaction_Cost</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_08240_CTI_LDI_IE01_Transaction_Cost"
                                    value={this.state.x_08240_CTI_LDI_IE01_Transaction_Cost}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary" type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary" onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Narrative information' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Narrative information')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-09010_CTI_Transaction_Costs_Methodology'}>
                                    Description of the methodology used to estimate implicit transaction costs
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="09010_CTI_Transaction_Costs_Methodology.controlID">
                                  <Form.Label>09010_CTI_Transaction_Costs_Methodology</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_09010_CTI_Transaction_Costs_Methodology"
                                    value={this.state.x_09010_CTI_Transaction_Costs_Methodology}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="3000 Character Limit"
                                    maxLength="3000"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 3000 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-09020_CTI_Incidental_Costs_Methodology'}>
                                    Description of the methodology used to calculate performance fees or carried interests
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="09020_CTI_Incidental_Costs_Methodology.controlID">
                                  <Form.Label>09020_CTI_Incidental_Costs_Methodology</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_09020_CTI_Incidental_Costs_Methodology"
                                    value={this.state.x_09020_CTI_Incidental_Costs_Methodology}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="3000 Character Limit"
                                    maxLength="3000"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 3000 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-09030_CTI_Return_Methodology'}>
                                    Description of the methodology used to calculate returns
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="09030_CTI_Return_Methodology.controlID">
                                  <Form.Label>09030_CTI_Return_Methodology</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_09030_CTI_Return_Methodology"
                                    value={this.state.x_09030_CTI_Return_Methodology}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="3000 Character Limit"
                                    maxLength="3000"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 3000 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-09040_CTI_Additional_Notes'}>
                                    Free narrative
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="09040_CTI_Additional_Notes.controlID">
                                  <Form.Label>09040_CTI_Additional_Notes</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_09040_CTI_Additional_Notes"
                                    value={this.state.x_09040_CTI_Additional_Notes}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="3000 Character Limit"
                                    maxLength="3000"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 3000 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="secondary" onClick={() => { this.setState({ redirect: true }); }}>
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Asset class portfolio transaction costs' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Asset class portfolio transaction costs')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10000_CTI_Equities_Used'}>
                                    Affirms that transaction cost data is included in respect of listed equities
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10000_CTI_Equities_Used.controlID">
                                  <Form.Label>10000_CTI_Equities_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10000_CTI_Equities_Used"
                                    value={this.state.x_10000_CTI_Equities_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of Y/N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10030_CTI_Explicit_Transaction_Taxes_Equities'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10030_CTI_Explicit_Transaction_Taxes_Equities.controlID">
                                  <Form.Label>10030_CTI_Explicit_Transaction_Taxes_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10030_CTI_Explicit_Transaction_Taxes_Equities"
                                    value={this.state.x_10030_CTI_Explicit_Transaction_Taxes_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities.controlID">
                                  <Form.Label>10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities"
                                    value={this.state.x_10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10050_CTI_Explicit_Transaction_Related_Services_Equities'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10050_CTI_Explicit_Transaction_Related_Services_Equities.controlID">
                                  <Form.Label>10050_CTI_Explicit_Transaction_Related_Services_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10050_CTI_Explicit_Transaction_Related_Services_Equities"
                                    value={this.state.x_10050_CTI_Explicit_Transaction_Related_Services_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10060_CTI_Explicit_Other_Charges_Equities'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10060_CTI_Explicit_Other_Charges_Equities.controlID">
                                  <Form.Label>10060_CTI_Explicit_Other_Charges_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10060_CTI_Explicit_Other_Charges_Equities"
                                    value={this.state.x_10060_CTI_Explicit_Other_Charges_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10060_CTI_Explicit_Other_Charges_Equities'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10060_CTI_Explicit_Other_Charges_Equities.controlID">
                                  <Form.Label>10060_CTI_Explicit_Other_Charges_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10060_CTI_Explicit_Other_Charges_Equities"
                                    value={this.state.x_10060_CTI_Explicit_Other_Charges_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10080_CTI_Indirect_Transaction_Costs_Equities'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10080_CTI_Indirect_Transaction_Costs_Equities.controlID">
                                  <Form.Label>10080_CTI_Indirect_Transaction_Costs_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10080_CTI_Indirect_Transaction_Costs_Equities"
                                    value={this.state.x_10080_CTI_Indirect_Transaction_Costs_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10100_CTI_Bonds_Used'}>
                                    Affirms that transaction cost data is included in respect of debt instruments
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10100_CTI_Bonds_Used.controlID">
                                  <Form.Label>10100_CTI_Bonds_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10100_CTI_Bonds_Used"
                                    value={this.state.x_10100_CTI_Bonds_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of Y/N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10130_CTI_Explicit_Transaction_Taxes_Bonds'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10130_CTI_Explicit_Transaction_Taxes_Bonds.controlID">
                                  <Form.Label>10130_CTI_Explicit_Transaction_Taxes_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10130_CTI_Explicit_Transaction_Taxes_Bonds"
                                    value={this.state.x_10130_CTI_Explicit_Transaction_Taxes_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds.controlID">
                                  <Form.Label>10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds"
                                    value={this.state.x_10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10150_CTI_Explicit_Transaction_Related_Services_Bonds'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10150_CTI_Explicit_Transaction_Related_Services_Bonds.controlID">
                                  <Form.Label>10150_CTI_Explicit_Transaction_Related_Services_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10150_CTI_Explicit_Transaction_Related_Services_Bonds"
                                    value={this.state.x_10150_CTI_Explicit_Transaction_Related_Services_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10160_CTI_Explicit_Other_Charges_Bonds'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10160_CTI_Explicit_Other_Charges_Bonds.controlID">
                                  <Form.Label>10160_CTI_Explicit_Other_Charges_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10160_CTI_Explicit_Other_Charges_Bonds"
                                    value={this.state.x_10160_CTI_Explicit_Other_Charges_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10170_CTI_Implicit_Costs_Bonds'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10170_CTI_Implicit_Costs_Bonds.controlID">
                                  <Form.Label>10170_CTI_Implicit_Costs_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10170_CTI_Implicit_Costs_Bonds"
                                    value={this.state.x_10170_CTI_Implicit_Costs_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10180_CTI_Indirect_Transaction_Costs_Bonds'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10180_CTI_Indirect_Transaction_Costs_Bonds.controlID">
                                  <Form.Label>10180_CTI_Indirect_Transaction_Costs_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10180_CTI_Indirect_Transaction_Costs_Bonds"
                                    value={this.state.x_10180_CTI_Indirect_Transaction_Costs_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10200_CTI_Pooled_Vehicle_Used'}>
                                    Affirms that transaction cost data is included in respect of pooled vehicles
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10200_CTI_Pooled_Vehicle_Used.controlID">
                                  <Form.Label>10200_CTI_Pooled_Vehicle_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10200_CTI_Pooled_Vehicle_Used"
                                    value={this.state.x_10200_CTI_Pooled_Vehicle_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be one of Y/N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10230_CTI_Explicit_Transaction_Taxes_Pooled'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10230_CTI_Explicit_Transaction_Taxes_Pooled.controlID">
                                  <Form.Label>10230_CTI_Explicit_Transaction_Taxes_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10230_CTI_Explicit_Transaction_Taxes_Pooled"
                                    value={this.state.x_10230_CTI_Explicit_Transaction_Taxes_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled.controlID">
                                  <Form.Label>10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled"
                                    value={this.state.x_10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10250_CTI_Explicit_Transaction_Related_Services_Pooled'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10250_CTI_Explicit_Transaction_Related_Services_Pooled.controlID">
                                  <Form.Label>10250_CTI_Explicit_Transaction_Related_Services_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10250_CTI_Explicit_Transaction_Related_Services_Pooled"
                                    value={this.state.x_10250_CTI_Explicit_Transaction_Related_Services_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10260_CTI_Explicit_Other_Charges_Pooled'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10260_CTI_Explicit_Other_Charges_Pooled.controlID">
                                  <Form.Label>10260_CTI_Explicit_Other_Charges_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10260_CTI_Explicit_Other_Charges_Pooled"
                                    value={this.state.x_10260_CTI_Explicit_Other_Charges_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10270_CTI_Implicit_Costs_Pooled'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10270_CTI_Implicit_Costs_Pooled.controlID">
                                  <Form.Label>10270_CTI_Implicit_Costs_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10270_CTI_Implicit_Costs_Pooled"
                                    value={this.state.x_10270_CTI_Implicit_Costs_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10280_CTI_Indirect_Transaction_Costs_Pooled'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10280_CTI_Indirect_Transaction_Costs_Pooled.controlID">
                                  <Form.Label>10280_CTI_Indirect_Transaction_Costs_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10280_CTI_Indirect_Transaction_Costs_Pooled"
                                    value={this.state.x_10280_CTI_Indirect_Transaction_Costs_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10300_CTI_ETD_Used'}>
                                    Affirms that transaction cost data is included in respect of exchange-traded derivatives
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10300_CTI_ETD_Used.controlID">
                                  <Form.Label>10300_CTI_ETD_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10300_CTI_ETD_Used"
                                    value={this.state.x_10300_CTI_ETD_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10330_CTI_Explicit_Transaction_Taxes_ETD'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10330_CTI_Explicit_Transaction_Taxes_ETD.controlID">
                                  <Form.Label>10330_CTI_Explicit_Transaction_Taxes_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10330_CTI_Explicit_Transaction_Taxes_ETD"
                                    value={this.state.x_10330_CTI_Explicit_Transaction_Taxes_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD.controlID">
                                  <Form.Label>10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD"
                                    value={this.state.x_10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10350_CTI_Explicit_Transaction_Related_Services_ETD'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10350_CTI_Explicit_Transaction_Related_Services_ETD.controlID">
                                  <Form.Label>10350_CTI_Explicit_Transaction_Related_Services_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10350_CTI_Explicit_Transaction_Related_Services_ETD"
                                    value={this.state.x_10350_CTI_Explicit_Transaction_Related_Services_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10360_CTI_Explicit_Other_Charges_ETD'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10360_CTI_Explicit_Other_Charges_ETD.controlID">
                                  <Form.Label>10360_CTI_Explicit_Other_Charges_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10360_CTI_Explicit_Other_Charges_ETD"
                                    value={this.state.x_10360_CTI_Explicit_Other_Charges_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10370_CTI_Implicit_Costs_ETD'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10370_CTI_Implicit_Costs_ETD.controlID">
                                  <Form.Label>10370_CTI_Implicit_Costs_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10370_CTI_Implicit_Costs_ETD"
                                    value={this.state.x_10370_CTI_Implicit_Costs_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10380_CTI_Indirect_Transaction_Costs_ETD'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10380_CTI_Indirect_Transaction_Costs_ETD.controlID">
                                  <Form.Label>10380_CTI_Indirect_Transaction_Costs_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10380_CTI_Indirect_Transaction_Costs_ETD"
                                    value={this.state.x_10380_CTI_Indirect_Transaction_Costs_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10400_CTI_OTCD_Used'}>
                                    Affirms that transaction cost data is included in respect of over-the-counter derivatives
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10400_CTI_OTCD_Used.controlID">
                                  <Form.Label>10400_CTI_OTCD_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10400_CTI_OTCD_Used"
                                    value={this.state.x_10400_CTI_OTCD_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10430_CTI_Explicit_Transaction_Taxes_OTCD'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10430_CTI_Explicit_Transaction_Taxes_OTCD.controlID">
                                  <Form.Label>10430_CTI_Explicit_Transaction_Taxes_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10430_CTI_Explicit_Transaction_Taxes_OTCD"
                                    value={this.state.x_10430_CTI_Explicit_Transaction_Taxes_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD.controlID">
                                  <Form.Label>10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD"
                                    value={this.state.x_10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10450_CTI_Explicit_Transaction_Related_Services_OTCD'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10450_CTI_Explicit_Transaction_Related_Services_OTCD.controlID">
                                  <Form.Label>10450_CTI_Explicit_Transaction_Related_Services_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10450_CTI_Explicit_Transaction_Related_Services_OTCD"
                                    value={this.state.x_10450_CTI_Explicit_Transaction_Related_Services_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10460_CTI_Explicit_Other_Charges_OTCD'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10460_CTI_Explicit_Other_Charges_OTCD.controlID">
                                  <Form.Label>10460_CTI_Explicit_Other_Charges_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10460_CTI_Explicit_Other_Charges_OTCD"
                                    value={this.state.x_10460_CTI_Explicit_Other_Charges_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10470_CTI_Implicit_Costs_OTCD'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10470_CTI_Implicit_Costs_OTCD.controlID">
                                  <Form.Label>10470_CTI_Implicit_Costs_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10470_CTI_Implicit_Costs_OTCD"
                                    value={this.state.x_10470_CTI_Implicit_Costs_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10480_CTI_Indirect_Transaction_Costs_OTCD'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10480_CTI_Indirect_Transaction_Costs_OTCD.controlID">
                                  <Form.Label>10480_CTI_Indirect_Transaction_Costs_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10480_CTI_Indirect_Transaction_Costs_OTCD"
                                    value={this.state.x_10480_CTI_Indirect_Transaction_Costs_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              </Col>
                              <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10500_CTI_FX_Used'}>
                                    Affirms that transaction cost data is included in respect of FX contracts
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10500_CTI_FX_Used.controlID">
                                  <Form.Label>10500_CTI_FX_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10500_CTI_FX_Used"
                                    value={this.state.x_10500_CTI_FX_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10530_CTI_Explicit_Transaction_Taxes_FX'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10530_CTI_Explicit_Transaction_Taxes_FX.controlID">
                                  <Form.Label>10530_CTI_Explicit_Transaction_Taxes_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10530_CTI_Explicit_Transaction_Taxes_FX"
                                    value={this.state.x_10530_CTI_Explicit_Transaction_Taxes_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX.controlID">
                                  <Form.Label>10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX"
                                    value={this.state.x_10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10550_CTI_Explicit_Transaction_Related_Services_FX'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10550_CTI_Explicit_Transaction_Related_Services_FX.controlID">
                                  <Form.Label>10550_CTI_Explicit_Transaction_Related_Services_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10550_CTI_Explicit_Transaction_Related_Services_FX"
                                    value={this.state.x_10550_CTI_Explicit_Transaction_Related_Services_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10560_CTI_Explicit_Other_Charges_FX'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10560_CTI_Explicit_Other_Charges_FX.controlID">
                                  <Form.Label>10560_CTI_Explicit_Other_Charges_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10560_CTI_Explicit_Other_Charges_FX"
                                    value={this.state.x_10560_CTI_Explicit_Other_Charges_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10570_CTI_Implicit_Costs_FX'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10570_CTI_Implicit_Costs_FX.controlID">
                                  <Form.Label>10570_CTI_Implicit_Costs_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10570_CTI_Implicit_Costs_FX"
                                    value={this.state.x_10570_CTI_Implicit_Costs_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10580_CTI_Indirect_Transaction_Costs_FX'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10580_CTI_Indirect_Transaction_Costs_FX.controlID">
                                  <Form.Label>10580_CTI_Indirect_Transaction_Costs_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10580_CTI_Indirect_Transaction_Costs_FX"
                                    value={this.state.x_10580_CTI_Indirect_Transaction_Costs_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10600_CTI_MMI_Used'}>
                                    Affirms that transaction cost data is included in respect of market market instruments
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10600_CTI_MMI_Used.controlID">
                                  <Form.Label>10600_CTI_MMI_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10600_CTI_MMI_Used"
                                    value={this.state.x_10600_CTI_MMI_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10630_CTI_Explicit_Transaction_Taxes_MMI'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10630_CTI_Explicit_Transaction_Taxes_MMI.controlID">
                                  <Form.Label>10630_CTI_Explicit_Transaction_Taxes_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10630_CTI_Explicit_Transaction_Taxes_MMI"
                                    value={this.state.x_10630_CTI_Explicit_Transaction_Taxes_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI.controlID">
                                  <Form.Label>10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI"
                                    value={this.state.x_10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10650_CTI_Explicit_Transaction_Related_Services_MMI'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10650_CTI_Explicit_Transaction_Related_Services_MMI.controlID">
                                  <Form.Label>10650_CTI_Explicit_Transaction_Related_Services_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10650_CTI_Explicit_Transaction_Related_Services_MMI"
                                    value={this.state.x_10650_CTI_Explicit_Transaction_Related_Services_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10660_CTI_Explicit_Other_Charges_MMI'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10660_CTI_Explicit_Other_Charges_MMI.controlID">
                                  <Form.Label>10660_CTI_Explicit_Other_Charges_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10660_CTI_Explicit_Other_Charges_MMI"
                                    value={this.state.x_10660_CTI_Explicit_Other_Charges_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10670_CTI_Implicit_Costs_MMI'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10670_CTI_Implicit_Costs_MMI.controlID">
                                  <Form.Label>10670_CTI_Implicit_Costs_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10670_CTI_Implicit_Costs_MMI"
                                    value={this.state.x_10670_CTI_Implicit_Costs_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10680_CTI_Indirect_Transaction_Costs_MMI'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10680_CTI_Indirect_Transaction_Costs_MMI.controlID">
                                  <Form.Label>10680_CTI_Indirect_Transaction_Costs_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10680_CTI_Indirect_Transaction_Costs_MMI"
                                    value={this.state.x_10680_CTI_Indirect_Transaction_Costs_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10700_CTI_Physical_Assets_Used'}>
                                    Affirms that transaction cost data is included in respect of physical assets
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10700_CTI_Physical_Assets_Used.controlID">
                                  <Form.Label>10700_CTI_Physical_Assets_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10700_CTI_Physical_Assets_Used"
                                    value={this.state.x_10700_CTI_Physical_Assets_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10730_CTI_Explicit_Transaction_Taxes_Physical'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10730_CTI_Explicit_Transaction_Taxes_Physical.controlID">
                                  <Form.Label>10730_CTI_Explicit_Transaction_Taxes_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10730_CTI_Explicit_Transaction_Taxes_Physical"
                                    value={this.state.x_10730_CTI_Explicit_Transaction_Taxes_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical.controlID">
                                  <Form.Label>10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical"
                                    value={this.state.x_10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10750_CTI_Explicit_Transaction_Related_Services_Physical'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10750_CTI_Explicit_Transaction_Related_Services_Physical.controlID">
                                  <Form.Label>10750_CTI_Explicit_Transaction_Related_Services_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10750_CTI_Explicit_Transaction_Related_Services_Physical"
                                    value={this.state.x_10750_CTI_Explicit_Transaction_Related_Services_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10760_CTI_Explicit_Other_Charges_Physical'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10760_CTI_Explicit_Other_Charges_Physical.controlID">
                                  <Form.Label>10760_CTI_Explicit_Other_Charges_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10760_CTI_Explicit_Other_Charges_Physical"
                                    value={this.state.x_10760_CTI_Explicit_Other_Charges_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10770_CTI_Implicit_Costs_Physical'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10770_CTI_Implicit_Costs_Physical.controlID">
                                  <Form.Label>10770_CTI_Implicit_Costs_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10770_CTI_Implicit_Costs_Physical"
                                    value={this.state.x_10770_CTI_Implicit_Costs_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10780_CTI_Indirect_Transaction_Costs_Physical'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10780_CTI_Indirect_Transaction_Costs_Physical.controlID">
                                  <Form.Label>10780_CTI_Indirect_Transaction_Costs_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10780_CTI_Indirect_Transaction_Costs_Physical"
                                    value={this.state.x_10780_CTI_Indirect_Transaction_Costs_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10800_CTI_Private_Assets_Used'}>
                                    Affirms that transaction cost data is included in respect of private assets
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10800_CTI_Private_Assets_Used.controlID">
                                  <Form.Label>10800_CTI_Private_Assets_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10800_CTI_Private_Assets_Used"
                                    value={this.state.x_10800_CTI_Private_Assets_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     Must be one of the following Y/N
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10830_CTI_Explicit_Transaction_Taxes_Private'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10830_CTI_Explicit_Transaction_Taxes_Private.controlID">
                                  <Form.Label>10830_CTI_Explicit_Transaction_Taxes_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10830_CTI_Explicit_Transaction_Taxes_Private"
                                    value={this.state.x_10830_CTI_Explicit_Transaction_Taxes_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private.controlID">
                                  <Form.Label>10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private"
                                    value={this.state.x_10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10850_CTI_Explicit_Transaction_Related_Services_Private'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10850_CTI_Explicit_Transaction_Related_Services_Private.controlID">
                                  <Form.Label>10850_CTI_Explicit_Transaction_Related_Services_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10850_CTI_Explicit_Transaction_Related_Services_Private"
                                    value={this.state.x_10850_CTI_Explicit_Transaction_Related_Services_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10860_CTI_Explicit_Other_Charges_Private'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10860_CTI_Explicit_Other_Charges_Private.controlID">
                                  <Form.Label>10860_CTI_Explicit_Other_Charges_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10860_CTI_Explicit_Other_Charges_Private"
                                    value={this.state.x_10860_CTI_Explicit_Other_Charges_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10870_CTI_Implicit_Costs_Private'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10870_CTI_Implicit_Costs_Private.controlID">
                                  <Form.Label>10870_CTI_Implicit_Costs_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10870_CTI_Implicit_Costs_Private"
                                    value={this.state.x_10870_CTI_Implicit_Costs_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10880_CTI_Indirect_Transaction_Costs_Private'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10880_CTI_Indirect_Transaction_Costs_Private.controlID">
                                  <Form.Label>10880_CTI_Indirect_Transaction_Costs_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10880_CTI_Indirect_Transaction_Costs_Private"
                                    value={this.state.x_10880_CTI_Indirect_Transaction_Costs_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10900_CTI_Other_Assets_Used'}>
                                    Affirms that transaction cost data is included in respect of assets not included elsewhere
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10900_CTI_Other_Assets_Used.controlID">
                                  <Form.Label>10900_CTI_Other_Assets_Used</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10900_CTI_Other_Assets_Used"
                                    value={this.state.x_10900_CTI_Other_Assets_Used}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="Y/N"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be Y/N.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10930_CTI_Explicit_Transaction_Taxes_Other'}>
                                    As per 03030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10930_CTI_Explicit_Transaction_Taxes_Other.controlID">
                                  <Form.Label>10930_CTI_Explicit_Transaction_Taxes_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10930_CTI_Explicit_Transaction_Taxes_Other"
                                    value={this.state.x_10930_CTI_Explicit_Transaction_Taxes_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other'}>
                                    As per 03040
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other.controlID">
                                  <Form.Label>10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other"
                                    value={this.state.x_10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10950_CTI_Explicit_Transaction_Related_Services_Other'}>
                                    As per 03050
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10950_CTI_Explicit_Transaction_Related_Services_Other.controlID">
                                  <Form.Label>10950_CTI_Explicit_Transaction_Related_Services_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10950_CTI_Explicit_Transaction_Related_Services_Other"
                                    value={this.state.x_10950_CTI_Explicit_Transaction_Related_Services_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10960_CTI_Explicit_Other_Charges_Other'}>
                                    As per 03060
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10960_CTI_Explicit_Other_Charges_Other.controlID">
                                  <Form.Label>10960_CTI_Explicit_Other_Charges_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10960_CTI_Explicit_Other_Charges_Other"
                                    value={this.state.x_10960_CTI_Explicit_Other_Charges_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10970_CTI_Implicit_Costs_Other'}>
                                    As per 03070
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10970_CTI_Implicit_Costs_Other.controlID">
                                  <Form.Label>10970_CTI_Implicit_Costs_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10970_CTI_Implicit_Costs_Other"
                                    value={this.state.x_10970_CTI_Implicit_Costs_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-10980_CTI_Indirect_Transaction_Costs_Other'}>
                                    As per 03080
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="10980_CTI_Indirect_Transaction_Costs_Other.controlID">
                                  <Form.Label>10980_CTI_Indirect_Transaction_Costs_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_10980_CTI_Indirect_Transaction_Costs_Other"
                                    value={this.state.x_10980_CTI_Indirect_Transaction_Costs_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="primary" type="submit">Save</Button> : null
                                }
                                <Button style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }} variant="secondary" onClick={() => { this.setState({ redirect: true }); }}>
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Asset class other relevant information' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Asset class other relevant information')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12010_CTI_Start_Asset_Value_Equities'}>
                                    Value of equities at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12010_CTI_Start_Asset_Value_Equities.controlID">
                                  <Form.Label>12010_CTI_Start_Asset_Value_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12010_CTI_Start_Asset_Value_Equities"
                                    value={this.state.x_01010_Percentage_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12020_CTI_End_Asset_Value_Equities'}>
                                    Value of equities at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12020_CTI_End_Asset_Value_Equities.controlID">
                                  <Form.Label>12020_CTI_End_Asset_Value_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12020_CTI_End_Asset_Value_Equities"
                                    value={this.state.x_12020_CTI_End_Asset_Value_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12030_CTI_Purchases_Equities'}>
                                    Total consideration paid for the purchase of equities during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12030_CTI_Purchases_Equities.controlID">
                                  <Form.Label>12030_CTI_Purchases_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12030_CTI_Purchases_Equities"
                                    value={this.state.x_12030_CTI_Purchases_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12040_CTI_Sales_Equities'}>
                                    Total consideration received from the sale of equities during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12040_CTI_Sales_Equities.controlID">
                                  <Form.Label>12040_CTI_Sales_Equities</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12040_CTI_Sales_Equities"
                                    value={this.state.x_12040_CTI_Sales_Equities}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12110_CTI_Start_Asset_Value_Bonds'}>
                                    Value of bonds at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12110_CTI_Start_Asset_Value_Bonds.controlID">
                                  <Form.Label>12110_CTI_Start_Asset_Value_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12110_CTI_Start_Asset_Value_Bonds"
                                    value={this.state.x_12110_CTI_Start_Asset_Value_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12120_CTI_End_Asset_Value_Bonds'}>
                                    Value of bonds at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12120_CTI_End_Asset_Value_Bonds.controlID">
                                  <Form.Label>12120_CTI_End_Asset_Value_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12120_CTI_End_Asset_Value_Bonds"
                                    value={this.state.x_12120_CTI_End_Asset_Value_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12130_CTI_Purchases_Bonds'}>
                                    Total consideration paid for the purchase of bonds during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12130_CTI_Purchases_Bonds.controlID">
                                  <Form.Label>12130_CTI_Purchases_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12130_CTI_Purchases_Bonds"
                                    value={this.state.x_12130_CTI_Purchases_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12140_CTI_Sales_Bonds'}>
                                    Total consideration received from the sale of bonds during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12140_CTI_Sales_Bonds.controlID">
                                  <Form.Label>12140_CTI_Sales_Bonds</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12140_CTI_Sales_Bonds"
                                    value={this.state.x_12140_CTI_Sales_Bonds}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12210_CTI_Start_Asset_Value_Pooled'}>
                                    Value of holdings in pooled vehicles at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12210_CTI_Start_Asset_Value_Pooled.controlID">
                                  <Form.Label>12210_CTI_Start_Asset_Value_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12210_CTI_Start_Asset_Value_Pooled"
                                    value={this.state.x_12210_CTI_Start_Asset_Value_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12220_CTI_End_Asset_Value_Pooled'}>
                                    Value of holdings in pooled vehicles at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12220_CTI_End_Asset_Value_Pooled.controlID">
                                  <Form.Label>12220_CTI_End_Asset_Value_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12220_CTI_End_Asset_Value_Pooled"
                                    value={this.state.x_12220_CTI_End_Asset_Value_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12230_CTI_Purchases_Pooled'}>
                                    Total consideration paid for the purchase of units in pooled vehicles during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12230_CTI_Purchases_Pooled.controlID">
                                  <Form.Label>12230_CTI_Purchases_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12230_CTI_Purchases_Pooled"
                                    value={this.state.x_12230_CTI_Purchases_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12240_CTI_Sales_Pooled'}>
                                    Total consideration received from the sale of units in pooled vehicles during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12240_CTI_Sales_Pooled.controlID">
                                  <Form.Label>12240_CTI_Sales_Pooled</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12240_CTI_Sales_Pooled"
                                    value={this.state.x_12240_CTI_Sales_Pooled}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12310_CTI_Start_Asset_Value_ETD'}>
                                    Value of exchange-traded derivative positions at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12310_CTI_Start_Asset_Value_ETD.controlID">
                                  <Form.Label>12310_CTI_Start_Asset_Value_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12310_CTI_Start_Asset_Value_ETD"
                                    value={this.state.x_12310_CTI_Start_Asset_Value_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12320_CTI_End_Asset_Value_ETD'}>
                                    Value of exchange-traded derivative positions at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12320_CTI_End_Asset_Value_ETD.controlID">
                                  <Form.Label>12320_CTI_End_Asset_Value_ETD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12320_CTI_End_Asset_Value_ETD"
                                    value={this.state.x_12320_CTI_End_Asset_Value_ETD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12410_CTI_Start_Asset_Value_OTCD'}>
                                    Value of over-the-counter derivative positions at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12410_CTI_Start_Asset_Value_OTCD.controlID">
                                  <Form.Label>12410_CTI_Start_Asset_Value_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12410_CTI_Start_Asset_Value_OTCD"
                                    value={this.state.x_12410_CTI_Start_Asset_Value_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12420_CTI_End_Asset_Value_OTCD'}>
                                    Value of over-the-counter derivative positions at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12420_CTI_End_Asset_Value_OTCD.controlID">
                                  <Form.Label>12420_CTI_End_Asset_Value_OTCD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12420_CTI_End_Asset_Value_OTCD"
                                    value={this.state.x_12420_CTI_End_Asset_Value_OTCD}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12510_CTI_Start_Asset_Value_FX'}>
                                    Value of FX contracts at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12510_CTI_Start_Asset_Value_FX.controlID">
                                  <Form.Label>12510_CTI_Start_Asset_Value_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12510_CTI_Start_Asset_Value_FX"
                                    value={this.state.x_12510_CTI_Start_Asset_Value_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12520_CTI_End_Asset_Value_FX'}>
                                    Value of FX contracts at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12520_CTI_End_Asset_Value_FX.controlID">
                                  <Form.Label>12520_CTI_End_Asset_Value_FX</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12520_CTI_End_Asset_Value_FX"
                                    value={this.state.x_12520_CTI_End_Asset_Value_FX}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12610_CTI_Start_Asset_Value_MMI'}>
                                    Value of money market instruments at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12610_CTI_Start_Asset_Value_MMI.controlID">
                                  <Form.Label>12610_CTI_Start_Asset_Value_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12610_CTI_Start_Asset_Value_MMI"
                                    value={this.state.x_12610_CTI_Start_Asset_Value_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12620_CTI_End_Asset_Value_MMI'}>
                                    Value of money market instruments at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12620_CTI_End_Asset_Value_MMI.controlID">
                                  <Form.Label>12620_CTI_End_Asset_Value_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12620_CTI_End_Asset_Value_MMI"
                                    value={this.state.x_12620_CTI_End_Asset_Value_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12630_CTI_Purchases_MMI'}>
                                    Total consideration paid for the purchase of money market instruments during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12630_CTI_Purchases_MMI.controlID">
                                  <Form.Label>12630_CTI_Purchases_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12630_CTI_Purchases_MMI"
                                    value={this.state.x_12630_CTI_Purchases_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12640_CTI_Sales_MMI'}>
                                    Total consideration received from the sale of money market instruments during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12640_CTI_Sales_MMI.controlID">
                                  <Form.Label>12640_CTI_Sales_MMI</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12640_CTI_Sales_MMI"
                                    value={this.state.x_12640_CTI_Sales_MMI}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12710_CTI_Start_Asset_Value_Physical'}>
                                    Value of physical assets at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12710_CTI_Start_Asset_Value_Physical.controlID">
                                  <Form.Label>12710_CTI_Start_Asset_Value_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12710_CTI_Start_Asset_Value_Physical"
                                    value={this.state.x_12710_CTI_Start_Asset_Value_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12720_CTI_End_Asset_Value_Physical'}>
                                    Value of physical assets at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12720_CTI_End_Asset_Value_Physical.controlID">
                                  <Form.Label>12720_CTI_End_Asset_Value_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12720_CTI_End_Asset_Value_Physical"
                                    value={this.state.x_12720_CTI_End_Asset_Value_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12730_CTI_Purchases_Physical'}>
                                    Total consideration paid for the purchase of physical assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12730_CTI_Purchases_Physical.controlID">
                                  <Form.Label>12730_CTI_Purchases_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12730_CTI_Purchases_Physical"
                                    value={this.state.x_12730_CTI_Purchases_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12740_CTI_Sales_Physical'}>
                                    Total consideration received from the sale of physical assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12740_CTI_Sales_Physical.controlID">
                                  <Form.Label>12740_CTI_Sales_Physical</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12740_CTI_Sales_Physical"
                                    value={this.state.x_12740_CTI_Sales_Physical}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12810_CTI_Start_Asset_Value_Private'}>
                                    Value of private assets at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12810_CTI_Start_Asset_Value_Private.controlID">
                                  <Form.Label>12810_CTI_Start_Asset_Value_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12810_CTI_Start_Asset_Value_Private"
                                    value={this.state.x_12810_CTI_Start_Asset_Value_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12820_CTI_End_Asset_Value_Private'}>
                                    Value of private assets at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12820_CTI_End_Asset_Value_Private.controlID">
                                  <Form.Label>12820_CTI_End_Asset_Value_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12820_CTI_End_Asset_Value_Private"
                                    value={this.state.x_12820_CTI_End_Asset_Value_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12830_CTI_Purchases_Private'}>
                                    Total consideration paid for the purchase of private assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12830_CTI_Purchases_Private.controlID">
                                  <Form.Label>12830_CTI_Purchases_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12830_CTI_Purchases_Private"
                                    value={this.state.x_12830_CTI_Purchases_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12840_CTI_Sales_Private'}>
                                    Total consideration received from the sale of private assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12840_CTI_Sales_Private.controlID">
                                  <Form.Label>12840_CTI_Sales_Private</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12840_CTI_Sales_Private"
                                    value={this.state.x_12840_CTI_Sales_Private}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12910_CTI_Start_Asset_Value_Other'}>
                                    Value of other assets at the start of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12910_CTI_Start_Asset_Value_Other.controlID">
                                  <Form.Label>12910_CTI_Start_Asset_Value_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12910_CTI_Start_Asset_Value_Other"
                                    value={this.state.x_12910_CTI_Start_Asset_Value_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12920_CTI_End_Asset_Value_Other'}>
                                    Value of other assets at the end of the period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12920_CTI_End_Asset_Value_Other.controlID">
                                  <Form.Label>12920_CTI_End_Asset_Value_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12920_CTI_End_Asset_Value_Other"
                                    value={this.state.x_12920_CTI_End_Asset_Value_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12930_CTI_Purchases_Other'}>
                                    Total consideration paid for the purchase of other assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12930_CTI_Purchases_Other.controlID">
                                  <Form.Label>12930_CTI_Purchases_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12930_CTI_Purchases_Other"
                                    value={this.state.x_12930_CTI_Purchases_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"

                                placement="right"
                                overlay={(
                                  <Popover id={'popover-12940_CTI_Sales_Other'}>
                                    Total consideration received from the sale of other assets during the reporting period
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="12940_CTI_Sales_Other.controlID">
                                  <Form.Label>12940_CTI_Sales_Other</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_12940_CTI_Sales_Other"
                                    value={this.state.x_12940_CTI_Sales_Other}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }} variant="primary" type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                </Row>
              </>
            }
          </RegEditBody>
        </RegEditView>
      ));
  }
}

CTIeditor.contextType = AppContext;

export default CTIeditor;
