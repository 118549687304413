export const permissionTypes = 
{
    view: 'view',
    edit: 'edit',
    calculations: 'run-calculations',
    create: 'create',
    validate: 'validate-transactions',
    send: 'send-funds-for-market-data',
    preview: 'show-preview',
    approve: 'approve',
    delete: 'delete',
    upload: 'upload',
    underlyingcalculations: 'run-underlying-calculation',
    rollupcalculations: 'run-rollup-calculation',
    approvereopen: 'approve/reopen',
    calculationandcompileerrors: 'run-calculations-and-compile-errors-',
    runbulkuiocalculations: 'run-bulk-uio-calculations',
    runbulkproductcalculations: 'run-bulk-product-calculations',
    generatekiddocuments: 'generate-kid-documents',
    deleteSelectedFiles: 'delete-selected-files-from-test-site',
    publishSelectedTest: 'publish-selected-files-to-test-site',
    publishToLive: 'publish-to-live-site',
    generate: 'generate',
}