import styled from 'styled-components';

export const Counter = styled.div`
  align-items: center;
  background-color: #e9e8f0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  color: #2d235b;
  display: flex;
  font-family: 'Fira Sans', sans-serif;
  font-size: 10px;
  height: 32px;
  margin-left: 7px;
  padding: 0 10px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const Showing = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
`;

export const Total = styled.div`
  align-items: center;
  display: flex;
  margin-left: 4px;
  font-size: 12px;
`;

export const Display = styled.div`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  margin-left: 4px;
  padding: 2px 4px;
  font-size: 10px;
`;
