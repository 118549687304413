import React, { Component } from 'react';
import Viewer from '../Viewer/Viewer';
import AppContext from '../../AppContext';


class TCResultsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      post_data: {
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code
      }
    });
  }

  render() {
    return (
      this.state.post_data ? <Viewer {...this.props} post_data={this.state.post_data} /> : <></>
    );
  }
}

TCResultsDashboard.contextType = AppContext;

export default TCResultsDashboard;
