import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classes from './GrpToaster.module.scss';

const GrpToaster = ({ handler, toast }) => {
  const type = {};

  if (toast) {
    switch (toast.type) {
      case 'error':
        type.class = classes.Error;
        type.icon = 'exclamation';
        break;
      case 'success':
        type.class = classes.Success;
        type.icon = 'check';
        break;
      default:
        type.class = classes.Info;
        type.icon = 'info';
        break;
    }
  }

  const closeClick = () => {
    // console.log('Close');
    handler();
  };

  return (
    <div
      className={`${classes.GrpToaster} ${toast && toast.body && classes.Show}`}
      onClick={closeClick}
      onKeyPress={closeClick}
      tabIndex={-1}
      role="button"
    >
      <div className={`${classes.IconBox} ${type.class}`}>
        <FontAwesomeIcon icon={['fas', type.icon ? type.icon : 'info']} />
      </div>
      <div className={classes.Body}>
        {toast && toast.body}
      </div>
    </div>
  );
};

GrpToaster.defaultProps = {
  handler: () => {},
  toast: null,
};

GrpToaster.propTypes = {
  handler: PropTypes.func,
  toast: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.element,
  }),
};

export default GrpToaster;
