import React, { Component } from 'react';
import {
  Col, Row, Form, ButtonToolbar,
} from 'react-bootstrap';
import '@kenshooui/react-multi-select/dist/style.css';
import { Redirect } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import CDATA from '../../Services/CDATA';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import AppContext from '../../AppContext';
import config from '../../config';
import {
  RoleBody, RoleButton, RoleView, RoleMultiSelect,
} from './Role.styles';
import DescriptiveItem from '../../Library/ArpMultiSelect/DesciptiveItem/DescriptiveItem';

const { encKey } = config.sessionStorage;

/**
 * @todo: remove react-bootstrap components
 */
class Role extends Component {
  constructor(props) {
    super(props);

    if (this.props.location.action_type) {
      this.state = {
        action_type: this.props.location.action_type,
        loaded: false,
        page: window.location.pathname,
        permissions: [],
        redirect: false,
        role_id: this.props.location.role_id,
        selectedPermissions: [],
        title: this.props.location.action_type === 'Edit' ? 'Edit Role' : 'Add Role',
        validated: false,
      };
    } else {
      this.state = { redirect: true };
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePermissionsSelectChange = this.handlePermissionsSelectChange.bind(this);
    this.handleDeleteRole = this.handleDeleteRole.bind(this);
  }

  componentDidMount() {
    this.getRoleData();
  }

  async handleSubmit(event) {
    const form = event.currentTarget;
    const valid = form.checkValidity() === true;
    this.setState({ validated: true });

    event.preventDefault();
    if (!valid) {
      event.stopPropagation();
    } else {
      const isEdit = (this.state.action_type === 'Edit');
      let toast = null;

      try {
        // Upsert the role.
        const authRoleUrl = `PRIIPS_auth_roles${(isEdit ? `(${this.state.role_id})` : '')}`;
        const authRoleResponse = await CDATA.makeRequest(
          (isEdit ? 'PUT' : 'POST'),
          authRoleUrl,
          null,
          {
            roleName: this.state.role_name,
            roleDesc: this.state.role_desc,
            visible: this.state.role_visible,
          },
          `We\'re sorry, there was an error ${(isEdit ? 'updating' : 'creating')} this role.`,
        );
        if (!isEdit) {
          this.state.role_id = authRoleResponse.id;
        }

        // Upsert the role's permissions.
        CDATA.makeRequest(
          'POST',
          'PRIIPS_auth_InsertRolePermissionsByRoleId',
          null,
          {
            roleId: this.state.role_id,
            appPermissionIds: this.state.selectedPermissions.map((item) => item.id).join(','),
          },
          `We\'re sorry, there was an error ${(isEdit ? 'updating' : 'creating')} this role\'s permissions.`,
        );

        // Update the UI.
        toast = {
          type: 'success',
          body: (
            <>
              {`You successfully ${(isEdit ? 'updated' : 'created')} role `}
              <span>{this.state.role_name}</span>
              {'.'}
            </>
          ),
        };
        this.setState({
          redirect: true,
        });
      } catch (err) {
        toast = {
          type: 'error',
          body: (
            <>
              {`Error - something went wrong and we were not able to ${(isEdit ? 'update' : 'create')} role `}
              <span>{this.state.role_name}</span>
              {'.'}
            </>
          ),
        };
      }

      this.context.handlers.setToast(toast);
    }
  }

  async handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  async handlePermissionsSelectChange(selectedItems) {
    this.setState({ selectedPermissions: selectedItems });
  }

  async handleDeleteRole(event) {
    event.preventDefault();
    event.stopPropagation();

    let toast = null;

    try {
      // Delete the role.
      await CDATA.makeRequest(
        'DELETE',
        `PRIIPS_auth_roles(${this.state.role_id})`,
        null,
        null,
        'We\'re sorry, there was an error deleting this role.',
      );

      // Update the UI.
      toast = {
        type: 'success',
        body: (
          <>
            {'You successfully deleted role '}
            <span>{this.state.role_name}</span>
            {'.'}
          </>
        ),
      };
      this.setState({
        redirect: true,
      });
    } catch (err) {
      toast = {
        type: 'error',
        body: (
          <>
            {'Error - something went wrong and we were not able to delete role '}
            <span>{this.state.role_name}</span>
            {'.'}
          </>
        ),
      };
    }

    this.context.handlers.setToast(toast);
  }

  /**
   * @todo: find out what this was supposed to be
   */
  async getPermissions() {

  }

  async getRoleData() {
    try {
      // Get the role data.
      let role = null;
      if (this.state.action_type === 'Edit') {
        const roleData = await CDATA.makeRequest(
          'GET',
          'PRIIPS_auth_roles',
          CDATA.addFilter(`id eq ${this.state.role_id}`),
          null,
          'Error retrieving role data.',
        );
        role = roleData.value[0];
      } else {
        role = {
          roleName: null,
          roleDesc: null,
          visible: true,
        };
      }

      // Get the permissions.
      const permissionData = await CDATA.makeRequest(
        'POST',
        'PRIIPS_auth_RolePermissionsByRoleId',
        null,
        { roleId: this.state.role_id ?? 0 },
        'Error retrieving role permissions data.',
      );
      const permissions = permissionData
        .sort((a, b) => a.apppermissionname > b.apppermissionname ? 1 : -1)
        .map((item) => ({
          id: item.id,
          label: item.apppermissionname,
          description: item.apppermissiondesc, // tooltip
          selected: item.selected,
          group: item.apppermissionname.substr(0, item.apppermissionname.indexOf('/')),
        }));

      this.setState({
        role_name: role.roleName,
        role_desc: role.roleDesc,
        role_visible: role.visible,
        permissions,
        selectedPermissions: permissions.filter((item) => item.selected),
        loaded: true,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loaded: true,
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading role data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  render() {
    return (
      <RoleView>
        <RoleBody>
          {
          this.state.redirect === true ? <Redirect to="/admin/manage-roles" /> : (
            <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: '1',
                  flexGrow: '1',
                }}
                className="no-padding"
              >
                {
                  this.state.loaded ? (
                    <Col>
                      <Form
                        noValidate
                        validated={this.state.validated}
                        onSubmit={(e) => this.handleSubmit(e)}
                      >
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Role Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="role_name"
                                value={this.state.role_name}
                                onChange={this.handleInputChange}
                                maxLength="255"
                              />
                              <Form.Control.Feedback type="invalid">
                                A role name is required.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Role Description</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="role_desc"
                                value={this.state.role_desc}
                                onChange={this.handleInputChange}
                                maxLength="255"
                              />
                              <Form.Control.Feedback type="invalid">
                                A role description is required.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            Permissions
                            <RoleMultiSelect
                              items={this.state.permissions}
                              selectedItems={this.state.selectedPermissions}
                              onChange={this.handlePermissionsSelectChange}
                              withGrouping
                              itemHeight={50}
                              itemRenderer={DescriptiveItem}
                              showSelectedItems={true}
                              showSearch={true}
                              showSelectAll={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <br />
                            <ButtonToolbar>
                              <RoleButton
                                type="Primary"
                                actionType="Submit"
                                size="Medium"
                                text={this.state.action_type === 'Edit' ? <span>Update Role</span> : <span>Create Role</span>}
                              />
                              {
                                this.state.action_type === 'Edit' ? (
                                  <RoleButton
                                    type="Primary"
                                    onClick={(e) => this.handleDeleteRole(e)}
                                    size="Medium"
                                    text="Delete"
                                  />
                                ) : (<></>)
                              }
                              <RoleButton
                                type="Secondary"
                                size="Medium"
                                text="Cancel"
                                onClick={() => { this.setState({ redirect: true }); }}
                              />
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  ) : (<GrpSpinner text="Loading role management" />)
                }
              </Row>
            </div>
          )
          }
        </RoleBody>
      </RoleView>
    );
  }
}

Role.contextType = AppContext;

export default Role;
