import styled from 'styled-components';
import GrpFileBrowser from '../../Library/GrpFileBrowser/GrpFileBrowser';
import GrpDropzone from '../../Library/GrpDropzone/GrpDropzone';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../Components/GrpError/GrpError';

export const Container = styled.div`
  display: flex;
  padding: 25px;
  flex-direction: column;
  flex: 1;
`;

export const FileBrowserContainer = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FileBrowser = styled(GrpFileBrowser)`

`;

export const Dropzone = styled(GrpDropzone)`

`;

export const Spinner = styled(GrpSpinner)`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Error = styled(GrpError)`

`;

export const DropzoneWrapper = styled.div`
  height: 50vh;
  background-color: red;
`;
