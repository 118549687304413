import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Fira Sans', sans-serif;
  color: rgb(88, 7, 163);
`;

export const Description = styled.div`
  border-radius: 2px;
  border-color: #999;
`;

export const Icon = styled.div`
  font-size: 24px;
  margin: 0 12px 0 0;
`;

export const DescriptiveItemWrapper = styled.div`
  display: flex;
  align-items: center;
  lineHeight: 14px;
  height: 50px;
  margin: 12px;
  flex-direction: row;
`;

export const TextWrapper = styled.div`
  flex-direction: column;
  margin: 5px;
`;