/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import {
  Col, Row, Nav, Navbar, Form, Button, ButtonToolbar, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { RegEditView, RegEditBody, GButton, Spinner, Toolbar } from './RegulationEditor.styles';
import AppContext from '../../AppContext';
// import GrpError from '../../Components/GrpError/GrpError';
import '../../Styles/SCSS/ag-theme-material.scss';
import config from '../../config';
import CDATA from '../../Services/CDATA';

const { encKey } = config.sessionStorage;

class DCPTeditor extends Component {
  constructor(props) {
    super(props);

    let sessionState = sessionStorage.getItem('PageStateStorage');
    let propsID = this.props.location.classcode_bk;
    // console.log(propsID)
    if (propsID) {
      this.state = {
        page: window.location.pathname,
        raw_api: this.props.raw_api,
        api_name: this.props.location.api_name,
        loading: false,
        failed: false,
        Class_Code: this.props.location.classcode_bk,
        Fund_Code: this.props.location.fundcode_bk,
        eventKey: 'General Info',
        action_type: this.props.location.action_type,
        toolbarButtons: [
          'Placeholder' === 'Edit' ? (
            <GButton key={0} onClick={() => { this.handleApprove(); }} size="Medium" type="Primary" text="Approve" />
          ) : (
            <GButton key={0} onClick={() => { this.handleReject(); }} size="Medium" type="Primary" text="Re-Open" />
          ),
        ],
        validated: false,
        loaded: false,
        redirect: false,
      };
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page === window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async componentDidMount() {
    const urlParameters = CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and x_00060_End_Of_Period eq '${this.context.state.selectedReportingDate.code}' and x_00010_Portfolio_Identifying_Data eq '${this.state.Class_Code}'`);
    try {
      const data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_app_DCPT_OUTPUT',
        urlParameters,
        {},
        'Error retrieving data.',
      );

      let dcptFields = data.value[0];
      console.log(data);

      this.setState(dcptFields);
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  async saveDCPT(dcptSection) {
    if (dcptSection === 'General Info') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_app_DCPT(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            OF_ISIN: this.state.x_00010_Portfolio_Identifying_Data,
            EPT_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio: this.state.x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument,
            OF_Full_Share_Class_Name: this.state.x_00030_Portfolio_Name,
            OF_ManCo: this.state.x_00040_Portfolio_Issuer_Name,
            Start_Of_Period: this.state.x_00050_Start_Of_Period,
            Reporting_Period: this.state.x_00060_End_Of_Period,
          },
          'DCPT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `DCPT Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    } else if (dcptSection === 'Obtaining Info') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_App_DCPT(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            DCPT_Percentage_Not_Obtained: this.state.x_01010_Percentage_Not_Obtained,
            DCPT_Categories_Not_Obtained: this.state.x_01020_Categories_Not_Obtained,
            DCPT_Transaction_Costs_Methodology: this.state.x_01030_Transaction_Costs_Methodology,
            DCPT_Reason_Not_Obtained: this.state.x_01040_Reason_Not_Obtained,
          },
          'DCPT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `DCPT Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    } else if (dcptSection === 'Transaction Costs') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_App_DCPT(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            DCPT_Aggregation_Of_Transaction_Costs: this.state.x_02010_Aggregation_Of_Transaction_Costs,
            DCPT_Transaction_Costs_For_Buy_And_Sell_Transactions: this.state.x_02020_Transaction_Costs_For_Buy_And_Sell_Transactions,
            DCPT_Transaction_Costs_For_Lending_And_Borrowing_Transactions: this.state.x_02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions,
            DCPT_Explicit_Transaction_Taxes: this.state.x_02040_Explicit_Transaction_Taxes,
            DCPT_Explicit_Fees_And_Charges: this.state.x_02050_Explicit_Fees_And_Charges,
            DCPT_Implicit_Costs: this.state.x_02060_Implicit_Costs,
            DCPT_Indirect_Transaction_Costs: this.state.x_02070_Indirect_Transaction_Costs,
            DCPT_Anti_Dilution_Offset: this.state.x_02080_Anti_Dilution_Offset,
            DCPT_Securities_Lending_And_Borrowing_Costs: this.state.x_02090_Securities_Lending_And_Borrowing_Costs,
          },
          'DCPT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `DCPT Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    } else if (dcptSection === 'AdminCharges OtherInfo') {
      try {
        const data = await CDATA.makeRequest(
          'PUT',
          `PRIIPS_App_DCPT(${this.state.RECORD_ID})`,
          '',
          {
            Version: (this.state.Version + 0.001),
            ClientCode_bk: this.state.Client_Code,
            FundCode_bk: this.state.Fund_Code,
            Status: this.state.Status,
            UpdatedBy: this.props.mail,
            DCPT_Administration_Charges: this.state.x_05010_Return_Profile_Preservation,
            DCPT_Costs_Other_Than_Transaction_Costs: this.state.x_05020_Return_Profile_Growth,
            DCPT_Portfolio_Turnover_Rate: this.state.x_05030_Return_Profile_Income,
            DCPT_Proportion_Of_Securities_Loaned_Or_Borrowed: this.state.x_05040_Return_Profile_Hedging,
          },
          'DCPT saved successfully',
        );
        this.setState({ Version: this.state.Version + 0.001 });
      } catch (err) {
        this.setState({
          loading: false,
          failed: true,
          failMessage: `DCPT Not Saved. Code: ${err.response.data.error.code}\r\nMessage: ${err.response.data.err.message}`,
        });
      }
    }
  }

  handleSubmit(event, dcptSection) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true, Status: 'Ready for Review' });
      this.saveDCPT(dcptSection);
      event.preventDefault();
      event.stopPropagation();
      // console.log('here')
    } else {
      event.preventDefault();
      this.setState({ validated: true, Status: 'Ready for Review' });
      this.saveDCPT(dcptSection);
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    // console.log(target.name);

    this.setState({
      [name]: value,
    });
    sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
  }

  handleSelectChange(selectedItems) {
    this.setState({ selectedItems });
  }

  disableValidation() {
    this.setState({ validated: false });
  }

  handleApprove(event) {
    const modalContent = <div><p>Approving {this.state.Class_Code} DCPT...</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);

    CDATA.makeRequest(
      'POST',
      'PRIIPS_App_APPROVE_DCPT_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        RecordIDList: this.state.RECORD_ID.toString(),
        UserName: this.context.state.userEmail,
      },
      'Approve failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved the DCPT for Portfolio: '}
              <span>{this.state.Class_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ action_type: 'View', status: 'Approved' });
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to approve the DCPT for the Portfolio:'}
              <span> {this.state.Class_Code} </span>
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  handleReject(event) {
    const modalContent = <div><p>Re Opening {this.state.Class_Code} DCPT...</p></div>;
    this.context.handlers.setModal('Re Opening ...', modalContent, null, null, true);

    CDATA.makeRequest(
      'POST',
      'PRIIPS_App_REOPEN_DCPT_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        RecordIDList: this.state.RECORD_ID.toString(),
        UserName: this.context.state.userEmail,
      },
      'Re-Open failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'DCPT re-opened successfully for the Portfolio: '}
              <span>{this.state.Class_Code}</span>
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ action_type: 'Edit', status: 'Ready for review' });
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to re-open the DCPT for the Portfolio'}
              <span> {this.state.Class_Code} </span>
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  refreshData() {
    this.setState({ loading: false }, () => this.saveDCPT());
  }

  render() {
    return (
      this.state.redirect === true ? <Redirect to="/regulatory-reporting/dcpt/dashboard" /> : (
        <RegEditView>
          <RegEditBody>
            {
            // this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                <Navbar style={{ backgroundColor: '#e9e8f0' }}>
                  <Nav
                    class="nav nav-tabs"
                    style={{ backgroundColor: '#e9e8f0', marginLeft: -15 }}
                    onSelect={(selectedKey) => this.setState({ eventKey: selectedKey })}
                    onClick={() => { this.disableValidation(); }}
                  >
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="General Info">General Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Obtaining Info">Obtaining Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="Transaction Costs">Transaction Costs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ color: '#6F08CD' }} eventKey="AdminCharges OtherInfo">Administration Charges + Other Info</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Nav className="ml-auto" style={{ marginRight: '40px' }}>
                    {
                      this.state.action_type === 'Edit' ? (
                        <GButton
                          onClick={() => this.handleApprove()}
                          text="Approve"
                          type="Primary"
                        />
                      ) : (
                        <GButton
                          type='Primary'
                          onClick={() => this.handleReject()}
                          text="Re-Open"
                        />
                      )
                    }
                  </Nav>
                  <Nav>
                    <Navbar.Collapse className="justify-content-end">
                      <Navbar.Text>
                        {`${this.state.Class_Code} - ` }
                        <a style={{ color: '#6F08CD' }}>{this.state.eventKey}</a>
                      </Navbar.Text>
                    </Navbar.Collapse>
                    {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        exportName={this.state.api_name}
                        controls={this.state.toolbarButtons}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
              }
                  </Nav>
                </Navbar>
                <Row style={{ display: 'flex', flexDirection: '1', flexGrow: '1' }} className="no-padding">
                  {
                    this.state.eventKey === 'General Info' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'General Info')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover
                                    id={'popover-00010_Portfolio_Identifying_Data'}
                                  >
                                    Identification of the fund or share class or segregated account
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00010_Portfolio_Identifying_Data.controlID">
                                  <Form.Label>00010_Financial_Instrument_Identifying_Data</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00010_Portfolio_Identifying_Data"
                                    value={this.state.x_00010_Portfolio_Identifying_Data}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    One of the following options is required in this field: ISIN, CUSIP, SEDOL, WKN , Bloomberg Ticker, BBGID, Reuters RIC, FIGI, LEI, Other code by members of the Association of National Numbering Agencies, Code attributed by the undertaking
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00020_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio'}>
                                    Codification chosen to identify the share of the CIS
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00020_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio.controlID">
                                  <Form.Label>00020_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00020_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio"
                                    value={this.state.x_00020_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder=""
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be one of the following values: 1, 2, 3, 4, 5, 6, 7, 8, 9, 99.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00030_Portfolio_Name'}>
                                    Name of the Portfolio or name of the CIS
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00030_Portfolio_Name.controlID">
                                  <Form.Label>00030_Portfolio_Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00030_Portfolio_Name"
                                    value={this.state.x_00030_Portfolio_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00040_Portfolio_Issuer_Name'}>
                                    Name of issuer of the fund or share class or segregated account manager
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00040_Portfolio_Issuer_Name.controlID">
                                  <Form.Label>00040_Portfolio_Issuer_Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00040_Portfolio_Issuer_Name"
                                    value={this.state.x_00040_Portfolio_Issuer_Name}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="255 Character Limit"
                                    maxLength="255"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 255 characters.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00050_Start_Of_Period'}>
                                    Date of the first day to which the data refers
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00050_Start_Of_Period.controlID">
                                  <Form.Label>00050_Start_Of_Period</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_00050_Start_Of_Period"
                                    value={this.state.x_00050_Start_Of_Period}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-00060_End_Of_Period'}>
                                    Date of the last day to which the data refers
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="00060_End_Of_Period.controlID">
                                  <Form.Label>00060_End_Of_Period</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: '#F8F8FF' }}
                                    name="x_00060_End_Of_Period"
                                    value={this.state.x_00060_End_Of_Period}
                                    onChange={this.handleInputChange}
                                    disabled
                                    placeholder="YYYY-MM-DD"
                                    pattern="^[1-2]{1}[0-9]{3}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a date formatted as YYYY-MM-DD.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }} variant="primary" type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Obtaining Info' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Obtaining Info')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-01010_Percentage_Not_Obtained'}>
                                    Percentage of investments for which transaction cost data has not been obtained. % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01010_Percentage_Not_Obtained.controlID">
                                  <Form.Label>01010_Percentage_Not_Obtained</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01010_Percentage_Not_Obtained"
                                    value={this.state.x_01010_Percentage_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-01020_Categories_Not_Obtained'}>
                                    EQ - Equity, FI - Fixed Interest, RE - Real Estate, PF - Pooled Fund, FX - Foreign Exchange, ED -Exchange Traded Derivative, OD - Over The Counter Derivative, text
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01020_Categories_Not_Obtained.controlID">
                                  <Form.Label>01020_Categories_Not_Obtained</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01020_Categories_Not_Obtained"
                                    value={this.state.x_01020_Categories_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="100 Character Limit"
                                    maxLength="100"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field cannot be longer than 100 characters
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-01030_Transaction_Costs_Methodology'}>
                                    1, 2, 3, or text
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01030_Transaction_Costs_Methodology.controlID">
                                  <Form.Label>01030_Transaction_Costs_Methodology</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01030_Transaction_Costs_Methodology"
                                    value={this.state.x_01030_Transaction_Costs_Methodology}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="300 Character Limit"
                                    maxLength="300"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and cannot be longer than 300 characters
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-01040_Reason_Not_Obtained'}>
                                    Explanation as to why it has not been possible to comply with COBS 19.8.4R and COBS 19.8.5R including reasons why data is not given in relation to 01010 and details and reasons for the modifications and alternatives noted in 01030
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="01040_Reason_Not_Obtained.controlID">
                                  <Form.Label>01040_Reason_Not_Obtained</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_01040_Reason_Not_Obtained"
                                    value={this.state.x_01040_Reason_Not_Obtained}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="3000 Character Limit"
                                    maxLength="3000"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field cannot be longer than 3000 characters
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'Transaction Costs' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'Transaction Costs')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02010_Aggregation_Of_Transaction_Costs'}>
                                    TOTAL: 02020 + 02030. % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02010_Aggregation_Of_Transaction_Costs.controlID">
                                  <Form.Label>02010_Aggregation_Of_Transaction_Costs</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02010_Aggregation_Of_Transaction_Costs"
                                    value={this.state.x_02010_Aggregation_Of_Transaction_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02020_Transaction_Costs_For_Buy_And_Sell_Transactions'}>
                                    TOTAL: 02040 + 02050 + 02060 + 02070 - 02080. % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02020_Transaction_Costs_For_Buy_And_Sell_Transactions.controlID">
                                  <Form.Label>02020_Transaction_Costs_For_Buy_And_Sell_Transactions</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02020_Transaction_Costs_For_Buy_And_Sell_Transactions"
                                    value={this.state.x_02020_Transaction_Costs_For_Buy_And_Sell_Transactions}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions'}>
                                    Calculated according to COBS 19.8.17R to COBS 19.8.19R. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions.controlID">
                                  <Form.Label>02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions"
                                    value={this.state.x_02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02040_Explicit_Transaction_Taxes'}>
                                    Transaction taxes such as stamp duty and financial transaction taxes. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02040_Explicit_Transaction_Taxes.controlID">
                                  <Form.Label>02040_Explicit_Transaction_Taxes</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02040_Explicit_Transaction_Taxes"
                                    value={this.state.x_02040_Explicit_Transaction_Taxes}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02050_Explicit_Fees_And_Charges'}>
                                    Broker commission and other explicit transaction costs. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02050_Explicit_Fees_And_Charges.controlID">
                                  <Form.Label>02050_Explicit_Fees_And_Charges</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02050_Explicit_Fees_And_Charges"
                                    value={this.state.x_02050_Explicit_Fees_And_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02060_Implicit_Costs'}>
                                    Implicit costs. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02060_Implicit_Costs.controlID">
                                  <Form.Label>02060_Implicit_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02060_Implicit_Costs"
                                    value={this.state.x_02060_Implicit_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02070_Indirect_Transaction_Costs'}>
                                    Transaction costs incurred in an underlying investment vehicle. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02070_Indirect_Transaction_Costs.controlID">
                                  <Form.Label>02070_Indirect_Transaction_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02070_Indirect_Transaction_Costs"
                                    value={this.state.x_02070_Indirect_Transaction_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02080_Anti_Dilution_Offset'}>
                                    Calculated according to COBS 19.8.21. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02080_Anti_Dilution_Offset.controlID">
                                  <Form.Label>02080_Anti_Dilution_Offset</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02080_Anti_Dilution_Offset"
                                    value={this.state.x_02080_Anti_Dilution_Offset}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-02090_Securities_Lending_And_Borrowing_Costs'}>
                                    Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="02090_Securities_Lending_And_Borrowing_Costs.controlID">
                                  <Form.Label>02090_Securities_Lending_And_Borrowing_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_02090_Securities_Lending_And_Borrowing_Costs"
                                    value={this.state.x_02090_Securities_Lending_And_Borrowing_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                  {
                    this.state.eventKey === 'AdminCharges OtherInfo' ? (
                      <Col>
                        <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={(e) => this.handleSubmit(e, 'AdminCharges OtherInfo')}
                        >
                          <Row>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03010_Administration_Charges'}>
                                    Administration charges that would fall within the scope of the default arrangement charge cap. Already includes management fees that also may be disclosed additionally in 02120. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03010_Administration_Charges.controlID">
                                  <Form.Label>03010_Administration_Charges</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03010_Administration_Charges"
                                    value={this.state.x_03010_Administration_Charges}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field is required and must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-03020_Costs_Other_Than_Transaction_Costs'}>
                                    Costs other than transaction costs that would be excluded from the default arrangement charge cap. Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="03020_Costs_Other_Than_Transaction_Costs.controlID">
                                  <Form.Label>03020_Costs_Other_Than_Transaction_Costs</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_03020_Costs_Other_Than_Transaction_Costs"
                                    value={this.state.x_03020_Costs_Other_Than_Transaction_Costs}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                            <Col>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04010_Portfolio_Turnover_Rate'}>
                                    DEFINITION TBD Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04010_Portfolio_Turnover_Rate.controlID">
                                  <Form.Label>04010_Portfolio_Turnover_Rate</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04010_Portfolio_Turnover_Rate"
                                    value={this.state.x_04010_Portfolio_Turnover_Rate}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                              <OverlayTrigger
                                trigger="click"
                                
                                placement="right"
                                overlay={(
                                  <Popover id={'popover-04020_Proportion_Of_Securities_Loaned_Or_Borrowed'}>
                                    DEFINITION TBD Annualised % of NAV
                                  </Popover>
                                )}
                              >
                                <Form.Group controlId="04020_Proportion_Of_Securities_Loaned_Or_Borrowed.controlID">
                                  <Form.Label>04020_Proportion_Of_Securities_Loaned_Or_Borrowed</Form.Label>
                                  <Form.Control
                                    type="text"
                                    style={{ backgroundColor: this.state.action_type === 'Edit' ? '#fff' : '#F8F8FF' }}
                                    name="x_04020_Proportion_Of_Securities_Loaned_Or_Borrowed"
                                    value={this.state.x_04020_Proportion_Of_Securities_Loaned_Or_Borrowed}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.action_type === 'Edit' ? false : true}
                                    placeholder="decimal (0.00)"
                                    pattern="^[+-]?((\d+(\.\d*)?)|(\.\d+))$"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    This field must be a decimal.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonToolbar>
                                {
                                  this.state.action_type === 'Edit' ? (
                                    <Button
                                      style={{
                                        backgroundColor: '#6F08CD',
                                        border: '1px hidden',
                                        borderRadius: '100px',
                                        letterSpacing: '1.25px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        minHeight: '24px',
                                        padding: '0.5rem 1.2rem',
                                      }}
                                      variant="primary"
                                      type="submit"
                                    >
                                      Save
                                    </Button>
                                  ): null
                                }
                                <Button
                                  style={{
                                    backgroundColor: '#6F08CD',
                                    border: '1px hidden',
                                    borderRadius: '100px',
                                    letterSpacing: '1.25px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    minHeight: '24px',
                                    padding: '0.5rem 1.2rem',
                                  }}
                                  variant="secondary"
                                  onClick={() => { this.setState({ redirect: true }); }}
                                >
                                  Cancel
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : null
                  }
                </Row>
              </>
            }
          </RegEditBody>
        </RegEditView>
      ));
  }
}

DCPTeditor.contextType = AppContext;

export default DCPTeditor;
