/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CDATA from '../../../Services/CDATA';
import classes from './Grid.module.scss';
import AppContext from '../../../AppContext';
// import GrpSelectBox from '../../Library/GrpSelectBox/GrpSelectBox';
import GrpToolbar from '../../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../../Library/GrpGrid/GrpGrid';
import GrpSpinner from '../../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../../Components/GrpError/GrpError';
import '../../../Styles/SCSS/ag-theme-material.scss';
import GrpButton from '../../../Library/GrpButton/GrpButton';


class PreviewUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_app_PREVIEW_MERGE',
      mergeid: this.props.mergeid,
      // raw_api: this.props.raw_api,
      // file_id: this.props.file_id,
      table_name: this.props.table_name,
      // trans_batch_id: this.props.trans_batch_id,
      // entity: this.props.entity,
      // file_name: this.props.file_name,
      // file_url: this.props.file_url,
      approvable: this.props.approvable,
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      toolbarButtons: [
        <GrpButton onClick={this.props.approve} size="Medium" type="Primary" text="Approve" />,
        <GrpButton onClick={this.props.reject} size="Medium" type="Primary" text="Reject" />,
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: this.state.mergeid,
        },
        'Error retrieving column names and metadata.',
      );
      this.setState({ columnDefs: this.mapColumns(columns.value), rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        { merge_queue_id: this.state.mergeid, client_code: this.context.state.selectedClient.code },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: false,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
    cellStyle(params) {
      const columnArray = [];
      Object.keys(params.node.data).map((y) => {
        if (y.indexOf('same_') === 0) {
          columnArray.push(y);
        }
        return columnArray;
      });
      let background = 'rgba(0, 151, 19, 0.1) !important';
      columnArray.map((y) => {
        const subsString = 'same_';
        const headerStartsWithSame = y.substring(y.indexOf(subsString) + subsString.length);
        const colField = params.colDef.field;
        if (colField !== undefined) {
          if (colField.indexOf(headerStartsWithSame) !== -1 && (colField !== y)) {
            const boolValue = params.node.data[y];
            if (boolValue.toLowerCase() === 'false') {
              background = 'rgba(255, 0, 0, 0.1) !important';
            }
          }
        }
        return null;
      });
      return {
        background,
      };
    },
  }));


  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }


  externalFilterChanged = (filterVal) => {
    this.setState({ filterVal },
      () => { this.gridApi.onFilterChanged(); });
  }

  doesExternalFilterPass = (node) => {
    switch (this.state.filterVal) {
      case 'New Record': return node.data.record_type === this.state.filterVal;
      case 'Updated Record': return node.data.record_type === this.state.filterVal;
      case 'Duplicate Record': return node.data.record_type === this.state.filterVal;
      case 'Show All Records': return true;
      default: return true;
    }
  }


  deletePrompt = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>{`Are you sure you want to delete ${listOfRows.length} row(s)?`}</p>
          <GrpButton onClick={() => this.deleteRecords()} size="Medium" type="Primary" text="Delete" />
          {' '}
          <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Delete', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to delete.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  deleteRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimRecords = listOfRows.map((row) => row.source_record_id.toString()).join(',');

    const modalContent = (
      <div>
        <p>
          Deleting
          {listOfRows.length}
          {' '}
          row(s)...
        </p>
      </div>
    );
    this.context.handlers.setModal('Deleting ...', modalContent, null, null, true);

    CDATA.makeRequest('POST', 'PRIIPS_app_DELETE_BULK', '',
      {
        RecordIDList: delimRecords,
        UserName: this.context.state.userEmail,
        UserID: this.context.state.userAzureID,
        ClientCode_bk: this.context.state.selectedClient.code,
        SchemaName: 'client_di_raw',
        TableName: this.state.table_name,
      },
      'Delete failed.')
      .then(() => {
        this.context.handlers.toggleModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully deleted '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.gridApi.updateRowData({ remove: listOfRows });
        this.setState({ totalRowCount: (this.state.totalRowCount - listOfRows.length) });
      })
      .catch(() => {
        this.context.handlers.toggleModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to delete the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        // console.log('delete error: ',error);
      });
  }


  render() {
    return (
      <div className={classes.Grid}>
        <div className={classes.GridBody}>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        deleter={this.deletePrompt}
                        controls={this.state.approvable ? this.state.toolbarButtons : null}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        externalFilterDropdown={{
                          title: 'Filter:',
                          options: [
                            { label: 'Show All Records', value: 'Show All Records' },
                            { label: 'Show New Records', value: 'New Record' },
                            { label: 'Show Updated Records', value: 'Updated Record' },
                            { label: 'Show Duplicate Records', value: 'Duplicate Record' }],
                          defaultValue: { label: 'Show All Records', value: 'Show All Records' },
                        }}
                        externalFilterChanged={this.externalFilterChanged}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                  externalFilter={this.doesExternalFilterPass}
                />
              </>
            )
          }
        </div>
      </div>
    );
  }
}

PreviewUpdates.defaultProps = {
  approve: () => {},
  reject: () => {},
  // entity: '',
  // file_id: '',
  // file_name: '',
  // file_url: '',
  mergeid: '',
  // raw_api: '',
  table_name: '',
  // trans_batch_id: '',
};

PreviewUpdates.propTypes = {
  approve: PropTypes.func,
  reject: PropTypes.func,
  approvable: PropTypes.bool.isRequired,
  // entity: PropTypes.string,
  // file_id: PropTypes.string,
  // file_name: PropTypes.string,
  // file_url: PropTypes.string,
  mergeid: PropTypes.string,
  // raw_api: PropTypes.string,
  table_name: PropTypes.string,
  // trans_batch_id: PropTypes.string,
};

PreviewUpdates.contextType = AppContext;

export default PreviewUpdates;
