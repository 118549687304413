import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classes from './GrpNavItem.module.scss';

/**
 * A navigation control that can be used to either link to a view through a single click
 * or it can be clicked to show a child collection of links.
 *
 * The "Active" class will be set on the component if either the path property equals the router match.path property,
 * or in the case of a "multi" GrpNavItem component,
 * any of the children link's path property equals the router match.path property.
 */
class GrpNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBoxState: '',
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
  * Set the wrapper ref
  */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  isOnSubPath = (items) => {
    const itemPaths = items.map((item) => item.path);
    for (let i = 0; i < itemPaths.length; i += 1) {
      if (this.props.location.pathname.includes(itemPaths[i])) {
        return true;
      }
    }
    return false;
  }

  clicked = () => {
    let listBoxState;
    // console.log('clickedinside')
    if (this.state.listBoxState === '') {
      listBoxState = 'open';
    } else {
      listBoxState = '';
    }
    this.setState({ listBoxState });
  };// clicked()

  // close listBox on ClickOutside
  handleClickOutside(event) {
    // console.log('clickedoutside')
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.listBoxState === 'open') {
        this.setState({ listBoxState: '' });
      }
    }
  }

  render() {
    const segments = this.props.location.pathname.split('/').filter((item) => item.length > 0);
    const active = this.props.base === true && segments.includes(this.props.basePath);
    const ActiveClass = active === true && classes.ActiveNavItem;

    let content;
    if (this.props.visibility === false) {
      content = null;
    } else if (this.props.type === 'single') {
      content = (
        <NavLink
          activeClassName={classes.ActiveNavItem}
          className={([classes.GrpNavItem, this.props.className, ActiveClass]).join(' ')}
          to={this.props.path}
          exact={this.props.base}
        >
          <div className={`${classes.IconBox} ${this.props.activeClassName}`}>
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
          {
            this.props.state === true && (
              <div className={classes.LabelBox}>
                <div>{this.props.label}</div>
              </div>
            )
          }
        </NavLink>
      );
    } else {
      content = (
        <div
          className={
            ([
              classes.GrpNavItem,
              `${this.props.className}`,
              `${this.isOnSubPath(this.props.items) && classes.ActiveNavItem}`,
            ]).join(' ')
          }
          onClick={this.clicked}
          onKeyPress={this.clicked}
          aria-label="button"
          role="button"
          tabIndex={0}
        >
          <div className={classes.IconBox}>
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
          {
            this.props.state === true && (
              <div className={classes.LabelBox}>
                <div>{this.props.label}</div>
                <FontAwesomeIcon className={classes.Caret} icon={['fal', 'angle-right']} />
              </div>
            )
          }
          <div className={`${classes.ListBox} ${this.state.listBoxState === 'open' && classes.Show}`}>
            <div className={classes.Arrow} />
            <div ref={this.setWrapperRef} className={classes.ListWrapper}>
              {
                this.props.items && this.props.items.map(
                  (item) => (
                    <NavLink
                      activeClassName={classes.ActiveSubItem}
                      className={`${classes.Item} ${ActiveClass}`}
                      key={item.display}
                      to={item.path}
                      exact={this.props.base}
                    >
                      {item.display}
                    </NavLink>
                  ),
                )
              }
            </div>
          </div>
        </div>
      );
    }
    return <>{content}</>;
  }
}

// ({icon, label, state, type, path, visibility}) =>
GrpNavItem.defaultProps = {
  activeClassName: '',
  base: false,
  className: '',
  icon: [],
  items: null,
  label: '',
  path: '',
  state: null,
  type: '',
};

GrpNavItem.propTypes = {
  activeClassName: PropTypes.string,
  base: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      display: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  path: PropTypes.string,
  state: PropTypes.bool,
  type: PropTypes.string,
};
// withRoute
export default withRouter(GrpNavItem);
