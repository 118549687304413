import styled from 'styled-components';
import ArpMultiSelect from '../../Library/ArpMultiSelect/ArpMultiSelect';
import GrpButton from '../../Library/GrpButton/GrpButton';

export const RoleMultiSelect = styled(ArpMultiSelect)``;

export const RoleView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RoleBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const RoleButton = styled(GrpButton)``;
