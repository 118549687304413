import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classes from './HomeAside.module.scss';
import DLogo from 'Images/logo_1.svg';
import Logo from 'Images/logo_4.svg';
import ARLogo from 'Images/arc_reg_logo.svg';
import Checkers from 'Images/checkers.svg';

const HomeAside = ({ asideOpen, children, toggleAside }) => {
  const collapseBtnClick = () => {
    toggleAside();
  };

  return (
    <aside
      style={{
        background: `url(${Checkers})`,
        backgroundPosition: '45% 30%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className={`${classes.Aside} ${!asideOpen && classes.Collapse}`}
    >
      <a href="/" className={classes.LogoBox}>
        <img
          alt="DFIN"
          src={asideOpen ? Logo : DLogo}
          className={asideOpen ? classes.Open : ''}
        />
        {
          asideOpen ? (
            <img
              alt='ArcRegulatory'
              src={asideOpen && ARLogo}
              className={asideOpen ? classes.SubLogo: ''}
            />
          ) : <div></div>
        }
      </a>
      <div className={classes.NavWrapper}>
        {children}
      </div>
      <div
        className={classes.CollapseBtn}
        onClick={collapseBtnClick}
        onKeyPress={collapseBtnClick}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon icon={['fas', 'bars']} />
      </div>
    </aside>
  );
};

HomeAside.defaultProps = {
  asideOpen: false,
  toggleAside: () => {},
};

HomeAside.propTypes = {
  asideOpen: PropTypes.bool,
  children: PropTypes.element,
  toggleAside: PropTypes.func,
};

export default HomeAside;
