import React from 'react';
import PropTypes from 'prop-types';
import {
  ErrorWrapper, Skull, Name,
} from './GrpError.styles';

const GrpError = ({ text }) => (
  <ErrorWrapper>
    <Skull
      icon={['fal', 'skull-crossbones']}
      size="4x"
    />
    <Name>{text}</Name>
    {/* We're sorry, there was an issue loading your data. Please try again. */}
  </ErrorWrapper>
);

GrpError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default GrpError;
