import styled from 'styled-components';
import GrpFileBrowser from '../../Library/GrpFileBrowser/GrpFileBrowser';
import GrpError from '../../Components/GrpError/GrpError';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';

export const Container = styled.div`
  display: flex;
  padding: 25px;
  flex-direction: column;
  flex: 1;
`;

export const FileBrowserContainer = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FileBrowser = styled(GrpFileBrowser)``;

export const Error = styled(GrpError)``;

export const Spinner = styled(GrpSpinner)``;
