/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import CDATA from 'Services/CDATA';
import AppContext from 'AppContext';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import '../../Styles/SCSS/ag-theme-material.scss';
import { DataManagementView, DataManagementBody } from './DataManager.styles';
import DateModalContent from '../TransactionDashboard/DateModalContent';
import APIM from '../../Services/APIM';

// this exists just to speed up the select dropdown by disabling mouseover events,
// so now it's merely shitty instead of completely unusable
// eslint-disable-next-line react/prefer-stateless-function
class CustomOption extends Component {
    render() {
        const { innerProps, isFocused, ...otherProps } = this.props;
        const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
        const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
        return (
            <components.Option {...newProps} className="your-option-css-class">
                {this.props.children}
            </components.Option>
        );
    }
}

class DataManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api_name: this.props.location.api_name ? this.props.location.api_name : this.props.api_name,
            pk: this.props.pk,
            editable: this.props.editable,
            bigDataMode: ((this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_transaction' || (this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_class_nav') ||
                (this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_proxy' ? true: false,
            selectedValue: this.props.location.selectedValue && this.props.location.selectedValue,
            loading: true,
            failed: false,
            totalRowCount: 0,
            displayedRowCount: 0,
            extract: (this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_transaction' ? '1' : (
                (this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_class_nav' ? '2' : (
                    (this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_fund_nav' ? '3' : '0'
                )),
            toolbarButtons: [
                <GrpButton
                    key={0}
                    onClick={() => {
                        (this.props.location.api_name ? this.props.location.api_name : this.props.api_name) === 'PRIIPS_app_transaction' ? this.createDateprompt() : this.callExtractGeneration();
                    }}
                    size="Medium"
                    type="Secondary"
                    text="Extract"
                />,
            ],
        };
    }

    componentDidMount() {
        this.getMetadata();
    }

    async getMetadata() {
        try {
            const columns = await CDATA.makeRequest(
                'POST',
                'PRIIPS_app_GET_COLUMNS',
                '',
                {
                    API_NAME: this.state.api_name,
                    MERGE_QUEUE_ID: -99,
                },
                'Error retrieving column names and metadata.',
            );


            const columnDefs = this.mapColumns(columns.value);
            columnDefs.unshift({
                field: 'SelectRow',
                headerName: 'Select Row',
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                sortable: false,
                maxWidth: 45,
                autoHeight: true,
                resizable: true,
            });

            this.setState({ columnDefs: columnDefs, rawColumns: columns.value });
        } catch (err) {
            this.setState({
                loading: false,
                failed: true,
                failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
            });
            console.log(err);
        }
    }

    async getData() {
        const reportingDate = (
            this.context.state.selectedReportingDate ?? this.context.state.availableReportingDates[0]
        ).code;

        let urlParameters = "";
        if (!this.props.noClient && this.context.state.availableRoutes.some(route => route.full_path == window.location.pathname && route.force_reportingdate)) {
            if (this.state.api_name === 'PRIIPS_app_INS_CALC_ERRORS') {
                urlParameters = `${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and ReportingDate eq ${reportingDate}`)}`;
            } else {
                urlParameters = `${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and Reference_Date eq ${reportingDate}`)}`;
            }
        } else if (!this.props.noClient && this.state.api_name === "PRIIPS_app_class_nav") {
            urlParameters = `${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and ClassCode_bk eq '${this.state.selectedValue.value}'`)}`;
        } else if (!this.props.noClient && this.state.api_name === "PRIIPS_app_proxy") {
           urlParameters = `${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and Proxy_ID eq '${this.state.selectedValue.value}'`)}`;
        } else if (!this.props.noClient) {
            urlParameters = `${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}'`)}`;
        }

        this.setState({ loading: true });
        let data = [];
        try {
            if (this.state.api_name === 'PRIIPS_app_transaction') {
                data = await CDATA.makeRequest(
                    'POST',
                    'PRIIPS_app_GET_TRANSACTION',
                    '',
                    {
                        ClientCode_bk: this.context.state.selectedClient.code,
                        FundCode_bk: this.state.selectedValue.value
                    },
                    'Error retrieving data.',
                );
            }
            else if (this.state.api_name === 'PRIIPS_app_class_calc') {
                data = await CDATA.makeRequest(
                    'POST',
                    'PRIIPS_app_GetShareClassCalcResults',
                    '',
                    {
                        ClientCode_bk: this.context.state.selectedClient.code,
                        ReportingPeriod: this.context.state.selectedReportingDate.code
                    },
                    'Error retrieving data.',
                );
            }
            else if (this.state.api_name === 'PRIIPS_INS_app_CEPT_Perf_v2') {
                data = await CDATA.makeRequest(
                    'GET',
                    'PRIIPS_app_CEPT_Perf_v2',
                    urlParameters,
                    {},
                    'Error retrieving data.',
                );
            }
            else if (this.state.api_name === 'PRIIPS_app_IO_EPT') {
                data = await CDATA.getAllDataChunks(
                    'GET',
                    this.state.api_name,
                    urlParameters,
                    {},
                    'Error retrieving data.',
                    1000,
                );
            }
            else {
                data = await CDATA.makeRequest(
                    'GET',
                    this.state.api_name,
                    urlParameters,
                    {},
                    'Error retrieving data.',
                    '', //skip parameter
                    '' //pageSize parameter
                );
            }

            let values, count;

            if (data && data.Content) {
                values = data.Content;
                count = data.ContentCount;
            }
            else {
                values = data.value ?? data;
                count = data['@odata.count'] ?? data.length;
            }


            const totalRowCount = data && count !== 0 ? count : values.filter((item) => item.return_value !== '0').length;
            const filteredData = values.filter((item) => item.return_value !== '0');
            this.gridApi.setRowData(values.length === 0 ? [] : filteredData);
            this.setState({ loading: false, totalRowCount });

        } catch (err) {
            this.setState({
                loading: false,
                failed: true,
                failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
            });
        }
    }

    mapColumns = (rawColumns) => rawColumns.map((x) => ({
        // this is if you want to do a default sort for a specific column
        // sort: this.props.defaultGridSort !== undefined
        // && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
        //   direction: 'desc',
        //   priority: 0
        // } : null,
        autoHeight: true,
        cellStyle: {
            'whiteSpace': 'pre-wrap',
            'lineHeight': '18px',
            padding: '12px',
            maxHeight: 'auto',
        },
        comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
        editable: this.state.editable === false ? false : x.editable,
        field: x.dv_column,
        headerName: x.display_name,
        headerTooltip: `${x.dv_column}`,
        hide: x.is_hidden,
        sortType: x.sorttype,
        tooltipValueGetter(params) {
            if (params.node.data) {
                let tooltipText = '';
                return (tooltipText);
            }
            return null;
        },
        wrapText: true,
    }));

    getClasses = async () => {
        if (this.context.state.selectedClient.type === 'AM') {
            try {
                const classes = await CDATA.makeRequest(
                    'GET',
                    'PRIIPS_app_class',
                    `${CDATA.addSelect('ClientCode_bk,ClassCode_bk,OF_Full_Share_Class_Name')}&${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}'`)}`,
                    {},
                    'Error retrieving list of classes for the selected client',
                );
                const filteredClasses = classes.value.filter((item) => item.return_value !== '0');
                const availableClasses = filteredClasses.map((x) => (
                    {
                        value: x.ClassCode_bk,
                        label: `${x.OF_Full_Share_Class_Name} - ${x.ClassCode_bk}`,
                    }
                ));
                return availableClasses;
            } catch (err) {
                console.log(err);
            }
        }
    }

    getProxyIds = async () => {
        if (this.context.state.selectedClient.type === 'AM') {
            try {
               
                const ProxyIds = await CDATA.makeRequest(
                    'GET',
                    'PRIIPS_app_proxy',
                    `${CDATA.addGroupBy('Proxy_ID,ClientCode_bk')}&${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}'`)}`,
                    {},
                    'Error retrieving list of ProxyIds for the selected client',
                );
                const filteredProxyIds = ProxyIds.value.filter((item) => item.return_value !== '0');
                const availableProxyIds = filteredProxyIds.map((x) => (
                    {
                        value: x.Proxy_ID,
                        label: x.Proxy_ID,
                    }
                ));
                return availableProxyIds;
            } catch (err) {
                console.log(err);
            }
        }
    }


    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (!this.state.bigDataMode) {
            await this.getData();
        } else {
            let availableClasses = [];
            if (this.state.api_name === 'PRIIPS_app_class_nav') {
                availableClasses = await this.getClasses();
                this.context.state.availableClasses = availableClasses;
            }
            else if (this.state.api_name === 'PRIIPS_app_proxy') {
                availableClasses = await this.getProxyIds();
                this.context.state.availableClasses = availableClasses;
                if (availableClasses !== undefined && availableClasses.length > 1) {
                    this.state.selectedValue = availableClasses[0];
                }
                else {
                    this.state.selectedValue = '';
                }
                this.refresh(); 
                return;
            }
            const customStyles = {
                option: (styles, state) => ({
                    ...styles,
                    cursor: 'pointer',
                }),
                control: (styles) => ({
                    ...styles,
                    cursor: 'pointer',
                }),
            }
            const modalFundDropdown = (
                <div style={{ overflow: 'visible' }}>
                    <Select
                        components={{ Option: CustomOption }}
                        ignoreAccents={false}
                        isSearchable
                        onChange={this.handleSelect}
                        options={this.state.api_name === 'PRIIPS_app_transaction' ? this.context.state.availableFunds : availableClasses}
                        styles={customStyles}
                        value={this.state.selectedValue}
                    />
                </div>
            );

            const modalFooter = (
                <div>
                    <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Nevermind" />
                </div>
            );

            this.context.handlers.setModal(
                this.state.api_name === 'PRIIPS_app_transaction' ? 'Select a Fund' : 'Select a Class',
                modalFundDropdown,
                'overflow',
                modalFooter,
                false,
                null,
            );
            this.context.handlers.toggleModal();
        }
    };

    handleSelect = (selectedValue) => {
        // console.log(event);
        this.setState({ selectedValue }, () => { this.context.handlers.closeModal(); this.refresh(); });
    }

    async refresh() {
        await this.getData();
        let columns = this.gridColumnApi.getAllGridColumns()
            .filter(x => x.colDef.hide === undefined || x.colDef.hide !== true);
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].colId.toLowerCase().includes('created')) {
                const sortModel = [
                    { colId: columns[i].colId, sort: 'desc' }
                ];
                this.gridApi.setSortModel(sortModel);
                break;
            }
        }
    }

    onModelUpdated = (params) => {
        // console.log('ag grid model updated', params);
        this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
    }

    onCellEditingComplete = (event) => {
        const rowNode = event.api.getDisplayedRowAtIndex(event.rowIndex);
        if (rowNode.data.ClassCode_bk !== undefined && event.column.colId === "Active" && event.api.z.newValue.trim() === "N") {
            //Active column has been set to N, trigger a blob deletion
            let blobToDelete = {
                ClientCode_bk: this.context.state.selectedClient.code,
                ReferenceDate: this.context.state.selectedReportingDate.code,
                ClassCodes: [rowNode.data.ClassCode_bk],
                Type: "IO",
                User: this.context.state.userEmail,
                AzureID: this.context.state.userAzureID,
            };

            APIM.makeRequest('birtPdfDelete', JSON.stringify(blobToDelete), 'Error deleting Blob from container', Auth0SessionInfo.GetSessionInfo().accessToken)
        }

    }

    deletePrompt = () => {
        const listOfRows = this.gridApi.getSelectedRows();
        if (listOfRows.length > 0) {
            const modalContent = (
                <div>
                    <p>
                        {`Are you sure you want to delete ${listOfRows.length} row(s)?`}
                    </p>
                    <GrpButton
                        onClick={() => this.deleteRecords()}
                        size="Medium"
                        type="Primary"
                        text="Delete"
                    />
                    <GrpButton
                        onClick={() => this.context.handlers.toggleModal()}
                        size="Medium"
                        type="Secondary"
                        text="Cancel"
                    />
                </div>
            );
            this.context.handlers.setModal('Delete', modalContent, null, null, false);
            this.context.handlers.toggleModal();
        } else {
            const toast = {
                type: 'error',
                body: (<>No rows were selected to delete.</>),
            };
            this.context.handlers.setToast(toast);
        }
    }

    deleteRecords = () => {
        const listOfRows = this.gridApi.getSelectedRows();
        var delimRecords = "";
        if (listOfRows.some(x => x.RECORD_ID === undefined)) {
            delimRecords = listOfRows.map((row) => row.record_id.toString()).join(',');
        }
        else {
            delimRecords = listOfRows.map((row) => row.RECORD_ID.toString()).join(',');
        }
        const recordsWithClassCode = listOfRows.filter(row => row.ClassCode_bk !== undefined)

        const modalContent = (
            <div>
                <p>{`Deleting ${listOfRows.length} row(s)...`}</p>
            </div>
        );
        this.context.handlers.setModal('Deleting ...', modalContent, null, null, true);

        CDATA.makeRequest(
            'POST',
            'PRIIPS_app_DELETE_BULK',
            '',
            {
                RecordIDList: delimRecords,
                UserName: this.context.state.userEmail,
                UserID: this.context.state.userAzureID,
                ClientCode_bk: this.context.state.selectedClient.code,
                SchemaName: 'app',
                TableName: this.state.api_name.substring(this.state.api_name.indexOf('_app_') + 5),
            },
            'Delete failed.',
        )
            .then(() => { // res
                this.context.handlers.closeModal();
                const toast = {
                    type: 'success',
                    body: (
                        <>
                            {'You successfully deleted '}
                            <span>{listOfRows.length}</span>
                            {' row'}
                            {listOfRows.length > 1 ? 's' : ''}
                            .
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
                this.gridApi.updateRowData({ remove: listOfRows });
                this.setState({ totalRowCount: (this.state.totalRowCount - listOfRows.length) });

                if (recordsWithClassCode.length > 0) {
                    let blobsToDelete = {
                        ClientCode_bk: this.context.state.selectedClient.code,
                        ReferenceDate: this.context.state.selectedReportingDate.code,
                        ClassCodes: recordsWithClassCode.map((row) => row.ClassCode_bk.toString()).join(',').split(','),
                        Type: "IO",
                        User: this.context.state.userEmail,
                        AzureID: this.context.state.userAzureID,
                    };

                    APIM.makeRequest('birtPdfDelete', JSON.stringify(blobsToDelete), 'Error deleting Blob from container', Auth0SessionInfo.GetSessionInfo().accessToken)
                }

            })
            .catch(() => { // error
                this.context.handlers.closeModal();
                const toast = {
                    type: 'error',
                    body: (
                        <>
                            {'Error - something went wrong and we were not able to delete the '}
                            <span>{listOfRows.length}</span>
                            {' row'}
                            {listOfRows.length > 1 ? 's' : ''}
                            {' you had selected.'}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
                // console.log('delete error: ',error);
            });
    }

    callExtractGeneration = async () => {
        const jobType = this.state.extract;
        const data = JSON.stringify({
            ClientCode: this.context.state.selectedClient.code,
            BaseFileName: jobType === '2' ? 'Class_NAV' : 'Fund_NAV',
            DataFilterColumn: 'ResultType',
            FileType: 'EXCEL',
            Parameters: {
                Client_Code: this.context.state.selectedClient.code
            },
            Procedure: jobType === '2' ? 'Get_Data_All_Class_NAV' : 'Get_Data_All_Fund_NAV',
            Delimiter: null,
            DateFormatString: null
        });

        await APIM.makeRequest('generateReport', data, 'Error submitting request for data extract', Auth0SessionInfo.GetSessionInfo().accessToken)
            .then(() => { // res
                this.context.handlers.closeModal();
                const toast = {
                    type: 'success',
                    body: (
                        <>
                            {'You successfully submitted data extract request '}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            })
            .catch(() => { // error
                this.context.handlers.closeModal();
                const toast = {
                    type: 'error',
                    body: (
                        <>
                            {'Error - something went wrong and we were not able to run data extract request '}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            });
    }

    createDateprompt() {
        this.context.handlers.setModal(
            'Select Dates for Extract',
            <DateModalContent />,
            null,
            null,
            false,
        );
        this.context.handlers.toggleModal();
    }

    render() {
        return (
            <DataManagementView>
                <DataManagementBody>
                    {
                        this.state.failed ? <GrpError text={this.state.failMessage} /> : (
                            <>
                                {
                                    (this.state.loading || (this.state.bigDataMode && this.state.selectedValue === null) ? <GrpSpinner text="Loading data..." />
                                        : this.gridApi && (
                                            <GrpToolbar
                                                api={this.gridApi}
                                                working={this.state.toolbarWorking}
                                                controls={this.state.extract === '1' || this.state.extract === '2' || this.state.extract === '3' ? this.state.toolbarButtons : null}
                                                dropdown={
                                                    (this.state.api_name === 'PRIIPS_app_transaction' || this.state.api_name === 'PRIIPS_app_class_nav' || this.state.api_name === 'PRIIPS_app_proxy') && (
                                                        <Select
                                                            // filterOption={createFilter({ignoreAccents: false})}
                                                            components={{ Option: CustomOption }}
                                                            ignoreAccents={false}
                                                            isSearchable
                                                            onChange={this.handleSelect}
                                                            options={this.state.api_name === 'PRIIPS_app_transaction' ? this.context.state.availableFunds : this.context.state.availableClasses}
                                                            styles={{
                                                                option: (styles, state) => ({
                                                                    ...styles,
                                                                    cursor: 'pointer',
                                                                }),
                                                                control: (styles) => ({
                                                                    ...styles,
                                                                    cursor: 'pointer',
                                                                }),
                                                            }}
                                                            value={this.state.selectedValue}
                                                        />
                                                    )
                                                }
                                                deleter={this.state.editable ? this.deletePrompt : null}
                                                exportName={this.state.api_name}
                                                count={{
                                                    total: this.state.totalRowCount,
                                                    showing: this.state.displayedRowCount,
                                                }}
                                            />
                                        )
                                    )
                                }

                                <GrpGrid
                                    apiName={this.state.api_name}
                                    columnDefs={this.state.columnDefs}
                                    editable
                                    getAudit
                                    onGridReady={this.onGridReady}
                                    onModelUpdated={this.onModelUpdated}
                                    pivotable
                                    pk={this.state.pk}
                                    rawColumnData={this.state.rawColumns}
                                    onCellEditingComplete={this.onCellEditingComplete}
                                />
                            </>
                        )
                    }
                </DataManagementBody>
            </DataManagementView>
        );
    }
}

DataManager.propTypes = {
    children: PropTypes.element,
    location: PropTypes.shape({
        api_name: PropTypes.string,
    }).isRequired,
};

DataManager.contextType = AppContext;

export default DataManager;
