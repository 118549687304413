/* eslint-disable max-len */
import React, { useContext } from 'react';
import { Redirect, useLocation, withRouter } from 'react-router-dom';
import AppContext from 'AppContext';

/**
 * Handles redirects from New ArcRegUi
 *
 * Test String:
 *   inbound/eyJEZXN0aW5hdGlvblBhZ2VVcmwiOiIvZGF0YS1tYW5hZ2VtZW50L2NsaWVudCIsIkNsaWVudENvZGUiOiJBU0kiLCJSZXBvcnRpbmdQZXJpb2QiOiIyMDIwLTEyLTMxIiwiQ2xpZW50TmFtZSI6IkFiZXJkZWVuIFN0YW5kYXJkIEludmVzdG1lbnRzIiwiQ2xpZW50VHlwZSI6IkFNIn0=
 *   {"DestinationPageUrl":"/data-management/client","ClientCode":"ASI","ReportingPeriod":"2020-12-31","ClientName":"Aberdeen Standard Investments","ClientType":"AM"}
 * 
 * @returns <Redirect>
 */
const Inbound = () => {
  const { handlers } = useContext(AppContext);
  const location = useLocation();

  const parsePayload = (pathname) => {
    let receivedPayload = JSON.parse(atob(pathname.substring(9)));

    handlers.setSelectedClient({
      name: receivedPayload.ClientName,
      type: receivedPayload.ClientType,
      code: receivedPayload.ClientCode,
    });

    if (!receivedPayload.ReportingPeriod) {
      handlers.setSelectedReportingDate(null);
    } else {
      handlers.setSelectedReportingDate({
        code: receivedPayload.ReportingPeriod,
        name: receivedPayload.ReportingPeriod,
        selected: true,
      });  
    }
    
    return (receivedPayload.DestinationPageUrl);
  };

  const destination = parsePayload(location.pathname);

  return (
    <Redirect from="/inbound/" to={destination} />
  );
};

export default withRouter(Inbound);
