import React, { useContext } from 'react';
import IdleTimer from 'react-idle-timer';
import AppContext from '../../AppContext';
import config from '../../config';

const Auth0IdleTimer = ({ AuthClient, intervalFunction }) => {
  const context = useContext(AppContext);
  const handleOnIdle = () => {
    context.state = {};
    window.localStorage.setItem("callbackURL", window.location.pathname !== "/callback" ? window.location.pathname : "/")
    clearInterval(intervalFunction);
    sessionStorage.clear();
    AuthClient.logout({ redirect_uri: config.auth0.logoutRedirectUrl });
  };

  return (
    <IdleTimer
      timeout={1000 * 60 * config.auth0.idleTimeout}
      onIdle={handleOnIdle}
      debounce={250}
    />
  );
};

export default Auth0IdleTimer;
