/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { v4 as uuid } from 'uuid';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'Styles/ag-theme-reborn/ag-theme-reborn.scss';

import PropTypes from 'prop-types';
import AppContext from 'AppContext';
import AuditTrail from './AuditTrailModalTable/AuditTrailModalTable';
import CDATA from 'Services/CDATA';
import classes from './GrpGrid.module.scss';
import GrpCellEditor from './GrpCellEditor/GrpCellEditor';

const GrpGrid = ({
  // deletable,
  // exportable,
  // rowData,
  apiName,
  columnDefs,
  disabled,
  editable,
  externalFilter,
  frameworkComponents,
  getAudit,
  getContextMenuItems,
  getVersionHistory,
  groupSelectsChildren,
  onCellClicked,
  onCellEditingComplete,
  onCellValueChanged,
  onGridReady,
  onModelUpdated,
  onSelectionChanged,
  pivotable,
  pk,
  rawColumnData,
  singleClickEdit,
  treeData,
}) => {
  LicenseManager.setLicenseKey('CompanyName=Donnelley Financial, LLC,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=15,LicensedProductionInstancesCount=4,AssetReference=AG-011092,ExpiryDate=23_June_2022_[v2]_MTY1NTkzODgwMDAwMA==84055abc5b93544984e126141426f934');

  const context = useContext(AppContext);
  const  initGrid = async (params) => {
    if (onGridReady) {
      await onGridReady(params); // 
      setTimeout(() => {
        let columns = params.columnApi.getAllGridColumns();
        //Get the first column that has the word date or created and sort desc with it by default.
          if (typeof columns !== 'undefined' && columns.length > 0){
          let dateColumn = getDateColumnToSort(columns
            .filter(x => x.colDef.hide === undefined || x.colDef.hide !== true));
          if(dateColumn){
            const sortModel = [
              {colId: dateColumn.colId, sort: 'desc'}
            ];
            params.api.setSortModel(sortModel);
          }
        }
      }, 100);
     
    }
  };

  // Potentially causing issues, could be accomplished by setting default
  // sort of columns on a per page basis using GridAPI.
  const getDateColumnToSort = (columns) => {
    for (var i = 0; i < columns.length; i++){
      if(columns[i].colId.toLowerCase().includes('date') ){
        return columns[i]
      }
    }
    for (i = 0; i < columns.length; i++){
      if(columns[i].colId.toLowerCase().includes('created') ){
        return columns[i]
      }
    }
    for (i = 0; i < columns.length; i++){
      if(columns[i].colId.toLowerCase().includes('_from') ){
        return columns[i]
      }
    }
    for (i = 0; i < columns.length; i++){
      if(columns[i].colId.toLowerCase().includes('_to') ){
        return columns[i]
      }
    }
    for (i = 0; i < columns.length; i++){
      if(columns[i].colId.toLowerCase().includes('time') ){
        return columns[i]
      }
    }
    return null;
  }

  const gridUpdated = (params) => {
    // this does auto resizing
    if (params.columnApi.getAllColumns()) {
      let allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.colId);
      });
      params.columnApi.autoSizeColumns(allColumnIds);
    }

    /* this is for when we figure out how to show narratives better,
      it sets the height of the rows specific to the content
    */
    // if (params.newData ) {
    //   params.api.resetRowHeights();
    // }

    if (params.api.getModel().rootNode.childrenAfterFilter.length === 0) {
      params.api.showNoRowsOverlay();
    } else {
      params.api.hideOverlay();
    }

    // this calls whatever extra custom stuff we pass in from above
    if (onModelUpdated) {
      onModelUpdated(params);
    }
  };

  if (editable === false && columnDefs) {
    columnDefs = columnDefs.map((item) => {
      item.editable = false;
      return item;
    });
  }

  // useMemo optional, just an attempt at optimization.
  const defaultColDef = useMemo(()=> ({
    editable,
    resizable: true,
    sortable: true,
    sortType: 'string',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains', 'equals'],
      suppressAndOrCondition: true,
      newRowsAction: 'keep',
      ebounceMs: 2000,
    },   
    enableCellChangeFlash: true,
    cellEditor: 'grpCellEditor',
    enablePivot: pivotable === true, // TODO: Make these 3 pivotable column settings come from metadata.api_display
    enableRowGroup: pivotable === true, // TODO: Make these 3 pivotable column settings come from metadata.api_display
    enableValue: pivotable === true, // TODO: Make these 3 pivotable column settings come from metadata.api_display
    maxWidth: 1000, // this is for when we figure out how to show narratives better
    // autoHeight:true, // this is for when we figure out how to show narratives better
    // cellStyle: {'white-space': 'normal'} 
    //this is for when we figure out how to show narratives better
    // flex: 1,
    // wrapText: true,     // <-- HERE
    // autoHeight: true,   // <-- & HERE
  }), [editable, pivotable]);

  const onCellEditingStopped = async (event) => {
    const rowNode = event.api.getDisplayedRowAtIndex(event.rowIndex);
    // the other value for event.api.z.action is 'trash'
    if (event.api.z && event.api.z.action === 'accept') {
      // trim leading/trailing spaces from any string that is entered
      // make the new value null if it's a blank string or "[NULL]"
      let { newValue } = event.api.z;
      if (typeof (newValue) === 'string') {
        const newValueTrimmed = event.api.z.newValue.trim();
        newValue = (newValueTrimmed === '' || newValueTrimmed.toUpperCase() === '[NULL]') ? null : newValueTrimmed;
      }
      
      rowNode.setDataValue(event.column.colId, newValue);

      // has the value changed?
      if (newValue !== event.api.z.oldValue) {
        // get any regex associated with this field
        const regexArray = rawColumnData.filter(
          (item) => item.regex !== null && item.dv_column === event.column.colId,
        );
        const regex = regexArray.length > 0 ? regexArray[0].regex : null;
        // console.log('regex', regex, typeof(regex))

        // TODO: this does not check the nullability of the field/column
        if ((newValue === null) || (regex === undefined) || (regex === null) || (regex === '') || (newValue.match(regex))) {
          const guid = uuid();
          const src_ColumnName = `SRC_${event.column.colId && event.column.colId.substring(0, 2).toLowerCase() === 'x_' ? event.column.colId.substring(2) : event.column.colId}`;
          const newValueForDB = (newValue === null ? null : event.colDef.sortType === 'numeric' ? parseFloat(newValue) : newValue);

          try {
            await CDATA.makeRequest(
              'PUT',
              `${apiName}(${event.data.RECORD_ID})`,
              '',
              {
                [event.column.colId]: newValueForDB,
                [src_ColumnName]: guid,
              },
              'Error updating data.',
            );

            onCellEditingComplete(event)

            rowNode.setDataValue(event.column.colId, newValue);
            context.handlers.setToast({
              type: 'success',
              body: (
                <>
                  <span>{event.colDef.headerName}</span>
                  {' successfully updated from '}
                  <span>
                    {
                      event.api.z.oldValue === null ? '[NULL]'
                        : event.api.z.oldValue === '' ? '<<empty string>>'
                          : event.api.z.oldValue?.length > 150 ? `${event.api.z.oldValue?.substring(0, 150)} ...`
                            : event.api.z.oldValue
                    }
                  </span>
                  {' to '}
                  <span>
                    {
                      newValue === null ? '[NULL]'
                        : newValue === '' ? '<<empty string>>'
                          : newValue.length > 150 ? `${newValue.substring(0, 150)} ...`
                            : newValue
                    }
                  </span>
                </>
              ),
            });
            try {
              await CDATA.makeRequest(
                'POST',
                'PRIIPS_logging_AuditTrail', '',
                {
                  RECORD_ID: event.data.RECORD_ID,
                  ENTITY: apiName.substring(apiName.indexOf('_') + 1).replace('_', '.'),
                  MAIL: context.state.userEmail,
                  CLIENT_CODE: context.state.selectedClient.code,
                  USER: context.state.userAzureID,
                  TIME_STAMP: new Date().toISOString(), // make this a default in the sql table
                  FIELD: event.column.colId,
                  SRC_ID: guid,
                  STATUS: 'Updated',
                  ACTION_DESCRIPTION: 'Updated by User',
                },
                'Error logging update to audit trail.',
              );
            } catch (err) {
              context.handlers.setToast({
                type: 'error',
                body: (
                  <>
                    An error occurred when logging your update to the audit trail,
                    please contact your administrator.
                  </>
                ),
              });
            }
          } catch (err) {
            context.handlers.setToast({
              type: 'error',
              body: (
                <>
                  {'An error occurred when editing column '}
                  <span>{event.column.colId}</span>
                  {' and row '}
                  <span>{event.rowIndex + 1}</span>
                  {'. You entered '}
                  <span>
                    {
                      newValue === null ? '[NULL]'
                        : newValue === '' ? '<<empty string>>'
                          : newValue.length > 150 ? `${newValue.substring(0, 150)} ...`
                            : newValue
                    }
                  </span>
                  {' but it is being rolled back to '}
                  <span>
                    {
                      event.api.z.oldValue === null ? '[NULL]'
                        : event.api.z.oldValue === '' ? '<<empty string>>'
                          : event.api.z.oldValue?.length > 150 ? `${event.api.z.oldValue?.substring(0, 150)} ...`
                            : event.api.z.oldValue
                    }
                  </span>
                </>
              ),
            });
            rowNode.setDataValue(event.column.colId, event.api.z.oldValue);
          }
        } else {
          let errorMessage = <>{' does not match the required regex pattern '}
            <span>{regex.length > 150 ? `${regex.substring(0, 150)} ...` : regex}</span>{' for '}</>;
          
          // console.log((rawColumnData.map(({ regex }) => regex)).join('\n'))
          const errorMessages = {
            "^(?i:Y|N)$": <> { ' is not ' } <span>Y/N</span> {' as required by '} </>,
            "^[+-]?(\\d*)(\\.\\d+)?$": <> { ' is not ' } <span>numeric</span> {' as required by '} </>,
            "^(\\d*)(\\.\\d+)?$": <> { ' is not ' } <span>a decimal</span> {' as required by '} </>,
            "[a-zA-Z]{3}": <> { ' is not ' } <span>a three-letter code</span> {' as required by '} </>,
            "^\\-?[0-9]\\d{0,1}$": <> { ' is not ' } <span>a number between -99 and 99</span> {' as required by '} </>,
            "^(0?[1-9]|[1-3][0-9]|40)$": <> { ' is not ' } <span>between 1 and 40</span> {' as required by '} </>,
            "^([1-9]|[1-4][0-9]|50)$": <> { ' is not ' } <span>a number 1 to 50</span> {' as required by '} </>,
            "^[0-9]{4}-[0-9]{2}-[0-9]{2}..................|^[0-9]{4}-[0-9]{2}-[0-9]{2}.........|^[0-9]{4}-[0-9]{2}-[0-9]{2}": <> { ' is not in date format ' } <span>YYYY-MM-DD</span> {' as required by '} </>,
            "^A?B?C?D?E?F?G?H?I?J?K?L?M?N?O?$": <> { ' is not one or more of ' } <span>A/B/C/D/E/F/G/H/I/J/K/L/M/N/O</span> {' as required by '} </>,
            "^2$": <> { ' is not ' } <span>2</span> {' as required by '} </>,
            "^-?[0-9]d{0,1}$": <> { ' is not ' } <span>-9 to 9</span> {' as required by '} </>,
            "^[a-zA-Z]{3}$": <> { ' is not ' } <span>a 3-letter code</span> {' as required by '} </>,
            "[a-zA-Z]{3,3}": <> { ' is not ' } <span>a 3-letter code</span> {' as required by '} </>,
            "^[a-zA-Z0-9][a-zA-Z0-9_&]*$": <> { ' is not ' } <span>alphanumeric with _ and &amp;</span> {' as required by '} </>,
            "^[a-zA-Z0-9]+$": <> { ' is not ' } <span>alphanumeric</span> {' as required by '} </>,
            "^[A-Z0-9]{18}[0-9]{2}$": <> { ' is not ' } <span>18 uppercase alphanumeric characters followed by two a digit number</span> {' as required by '} </>,
            "^[0-9|a-zA-Z]{0,7}$": <> { ' is not ' } <span>0 to 7 alphanumeric characters</span> {' as required by '} </>,
            "^[0-9BCDFGHJKLMNPQRSTVWXYZ]{6}[0-9]?$": <> { ' is not ' } <span>6 or 7 numbers or consonants</span> {' as required by '} </>,
            "^[+-]?(d*)(.d+)?$": <> { ' is not ' } <span>a positive or negative number followed by 1 or more letter 'd'</span> {' as required by '} </>,
            "^(d*)(.d+)?$": <> { ' is not ' } <span>blank, or unlimited letter 'd' followed by any character followed by unlimited of the letter 'd'</span> {' as required by '} </>,
            "^([a-zA-Z0-9]{3}[0-9]|[a-zA-z0-9]{2}[0-9]|[a-zA-z0-9]{2})$": <> { ' is not ' } <span>2 alphanumeric characters followed by an optional single digit number, or 3 alphanumeric characters followed by a required single digit number</span> {' as required by '} </>,
            "^([A-Z]{2})[A-Z0-9]{9}[0-9]$": <> { ' is not ' } <span>2 uppercase letters followed by 9 uppercase alphanumeric characters followed by a one digit number</span> {' as required by '} </>,
            "^([1-9]|99)$": <> { ' is not ' } <span>a number between 1 to 9, or the number 99</span> {' as required by '} </>,
            "^([0-9]){6}$": <> { ' is not ' } <span>a 6-digit number</span> {' as required by '} </>,
            "^([0-1][0-9]|[2][0-2])$": <> { ' is not ' } <span>a number between 10 and 22</span> {' as required by '} </>,
            "^(10000|[0-9]{1,4})$": <> { ' is not ' } <span>a number between 0 and 10000</span> {' as required by '} </>,
            "[0-9A-HJ-NP-Z]{5}[0-9A-HJ-NP-Z#*@]{3}[0-9]|[0-9A-HJ-NP-Z]{5}[0-9A-HJ-NP-Z#*@]{3}": <> { ' is not ' } <span>5 uppercase alphanumeric characters (excluding I and O) followed by 5 uppercase characters (excluding I and O, and including #, *, &amp;) followed by an optional 1-digit number</span> {' as required by '} </>,
            "^([0-9]{2}:[0-9]{2}:[0-9]{2})$": <> { ' is not in the format ' } <span>hh:mm:ss</span> {' as required by '} </>,
          };
          const isNumXtoY = regex === null ? false :
           String(regex).match(/^\^\(?\[(\d)-(\d)]\)?\$$/);
          const isExplicitOptions = regex === null ? false : 
          // eslint-disable-next-line no-useless-escape
          String(regex).match(/^\^\(([^\[^\]\{\}][\w\|]+)\)\$$/);
          const isInErrorMessagesDict = regex === null ? false : errorMessages[String(regex)];
          if (isNumXtoY) {
            errorMessage = <> { ' is not ' } <span>a number between {isNumXtoY[1]} and {isNumXtoY[2]}</span> {' as required by '} </>;
          } else if (isExplicitOptions && isExplicitOptions[1]) {
            errorMessage = <> { ' is not one of ' } <span>{isExplicitOptions[1].replaceAll('|', '/')}</span> {' as required by '} </>;
          } else if (isInErrorMessagesDict) {
            errorMessage = isInErrorMessagesDict;
          }
          context.handlers.setToast({
            type: 'error',
            body: (
              <>
                {'Update was not made. The new value '}
                <span>
                  {
                    newValue === null ? '[NULL]'
                      : newValue === '' ? '<<empty string>>'
                        : newValue.length > 150 ? `${newValue.substring(0, 150)} ...`
                          : newValue
                  }
                </span>
                {errorMessage}
                <span>{event.column.colId}</span>
              </>
            ),
          });
          rowNode.setDataValue(event.column.colId, event.api.z.oldValue);
        }
      }
    }
  };

  const getDefaultContextMenuItems = (params) => {
    const cellDetail = params.api.getFocusedCell();
    if (cellDetail !== null) {
      const menuItems = [
        'copy',
        'copyWithHeaders',
      ];

      const rowNode = params.api.getDisplayedRowAtIndex(cellDetail.rowIndex);

      if (getAudit === true) {
        const auditMenu = [
          {
            name: 'Get Audit Trail',
            action: async () => {
              // modal saying approving data
              const modalContent = (
                <div>
                  <p>Loading Audit Trail ...</p>
                </div>
              );
              context.handlers.setModal('Loading ...', modalContent, null, null, true);
              context.handlers.toggleModal();

              // actually making request
              // success closes the modal and redirects to the approval queue,
              //  adds a toast that says file x was successfully approved
              // failure removes the approval buttons? but doesn't redirect?
              try {
                const result = await CDATA.makeRequest(
                  'POST',
                  'PRIIPS_app_GET_AUDIT_DETAILS',
                  '',
                  {
                    ClientCode_bk: context.state.selectedClient.code,
                    PrimaryKey: rowNode.data[pk],
                    TableName: apiName.substring(apiName.indexOf('_app_') + 5),
                    FieldName: cellDetail.column.colDef.field,
                  },
                  'Failed to retrieve audit trail.',
                );
                context.handlers.setModal(
                  'Audit Trail',
                  <AuditTrail
                    clickedColumnName={cellDetail.column.colDef.field}
                    data={result.value}
                  />,
                  null,
                  null,
                  false,
                  '800px',
                );
                // context.handlers.toggleModal();
              } catch (err) {
                context.handlers.closeModal();
                const toast = {
                  type: 'error',
                  body: (
                    <>
                      Error - something went wrong and we were not retrieve 
                      the audit trail for this data point.
                    </>
                  ),
                };
                context.handlers.setToast(toast);
              }
            },
          },
          'separator',
        ];
        return auditMenu.concat(menuItems);
      }

      return menuItems;
    }
    return null;
  };

  const onContext = (e) => {
    e.persist();
    e.preventDefault();
  };

  const compiledFrameworkComponents = {
    ...frameworkComponents,
    grpCellEditor: GrpCellEditor,
  };

  return (
    <div
      className={classes.Wrapper}
      style={{ 
        backgroundColor: '#f4f4f4',
        pointerEvents: (disabled ? "none" : "all"),
      }}
      onContextMenu={(e) => { onContext(e); }}
    >
      <AgGridReact
        className="ag-theme-reborn"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        doesExternalFilterPass={(node) => externalFilter(node)}
        enableCellChangeFlash
        enableRangeSelection
        frameworkComponents={compiledFrameworkComponents}
        getContextMenuItems={
          getContextMenuItems ? getContextMenuItems : getDefaultContextMenuItems
        }
        groupSelectsChildren={groupSelectsChildren}
        headerHeight={40}
        isExternalFilterPresent={externalFilter ? () => true : false}
        onCellClicked={onCellClicked}
        onCellEditingStopped={onCellEditingStopped}
        onCellValueChanged={onCellValueChanged}
        onGridReady={initGrid}
        onModelUpdated={gridUpdated}
        onSelectionChanged={onSelectionChanged}
        pagination={false}
        paginationAutoPageSize
        reactNext
        reactUi={false}
        rowData={undefined}
        rowHeight={45}
        rowSelection="multiple"
        sideBar={pivotable === true ? {
          toolPanels: [{
            id: 'columns',
            labelDefault: 'Pivot',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          }],
        } : false}
        singleClickEdit={singleClickEdit}
        treeData={treeData}
      />
    </div>
  );
};

// default props will likely break something (especially the funcions)

GrpGrid.propTypes = {
  apiName: PropTypes.string,
  disabled: PropTypes.bool,
  columnDefs: PropTypes.array,
  editable: PropTypes.bool,
  externalFilter: PropTypes.func,
  frameworkComponents: PropTypes.object,
  getAudit: PropTypes.bool,
  getContextMenuItems: PropTypes.func,
  getVersionHistory: PropTypes.bool,
  groupSelectsChildren: PropTypes.bool,
  onCellValueChanged: PropTypes.func,
  onCellClicked: PropTypes.func,
  onCellEditingComplete: PropTypes.func,
  onGridReady: PropTypes.func,
  onModelUpdated: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  pivotable: PropTypes.bool,
  pk: PropTypes.string,
  rawColumnData: PropTypes.array,
  singleClickEdit: PropTypes.bool,
  treeData: PropTypes.bool,
};

export default GrpGrid;
