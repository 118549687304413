
import config from '../config';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
const { encKey } = config.sessionStorage;

class Auth0SessionInfo {
  async SetSessionInfo(authClient) {
    const accessToken = await authClient.getTokenSilently();
    const user = await authClient.getUser();
    const session = { accessToken, user };
    const encryptedSession = CryptoAES.encrypt(JSON.stringify(session).toString(), encKey);
    sessionStorage.setItem('auth0Session', encryptedSession);
  }
  async SetRefreshTokenInterval(authClient) {
    return setInterval(async () => {
      const session = this.GetSessionInfo();
      session.accessToken = await authClient.getTokenSilently();
      sessionStorage.setItem('auth0Session', CryptoAES.encrypt(JSON.stringify(session).toString(), encKey));
    }, 1000 * config.auth0.refreshInterval)
  }
  GetSessionInfo()
  {
    return JSON.parse(CryptoAES.decrypt(sessionStorage.getItem('auth0Session'), encKey).toString(CryptoEnc));
  }
}
export default new Auth0SessionInfo();