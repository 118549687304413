import React, { Component } from 'react';
import Viewer from '../Viewer/Viewer';
import AppContext from '../../AppContext';
import APIM from 'Services/APIM';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpSelect from 'Library/GrpSelect/GrpSelect';
import UrlPoller from 'Library/UrlPoller/UrlPoller';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';


class TCResultsDashboardv2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolbarButtons: [
        <GrpSelect
          items={[
            {
              display: (
                <GrpButton
                  key={1}
                  onClick={() => this.makeExtractGeneratorRequest("Transaction results") }
                  size="Small"
                  type="Tertiary"
                  text="Transaction results"
                />
              ),
              value: 1,
            },
            {
              display: (
                <GrpButton
                  key={2}
                  onClick={() => this.makeExtractGeneratorRequest("Per month breakdowns") }
                  size="Small"
                  type="Tertiary"
                  text="Per month breakdowns"
                />
              ),
              value: 2,
            },
            {
              display: (
                <GrpButton
                  key={3}
                  onClick={() => this.makeExtractGeneratorRequest("Transaction history 1 year")}
                  size="Small"
                  type="Tertiary"
                  text="Transaction history 1 year"
                />
              ),
              value: 3,
            },
            {
              display: (
                <GrpButton
                  key={4}
                  onClick={() => this.makeExtractGeneratorRequest("Transaction history 3 years") }
                  size="Small"
                  type="Tertiary"
                  text="Transaction history 3 years"
                />
              ),
              value: 4,
            },
            {
              display: (
                <GrpButton
                  key={5}
                  onClick={() => this.makeExtractGeneratorRequest("Lookthrough results") }
                  size="Small"
                  type="Tertiary"
                  text="Lookthrough results"
                />
              ),
              value: 5,
            }
          ]}
          placeholder={{ display: 'Extract Data', value: null }}
          alwaysDefault
        />
      ],
    };
  }

  componentDidMount() {
    this.setState({
      post_data: {
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code
      }
    });
  }

  async makeExtractGeneratorRequest(actionString) {
    console.log(actionString, this.context.state.selectedClient.code, this.context.state.selectedReportingDate.code);

    const payload = {
      Version: 'v2tc',
      ClientCode: this.context.state.selectedClient.code,
      Reference_Date: this.context.state.selectedReportingDate.code,
      FileType: 'Excel',
      DataFilterColumn: 'ResultType',
      Parameters: {
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
      }
    };

    if (actionString === "Transaction results") {
      payload.Procedure = 'Transaction_Costs_Output_Extract_v2';
      payload.BaseFileName = 'TC_Output__1_Transaction_results';
      payload.ColumnFilters = {
        "Per period": "EXTRACT_TC_1_Per period",
        "Per year":   "EXTRACT_TC_1_Per year",
        "Per 3 year": "EXTRACT_TC_1_Per 3 year"
      };
    } else if (actionString === "Per month breakdowns") {
      payload.Procedure = 'Transactions_Per_Month_Extract_v2';
      payload.BaseFileName = 'TC_Output__2_Per_month_breakdowns';
      payload.ColumnFilters = {
        "Per month": "EXTRACT_TC_2_Per month",
      };
    } else if (actionString === "Transaction history 1 year") {
      payload.Parameters.Calculation_Type = "YEAR";
      payload.FileType = "Excel";
      payload.Procedure = 'Transaction_Costs_History_Extract_v2';
      payload.BaseFileName = 'TC_Output__3_Transaction_history_1_year';
      payload.ColumnFilters = {
        "Per Transaction - Source Data":  "EXTRACT_TC_3_Per Transaction - Source Data",
        "Per Transaction - API Results":  "EXTRACT_TC_3_Per Transaction - API Results",
        "Per Transaction - Calc Results": "EXTRACT_TC_3_Per Transaction - Calc Results"
      };
    } else if (actionString === "Transaction history 3 years") {
      payload.Parameters.Calculation_Type = "3YEAR";
      payload.FileType = "Excel";
      payload.Procedure = 'Transaction_Costs_History_Extract_v2';
      payload.BaseFileName = 'TC_Output__4_Transaction_history_3_year';
      payload.ColumnFilters = {
        "Per Transaction - Source Data":  "EXTRACT_TC_4_Per Transaction - Source Data",
        "Per Transaction - API Results":  "EXTRACT_TC_4_Per Transaction - API Results",
        "Per Transaction - Calc Results": "EXTRACT_TC_4_Per Transaction - Calc Results"
      };
    } else if (actionString === "Lookthrough results") {
      payload.Procedure = 'Preview_Lookthrough_Indirect_Costs_v2';
      payload.BaseFileName = 'TC_Output__5_Lookthrough_results';
      payload.ColumnFilters = {
        "Lookthrough":  "EXTRACT_TC_5_Lookthrough",
      };
    }

    APIM.makeRequest('generateReport', JSON.stringify(payload), 'Error extracting data', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {`You have queued a request to extract ${actionString}. It will appear on the Data Management > Exports page when the extract has completed.`}.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        // this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Data extraction'});
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <> 
              {`Error - something went wrong and we were not able to extract ${actionString} at this time.`}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  onPollingDone() {
    this.setState({ monitorUrl: undefined });
  }

  render() {
    return (
      this.state.post_data ? 
      <>
        <Viewer {...this.props} post_data={this.state.post_data} toolbarButtons={this.state.toolbarButtons} />
        <div>
            {
              this.state.monitorUrl ? (
                <UrlPoller
                  monitorUrl={this.state.monitorUrl}
                  monitorType={this.state.monitorType }
                  refreshIntervalSeconds={5}
                  responseDisplayElementPath="$.customStatus"
                  endStates={['Completed']}
                  successOutputs={["Success", "blob.core.windows.net"]}
                  completeCallback={() => this.onPollingDone()}
                />
              ) : <></>
            }
          </div>
      </>
       : <></>
    );
  }
}

TCResultsDashboardv2.contextType = AppContext;

export default TCResultsDashboardv2;
