/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Axios from 'axios';
import { format, parse } from 'date-fns';
import CDATA from '../../Services/CDATA';
import APIM from '../../Services/APIM';
import { ProductDashboardView, ProductDashboardBody } from './ProductDashboard.styles';
import AppContext from '../../AppContext';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpDropFile from '../../Library/GrpDropFile/GrpDropFile';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../Components/GrpError/GrpError';
import '../../Styles/SCSS/ag-theme-material.scss';
import GrpButton from '../../Library/GrpButton/GrpButton';
import config from '../../config';
import { renderWithPermissions } from '../../Components/Auth0/permissionFunctions';
import { permissionTypes } from '../../Constants/Auth0Constants';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';
import UrlPoller from '../../Library/UrlPoller/UrlPoller';

const { encKey } = config.sessionStorage;
const baseURLvar = config.apim.url;

// this exists just to speed up the select dropdown by disabling mouseover events, so now it's merely shitty instead of completely unusable
// eslint-disable-next-line react/prefer-stateless-function

class ProductDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfFiles: [], zoneQueuedFileMessage: '',
            pdfMatchedRows: [],
            rowIndexLookup: {},
            api_name: this.props.api_name,
            post_data: this.props.post_data,
            loading: true,
            failed: false,
            totalRowCount: 0,
            displayedRowCount: 0,
            toolbarButtons: [
                renderWithPermissions(() => <GrpButton
                    key={0}
                    onClick={() => { this.runApproveKID(); }}
                    size="Medium"
                    type="Primary"
                    text="Approve"
                />, permissionTypes.approve, () => <></>),
            ],
            monitorUrl: '',
            monitorType: '',
        };
        this.onPDFDrop = this.onPDFDrop.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onPDFDrop = (acceptedFiles) => {
        acceptedFiles.map((file) => {
            let fileName = file.name;
            var rowKey = fileName.replace('.pdf', '');
            var rowIndex = this.state.rowIndexLookup[rowKey];
            var rowNode = this.gridApi.getRowNode(rowIndex);

            if (rowNode) {
                this.setState({ pdfFiles: this.state.pdfFiles.concat(file) });
                //set file upload json message payload state
                this.setState({ pdfMatchedRows: this.state.pdfMatchedRows.concat(rowNode) });
            } else {
                const toast = {
                    type: 'info',
                    body: (
                        <>
                            <span>{'File Name not found, Please check the file name.'}</span>
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            }
        });

        const fileCount = this.state.pdfFiles.length;
        const s = fileCount > 1 ? 's' : '';
        const av = fileCount > 1 ? 'are' : 'is';
        const zoneQueuedFileMessage = (fileCount > 0) ? `${fileCount} File${s} ${av} queued for upload.` : '';
        this.setState({ zoneQueuedFileMessage: zoneQueuedFileMessage });
        acceptedFiles.length = 0;
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (this.state.pdfFiles.length > 0) {
            const data = this.state.pdfMatchedRows.map((row) => {
                var result = parse(
                    this.context.state.selectedReportingDate.code,
                    'yyyy-MM-dd',
                    new Date()
                );
                const last_revised = format(result, 'dd/MM/yyyy');

                return {
                    ISIN: '',
                    ReferenceDate: this.context.state.selectedReportingDate.code,
                    ProductID: row.data.product_id,
                    Language: row.data.kid_exists,
                    ReportDesign: row.data.kid_template,
                    ClientCode: this.context.state.selectedClient.code,
                    FundManager: row.data.product_name,
                    Products: row.data.product_id,
                    ShareClassName: row.data.product_name,
                    LastRevised: last_revised,
                    Type: row.data.type,
                    Filename: row.data.kid_filename,
                    User: this.context.state.userEmail
                };
            });
            const url = baseURLvar + '/pdf/birtdropped ';

            let formdata = new FormData();
            let files = this.state.pdfFiles;
            files.map((file, index) => {
                formdata.append(`pdfFiles${index}`, file);
            });
            formdata.append('message', JSON.stringify(data));

            const fileCount = this.state.pdfFiles.length;
            const s = fileCount > 1 ? 's' : '';
            const modalMessage = `Sending Request to upload ${fileCount} File${s}...`;
            this.setState({ zoneQueuedFileMessage: modalMessage });

            //const modalContent = <div><p>{`Sending Request for ${this.state.pdfFiles.length} KID${s}...`}</p></div>;
            //this.context.handlers.setModal('Sending Request ...', modalContent, null, null, true);

            const response = await Axios.post(url, formdata, {
                headers: { 'Content-Type': 'multipart/form-data', 'authorization': `Bearer ` + Auth0SessionInfo.GetSessionInfo().accessToken, }
            }).then(response => {
                console.log(response);
                this.context.handlers.closeModal();
                const toast = {
                    type: 'success',
                    body: (
                        <>
                            {'You successfully uploaded '}
                            <span>{this.state.pdfFiles.length}</span>
                            {' KID(s)'}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);

            }).catch(err => {
                console.log(err);
                this.context.handlers.closeModal();
                const toast = {
                    type: 'error',
                    body: (
                        <>
                            {'Error - something went wrong and we were not able to request the '}
                            <span>{this.state.pdfFiles.length}</span>
                            {' KID'}
                            {this.state.pdfFiles.length > 1 ? 's' : ''}
                            {' you submitted.'}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            });


        }
        this.state.pdfFiles.length = 0;
        this.state.pdfMatchedRows.length = 0;
        this.setState({ zoneQueuedFileMessage: '' });
    }

    runApproveKID = () => {
        const listOfRows = this.gridApi.getSelectedRows();
        if (listOfRows.length > 0) {
            const modalContent = (
                <div>
                    <p>
                        {`Are you sure you want to run ${listOfRows.length} calculation(s)? (This may take some time)`}
                    </p>
                    <GrpButton onClick={() => this.approveKID()} size="Medium" type="Primary" text="Run" />
                    <GrpButton onClick={() => { this.context.handlers.closeModal(); }} size="Medium" type="Secondary" text="Cancel" />
                </div>
            );
            this.context.handlers.setModal('Approve', modalContent, null, null, false);
            this.context.handlers.toggleModal();
        } else {
            const toast = {
                type: 'error',
                body: (<>No rows were selected to run.</>),
            };
            this.context.handlers.setToast(toast);
        }
    }

    approveKID = () => {
        const listOfRows = this.gridApi.getSelectedRows();
        const delimProductClassPks = listOfRows.map((row) => row.product_class_pk).join(',');
        const modalContent = <div><p>{`Approving ${listOfRows.length} row(s)...`}</p></div>;
        this.context.handlers.setModal('Approving ...', modalContent, null, null, true);

        CDATA.makeRequest(
            'POST',
            'PRIIPS_app_APPROVE_KID_DATA',
            '',
            {
                Client_Code: this.context.state.selectedClient.code,
                ListOfKeys: delimProductClassPks,
                CalcDate: this.context.state.selectedReportingDate.code,
                USER: this.context.state.userEmail,
                AzureID: this.context.state.userAzureID,
                Class_Name: 'Approving KID Information',
                Entity: 'Approval of KID Info',
            },
            'Approve failed.',
        )
        .then(() => {
            this.context.handlers.closeModal();
            const toast = {
              type: 'success',
              body: (
                <>
                  {'You successfully approved '}
                  <span>{listOfRows.length}</span>
                  {' row'}
                  {listOfRows.length > 1 ? 's' : ''}
                  .
                </>
              ),
            };
            this.context.handlers.setToast(toast);
          })
          .catch(() => {
                this.context.handlers.closeModal();
                const toast = {
                    type: 'error',
                    body: (
                        <>
                            {'Error - something went wrong and we were not able to run the '}
                            <span>{listOfRows.length}</span>
                            {' approval'}
                            {listOfRows.length > 1 ? 's' : ''}
                            {' you had selected.'}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            });
    }

    componentDidMount() {
        this.getMetadata();
    }

    async getMetadata() {
        try {
            const columns = await CDATA.makeRequest(
                'POST',
                'PRIIPS_app_GET_COLUMNS',
                '',
                {
                    API_NAME: this.state.api_name,
                    MERGE_QUEUE_ID: -99,
                },
                'Error retrieving column names and metadata.',
            );

            const columnDefs = this.mapColumns(columns.value)

            columnDefs.unshift({
                field: '',
                headerName: '',
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                sortable: false,
                maxWidth: 45,
            });

            this.setState({ columnDefs, rawColumns: columns.value });
        } catch (err) {
            this.setState({
                loading: false,
                failed: true,
                failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
            });
        }
    }

    async getData() {
        this.setState({ loading: true });
        try {
            const data = await CDATA.makeRequest(
                'POST',
                this.state.api_name,
                '',
                {
                    ClientCode: this.context.state.selectedClient.code,
                    Reporting_Period: this.context.state.selectedReportingDate.code,
                },
                'Error retrieving data.',
            );
            const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
            this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
            this.setState({ loading: false, totalRowCount });

            var rowLookup = {};
            this.gridApi.forEachNode(rownode => {
                var productID = rownode.data.product_id;
                rowLookup[productID] = rownode.id
            });
            this.setState({ rowIndexLookup: rowLookup }, () => {
            });

        } catch (err) {
            this.setState({
                loading: false,
                failed: true,
                failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
            });
        }

    }

    mapColumns = (rawColumns) => {
        return rawColumns.map((x) => ({
            field: x.dv_column,
            headerName: x.display_name,
            hide: x.is_hidden,
            editable: x.editable,
            comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
            // this is if you want to do a default sort for a specific column
            // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
            //   direction: 'desc',
            //   priority: 0
            // } : null,
        }));
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        await this.getData();
    };

    onModelUpdated = (params) => {
        this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
    }

    onPollingDone() {
        this.setState({ monitorUrl: undefined });
    }

    render() {
        const zonemessage = `Drag Files or Click to add to ${this.context.state.selectedClient.name}. 
                              Please name according to the Product ID (e.g. OIBA.pdf)`;
        const pdfzone = { zone: 'PDF', message: zonemessage, queuedFileMessage: this.state.zoneQueuedFileMessage, handleInputChange: this.onPDFDrop, handleSubmit: this.handleSubmit, acceptedFiles: '.pdf', maxFiles: 500 };
        return (
            <ProductDashboardView>
                <GrpDropFile {...pdfzone} />
                <ProductDashboardBody>
                    {
                        this.state.failed ? <GrpError text={this.state.failMessage} /> : (
                            <>
                                {
                                    this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                                        this.gridApi && (
                                            <GrpToolbar
                                                api={this.gridApi}
                                                working={this.state.toolbarWorking}
                                                controls={this.state.toolbarButtons}
                                                exportName={this.state.api_name}
                                                count={{
                                                    total: this.state.totalRowCount,
                                                    showing: this.state.displayedRowCount,
                                                }}
                                            />
                                        )
                                    )
                                }

                                <GrpGrid
                                    columnDefs={this.state.columnDefs}
                                    rawColumnData={this.state.rawColumns}
                                    onGridReady={this.onGridReady}
                                    apiName={this.state.api_name}
                                    onModelUpdated={this.onModelUpdated}
                                    pivotable
                                />
                            </>
                        )
                    }
                </ProductDashboardBody>
                <div>
                    {
                        this.state.monitorUrl ?
                            <UrlPoller
                                monitorUrl={this.state.monitorUrl}
                                monitorType={this.state.monitorType}
                                refreshIntervalSeconds={10}
                                responseDisplayElementPath="$.customStatus"
                                endStates={["Completed"]}
                                completeCallback={this.onPollingDone(this)}
                            />
                            :
                            <></>
                    }
                </div>
            </ProductDashboardView>
        );
    }
}

ProductDashboard.contextType = AppContext;

export default ProductDashboard;
