import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './GrpCellEditor.module.scss';

const KEY_BACKSPACE = 8;
const KEY_ENTER = 13;
const KEY_DELETE = 46;
const KEY_F2 = 113;

class GrpCellEditor extends Component {
  constructor(props) {
    super(props);
    // the entire ag-Grid properties are passed as one single object inside the params
    this.state = this.createInitialState(props);

    // this is a hacky, sneaky way of perpetuating some state back to the grid. This survives beyond the destruction of the cell editor
    this.props.api.z = {
      action: 'trash',
      oldValue: this.props.value,
    };
  }

  getValue() {
    return this.state.valid && this.state.valid.length > 0 ? this.state.value : '';
  }

  acceptClick = () => {
    // this.props.api.valueCache.myValue = 'accept';
    this.props.api.z = {
      action: 'accept',
      newValue: this.state.value,
      oldValue: this.props.value,
    };
    this.props.api.stopEditing();
  };

  trashClick = () => {
    this.props.api.z = {
      action: 'trash',
      newValue: this.state.value,
      oldValue: this.props.value,
    };
    this.props.api.stopEditing();
  };

  valueChanged = (e) => {
    this.setState({ value: e.target.value });
  };

  keyPressed(event) {
    if (event.key === 'Enter') {
      this.acceptClick();
    }
  }

  // work out how to present the data based on what the user hit. you don't need to do any of
  // this for your ag-Grid cellEditor to work, however it makes sense to do this so the user
  // experience is similar to Excel
  createInitialState(props) {
    let startValue;
    const putCursorAtEndOnFocus = false;
    const highlightAllOnFocus = false;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_ENTER) {
        this.acceptClick();
      } else if (props.keyPress === KEY_F2) {
        this.putCursorAtEndOnFocus = true;
      } else {
        this.highlightAllOnFocus = true;
      }
    }

    return {
      value: startValue,
      putCursorAtEndOnFocus,
      highlightAllOnFocus,
      working: false,
      valid: false,
    };
  }

  // afterGuiAttached() {
  //   this.refs.input.focus();
  // }

  // cannot use componentDidMount because although the component might be ready from React's point of
  // view, it may not yet be in the browser (put in by ag-Grid) so focus will not work
  afterGuiAttached() {
    // get ref from React component
    const eInput = this.refs.input;
    eInput.focus();
    if (this.highlightAllOnFocus) {
      eInput.select();
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }

    // keydown listener
    let self = this;
    eInput.addEventListener('keydown',function (event) {
      self.keyPressed(event)
    });
  }

  // isCancelAfterEnd() {
  //   console.log('isCancelAfterEnd()');
  //   if (this.state.value === this.props.value) {
  //     return true;
  //   }
  //   return false;
  // }

  render() {
    return (
      <div className={classes.GrpCellEditor}>
        <input
          type="text"
          ref="input"
          className={classes.Input}
          value={this.state.value}
          onChange={(e) => { this.valueChanged(e); }}
          onKeyPress={this.keyPressed}
        />

        <div className={classes.ButtonBox}>
          <div
            className={`${classes.Btn} ${classes.Accept}`}
            onClick={this.acceptClick}
            onKeyPress={this.acceptClick}
            role="button"
            tabIndex={0}
          >
            {
              this.state.working === true ? (
                <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin" />
              ) : <FontAwesomeIcon icon={['fas', 'check']} />
            }
          </div>
          <div
            className={`${classes.Btn} ${classes.Trash}`}
            onClick={this.trashClick}
            onKeyPress={this.trashClick}
            role="button"
            tabIndex={0}
          >
            <FontAwesomeIcon icon={['fas', 'trash']} />
          </div>
        </div>
      </div>
    );
  }
}

export default GrpCellEditor;
