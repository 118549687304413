import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, Title, Description, DescriptiveItemWrapper, TextWrapper } from './DescriptiveItem.styles';

const DescriptiveItem = ({item, group, checked}) => (
  <DescriptiveItemWrapper>
    {!group && (
      <Icon>
        {!checked ? <FontAwesomeIcon icon={['far', 'square']} /> : <FontAwesomeIcon icon={['far', 'check-square']} />}
      </Icon>
    )
    }
    <TextWrapper>
      <Title>{item.label}</Title>
      <Description>{item.description}</Description>
    </TextWrapper>
  </DescriptiveItemWrapper>
);

export default DescriptiveItem;
