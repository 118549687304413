import React from 'react';
import NoAccess from '../../Views/Auth0/NoAccess';

const check = (rule, userPermissions) => {
    return (Array.isArray(userPermissions) && (userPermissions.includes(rule) || userPermissions.includes(rule.replace('/view', ''))));
  };
  
  const Can = props =>
    check(props.rule, props.permissions)
      ? props.yes(props)
      : props.no(props);
  
  Can.defaultProps = {
    yes: (props) => null,
    no: (props) => <NoAccess {...props}></NoAccess>
  };
  
  export default Can;