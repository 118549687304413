import styled from 'styled-components';

export const DataManagementView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DataManagementBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;
