/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import CDATA from '../../Services/CDATA';
import classes from '../File/Sublevels/Grid.module.scss';
import AppContext from '../../AppContext';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../Components/GrpError/GrpError';
import GrpButton from '../../Library/GrpButton/GrpButton';
import NavlinkButton from '../../Library/GrpGrid/NavLinkButton/NavLinkButton';
import APIM from 'Services/APIM';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
// import '../../Styles/SCSS/ag-theme-material.scss';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_auth_UsersByClientCode',
      loading: true,
      failed: false,
      totalRowCount: 0,
      emailAddresses: [],
      displayedRowCount: 0,
      toolbarButtons: [
        <GrpButton
          key={0}
          actionType="NavLink"
          size="Medium"
          type="Secondary"
          text="Add User"
          path={{
            pathname: '/admin/manage-users/user',
            action_type: 'Add',
            existingUsers: () => (this.state.emailAddresses),
          }}
        />,
          <GrpButton
              key={1}
              onClick={() => { this.extractUserRoleRights(); }}
              size="Medium"
              type="Secondary"
              text="User Extract"
          />
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  linkData = (data) => ({
    pathname: '/admin/manage-users/user',
    record_id: data.record_id,
    action_type: 'Edit',
    existingUsers: () => (this.state.emailAddresses)
  });

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );

      const columnDefs = this.mapColumns(columns.value);

      columnDefs.unshift({
        field: 'Edit User',
        headerName: 'Edit User',
        cellRendererFramework: (props) => (
          <NavlinkButton
            {...props}
            functionToGenerateContent={() => 'Edit'}
            functionToGenerateLink={this.linkData}
          />
        ),
        sortable: false,
        cellClass: 'ag-cell', // (x.overflow === 'Y' ? "overflow-cell" : "ag-cell")
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
      });

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        {
          client_code: this.context.state.selectedClient.code,
        },
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.filter((item) => { return item.return_value !== '0'; }).length;
      const rowData = data.filter((item) => item.return_value !== '0');
      const emailAddresses = rowData.map(item => (item.mail ? item.mail : '').toLowerCase());
      this.gridApi.setRowData(rowData);
      this.setState({ loading: false, totalRowCount, emailAddresses });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: false,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  deletePrompt = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>{`Are you sure you want to delete ${listOfRows.length} row(s)?`}</p>
          <GrpButton onClick={() => this.deleteRecords()} size="Medium" type="Primary" text="Delete" />
          <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Delete', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to delete.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  deleteRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimRecords = listOfRows.map((row) => row.RECORD_ID.toString()).join(',');

    const modalContent = <div><p>{`Deleting ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Deleting ...', modalContent, null, null, true);

    CDATA.makeRequest('POST',
      'PRIIPS_app_DELETE_BULK',
      '',
      {
        RecordIDList: delimRecords,
        UserName: this.context.state.userEmail,
        UserID: this.context.state.userAzureID,
        ClientCode_bk: this.context.state.selectedClient.code,
        SchemaName: 'app',
        TableName: 'client_selection',
      },
      'Delete failed.')
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully deleted '}
              <span>{listOfRows.length}</span>
              {` row${listOfRows.length > 1 ? 's' : ''}.`}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.gridApi.updateRowData({ remove: listOfRows });
        this.setState({ totalRowCount: (this.state.totalRowCount - listOfRows.length) });
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to delete the '}
              <span>{listOfRows.length}</span>
              {` row${listOfRows.length > 1 ? 's' : ''} you had selected.`}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  render() {
    return (
      <div className={classes.Grid}>
        <div className={classes.GridBody}>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        // deleter={this.deletePrompt}
                        leftControls={this.state.toolbarButtons}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                />
              </>
            )
          }
        </div>
      </div>
    );
  }

    extractUserRoleRights = async () => {

        const data = {
            procedure: 'GetUserRoleRights',
            procedureSchema: 'auth',
            clientcode: this.context.state.selectedClient.code,
            parameters: {
                superUserEmail: this.context.state.userEmail,
            },
        };

        APIM.makeRequest('generateExtract', JSON.stringify(data), 'Get User Role Rights', Auth0SessionInfo.GetSessionInfo().accessToken)
            .then((res) => {
                this.context.handlers.closeModal();
                const toast = {
                    type: 'success',
                    body: (
                        <>
                            {'You have successfully submitted data extract request. '}
                            {'Please check for the results in a few minutes'}
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            })
            .catch(() => {
                this.context.handlers.closeModal();
                const toast = {
                    type: 'error',
                    body: (
                        <>
                            Error - something went wrong and we were not able to generate the
                            extract you requested.
                        </>
                    ),
                };
                this.context.handlers.setToast(toast);
            });
    }
}

ManageUsers.contextType = AppContext;

export default ManageUsers;
