/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Route, Redirect } from 'react-router-dom';
import CryptoEnc from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
import PropTypes from 'prop-types';
import CDATA from '../../Services/CDATA';
import AppContext from '../../AppContext';
import Raw from './Sublevels/Raw';
import PreviewUpdates from './Sublevels/PreviewUpdates';
import ValidationExceptions from './Sublevels/ValidationExceptions';
import RowLevelErrors from './Sublevels/RowLevelErrors';
// import '../../Styles/SCSS/ag-theme-material.scss';
import config from '../../config';
import GrpHeader from '../../Library/GrpHeader/GrpHeader';
import {
  LevelButton, SubLevelButton, LevelBlobButton, ClientAndReportingDate, Client, ReportingDate,
} from './File.styles';
import { renderWithPermissions } from '../../Components/Auth0/permissionFunctions';
import sasTokenApi from '../../Services/sasTokenApi';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';

const { encKey } = config.sessionStorage;

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    const sessionState = sessionStorage.getItem('PageStateStorage');
    const propsID = this.props.location.mergeid;
    if (propsID) {
      this.state = {
        // api_name: this.props.location.api_name ? this.props.location.api_name : this.props.api_name,
        approvable: this.props.location.approvable,
        entity: this.props.location.entity,
        file_id: this.props.location.file_id,
        file_name: this.props.location.file_name,
        file_url: this.props.location.file_url,
        mergeid: this.props.location.mergeid,
        page: window.location.pathname,
        raw_api: this.props.location.raw_api,
        redirect: false,
        table_name: this.props.location.table_name,
        trans_batch_id: this.props.location.trans_batch_id,
      };

      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page.substring(0, 12) === '/import/file') { // window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
  }

  levelBlobClick = async (url, filename) => {
    var storage = sasTokenApi.splitStorageUrl(url);
    const sasToken = await sasTokenApi.get(storage.account, storage.container, null, Auth0SessionInfo.GetSessionInfo().accessToken);
    url += `?${url}`;

    // if (this.state.loading === false) {
    this.setState({ loading: true }, () => {
      axios.request(
        url,
        {
          method: 'GET',
          headers: {
            'x-ms-version': '2018-03-28',
          },
          responseType: 'blob',
        },
      )
        .then((res) => {
          fileDownload(res.data, filename);
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    });
    // }
  };

  dateClick = () => {
    this.context.handlers.injectClientReportingIntoDrawer();
    // handlers.toggleDrawer();
  };

  async approve() {
    // modal saying approving data
    const modalContent = (
      <div>
        <p>{`Merging data from ${this.state.file_name} into app...`}</p>
      </div>
    );
    this.context.handlers.setModal('Approving ...', modalContent, null, null, true);
    this.context.handlers.toggleModal();

    // actually making request
    // success closes the modal and redirects to the approval queue,
    //  adds a toast that says file x was successfully approved
    // failure removes the approval buttons? but doesn't redirect?
    try {
      await CDATA.makeRequest(
        'POST', 'PRIIPS_app_MERGE_TABLE', '',
        {
          merge_queue_id: this.state.mergeid,
          mail: this.context.state.userEmail,
          user: this.context.state.userAzureID,
          client_code: this.context.state.selectedClient.code,
        },
        'We\'re sorry, there was an error accepting the data and merging it into the application.',
      );

      this.context.handlers.closeModal();
      const toast = {
        type: 'success',
        body: (
          <>
            {'You successfully approved and merged the file '}
            <span>{this.state.file_name}</span>
            {' into the application.'}
          </>
        ),
      };
      this.context.handlers.setToast(toast);
      this.setState({ redirect: true });
    } catch (err) {
      this.context.handlers.closeModal();
      const toast = {
        type: 'error',
        body: (
          <>
            {'Error - something went wrong and we were not able to merge the file '}
            <span>{this.state.file_name}</span>
            {' into the application.'}
          </>
        ),
      };
      this.context.handlers.setToast(toast);
    }
  }

  // eslint-disable-next-line class-methods-use-this

  async reject() {
    // attempt to do the reject
    // if reject is successful, toast and redirect
    // if reject fails, remove approve button and display error on toast?
    try {
      await CDATA.makeRequest(
        'POST', 'PRIIPS_app_REJECT_MERGE', '',
        {
          merge_queue_id: this.state.mergeid,
          mail: this.context.state.userEmail,
          user: this.context.state.userAzureID,
          client_code: this.context.state.selectedClient.code,
        },
      );
      const toast = {
        type: 'success',
        body: (
          <>
            {'You rejected the file '}
            <span>{this.state.file_name}</span>
            .
          </>
        ),
      };
      this.context.handlers.setToast(toast);
      this.setState({ redirect: true });
    } catch (err) {
      const toast = {
        type: 'error',
        body: (
          <>
            {'Error - something went wrong and we were not able to reject the file '}
            <span>{this.state.file_name}</span>
            .
          </>
        ),
      };
      this.context.handlers.setToast(toast);
    }
  }

  render() {
    const { loading } = this.state;
    const yes = () => (
      <>
        <GrpHeader
          title="Import"
          headerItems={[
            <LevelButton
              to={{ pathname: '/import/approval-queue' }}
            >
              Approve New Data
            </LevelButton>,
          ]}
          subHeaderItems={[
            <SubLevelButton
              to={{ pathname: '/import/file/raw' }}
            >
              Raw View
            </SubLevelButton>,
            <SubLevelButton
              to={{ pathname: '/import/file/row-level-errors' }}
            >
              Row Level Errors
            </SubLevelButton>,
            <SubLevelButton
              to={{ pathname: '/import/file/preview-updates' }}
            >
              Preview Updates
            </SubLevelButton>,
            <SubLevelButton
              to={{ pathname: '/import/file/validation-exceptions' }}
            >
              Validation Exceptions
            </SubLevelButton>,
          ]}
          fileName={loading ? (
            <FontAwesomeIcon
              className="loading-icon fa fa-pulse"
              icon={['fas', 'spinner']}
              style={{
                color: 'black',
                fontSize: '14px',
                marginRight: '10px',
              }}
            />
          ) : (
            <LevelBlobButton
              actionType=""
              type="LevelBlob"
              size="Small"
              text={this.state.file_name}
              onClick={() => this.levelBlobClick(this.state.file_url, this.state.file_name)}
            />
          )}
          headerControls={(
            <ClientAndReportingDate
              onClick={this.dateClick}
              text={(
                <div>
                  <Client>
                    {
                      !this.context.state.selectedClient.name === null ? (
                        'Select Client and Reporting Period'
                      ) : (this.context.state.selectedClient.name)
                    }
                  </Client>
                  <ReportingDate>
                    {
                      (this.context.state.selectedClient.name && this.context.state.selectedReportingDate)
                      && (this.context.state.selectedReportingDate.code)
                    }
                  </ReportingDate>
                </div>
              )}
            />
          )}
        />
        {
          this.state.redirect ? <Redirect to="/import/approval-queue" /> : (
            <Switch>
              <Route
                exact
                path="/import/file/raw"
                render={() => (
                  <Raw
                    {...this.state}
                    approve={this.approve.bind(this)}
                    reject={this.reject.bind(this)}
                  />
                )}
              />
              <Route
                exact
                path="/import/file/preview-updates"
                render={() => (
                  <PreviewUpdates
                    {...this.state}
                    approve={this.approve.bind(this)}
                    reject={this.reject.bind(this)}
                  />
                )}
              />
              <Route
                exact
                path="/import/file/validation-exceptions"
                render={() => (
                  <ValidationExceptions
                    {...this.state}
                    approve={this.approve.bind(this)}
                    reject={this.reject.bind(this)}
                  />
                )}
              />
              <Route
                exact
                path="/import/file/row-level-errors"
                render={() => (
                  <RowLevelErrors
                    {...this.state}
                    approve={this.approve.bind(this)}
                    reject={this.reject.bind(this)}
                  />
                )}
              />
            </Switch>
          )
        }
      </>
    );
    return renderWithPermissions(yes);
  }
}

File.propTypes = {
  location: PropTypes.shape({
    approvable: PropTypes.bool,
    entity: PropTypes.string,
    file_id: PropTypes.string,
    file_name: PropTypes.string,
    file_url: PropTypes.string,
    mergeid: PropTypes.string,
    table_name: PropTypes.string,
    trans_batch_id: PropTypes.string,
    raw_api: PropTypes.string,
  }).isRequired,
};

File.contextType = AppContext;

export default File;
