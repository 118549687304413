import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AppContext from '../../AppContext';

const UrlPoller = ({
  monitorUrl,
  monitorType,
  responseDisplayElementPath,
  refreshIntervalSeconds,
  maxLifespanSeconds,
  endStates,
  successStates,
  successOutputs,
  completeCallback
}) => {
  // Avoid loop since hook triggers toast, which sets state, which can trigger hook.
  let preventHookLoop = null;
  useEffect(() => startPolling(), [preventHookLoop]);
  const context = useContext(AppContext);
  const jp = require('jsonpath');

  if (isNaN(refreshIntervalSeconds)) refreshIntervalSeconds = 10; // 10 seconds
  if (isNaN(maxLifespanSeconds)) maxLifespanSeconds = 3600; // 1 hour
  let timer = null;

  const startPolling = () => {
    preventHookLoop = true;
    timer = setInterval(pollAndUpdate, refreshIntervalSeconds * 1000);
    setTimeout(stopPolling, maxLifespanSeconds * 1000)
  };

  const pollAndUpdate = async () => {
    let success = false;
    let message = null;

    try {
      const response = await pollUrl();
      const status = response.data.runtimeStatus;
      const output = response.data.output;
      
      if (successStates.includes(status)) {
        success = true;
        if (endStates.includes(status)) {          
          success = output != null && successOutputs.some(element => output.includes(element));
          stopPolling();
        }
      } else {
        stopPolling();
      }

      message = `${monitorType} is ${status}`

      if(!success){
        message = message + ` with a ${output}`
      }
    } catch (err){
        stopPolling();
        message = 'ERROR GETTING STATUS';
    }

    setToast(success, message);
  };


  const pollUrl = () => new Promise(async (resolve, reject) => {
    const api = axios.create({
      timeout: 600000 // 1 minute
    });
    const response = await api.request(monitorUrl, { method: 'GET' });
    if (Math.floor(response.status/100) === 2) {
      // HTTP 2xx is considered successful
      resolve(response);
    } else {
      reject();
    }
  });

  const stopPolling = () => {
    if (timer) {
      clearInterval(timer);
    }

    if (completeCallback) {
      completeCallback();
    }
  };

  const setToast = (success, message) => {
    context.handlers.setToast({
      type: (success ? 'success' : 'error'),
      body: (
        <>
          {message}<br />
          {new Date().toLocaleTimeString()}
        </>
      )
    });
  };

  return (
    <>
    </>
  );
};

UrlPoller.defaultProps = {
  refreshIntervalSeconds: 60,
  maxLifespanSeconds: 3600, // 1 hour
  responseDisplayElementPath: "runtimeStatus", // default Azure durable function member for status
  successStates: [ "Running", "Pending", "Completed" ], // default Azure durable function states
  successOutputs: ["Success"],
  endStates: [ "Completed" ] // default Azure durable function end state
};

UrlPoller.propTypes = {
  monitorUrl: PropTypes.string,
  monitorType: PropTypes.string,
  refreshIntervalSeconds: PropTypes.number,
  maxLifespanSeconds: PropTypes.number,
  responseDisplayElementPath: PropTypes.string,
  endStates: PropTypes.arrayOf(
    PropTypes.string
  ),
  successStates: PropTypes.arrayOf(
    PropTypes.string
  ),
};

export default UrlPoller;
