import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import React from 'react';
import config from '../../config';
import Can from './Can';


export const renderWithPermissions = (yes, permission, no, rule) => 
{
    const { encKey } = config.sessionStorage;
    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));
    let path = window.location.pathname.substring(1);
    path = path.endsWith('/') ? path.substring(0, path.length - 1) : path;
    const ruleProp = rule ?? `${path}/${permission ?? 'view'}`;
    if (no)
    {
        return <Can rule={ruleProp} yes={yes} no={no} permissions={appState.permissions} />;
    }
    else {
        return <Can rule={ruleProp} yes={yes} permissions={appState.permissions} />;
    }
}

export const userHasPermission = (permissions, permission, rule) =>
{
    if (rule) {
        return permissions.includes(rule);
    }
    if (permission)
    {
        return permissions.includes(`${window.location.pathname.substring(1)}/${permission}`);
    }
    else {
        return permissions.includes(window.location.pathname.substring(1));
    }
}