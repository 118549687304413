import React from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import GrpButton from '../../GrpButton/GrpButton';
import sasTokenApi from '../../../Services/sasTokenApi';
import Auth0SessionInfo from '../../../Services/Auth0SessionInfo';
import CDATA from '../../../Services/CDATA';

const handleBlobClick = async (clientcode, url, filename) => {
  const storage = sasTokenApi.splitStorageUrl(url);
  const sasToken = await sasTokenApi.get(storage.account, storage.container, null, Auth0SessionInfo.GetSessionInfo().accessToken);
  url += `?${sasToken}`;

  axios.request(
    url,
    {
      method: 'GET',
      headers: {
        'x-ms-version': '2018-03-28',
      },
      responseType: 'blob',
    },
  )
    .then((res) => {
      fileDownload(res.data, filename);
    })
    .catch((err) => {
      console.log(err);
      CDATA.makeRequest(
        'POST',
        'PRIIPS_logging_app_error',
        null,
        {
          ClientCode_bk: clientcode === null ? 'UNK' : clientcode,
          app_location: window.location.pathname,
          action_parameters: JSON.stringify({ url, filename }),
          error_message: err.message,
          additional_info: 'Error downloading blob',
        },
      );
    });
};

const BlobLinkButton = (props) => (
  props.functionToGenerateContent(props.data) !== null ? (
    <GrpButton
      actionType=""
      className="Secondary"
      size="Small"
      text={props.functionToGenerateContent(props.data)}
      onClick={() => handleBlobClick(
        props.data.clientcode,
        props.functionToGenerateURL(props.data),
        props.functionToGenerateContent(props.data),
        props.authC,
      )}
    />
  ) : <span />
);

export default BlobLinkButton;
