import React, { Component } from 'react';
import CDATA from '../../Services/CDATA';
import classes from '../File/Sublevels/Grid.module.scss';
import AppContext from '../../AppContext';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../Components/GrpError/GrpError';
import GrpButton from '../../Library/GrpButton/GrpButton';
import NavLinkButton from '../../Library/GrpGrid/NavLinkButton/NavLinkButton';
import '../../Styles/SCSS/ag-theme-material.scss';

const linkData = (data) => ({
  pathname: '/admin/manage-roles/role',
  role_id: data.id,
  action_type: 'Edit',
});

class ManageRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_auth_roles',
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      toolbarButtons: [
        <GrpButton
          key={0}
          actionType="NavLink"
          size="Medium"
          type="Secondary"
          text="Add Role"
          path={{
            pathname: '/admin/manage-roles/role',
            action_type: 'Add',
          }}
        />
      ]
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        null,
        {
          API_NAME: this.state.api_name,
        },
        'Error retrieving column names and metadata.',
      );
      
      const columnDefs = this.mapColumns(columns.value);

      columnDefs.unshift({
        field: 'Edit Role',
        headerName: 'Edit Role',
        cellRendererFramework: (props) => (
          <NavLinkButton
            {...props}
            functionToGenerateContent={() => 'Edit'}
            functionToGenerateLink={linkData}
          />
        ),
        sortable: false,
        cellClass: 'ag-cell',
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
      });

      this.setState({ columnDefs, rawColumns: columns.value});
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    this.setState({ loading: true });
    try {
      const data = await CDATA.makeRequest(
        'GET',
        this.state.api_name,
        null,
        null,
        'Error retrieving role data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: false,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2))
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  render() {
    return (
      <div className={classes.Grid}>
        <div className={classes.GridBody}>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        // deleter={this.deletePrompt}
                        leftControls={this.state.toolbarButtons}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  pivotable
                />
              </>
            )
          }
        </div>
      </div>
    );
  }
}

ManageRoles.contextType = AppContext;

export default ManageRoles;
