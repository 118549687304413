import styled from 'styled-components';

export const SelectBox = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  // margin-top: 40px;
  box-sizing: border-box;
  padding: 25px;
  border: 1px solid #e0e0e0;
  h3 {
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    color: #160b3e;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const SelectionPanel = styled.div`
  height: ${(props) => props.height || '400px'};
  width: 100%;
  overflow-y: scroll;
  background-color: #f7f7f7;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #e8e8e8;
  &.Short {
    height: 200px;
  }
`;

export const ActiveBox = styled.span`
  display: inline-block;
  margin-left: 10px;
  font-size: 10px;
  color: #fff;
  padding: 5px;
  background-color: #6F08CD;
  border-radius: 3px;
`;
