import React, { useContext } from 'react';
import AppContext from '../../../AppContext';
import GrpSelectBox from 'Library/GrpSelectBox/GrpSelectBox';

const ClientSelection = ({ panelHeight }) => {
  const { state, handlers } = useContext(AppContext);

  return (
    <GrpSelectBox
      panelHeight={panelHeight}
      collection={state.availableClientCodeAndNames}
      onChange={handlers.setSelectedClient}
      title="Select a Client"
      selectedID={state.selectedClient}
    />
  );
};

export default ClientSelection;
