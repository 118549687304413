import React, { Component } from 'react';
import { Col, Row, Form, ButtonToolbar } from 'react-bootstrap';
import MultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';
import { Redirect } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import CDATA from 'Services/CDATA';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import AppContext from '../../AppContext';
import config from '../../config';
import { UserBody, UserButton, UserView } from './User.styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EMAIL_REGEX } from '../../Constants/Regex';
import permissionsApi from 'Services/permissionsApi';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';

const { encKey } = config.sessionStorage;

class User extends Component {
  constructor(props) {
    super(props);

    const sessionState = sessionStorage.getItem('PageStateStorage');
    const propsID = this.props.location.action_type;
    const existingUsers = this.props.location.existingUsers ? this.props.location.existingUsers() : [];
    if (propsID) {
      this.state = {
        page: window.location.pathname,
        api_name: 'PRIIPS_global_users',
        action_type: this.props.location.action_type,
        title: this.props.location.action_type === 'Edit' ? 'Edit User' : 'Add User',
        validated: false,
        user_record_id: this.props.location.record_id,
        user_azure_id: '',
        user_active: '',
        user_email: '',
        user_role: '',
        user_FirstName: '',
        user_LastName: '',
        loaded: false,
        loaded2: false,
        redirect: false,
        items: [],
        selectedRoles: [],
        existingUsers: existingUsers,
      };
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page === window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRoleSelectChange = this.handleRoleSelectChange.bind(this);
  }

  componentDidMount() {
    this.getClientData();
    this.getRoles();
    if (this.state.action_type === 'Edit') {
      this.getUserData();
    } else {
      this.setState({ loaded: true });
    }
  }


  async getUserData() {
    try {
      const data = await CDATA.makeRequest(
        'GET',
        this.state.api_name,
        CDATA.addFilter(`record_id eq ${this.state.user_record_id}`),
        {},
        'Error retrieving data.',
      );

      let selectedRoles = [];
      const roleData = await CDATA.makeRequest(
        'POST',
        'PRIIPS_auth_UserRolesByEmail',
        '',
        {
          email: data.value[0].mail,
        },
        'Error Retrieving User Roles'
      );
      selectedRoles = roleData.value.map((x) => ({id: x.id, label: `${x.roleName} - ${x.roleDesc}`}));

      // const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] 
      //   : data.value.filter((item) => { return item.return_value !== '0'; }).length;
      this.setState({
        // rowData: res.data.value,
        user_azure_id: data.value[0].azure_id,
        user_active: data.value[0].active === 'Y' ? 'Y' : 'N',
        user_email: data.value[0].mail,
        user_role: data.value[0].role,
        user_FirstName: data.value[0].FirstName,
        user_LastName: data.value[0].LastName,
        // totalRowCount: data['@odata.count'],
        loaded: true,
        loading: false,
        selectedRoles,
      });
    } catch (err) {
      this.setState({
        loaded: true,
        loading: false, // use the big loading screen like we do on the other pages
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading user data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

   async getRoles() {
    try{
      const data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_auth_roles',
        '',
        {},
        'Error retrieving full role list.'
      );

      const roles = data.value.map((x) => ({id: x.id, label: `${x.roleName} - ${x.roleDesc}`}));

      this.setState({
        roles,
        loaded2: true,
      });
    } catch (err) {
      this.setState({
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading role data. Please try again.  If the issue persists, contact your administrator.',
      })
    }
  }

  async getClientData() {
    try {
      const data = await CDATA.makeRequest(
        'GET',
        'PRIIPS_global_client_selection',
        `${CDATA.addSelect('ClientCode_bk,display_name')}&${CDATA.addOrderBy('display_name')}`,
        {}, 
        'Error retrieving full client list.'
      );
      const clients = data.value.map((x) => ({ id: x.ClientCode_bk, label: x.display_name }));
      this.setState({
        items: clients,
        loaded2: true,
      });
    } catch (err) {
      this.setState({
        loaded2: true,
        loading: false, // use the big loading screen like we do on the other pages
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading client data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  async handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      this.setState({ validated: true });

      let roleIdString = this.state.selectedRoles.map((x) => x.id).toString();

      if (this.state.action_type === 'Edit') {

        try {
          await CDATA.makeRequest(
            'POST',
            'PRIIPS_auth_InsertUpdateUserAssignClientAssignRoles',
            ' ',
            {
              clientCode: this.context.state.selectedClient.code,
              email: this.state.user_email,
              userId: this.state.user_record_id,
              active: this.state.user_active,
              roleIDs: roleIdString,
              FirstName: this.state.user_FirstName,
              LastName: this.state.user_LastName,
            },
            'We\'re sorry there was an error updating this user\'s information.',
          );
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully updated '}
                <span>{this.state.user_email}'s</span>
                {' user data.'}
              </>
            ),
          };
          const { accessToken } = Auth0SessionInfo.GetSessionInfo();
          if (this.context.state.userEmail === this.state.user_email && !roleIdString.includes('4')) {
            const permissionsData = await permissionsApi.getUserAppPermissions(
              this.context.state.userEmail,
              this.context.state.selectedClient.code,
              accessToken,
            );
            const permissions = permissionsData.map((x) => x.appPermissionName);
            this.context.state.permissions = permissions
          }
          this.context.handlers.setToast(toast);
          this.setState({ redirect: true });
        } catch (err) {
          const toast = {
            type: 'error',
            body: (
              <>
                {'Error - something went wrong and we were not able to update '}
                <span>{this.state.user_email}'s</span>
                {' user data.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        }
      } else if (this.state.action_type === 'Add') {
        if (this.state.existingUsers && this.state.existingUsers.includes(this.state.user_email.toLowerCase())) {
          const toast = {
            type: 'error',
            body: (<>Error - User already exists.</>),
          };
          this.context.handlers.setToast(toast);
          return;
        }
        try {
          const data = await CDATA.makeRequest(
            'POST',
            'PRIIPS_auth_InsertUpdateUserAssignClientAssignRoles',
            ' ',
            {
              clientCode: this.context.state.selectedClient.code,
              email: this.state.user_email,
              userId: 0,
              active: this.state.user_active,
              roleIDs: roleIdString,
              FirstName: this.state.user_FirstName,
              LastName: this.state.user_LastName,
            },
            'We\'re sorry there was an error creating this user.',
          );
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully added '}
                <span>{this.state.user_email}</span>
                {' to the application.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
          this.setState({ redirect: true });
        } catch (err) {
          console.log(err);
          const toast = {
            type: 'error',
            body: (
              <>
                {'Error - something went wrong and we were not able to add '}
                <span>{this.state.user_email}</span>
                {' to the application.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        }
      }
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'user_email')
    {
      target.setCustomValidity(EMAIL_REGEX.test(value) ? '' : 'A valid email is required.')
    } else if (name === "user_active" && value === "N") {
      this.state.selectedRoles = [];
    }
    this.setState({
      [name]: value,
    });
  }

  handleRoleSelectChange(selectedItems) {
    this.setState({ selectedRoles: selectedItems });
  }

  render() {
    return (
      <UserView>
        <UserBody>
          {
          this.state.redirect === true ? <Redirect to="/admin/manage-users" /> : (
            <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: '1',
                  flexGrow: '1'
                }}
                className="no-padding"
              >
                {/* <Col
                  className="no-padding"
                  style={{display:"flex", flexDirection:"column", flexGrow: "1" }}
                  > */}
                {
                  this.state.loaded ? (
                    <Col >
                      <Form
                        noValidate
                        validated={this.state.validated}
                        onSubmit={(e) => this.handleSubmit(e)}
                      >
                        <Row>
                          <Col>
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                required
                                type="email"
                                name="user_email"
                                value={this.state.user_email}
                                placeholder="name@example.com"
                                maxLength="255"
                                onChange={this.handleInputChange} />
                              <Form.Control.Feedback type="invalid">
                                A valid email is required.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="user_FirstName"
                                value={this.state.user_FirstName}
                                maxLength="50"
                                onChange={this.handleInputChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter your First Name
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="user_LastName"
                                value={this.state.user_LastName}
                                maxLength="50"
                                onChange={this.handleInputChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter your Last Name
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput2">
                              <Form.Label>Azure ID</Form.Label>
                              <Form.Control
                                required
                                readOnly
                                type="text"
                                name="user_azure_id"
                                value={this.state.user_azure_id}
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                pattern="[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}" />
                              <Form.Control.Feedback type="invalid">
                                Azure ID is required, in the format of xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect2">
                              <Form.Label>Active</Form.Label>
                              <Form.Control
                                required
                                as="select"
                                name="user_active"
                                value={this.state.user_active}
                                onChange={this.handleInputChange}
                              >
                                <option defaultValue disabled value="">Select a Status</option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            {
                            this.state.loaded2 && (
                              <>
                                Roles
                                <MultiSelect
                                  items={this.state.roles}
                                  selectedItems={this.state.selectedRoles}
                                  onChange={this.handleRoleSelectChange}
                                />
                                {/* Clients
                                <MultiSelect
                                  items={this.state.items}
                                  selectedItems={this.state.selectedItems}
                                  onChange={this.handleSelectChange}
                                /> */}
                              </>
                            )
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ButtonToolbar>
                              <UserButton
                                type="Primary"
                                actionType="Submit"
                                size="Medium"
                                text={this.state.action_type === 'Edit' ? <span>Update User</span> : <span>Create User</span>}
                              />
                              <UserButton
                                type="Secondary"
                                size="Medium"
                                text="Cancel"
                                onClick={() => { this.setState({ redirect: true }); }}
                              />
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  ) : (<GrpSpinner text="Loading user management" />)
                }
              </Row>
            </div>
          )
          }
        </UserBody>
      </UserView>
    );
  }
}

User.contextType = AppContext;

export default User;
