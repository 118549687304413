import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AppContext from '../../AppContext';
import GrpHeader from 'Library/GrpHeader/GrpHeader';
import {
  LevelButton, SubLevelButton,
  ClientAndReportingDate, Client, ReportingDate,
} from './HomeHeader.styles';
import GrpSelect from 'Library/GrpSelect/GrpSelect';

const HomeHeader = ({ module, moduleDisplayName }) => {
  const { state, handlers } = useContext(AppContext);
  const [levels, setLevels] = useState(state.availableLevels);
  const [subLevels, setSubLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState();
  const [buttons, setButtons] = useState();

  useEffect(() => {
    handlers.setAvailableLevels(module);
  }, [module]);

  // Used when changing between modules
  useEffect(() => {
    setLevels(state.availableLevels);
  }, [state]);

  // No, it actually needs window.location.pathname for sublevel menus
  useEffect(() => {
    setSelectedLevel(window.location.pathname.split('/')[2]);
  }, [module, window.location.pathname]);

  useEffect(() => {
    setButtons([]);
    setSubLevels(_.uniqBy(state.selectedLayout
      .filter((item) => item.module === module
        && item.level === selectedLevel && item.sublevel !== null
        && item.sublevel_display_name !== null)
      .map((x) => ({
        path: x.sublevel,
        display_name: x.sublevel_display_name,
        api_name: x.api_name,
        display_order: x.display_order,
        parent: x.level,
        component: x.component,
      })),
    'path'));
  }, [state.selectedLayout, module, selectedLevel]);

  // Changes Sublevel buttons when level is changed
  useEffect(() => {
    if (subLevels.length > 5) {
      const items = subLevels.slice(0, 4).map((e) => (
        <SubLevelButton
          key={e.path}
          to={{
            pathname: `/${module}/${e.parent}/${e.path}`,
            api_name: e.api_name,
          }}
        >
          {e.display_name}
        </SubLevelButton>
      ));
      const dropdownItems = subLevels.slice(4, subLevels.length).map((e) => ({
        display: (
          <SubLevelButton
            key={e.path}
            to={{
              pathname: `/${module}/${e.parent}/${e.path}`,
              api_name: e.api_name,
            }}
          >
            {e.display_name}
          </SubLevelButton>
        ),
        value: `/${module}/${e.parent}/${e.path}`,
      }));
      const dropdown = (
        <GrpSelect
          sublevel
          items={dropdownItems}
          placeholder={{
            display: 'Select Item',
            value: null,
          }}
        />
      );
      setButtons(items.concat(dropdown));
    } else {
      const items = subLevels.map((e) => (
        <SubLevelButton
          key={e.path}
          to={{
            pathname: `/${module}/${e.parent}/${e.path}`,
            api_name: e.api_name,
          }}
        >
          {e.display_name}
        </SubLevelButton>
      ));
      setButtons(items);
    }
  }, [subLevels]);

  const levelsContent = (levels && levels.filter((item) => item.display_name !== null).map((l) => (
    <LevelButton
      to={{ pathname: `/${module}/${l.path}` }}
      key={l.path}
    >
      {l.display_name}
    </LevelButton>
  )));

  const dateClick = () => {
    handlers.injectClientReportingIntoDrawer();
    // handlers.toggleDrawer();
  };

  return (
    <GrpHeader
      title={moduleDisplayName}
      headerItems={levelsContent}
      subHeaderItems={buttons}
      headerControls={(
        <ClientAndReportingDate
          onClick={dateClick}
          // size="Large"
          text={(
            <div>
              <Client>
                {
                  !state.selectedClient.name === null ? (
                    'Select Client and Reporting Period'
                  ) : (state.selectedClient.name)
                }
              </Client>
              <ReportingDate>
                {
                  (state.selectedClient.name && state.selectedReportingDate)
                  && (state.selectedReportingDate.code)
                }
              </ReportingDate>
            </div>
          )}
          // type="Tertiary"
        />
      )}
    />
  );
};

HomeHeader.propTypes = {
  module: PropTypes.string.isRequired,
  moduleDisplayName: PropTypes.string.isRequired,
};

export default HomeHeader;
