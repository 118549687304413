import styled from 'styled-components';
import GrpError from '../../Components/GrpError/GrpError';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpButton from '../../Library/GrpButton/GrpButton';
import GrpButtonGroup from '../../Library/GrpButton/GrpButtonGroup/GrpButtonGroup';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';

export const ApprovalQueueBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ApprovalQueueView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Button = styled(GrpButton)``;

export const ButtonGroup = styled(GrpButtonGroup)``;

export const Grid = styled(GrpGrid)``;

export const Error = styled(GrpError)``;

export const Spinner = styled(GrpSpinner)``;

export const Toolbar = styled(GrpToolbar)``;
