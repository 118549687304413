import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Header = styled.div`
  width: 100%;
  background-color: red;
`;

export const MainHeader = styled.div`
  padding: 15px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2D235A;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const HeaderCaret = styled(FontAwesomeIcon)`
  margin: 0px 20px;
  color: #888;
  font-size: 16px;
  font-weight: 700;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const SubHeader = styled.div`
  padding: 0px 15px;
  background-color: #e9e8f0;
  border-style: solid;
  border-width: 1px 0 1px 0;
  border-color: #d5d5d5;
  display: flex;
  justify-content: space-evenly;
`;
