import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataManager from 'Views/DataManager/DataManager';
import ApprovalQueue from 'Views/ApprovalQueue/ApprovalQueue';
import FileImportStatus from 'Views/FileImportStatus/FileImportStatus';
import File from 'Views/File/File';
import RiskAndPerformanceRouter from 'Views/RiskAndPerformance/RiskAndPerformanceRouter';
import ManageReportingPeriods from 'Views/ManageReportingPeriods/ManageReportingPeriods';
import ManageUsers from 'Views/ManageUsers/ManageUsers';
import User from 'Views/ManageUsers/User';
import ManageRoles from 'Views/ManageRoles/ManageRoles';
import Role from 'Views/ManageRoles/Role';
import MarketDataStatus from 'Views/MarketDataStatus/MarketDataStatus';
import Output from 'Views/Output/Output';
import RPeptDashboard from 'Views/RegulatoryReportingDashboard/RPeptDashboard';
import RPeptV2Dashboard from 'Views/RegulatoryReportingDashboard/RPeptV2Dashboard';
import RPemtV3Dashboard from 'Views/RegulatoryReportingDashboard/RPemtV3Dashboard';
import RPemtV31Dashboard from 'Views/RegulatoryReportingDashboard/RPemtV31Dashboard';
import RPemtV4Dashboard from 'Views/RegulatoryReportingDashboard/RPemtV4Dashboard';
import RPceptDashboard from 'Views/RegulatoryReportingDashboard/RPceptDashboard';
import RPceptV2PerformanceDashboard from 'Views/RegulatoryReportingDashboard/RPceptV2PerformanceDashboard';
import RPceptV2HistoryDashboard from 'Views/RegulatoryReportingDashboard/RPceptV2HistoryDashboard';
import RPctiDashboard from 'Views/RegulatoryReportingDashboard/RPctiDashboard';
import ReportingOutput from 'Views/RegulatoryReportingDashboard/ReportingOutput/ReportingOutput';
import TCdcptDashboard from 'Views/RegulatoryReportingDashboard/TCdcptDashboard';
import NAVDashboard from 'Views/RegulatoryReportingDashboard/NAVDashboard';
import LoadMarketData from 'Views/LoadMarketData/LoadMarketData';
import TransactionDashboard from 'Views/TransactionDashboard/TransactionDashboard';
import PeriodComparison from 'Views/PeriodComparison/PeriodComparison';
import InsuranceProductClassDashboard from 'Views/InsuranceProductClassDashboard/InsuranceProductClassDashboard';
import ShareOverview from 'Views/Overview/ShareOverview';
import FundOverview from 'Views/Overview/FundOverview';
import ProductCalcResults from 'Views/InsuranceProduct/ProductCalcResults';
import ProductDashboard from 'Views/InsuranceProduct/ProductDashboard';
import InsProdRawCalcResults from 'Views/InsuranceProduct/InsProdRawCalcResults';
import LoadFile from 'Views/LoadFile/LoadFile';
import EPTeditor from 'Views/RegulationEditor/EPTeditor';
import EPTv2editor from 'Views/RegulationEditor/EPTv2editor';
import EMTeditor from 'Views/RegulationEditor/EMTeditor';
import EMTv3editor from 'Views/RegulationEditor/EMTv3editor';
import EMTv4editor from 'Views/RegulationEditor/EMTv4editor';
import DCPTeditor from 'Views/RegulationEditor/DCPTeditor';
import CTIeditor from 'Views/RegulationEditor/CTIeditor';
import Exports from 'Views/Exports/Exports';
import InsPreview from 'Views/InsuranceProductClassDashboard/InsPreview/InsPreview';
import FileImportStatusRouter from 'Views/FileImportStatus/FileImportStatusRouter';
import TransactionCostRouter from 'Views/TransactionCost/TransactionCostRouter';
import StaticSiteDashboard from 'Views/StaticSiteDashboard/StaticSiteDashboard';
import TransactionValidationErrors from 'Views/TransactionValidation/TransactionValidationErrors';
import TCResultsDashboard from 'Views/TransactionDashboard/TCResultsDashboard';
import TCResultsDashboardv2 from 'Views/TransactionDashboard/TCResultsDashboardv2';
import InsPublishLog from 'Views/InsPublishLog/InsPublishLog';
import Callback from 'Views/Auth0/Callback';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';
import HomeHeader from 'Components/HomeHeader/HomeHeader';
import { HomeContentView, ReportingMessage } from './HomeContent.styles';
import OuterComponent from './OuterComponent';
import Outbound from 'Layouts/Routing/Outbound';

const ClientOverview = () => <div><p>Client Overview</p></div>;
const ProductOverview = () => <div><p>Product Overview</p></div>;
const FixLater = () => <div><p>Fix Later</p></div>;
const RedirectToHome = () => <Redirect to="" />;

const compNameLookup = {
  ApprovalQueue,
  Callback,
  ClientOverview,
  CTIeditor,
  DataManager,
  DCPTeditor,
  EMTeditor,
  EMTv3editor,
  EMTv4editor,
  EPTeditor,
  EPTv2editor,
  Exports,
  File,
  FileImportStatus,
  FileImportStatusRouter,
  FixLater,
  FundOverview,
  InsPreview,
  InsProdRawCalcResults,
  InsPublishLog,
  InsuranceProductClassDashboard,
  LoadFile,
  LoadMarketData,
  ManageReportingPeriods,
  ManageRoles,
  ManageUsers,
  MarketDataStatus,
  NAVDashboard,
  Outbound,
  Output,
  PeriodComparison,
  ProductCalcResults,
  ProductDashboard,
  ProductOverview,
  ReportingOutput,
  RiskAndPerformanceRouter,
  Role,
  RPceptDashboard,
  RPceptV2PerformanceDashboard,
  RPceptV2HistoryDashboard,
  RPctiDashboard,
  RPemtV3Dashboard,
  RPemtV31Dashboard,
  RPemtV4Dashboard,
  RPeptDashboard,
  RPeptV2Dashboard,
  ShareOverview,
  StaticSiteDashboard,
  TCdcptDashboard,
  TCResultsDashboard,
  TCResultsDashboardv2,
  TransactionCostRouter,
  TransactionDashboard,
  TransactionValidationErrors,
  User,
};

const HomeContent = ({
  availableRoutes, displayName, moduleName, selectedReportingDate,
}) => (
  <HomeContentView>
    {
      !window.location.pathname.includes('/import/file')
      && !window.location.pathname.includes('/regulatory-reporting/preview')
      && !window.location.pathname.includes('/transactions/preview')
      && (
        <HomeHeader
          module={moduleName}
          moduleDisplayName={displayName}
        />
      )
    }
    <Switch>
      {
        availableRoutes && availableRoutes.map((x) => {
          const InnerComponent = compNameLookup[x.component];
          return (
            <Route
              exact={x.exact}
              key={x.full_path}
              path={x.full_path}
              render={(props) => (
                x.force_reportingdate && !selectedReportingDate ? <ReportingMessage /> : (
                  <OuterComponent
                    force_reportingdate={x.force_reportingdate}
                    selectedReportingDate={
                      selectedReportingDate ? selectedReportingDate.code : null
                    }
                  >
                    {
                    /**
                     * Checks user permissions to see if they have access to edit elements on this
                     * page.  If the user does have permissions the first function is returned and
                     * the editable property is set by what's returned from the database.  If not
                     * then the second function is run and the editable property is always set to
                     * false.
                     */
                    renderWithPermissions((canProps) => renderWithPermissions(
                      (canProps) => (
                        <InnerComponent
                          {...props}
                          {...canProps}
                          api_name={x.api_name}
                          pk={x.pk}
                          editable={x.editable}
                          noClient={['admin'].includes(moduleName)}
                        />
                      ),
                      'edit',
                      (canProps) => (
                        <InnerComponent
                          {...props}
                          {...canProps}
                          api_name={x.api_name}
                          pk={x.pk}
                          editable={false}
                          noClient={['admin'].includes(moduleName)}
                        />
                      ),
                    ),
                    'view')
                  }
                  </OuterComponent>
                )
              )}
            />
          );
        })
      }
      <Route component={RedirectToHome} />
    </Switch>
  </HomeContentView>
);

HomeContent.defaultProps = {
  availableRoutes: null,
  selectedReportingDate: {},
};

HomeContent.propTypes = {
  availableRoutes: PropTypes.arrayOf(PropTypes.shape({
    api_name: PropTypes.shape,
    component: PropTypes.string,
    full_path: PropTypes.string,
    pk: PropTypes.string,
  })),
  displayName: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  selectedReportingDate: PropTypes.shape({}),
};

export default HomeContent;
