/* eslint-disable class-methods-use-this */
import axios from 'axios';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import config from '../config';
import CDATA from '../Services/CDATA';

const { encKey } = config.sessionStorage;
const baseURLvar = config.pentaho.wrapperurl;

class PENTAHO {
  makeRequest(body, accessToken, errorMessage) {

    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));

    return new Promise(async (resolve, reject) => {
      try {
        const API = axios.create({
            baseURL: baseURLvar,
            timeout: 900000,
            headers: {
                'authorization': `Bearer `+ accessToken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        
        const response = await API.request('', { method:'POST', data: body });
        // console.log(response);
        if (response.data.value) {
          resolve(response.data);
        } else if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          try {
            const errorLogResponse = await CDATA.makeRequest(
              'POST',
              'PRIIPS_logging_app_error',
              null,
              {
                ClientCode_bk: appState.selectedClient.code !== null ? appState.selectedClient.code : 'UNK',
                mail: appState.userEmail,
                app_location: window.location.pathname,
                api_action: config.pentaho.wrapperurl,
                url: 'POST ' + config.pentaho.wrapperurl,
                action_parameters: JSON.stringify(body),
                error_message: `No valid response from request to ${config.pentaho.wrapperurl} with  (POST) as the method and (${JSON.stringify(body)}) as the request body.`,
                additional_info: errorMessage,
              }
            );
            reject({
              human: errorMessage,
              errorID: errorLogResponse.data.RECORD_ID,
              machine: `No valid response from request to ${config.pentaho.wrapperurl} with (POST) as the method and (${JSON.stringify(body)}) as the request body.`,
            });
          } catch {
            reject({
              human: errorMessage,
              machine: `No valid response from request to ${config.pentaho.wrapperurl} with (POST) as the method and (${JSON.stringify(body)}) as the request body.`,
            });
          }
        }
      } catch (err) {
        try {
          const errorLogResponse = await CDATA.makeRequest(
            'POST',
            'PRIIPS_logging_app_error',
            null,
            {
              ClientCode_bk: appState.selectedClient.code !== null ? appState.selectedClient.code : 'UNK',
              mail: appState.userEmail,
              app_location: window.location.pathname,
              api_action: config.pentaho.wrapperurl,
              url: 'POST ' + config.pentaho.wrapperurl,
              action_parameters: JSON.stringify(body),
              error_message: err.message,
              additional_info: errorMessage,
            }
          );
          reject({
            human: errorMessage,
            errorID: errorLogResponse.data.RECORD_ID,
            machine: err,
          });
        } catch {
          reject({
            human: errorMessage,
            machine: err,
          });
        }
      }
    });
  }
}

export default new PENTAHO();
