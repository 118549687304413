import axios from 'axios';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import config from '../config';

const { encKey } = config.sessionStorage;

const baseUrl = config.permissionsApi.url;

class permissionsApi {
  getUserClients(userId, authenticationToken) {
    return this.makeRequest('GET', '/GetUserClients', null, null, `Failed to acquire available clients for user ${userId}.`, authenticationToken);
  }

  getUserAppPermissions(userId, clientCode, authenticationToken) {
    const querystringParameters = `clientCode=${clientCode}`;
    return this.makeRequest('GET', '/GetUserAppPermissions', querystringParameters, null, `Failed to acquire permissions for user ${userId}, clientCode ${clientCode}.`, authenticationToken);
  }

  validateUserClient(userId, clientCode, authenticationToken) {
    const querystringParameters = `clientCode=${clientCode}`;
    return this.makeRequest('GET', '/ValidateUserClient', querystringParameters, null, `Failed to validate client code ${clientCode} for user ${userId}.`, authenticationToken);
  }

  makeRequest(method, route, querystringParameters, body, errorMessage, authenticationToken) {
    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));

    return new Promise(async (resolve, reject) => {
      let requestUrl = `${baseUrl}${route}`;
      if (querystringParameters) {
        requestUrl += `?${querystringParameters}`;
      }

      try {
        const API = axios.create({
          baseURL: baseUrl,
          timeout: 30000, // 30 seconds
          headers: {
            'Authorization' : `Bearer `+ authenticationToken,
            'Content-Type': 'application/json;charset=utf-8',
          },
        });

        const response = await API.request(requestUrl, { method, data: body });

        if (response.data) {
          resolve(response.data);
        } else {
          const machineErrorMesage = `No valid response from request to route ${route} with querystring parameters (${querystringParameters}), method (${method}), body (${JSON.stringify(body)}).`;
          this.logError(appState, requestUrl, querystringParameters, JSON.stringify(body), errorMessage, machineErrorMesage);
        }
      } catch (err) {
        this.logError(appState, requestUrl, querystringParameters, JSON.stringify(body), errorMessage, err);
        reject(Error(errorMessage));
      }
    });
  }

  logError(appState, requestUrl, querystringParameters, payload, humanErrorMessage, machineErrorMessage) {
    console.log({
      'userId': appState ? appState.userEmail : null,
      'clientCode': appState ? appState.clientCode : null,
      'requestUrl': requestUrl,
      'querystringParameters': querystringParameters,
      'payload': payload,
      'humanErrorMessage': humanErrorMessage,
      'machineErrorMessage': machineErrorMessage,
    });
  }
}

export default new permissionsApi();
