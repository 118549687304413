/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import CDATA from '../../Services/CDATA';
import {
  Error, Grid, Spinner, Toolbar, TransactionValidationErrorsView, TransactionValidationErrorsBody,
} from './TransactionValidationErrors.styles';
import AppContext from '../../AppContext';
// import '../../Styles/SCSS/ag-theme-material.scss';
import config from '../../config';

const { encKey } = config.sessionStorage;

class TransactionValidationErrors extends Component {
  constructor(props) {
    super(props);

    const sessionState = sessionStorage.getItem('PageStateStorage');
    const fundCode = this.props.location.fundcode_bk;

    if (fundCode) {
      this.state = {
        page: window.location.pathname,
        api_name: this.props.api_name,
        loading: true,
        failed: false,
        totalRowCount: 0,
        displayedRowCount: 0,
        fund_code: fundCode,
      };
      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page === window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);

      this.setState({
        columnDefs,
        rawColumns: columns.value,
      });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    const urlParameters = CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}' and FundCode_bk eq '${this.state.fund_code}'`);
    this.setState({ loading: true });
    // console.log(this.state.api_name);
    try {
      const data = await CDATA.makeRequest(
        'GET',
        this.state.api_name,
        urlParameters,
        {},
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    autoHeight: true,
    cellStyle: {
      'whiteSpace': 'normal',
      'lineHeight': '24px',
      padding: '12px',
      maxHeight: '122px',
    },
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    editable: x.editable,
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    wrapText: true,
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined
    //   && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //     direction: 'desc',
    //     priority: 0
    //   } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  render() {
    return (
      <TransactionValidationErrorsView>
        <TransactionValidationErrorsBody>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        exportName={this.state.api_name}
                      />
                    )
                  )
                }
                <Grid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  externalFilter={this.doesExternalFilterPass}
                  pivotable
                />
              </>
            )
          }
        </TransactionValidationErrorsBody>
      </TransactionValidationErrorsView>
    );
  }
}

TransactionValidationErrors.contextType = AppContext;

export default TransactionValidationErrors;
