import React, { Component } from 'react';
import CDATA from 'Services/CDATA';
import classes from '../File/Sublevels/Grid.module.scss';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import AppContext from 'AppContext';
import GrpButton from 'Library/GrpButton/GrpButton';

class MarketDataStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_pricing_FundSummary',
      filterVal: 'Active',
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      toolbarButtons: [
        <GrpButton
          onClick={() => { this.refreshData(); }}
          icon={['fas', 'sync']}
          iconSide="Round"
          size="Small"
        />,
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );

      const columnDefs = this.mapColumns(columns.value);

      columnDefs.unshift({
        field: 'SelectRow',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        maxWidth: 45,
        resizable: false,
      });

      this.setState({ columnDefs });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    this.setState({ loading: true });
    try {
      let data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        {
          ClientCode_bk: this.context.state.selectedClient.code,
        },
        'Error retrieving data.',
      );
      data = data.map((x) => {
        // yyyy-mm-dd HH:MM:SS
        const StartedDateTime = new Date(x.StartedDateTime).toISOString().replace('T', ' ').substr(0, 19);
        const CompletedDateTime = new Date(x.CompletedDateTime).toISOString().replace('T', ' ').substr(0, 19);;
        return {
          ...x,
          StartedDateTime: StartedDateTime,
          CompletedDateTime: CompletedDateTime,
        };
      });
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;

      this.gridApi.setRowData(data);
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: false,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)), // may not be needed
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  externalFilterChanged = (filterVal) => {
    this.setState({ filterVal },
      () => { this.gridApi.onFilterChanged(); });
  }

  doesExternalFilterPass = (node) => {
    switch (this.state.filterVal) {
      case 'Active': return node.data.State !== 'Completed';
      case 'Completed': return node.data.State === this.state.filterVal;
      default: return true;
    }
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  render() {
    return (
      <div className={classes.Grid}>
        <div className={classes.GridBody}>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        externalFilterDropdown={{
                          title: 'Status:',
                          options: [
                            { label: 'Active', value: 'Active' },
                            { label: 'Completed', value: 'Completed' },
                          ],
                          defaultValue: this.state.filterVal !== null ? { label: this.state.filterVal, value: this.state.filterVal } : { label: 'Active', value: 'Active' },
                        }}
                        externalFilterChanged={this.externalFilterChanged}
                        controls={this.state.toolbarButtons}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                  externalFilter={this.doesExternalFilterPass}
                />
              </>
            )
          }
        </div>
      </div>
    );
  }
}

MarketDataStatus.contextType = AppContext;

export default MarketDataStatus;
