import React, { Component, Fragment } from 'react';
import _ from 'lodash';

export default class OuterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { key: _.uniqueId('') };
  }

  componentDidUpdate(prevProps) {
    if (this.props.force_reportingdate && prevProps.selectedReportingDate !== this.props.selectedReportingDate) {
      this.setState({ key: _.uniqueId('') });
    }
  }

  render() {
    return <Fragment key={this.state.key}>{this.props.children}</Fragment>;
  }
}
