/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';

const ArpMultiSelect = ({
  items,
  selectedItems,
  onChange,
  withGrouping,
  itemRenderer,
  showSelectedItems,
  showSearch,
  showSelectAll,
  itemHeight,
}) => (
  <div>
    <MultiSelect
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      withGrouping={withGrouping}
      itemRenderer={itemRenderer}
      showSelectedItems={showSelectedItems}
      showSearch={showSearch}
      showSelectAll={showSelectAll}
      itemHeight={itemHeight}
    />
  </div>
);

ArpMultiSelect.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  onChange: PropTypes.func,
  withGrouping: PropTypes.bool,
  itemRenderer: PropTypes.object,
  showSelectedItems: PropTypes.bool,
  showSearch: PropTypes.bool,
  showSelectAll: PropTypes.bool,
};

export default ArpMultiSelect;
