import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GrpButton from '../GrpButton/GrpButton';
import GrpExporter from './GrpExporter/GrpExporter';
import GrpCounter from './GrpCounter/GrpCounter';

export const Toolbar = styled.div`
  align-items: center;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  min-height: 50px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;
  width: 100%;
`;

export const Spinner = styled(FontAwesomeIcon).attrs({
  className: 'fa-spin',
})`
  color: #6F08CD;
`;

export const Left = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-right: 7px;
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin-left: 7px;
`;

export const DeleteButton = styled(GrpButton)`
  border-radius: 3px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const ExportControl = styled(GrpExporter)`
  border-radius: 3px;
  &:not(:last-child) {
    margin-right: 5px !important;
  }
`;

export const Counter = styled(GrpCounter)`
  border-radius: 3px;
  margin-left: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;
