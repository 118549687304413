import React, { useContext } from 'react';
import AppContext from '../../../AppContext';
import GrpSelectBox from '../../../Library/GrpSelectBox/GrpSelectBox';

const ReportingDateSelection = () => {
  const context = useContext(AppContext);
  return (
    <GrpSelectBox
      panelHeight="40vh"
      collection={context.state.availableReportingDates}
      onChange={context.handlers.setSelectedReportingDate}
      title="Select a Reporting Date"
      selectedID={context.state.selectedReportingDate}
    />
  );
};

export default ReportingDateSelection;
