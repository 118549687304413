import React, { Component } from 'react';
import Viewer from '../../Views/Viewer/Viewer';
import AppContext from '../../AppContext';


class ProductCalcResults extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ post_data: { ClientCode: this.context.state.selectedClient.code, Reporting_Period: this.context.state.selectedReportingDate.code } });
  }

  render() {
    return (
      this.state.post_data ? <Viewer {...this.props} post_data={this.state.post_data} /> : <></>
    );
  }
}

ProductCalcResults.contextType = AppContext;

export default ProductCalcResults;
