/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import CryptoEnc from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
import PropTypes from 'prop-types';
import AppContext from '../../AppContext';
import JobBatchErrors from './Sublevels/JobBatchErrors';
// import '../../Styles/SCSS/ag-theme-material.scss';
import config from '../../config';
import GrpHeader from '../../Library/GrpHeader/GrpHeader';
import {
  LevelButton, SubLevelButton, ClientAndReportingDate, Client, ReportingDate,
} from '../File/File.styles';

const { encKey } = config.sessionStorage;

class FileImportStatusRouter extends Component {
  constructor(props) {
    super(props);

    const sessionState = sessionStorage.getItem('PageStateStorage');
    const propsID = this.props.location.mergeid;

    if (propsID) {
      this.state = {
        // api_name: this.props.location.api_name ? this.props.location.api_name : this.props.api_name,
        file_id: this.props.location.file_id,
        file_name: this.props.location.file_name,
        file_url: this.props.location.file_url,
        mergeid: this.props.location.mergeid,
        redirect: false,
        trans_batch_id: this.props.location.trans_batch_id,
      };

      sessionStorage.setItem('PageStateStorage', CryptoAES.encrypt(JSON.stringify(this.state), encKey));
    } else if (sessionState && JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc)).page.substring(0, 30) === '/import/fileimportstatusrouter') { // window.location.pathname) {
      this.state = JSON.parse(CryptoAES.decrypt(sessionState.toString(), encKey).toString(CryptoEnc));
    } else {
      this.state = { redirect: true };
    }
  }

  render() {
    return (
      <>
        <GrpHeader
          title="Import"
          headerItems={[
            <LevelButton
              to={{ pathname: '/import/status' }}
            >
              File Import Status
            </LevelButton>,
          ]}
          subHeaderItems={[
            <SubLevelButton
              to={{ pathname: '/import/fileimportstatusrouter/job-batch-errors' }}
            >
              Errors
            </SubLevelButton>,
          ]}
          headerControls={(
            <ClientAndReportingDate
              onClick={this.dateClick}
              text={(
                <div>
                  <Client>
                    {
                      !this.context.state.selectedClient.name === null ? (
                        'Select Client and Reporting Period'
                      ) : (this.context.state.selectedClient.name)
                    }
                  </Client>
                  <ReportingDate>
                    {
                      (this.context.state.selectedClient.name && this.context.state.selectedReportingDate)
                      && (this.context.state.selectedReportingDate.code)
                    }
                  </ReportingDate>
                </div>
              )}
            />
          )}
        />
        <Switch>
          <Route
            exact
            path="/import/fileimportstatusrouter/job-batch-errors"
            render={() => (
              <JobBatchErrors
                {...this.state}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

FileImportStatusRouter.propTypes = {
  location: PropTypes.shape({
    file_id: PropTypes.string,
    file_name: PropTypes.string,
    file_url: PropTypes.string,
    mergeid: PropTypes.string,
    trans_batch_id: PropTypes.string,
  }).isRequired,
};

FileImportStatusRouter.contextType = AppContext;

export default FileImportStatusRouter;
