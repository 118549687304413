import styled from 'styled-components';
import GrpButton from '../../Library/GrpButton/GrpButton';

export const UserView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const UserButton = styled(GrpButton)`

`;
