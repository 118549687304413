/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Select from 'react-select';
import CDATA from 'Services/CDATA';
import { OutputView, OutputBody } from './Output.styles';
import AppContext from '../../AppContext';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import 'Styles/SCSS/ag-theme-material.scss';
import { userHasPermission } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants'
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import GrpSelect from 'Library/GrpSelect/GrpSelect';
import APIM from 'Services/APIM';
import { RowPositionUtils } from 'ag-grid-community';

class Output extends Component {
  constructor(props) {
    super(props);

    let outputParameters = this.GetOutputParameters(this.props.api_name);

    this.state = {
      api_name: this.props.api_name,
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      filterVal: 'Ready for Review',
      permissions: this.props.permissions,
      level: outputParameters.regulation_name,
      outputColumns: outputParameters.output_columns,
      exportName: outputParameters.exportName,
      approveButton: [<GrpButton key={0} onClick={() => { this.approve(); }} size="Medium" type="Primary" text="Approve" />],
      reopenButton: [<GrpButton key={0} onClick={() => { this.reopen(); }} size="Medium" type="Primary" text="Re-Open" />]
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      await this.updateColumns(columns.value);
      await this.setState({ rawColumns: columns.value });
      await this.defineColumns();
      this.setState({ columnData: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  updateColumns(columnData) {
    let emtv3Columns = columnData.filter((column) => {
      let extraColumns = ['05105_Intended_Compatible_With_Clients_Having_Sustainability_Preferences', '09020_ESG_Label_Or_Standard', '09000_ESG_Category_For_German_Market', '09010_ESG_Focus', '10020_AMF_Doctrine'];
      return !extraColumns.includes(column.display_name);
    });

    let emtv31Columns = columnData.filter((column) => {
      if (column.display_name !== '05105_Intended_Compatible_With_Clients_Having_ESG_Preferences') {
        return column.display_name;
      }
    });

    const emtv3ColumnDefs = this.mapColumns(emtv3Columns);
    const emtv31ColumnDefs = this.mapColumns(emtv31Columns);
    
    this.setState({
      emtv3ColumnDefs: emtv3ColumnDefs,
      emtv31ColumnDefs: emtv31ColumnDefs,
      emtv31Columns: emtv31Columns,
      emtv3Columns: emtv3Columns
    });
  }

  defineColumns() {
    let columnDefs = this.state.emtv3ColumnDefs;
    if (this.state.level === 'EMTv31') {
      columnDefs = this.state.emtv31ColumnDefs;
    }

    columnDefs.unshift({
      field: "SelectRow",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      maxWidth: 45,
      resizable: false,
    });

    this.setState({ columnDefs });
  }

  async getData() {
    let endpoint = this.state.api_name;
    let odataFilter = `ClientCode_bk eq '${this.context.state.selectedClient.code}'`;
    let method = 'GET';
    let body = {}
    // note, the "x_00..." fields below are actually strings, not dates
    //
    if (this.state.level === 'EPT') {
      odataFilter += ` and x_00070_Reference_Date eq '${this.context.state.selectedReportingDate.code}'`;
    } else if (this.state.level === 'EPTv2') {
      odataFilter += ` and x_00070_PRIIPs_KID_Publication_Date eq '${this.context.state.selectedReportingDate.code}'`;
    } else if (this.state.level === 'EMTv3' || this.state.level === 'EMTv31') {
      odataFilter += ` and x_00050_General_Reference_Date eq '${this.context.state.selectedReportingDate.code}'`;
    } else if (this.state.level === 'EMTv4') {
      odataFilter += ` and x_00050_General_Reference_Date eq '${this.context.state.selectedReportingDate.code}'`;
    } else if (this.state.level === 'DCPT') {
      odataFilter += ` and x_00060_End_Of_Period eq '${this.context.state.selectedReportingDate.code}'`;
    } else if (this.state.level === 'CEPT') {
      odataFilter += ` and x_30060_Reporting_Date eq '${this.context.state.selectedReportingDate.code}'`;
    } else if (this.state.level === 'CEPT_Perf_v2') {
      odataFilter += ` and Reference_Date_Formatted eq '${this.context.state.selectedReportingDate.code}'`;
      endpoint+="('"+this.context.state.selectedReportingDate.code+"')"
    } else if (this.state.level === 'CEPT_Hist_v2') {
      body = {
        "ClientCode_bk": this.context.state.selectedClient.code,
        "ReportingPeriod": this.context.state.selectedReportingDate.code
      };
      odataFilter = `clientcode_bk eq '${this.context.state.selectedClient.code}'`;
      method = 'POST';
    } else if (this.state.level === 'CTI') {
      odataFilter += ` and Reference_Date eq ${this.context.state.selectedReportingDate.code}`;
    }

    const urlParameters = CDATA.addFilter(odataFilter);
    this.setState({ loading: true });

    try {
      const data = await CDATA.makeRequest(
        method,
        endpoint,
        urlParameters,
        body,
        'Error retrieving data.',
      );

      // Support new response object type `WebApiGenericReturn`
      const values = data.value ?? data.Content;
      const count = data['@odata.count'] ?? data.ContentCount;

      const newRows = values.map(item => {
        if (this.props.api_name === 'PRIIPS_app_DCPT_OUTPUT') {
          return {
            ...item,
            'x_04010_Portfolio_Turnover_Rate': null,
            'x_04020_Proportion_Of_Securities_Loaned_Or_Borrowed': null,
          }
        }
        return item;
      });
      const totalRowCount = data && count !== 0 ? count : values.filter((item) => { return item.return_value !== '0'; }).length;
      this.gridApi.setRowData(newRows.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => {
    if (this.props.api_name === 'PRIIPS_app_DCPT_OUTPUT') {
      return ({
        comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
        editable: x.editable,
        field: x.dv_column,
        headerName: x.display_name,
        hide: x.is_hidden,
        valueFormatter: this.roundedFormatter,
      });
    }
    return ({
      comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
      editable: x.editable,
      field: x.dv_column,
      headerName: x.display_name,
      hide: x.is_hidden,
    });
  });

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  externalFilterChanged = (filterVal) => {
    this.setState({ filterVal },
      () => { this.gridApi.onFilterChanged(); });
  }

  doesExternalFilterPass = (node) => {
    if (this.state.level === 'CEPT_Hist_v2') {
      switch (this.state.filterVal) {
        case 'Ready for Review': return node.data.status === this.state.filterVal;
        case 'Approved': return node.data.status === this.state.filterVal;
        default: return true;
      }
    } else {
      switch (this.state.filterVal) {
        case 'Ready for Review': return node.data.Status === this.state.filterVal;
        case 'Approved': return node.data.Status === this.state.filterVal;
        default: return true;
      }
    }
    
  }

  approve = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            Are you sure you want to approve {listOfRows.length} row(s)?
          </p>
          <GrpButton onClick={() => this.approveRecords()} size="Medium" type="Primary" text="Approve" />
          <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Approve', modalContent, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to approve.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  approveRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    
    listOfRows.forEach((row) => {
      if (row.RECORD_ID === undefined || row.RecordId !== undefined || row.record_id !== undefined) {
        row.RECORD_ID = row.record_id ?? row.RecordId ?? row.RECORD_ID;
      }
    });

    const delimRecords = listOfRows.map((row) => row.RECORD_ID.toString()).join(',');
    const modalContent = <div><p>Approving {listOfRows.length} row(s)...</p></div>;
    this.context.handlers.setModal('Approving ...', modalContent, true);

    let level = this.state.level;
    if(level === 'EMTv31'){
      level = 'EMTv3';
    }

    CDATA.makeRequest(
      'POST',
      `PRIIPS_app_APPROVE_${level}_BULK`,
      '',
      {
        RecordIDList: delimRecords,
        UserName: this.context.state.userEmail,
        ClientCode_bk: this.context.state.selectedClient.code,
        Level: this.state.level
      },
      'Approve failed.',
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully approved '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.refreshData();
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to approve the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  };

  reopen = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            Are you sure you want to re-open {listOfRows.length} row(s)?
          </p>
          <GrpButton onClick={() => this.reopenRecords()} size="Medium" type="Primary" text="Re-Open" />
          <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('ReOpen', modalContent, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to re-open.</>),
      };
      this.context.handlers.setToast(toast);
    }
  };

  reopenRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();

    listOfRows.forEach((row) => {
      if (row.RECORD_ID === undefined || row.RecordId !== undefined || row.record_id !== undefined) {
        row.RECORD_ID = row.record_id ?? row.RecordId ?? row.RECORD_ID;
      }
    });

    const delimRecords = listOfRows.map((row) => row.RECORD_ID.toString()).join(',');
    const modalContent = <div><p>Re-opening {listOfRows.length} row(s)...</p></div>;
    this.context.handlers.setModal('Re-opening ...', modalContent, true);

    let level = this.state.level;
    if(level === 'EMTv31'){
      level = 'EMTv3';
    }

    CDATA.makeRequest(
      'POST',
      `PRIIPS_app_REOPEN_${level}_BULK`,
      '',
      {
        RecordIDList: delimRecords,
        UserName: this.context.state.userEmail,
        ClientCode_bk: this.context.state.selectedClient.code,
        Level: this.state.level
      },
      'Re-open failed.',
    )
      .then((res) => {
        this.refreshData();
        this.context.handlers.closeModal();
        this.externalFilterChanged('Approved');
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully re-opened '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
      .catch((error) => {
        this.refreshData();
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to re-open the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  };

  makeExtractGeneratorRequest = (fileType,delimiter,dateformatstring) => {
    const payload = {
      Version: 'CEPTv2_Hist',
      ClientCode: this.context.state.selectedClient.code,
      Reference_Date: this.context.state.selectedReportingDate.code,
      FileType: fileType,
      Delimiter: delimiter,
      DateFormatString: dateformatstring,
      DataFilterColumn: 'ResultType',
      Procedure: 'CEPTv2_Hist_Extract',
      BaseFileName: 'CEPTv2_Hist',
      ColumnFilters: {
        "CEPTv2_History": "EXTRACT_CEPTv2_Hist",
      },
      Parameters: {
        Client_Code: this.context.state.selectedClient.code,
        ReferenceDate: this.context.state.selectedReportingDate.code,
      }
    };

    APIM.makeRequest('generateReport', JSON.stringify(payload), 'Error extracting data', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {`You have queued a request to extract CEPTv2 History. It will appear on the Data Management > Exports page when the extract has completed.`}.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        // this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Data extraction'});
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <> 
              {`Error - something went wrong and we were not able to extract CEPTv2 History at this time.`}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  } 

  refreshData() {
    this.updateColumns(this.state.columnData);
    this.defineColumns();
    this.setState({ loading: true }, () => this.getData());
  }

  // Utilized for applying styles to the AGGrid
  roundedFormatter = (params) => (this.processDCPT(params));

  // Utilized for applying styles to the Exports
  processCellCallback = (params) => (this.processDCPT(params));

  /**
   * Formats cells in DCPT
   * Programatically set to 6 decimal places
   * @param {*} params
   * @returns formattedvalue
   */
  processDCPT = (params) => {
    var fieldsToRound = [
      'x_02010_Aggregation_Of_Transaction_Costs',
      'x_02020_Transaction_Costs_For_Buy_And_Sell_Transactions',
      'x_02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions',
      'x_02040_Explicit_Transaction_Taxes',
      'x_02050_Explicit_Fees_And_Charges',
      'x_02060_Implicit_Costs',
      'x_02070_Indirect_Transaction_Costs',
      'x_02080_Anti_Dilution_Offset'
    ]
    if (this.props.api_name === 'PRIIPS_app_DCPT_OUTPUT') {
      if (fieldsToRound.includes(params.column.colDef.field))
      {
        if (params.value === null) return (null);
        if (!isNaN(params.value)) return (this.microRound(params.value));
      }
    }

    if (typeof params.value === 'string') return params.value?.replace(/[\n\r\t]+/g, ' ');

    return (params.value);
  };

  //Rounds to 10^-6 Place
  microRound = (number) => (Math.round(number * 1000000) / 1000000);

  GetOutputParameters = (api_name) => {
    let regulation_name = '';
    let output_columns = [];
    let exportName = api_name;
    if (api_name === 'PRIIPS_app_EPT_OUTPUT') {
      regulation_name = 'EPT';
      output_columns = [
        "x_00010_Portfolio_Issuer_Name",
        "x_00020_Portfolio_Guarantor_Name",
        "x_00030_Portfolio_Identifying_Data",
        "x_00040_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio",
        "x_00050_Portfolio_Name",
        "x_00060_Share_Class_Currency",
        "x_00070_Reference_Date",
        "x_00080_Portfolio_PRIIPS_Category",
        "x_00090_Fund_CIC_code",
        "x_00100_EOS_portfolio",
        "x_01010_Valuation_Frequency",
        "x_01020_Portfolio_VEV_Reference",
        "x_01030_IS_Flexible",
        "x_01040_Flex_VEV_Historical",
        "x_01050_Flex_VEV_Ref_Asset_Allocation",
        "x_01060_IS_Risk_Limit_Relevant",
        "x_01070_Flex_VEV_Risk_Limit",
        "x_01080_Existing_Credit_Risk",
        "x_01090_SRI",
        "x_01100_MRM",
        "x_01110_CRM",
        "x_01120_Recommended_Holding_Period",
        "x_01130_Maturity_Date",
        "x_01140_Liquidity_Risk",
        "x_02010_Portfolio_return_unfavorable_scenario_1_year",
        "x_02020_Portfolio_return_unfavorable_scenario_half_RHP",
        "x_02030_Portfolio_return_unfavorable_scenario_RHP",
        "x_02040_Portfolio_return_moderate_scenario_1_year",
        "x_02050_Portfolio_return_moderate_scenario_half_RHP",
        "x_02060_Portfolio_return_moderate_scenario_RHP",
        "x_02070_Portfolio_return_favorable_scenario_1_year",
        "x_02080_Portfolio_return_favorable_scenario_half_RHP",
        "x_02090_Portfolio_return_favorable_scenario_RHP",
        "x_02100_Portfolio_return_stress_scenario_1_year",
        "x_02110_Portfolio_return_stress_scenario_half_RHP",
        "x_02120_Portfolio_return_stress_scenario_RHP",
        "x_02130_Portfolio_number_of_observed_return_M0",
        "x_02140_Portfolio_mean_observed_returns_M1",
        "x_02150_Portfolio_observed_Sigma",
        "x_02160_Portfolio_observed_Skewness",
        "x_02170_Portfolio_observed_Excess_Kurtosis",
        "x_02180_Portfolio_observed_Stressed_Volatility",
        "x_03010_One_off_cost_Portfolio_entry_cost",
        "x_03015_One_off_cost_Portfolio_entry_cost_Acquired",
        "x_03020_One_off_costs_Portfolio_exit_cost_at_RHP",
        "x_03030_One_off_costs_Portfolio_exit_cost_at_1_year",
        "x_03040_One_off_costs_Portfolio_exit_cost_at_half_RHP",
        "x_03050_One_off_costs_Portfolio_sliding_exit_cost_Indicator",
        "x_03060_Ongoing_costs_Portfolio_other_costs",
        "x_03070_Ongoing_costs_Portfolio_management_costs",
        "x_03080_Ongoing_costs_Portfolio_transaction_costs",
        "x_03090_Existing_performance_fees",
        "x_03095_Incidental_costs_Portfolio_performance_fees",
        "x_03100_Existing_carried_interest_fees",
        "x_03105_Incidental_costs_Portfolio_carried_interest",
        "x_04010_Reference_Language",
        "x_04020_Comprehension_Alert_Portfolio",
        "x_04030_Intended_target_market_retail_investor_Portfolio",
        "x_04040_Investment_objective_Portfolio",
        "x_04050_Risk_narrative_Portfolio",
        "x_04060_Other_materially_relevant_risk_narrative_Portfolio",
        "x_04070_Type_of_underlying_Investment_Option",
        "x_04080_Capital_Guarantee",
        "x_04081_Capital_Guarantee_Level",
        "x_04082_Capital_Guarantee_Limitations",
        "x_04083_Capital_Guarantee_Early_Exit_Conditions",
        "x_04084_Capital_guarantee_Portfolio",
        "x_04085_Possible_maximum_loss_Portfolio",
        "x_04090_Portfolio_Performance_Fees_Narrative",
        "x_04100_Portfolio_Carried_Interest_Narrative",
        "x_04110_Other_comment",
        "x_05010_PRIIP_data_delivery",
        "x_05020_UCITS_data_delivery",
        "x_05030_Portfolio_UCITS_SRRI",
        "x_05040_Portfolio_UCITS_Vol",
        "x_05050_Ongoing_costs_Portfolio_other_costs_UCITS",
        "x_05060_Ongoing_costs_Portfolio_transaction_costs",
        "x_05065_Transactions_costs_methodology",
        "x_05070_Incidental_costs_Portfolio_performance_fees_UCITS",
        "x_05080_Incidental_costs_Portfolio_carried_interest_UCITS",
        "x_05090_UCITS_KID_Web_Address",
        "x_06010_Bonds_Weight",
        "x_06020_Annualized_Return_Volatility",
        "x_06030_Duration_Bonds",
        "x_06040_Existing_Capital_Preservation",
        "x_06050_Capital_Preservation_Level",
        "x_06060_Time_Interval_Maximum_Loss",
        "x_06070_Uses_PI",
        "x_06080_Multiplier_PI",
        "x_07010_Total_cost_1_year",
        "x_07020_RIY_1_year",
        "x_07030_Total_cost_half_RHP",
        "x_07040_RIY_half_RHP",
        "x_07050_Total_cost_RHP",
        "x_07060_RIY_RHP",
        "x_07070_One_off_costs_Portfolio_entry_cost_RIY",
        "x_07080_One_off_costs_Portfolio_exit_cost_RIY",
        "x_07090_Ongoing_costs_Portfolio_transaction_costs_RIY",
        "x_07100_Ongoing_costs_Other_ongoing_costs_RIY",
        "x_07110_Incidental_costs_Portfolio_performance_fees_RIY",
        "x_07120_Incidental_costs_Portfolio_carried_interests_RIY"
      ];
    } else if (api_name === 'PRIIPS_app_EPTv2_OUTPUT') {
      regulation_name = 'EPTv2';
      output_columns = [
        "x_00001_EPT_Version",
        "x_00002_EPT_Producer_Name",
        "x_00004_EPT_Producer_Email",
        "x_00005_File_Generation_Date_And_Time",
        "x_00006_EPT_Data_Reporting_Narratives",
        "x_00007_EPT_Data_Reporting_Costs",
        "x_00008_EPT_Data_Reporting_Additional_Requirements_German_MOPs",
        "x_00009_EPT_Additional_Information_Structured_Products",
        "x_00010_Portfolio_Manufacturer_Name",
        "x_00015_Portfolio_Manufacturer_Group_Name",
        "x_00016_Portfolio_Manufacturer_LEI",
        "x_00017_Portfolio_Manufacturer_Email",
        "x_00020_Portfolio_Guarantor_Name",
        "x_00030_Portfolio_Identifying_Data",
        "x_00040_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio",
        "x_00050_Portfolio_Name",
        "x_00060_Portfolio_Or_Share_Class_Currency",
        "x_00070_PRIIPs_KID_Publication_Date",
        "x_00075_PRIIPs_KID_Web_Address",
        "x_00080_Portfolio_PRIIPS_Category",
        "x_00090_Fund_CIC_code",
        "x_00110_Is_An_Autocallable_Product",
        "x_00120_Reference_Language",
        "x_01010_Valuation_Frequency",
        "x_01020_Portfolio_VEV_Reference",
        "x_01030_IS_Flexible",
        "x_01040_Flex_VEV_Historical",
        "x_01050_Flex_VEV_Ref_Asset_Allocation",
        "x_01060_IS_Risk_Limit_Relevant",
        "x_01070_Flex_VEV_Risk_Limit",
        "x_01080_Existing_Credit_Risk",
        "x_01090_SRI",
        "x_01095_IS_SRI_Adjusted",
        "x_01100_MRM",
        "x_01110_CRM",
        "x_01120_Recommended_Holding_Period",
        "x_01125_Has_A_Contractual_Maturity_Date",
        "x_01130_Maturity_Date",
        "x_01140_Liquidity_Risk",
        "x_02010_Portfolio_Return_Unfavourable_Scenario_1_Year",
        "x_02020_Portfolio_Return_Unfavourable_Scenario_Half_RHP",
        "x_02030_Portfolio_Return_Unfavourable_Scenario_RHP_Or_First_Call_Date",
        "x_02032_Autocall_Applied_Unfavourable_Scenario",
        "x_02035_Autocall_Date_Unfavourable_Scenario",
        "x_02040_Portfolio_return_moderate_scenario_1_year",
        "x_02050_Portfolio_return_moderate_scenario_half_RHP",
        "x_02060_Portfolio_Return_Moderate_Scenario_RHP_Or_First_Call_Date",
        "x_02062_Autocall_Applied_Moderate_Scenario",
        "x_02065_Autocall_Date_Moderate_Scenario",
        "x_02070_Portfolio_Return_Favourable_Scenario_1_Year",
        "x_02080_Portfolio_Return_Favourable_Scenario_Half_RHP",
        "x_02090_Portfolio_Return_Favourable_Scenario_RHP_Or_First_Call_Date",
        "x_02092_Autocall_Applied_Favourable_Scenario",
        "x_02095_Autocall_Date_Favourable_Scenario",
        "x_02100_Portfolio_return_stress_scenario_1_year",
        "x_02110_Portfolio_return_stress_scenario_half_RHP",
        "x_02120_Portfolio_Return_Stress_Scenario_RHP_Or_First_Call_Date",
        "x_02122_Autocall_Applied_Stress_Scenario",
        "x_02125_Autocall_Date_Stress_Scenario",
        "x_02130_Portfolio_number_of_observed_return_M0",
        "x_02140_Portfolio_mean_observed_returns_M1",
        "x_02150_Portfolio_observed_Sigma",
        "x_02160_Portfolio_observed_Skewness",
        "x_02170_Portfolio_observed_Excess_Kurtosis",
        "x_02180_Portfolio_observed_Stressed_Volatility",
        "x_02185_Portfolio_Past_Performance_Disclosure_Required",
        "x_02190_Past_Performance_Link",
        "x_02200_Previous_Performance_Scenarios_Calculation_Link",
        "x_02210_Past_Performance_Number_Of_Years",
        "x_02220_Reference_Invested_Amount",
        "x_03010_One_off_cost_Portfolio_entry_cost",
        "x_03015_One_off_cost_Portfolio_entry_cost_Acquired",
        "x_03020_One_Off_Costs_Portfolio_Exit_Cost_At_RHP",
        "x_03030_One_off_costs_Portfolio_exit_cost_at_1_year",
        "x_03040_One_off_costs_Portfolio_exit_cost_at_half_RHP",
        "x_03050_One_off_costs_Portfolio_sliding_exit_cost_Indicator",
        "x_03060_Ongoing_Costs_Management_Fees_And_Other_Administrative_Or_Operating_Costs",
        "x_03080_Ongoing_costs_Portfolio_transaction_costs",
        "x_03090_Existing_Incidental_Costs_Portfolio",
        "x_03095_Incidental_Costs",
        "x_04020_Comprehension_Alert_Portfolio",
        "x_04030_Intended_target_market_retail_investor_Portfolio",
        "x_04040_Investment_objective_Portfolio",
        "x_04050_Risk_narrative_Portfolio",
        "x_04060_Other_materially_relevant_risk_narrative_Portfolio",
        "x_04070_Type_of_underlying_Investment_Option",
        "x_04080_Capital_Guarantee",
        "x_04081_Capital_Guarantee_Level",
        "x_04082_Capital_Guarantee_Limitations",
        "x_04083_Capital_Guarantee_Early_Exit_Conditions",
        "x_04084_Capital_guarantee_Portfolio",
        "x_04085_Possible_maximum_loss_Portfolio",
        "x_04086_Description_Past_Interval_Unfavourable_Scenario",
        "x_04087_Description_Past_Interval_Moderate_Scenario",
        "x_04088_Description_Past_Interval_Favourable_Scenario",
        "x_04089_Was_Benchmark_Used_Performance_Calculation",
        "x_04090_Portfolio_Performance_Fees_Carried_Interest_Narrative",
        "x_04120_One_Off_Cost_Portfolio_Entry_Cost_Description",
        "x_04130_One_Off_Cost_Portfolio_Exit_Cost_Description",
        "x_04140_Ongoing_Costs_Portfolio_Management_Costs_Description",
        "x_04150_Do_Costs_Depend_On_Invested_Amount",
        "x_04160_Cost_Dependence_Explanation",
        "x_06005_German_MOPs_Reference_Date",
        "x_06010_Bonds_Weight",
        "x_06020_Annualized_Return_Volatility",
        "x_06030_Duration_Bonds",
        "x_06040_Existing_Capital_Preservation",
        "x_06050_Capital_Preservation_Level",
        "x_06060_Time_Interval_Maximum_Loss",
        "x_06070_Uses_PI",
        "x_06080_Multiplier_PI",
        "x_07005_First_Possible_Call_Date",
        "x_07010_Total_Cost_1_Year_Or_First_Call",
        "x_07020_RIY_1_Year_Or_First_Call",
        "x_07030_Total_cost_half_RHP",
        "x_07040_RIY_half_RHP",
        "x_07050_Total_cost_RHP",
        "x_07060_RIY_RHP",
        "x_07070_One_Off_Costs_Portfolio_Entry_Cost",
        "x_07080_One_Off_Costs_Portfolio_Exit_Cost",
        "x_07090_Ongoing_Costs_Portfolio_Transaction_Costs",
        "x_07100_Ongoing_Costs_Management_Fees_And_Other_Administrative_Or_Operating_Costs",
        "x_07110_Incidental_Costs_Portfolio_Performance_Fees_Carried_Interest"
      ];  
    } else if (api_name === 'PRIIPS_app_EMTv3_OUTPUT') {
      regulation_name = 'EMTv3';
      output_columns = [
        "x_00001_EMT_Version",
        "x_00002_EMT_Producer_Name",
        "x_00003_EMT_Producer_LEI",
        "x_00004_EMT_Producer_Email",
        "x_00005_File_Generation_Date_And_Time",
        "x_00006_EMT_Data_Reporting_Target_Market",
        "x_00007_EMT_Data_Reporting_Ex_Ante",
        "x_00008_EMT_Data_Reporting_Ex_Post",
        "x_00010_Financial_Instrument_Identifying_Data",
        "x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument",
        "x_00030_Financial_Instrument_Name",
        "x_00040_Financial_Instrument_Currency",
        "x_00045_Financial_Instrument_Performance_Fee",
        "x_00047_Financial_Instrument_Distribution_Of_Cash",
        "x_00050_General_Reference_Date",
        "x_00060_Financial_Instrument_Product_Type",
        "x_00065_Maturity_Date",
        "x_00067_May_Be_Terminated_Early",
        "x_00070_Financial_Instrument_Manufacturer_Name",
        "x_00073_Financial_Instrument_Manufacturer_LEI",
        "x_00074_Financial_Instrument_Manufacturer_Email",
        "x_00075_Financial_Instrument_Manufacturer_Product_Governance_Process",
        "x_00080_Financial_Instrument_Guarantor_Name",
        "x_00085_Financial_Instrument_Type_Notional_Or_Item_Based",
        "x_00090_Product_Category_Or_Nature_Germany",
        "x_00095_Structured_Securities_Product_Category_Or_Nature",
        "x_00096_Structured_Securities_Quotation",
        "x_00100_Leveraged_Financial_Instrument_Or_Contingent_Liability_Instrument",
        "x_00110_Fund_Share_Class_Without_Retrocession",
        "x_00120_Ex_Post_Cost_Calculation_Basis_Italy",
        "x_01000_Target_Market_Reference_Date",
        "x_01010_Investor_Type_Retail",
        "x_01020_Investor_Type_Professional",
        "x_01030_Investor_Type_Eligible_Counterparty",
        "x_02010_Basic_Investor",
        "x_02020_Informed_Investor",
        "x_02030_Advanced_Investor",
        "x_02040_Expert_Investor_Germany",
        "x_03010_Compatible_With_Clients_Who_Can_Not_Bear_Capital_Loss",
        "x_03020_Compatible_With_Clients_Who_Can_Bear_Limited_Capital_Loss",
        "x_03030_Limited_Capital_Loss_Level",
        "x_03040_Compatible_With_Clients_Who_Do_Not_Need_Capital_Guarantee",
        "x_03050_Compatible_With_Clients_Who_Can_Bear_Loss_Beyond_Capital",
        "x_04010_Risk_Tolerance_PRIIPS_Methodology",
        "x_04020_Risk_Tolerance_UCITS_Methodology",
        "x_04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_And_Non_UCITS",
        "x_04040_Risk_Tolerance_For_Non_PRIIPS_And_Non_UCITS_Spain",
        "x_04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany",
        "x_05010_Return_Profile_Client_Looking_For_Preservation",
        "x_05020_Return_Profile_Client_Looking_For_Capital_Growth",
        "x_05030_Return_Profile_Client_Looking_For_Income",
        "x_05040_Return_Profile_Hedging",
        "x_05050_Option_Or_Leveraged_Return_Profile",
        "x_05070_Return_Profile_Pension_Scheme_Germany",
        "x_05080_Minimum_Recommended_Holding_Period",
        "x_05105_Intended_Compatible_With_Clients_Having_ESG_Preferences",
        "x_05115_Other_Specific_Investment_Need",
        "x_06010_Execution_Only",
        "x_06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services",
        "x_06030_Investment_Advice",
        "x_06040_Portfolio_Management",
        "x_07020_Gross_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Non_Acquired",
        "x_07025_Net_One_off_Cost_Structured_Products_Entry_Cost_Non_Acquired",
        "x_07030_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Fixed_Amount_Italy",
        "x_07040_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Acquired",
        "x_07050_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Non_Acquired",
        "x_07060_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Fixed_Amount_Italy",
        "x_07070_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Acquired",
        "x_07080_One_off_Costs_Financial_Instrument_Typical_Exit_Cost",
        "x_07090_One_off_Cost_Financial_Instrument_Exit_Cost_Structured_Products_Prior_RHP",
        "x_07100_Financial_Instrument_Gross_Ongoing_Costs",
        "x_07105_Financial_Instrument_Borrowing_Costs_Ex_Ante_UK",
        "x_07110_Financial_Instrument_Management_Fee",
        "x_07120_Financial_Instrument_Distribution_Fee",
        "x_07130_Financial_Instrument_Transaction_Costs_Ex_Ante",
        "x_07140_Financial_Instrument_Incidental_Costs_Ex_Ante",
        "x_07150_Structured_Securities_Reference_Price_Ex_Ante",
        "x_07155_Structured_Securities_Notional_Reference_Amount_Ex_Ante",
        "x_07160_Ex_Ante_Costs_Reference_Date",
        "x_08010_Gross_One_off_Cost_Structured_Securities_Entry_Cost_Ex_Post",
        "x_08015_Net_One_off_Cost_Structured_Securities_Entry_Cost_Ex_Post",
        "x_08020_One_off_Costs_Structured_Securities_Exit_Cost_Ex_Post",
        "x_08025_One_off_Cost_Financial_Instrument_Entry_Cost_Acquired",
        "x_08030_Financial_Instrument_Ongoing_Costs_Ex_Post",
        "x_08040_Structured_Securities_Ongoing_Costs_Ex_Post_Accumulated",
        "x_08045_Financial_Instrument_Borrowing_Costs_Ex_Post_UK",
        "x_08050_Financial_Instrument_Management_Fee_Ex_Post",
        "x_08060_Financial_Instrument_Distribution_Fee_Ex_Post",
        "x_08070_Financial_Instrument_Transaction_Costs_Ex_Post",
        "x_08080_Financial_Instrument_Incidental_Costs_Ex_Post",
        "x_08090_Beginning_Of_Reference_Period",
        "x_08100_End_Of_Reference_Period",
        "x_08110_Structured_Securities_Reference_Price_Ex_Post",
        "x_08120_Structured_Securities_Notional_Reference_Amount"
      ];
    } else if (api_name === 'PRIIPS_app_EMTv31_OUTPUT') {
      regulation_name = 'EMTv31';
      output_columns = [
        "x_00001_EMT_Version",
        "x_00002_EMT_Producer_Name",
        "x_00003_EMT_Producer_LEI",
        "x_00004_EMT_Producer_Email",
        "x_00005_File_Generation_Date_And_Time",
        "x_00006_EMT_Data_Reporting_Target_Market",
        "x_00007_EMT_Data_Reporting_Ex_Ante",
        "x_00008_EMT_Data_Reporting_Ex_Post",
        "x_00010_Financial_Instrument_Identifying_Data",
        "x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument",
        "x_00030_Financial_Instrument_Name",
        "x_00040_Financial_Instrument_Currency",
        "x_00045_Financial_Instrument_Performance_Fee",
        "x_00047_Financial_Instrument_Distribution_Of_Cash",
        "x_00050_General_Reference_Date",
        "x_00060_Financial_Instrument_Product_Type",
        "x_00065_Maturity_Date",
        "x_00067_May_Be_Terminated_Early",
        "x_00070_Financial_Instrument_Manufacturer_Name",
        "x_00073_Financial_Instrument_Manufacturer_LEI",
        "x_00074_Financial_Instrument_Manufacturer_Email",
        "x_00075_Financial_Instrument_Manufacturer_Product_Governance_Process",
        "x_00080_Financial_Instrument_Guarantor_Name",
        "x_00085_Financial_Instrument_Type_Notional_Or_Item_Based",
        "x_00090_Product_Category_Or_Nature_Germany",
        "x_00095_Structured_Securities_Product_Category_Or_Nature",
        "x_00096_Structured_Securities_Quotation",
        "x_00100_Leveraged_Financial_Instrument_Or_Contingent_Liability_Instrument",
        "x_00110_Fund_Share_Class_Without_Retrocession",
        "x_00120_Ex_Post_Cost_Calculation_Basis_Italy",
        "x_01000_Target_Market_Reference_Date",
        "x_01010_Investor_Type_Retail",
        "x_01020_Investor_Type_Professional",
        "x_01030_Investor_Type_Eligible_Counterparty",
        "x_02010_Basic_Investor",
        "x_02020_Informed_Investor",
        "x_02030_Advanced_Investor",
        "x_02040_Expert_Investor_Germany",
        "x_03010_Compatible_With_Clients_Who_Can_Not_Bear_Capital_Loss",
        "x_03020_Compatible_With_Clients_Who_Can_Bear_Limited_Capital_Loss",
        "x_03030_Limited_Capital_Loss_Level",
        "x_03040_Compatible_With_Clients_Who_Do_Not_Need_Capital_Guarantee",
        "x_03050_Compatible_With_Clients_Who_Can_Bear_Loss_Beyond_Capital",
        "x_04010_Risk_Tolerance_PRIIPS_Methodology",
        "x_04020_Risk_Tolerance_UCITS_Methodology",
        "x_04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_And_Non_UCITS",
        "x_04040_Risk_Tolerance_For_Non_PRIIPS_And_Non_UCITS_Spain",
        "x_04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany",
        "x_05010_Return_Profile_Client_Looking_For_Preservation",
        "x_05020_Return_Profile_Client_Looking_For_Capital_Growth",
        "x_05030_Return_Profile_Client_Looking_For_Income",
        "x_05040_Return_Profile_Hedging",
        "x_05050_Option_Or_Leveraged_Return_Profile",
        "x_05070_Return_Profile_Pension_Scheme_Germany",
        "x_05080_Minimum_Recommended_Holding_Period",
        "x_05105_Intended_Compatible_With_Clients_Having_Sustainability_Preferences",
        "x_05115_Other_Specific_Investment_Need",
        "x_06010_Execution_Only",
        "x_06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services",
        "x_06030_Investment_Advice",
        "x_06040_Portfolio_Management",
        "x_07020_Gross_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Non_Acquired",
        "x_07025_Net_One_off_Cost_Structured_Products_Entry_Cost_Non_Acquired",
        "x_07030_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Fixed_Amount_Italy",
        "x_07040_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Acquired",
        "x_07050_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Non_Acquired",
        "x_07060_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Fixed_Amount_Italy",
        "x_07070_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Acquired",
        "x_07080_One_off_Costs_Financial_Instrument_Typical_Exit_Cost",
        "x_07090_One_off_Cost_Financial_Instrument_Exit_Cost_Structured_Products_Prior_RHP",
        "x_07100_Financial_Instrument_Gross_Ongoing_Costs",
        "x_07105_Financial_Instrument_Borrowing_Costs_Ex_Ante_UK",
        "x_07110_Financial_Instrument_Management_Fee",
        "x_07120_Financial_Instrument_Distribution_Fee",
        "x_07130_Financial_Instrument_Transaction_Costs_Ex_Ante",
        "x_07140_Financial_Instrument_Incidental_Costs_Ex_Ante",
        "x_07150_Structured_Securities_Reference_Price_Ex_Ante",
        "x_07155_Structured_Securities_Notional_Reference_Amount_Ex_Ante",
        "x_07160_Ex_Ante_Costs_Reference_Date",
        "x_08010_Gross_One_off_Cost_Structured_Securities_Entry_Cost_Ex_Post",
        "x_08015_Net_One_off_Cost_Structured_Securities_Entry_Cost_Ex_Post",
        "x_08020_One_off_Costs_Structured_Securities_Exit_Cost_Ex_Post",
        "x_08025_One_off_Cost_Financial_Instrument_Entry_Cost_Acquired",
        "x_08030_Financial_Instrument_Ongoing_Costs_Ex_Post",
        "x_08040_Structured_Securities_Ongoing_Costs_Ex_Post_Accumulated",
        "x_08045_Financial_Instrument_Borrowing_Costs_Ex_Post_UK",
        "x_08050_Financial_Instrument_Management_Fee_Ex_Post",
        "x_08060_Financial_Instrument_Distribution_Fee_Ex_Post",
        "x_08070_Financial_Instrument_Transaction_Costs_Ex_Post",
        "x_08080_Financial_Instrument_Incidental_Costs_Ex_Post",
        "x_08090_Beginning_Of_Reference_Period",
        "x_08100_End_Of_Reference_Period",
        "x_08110_Structured_Securities_Reference_Price_Ex_Post",
        "x_08120_Structured_Securities_Notional_Reference_Amount",
        "x_09000_ESG_Category_For_German_Market",
        "x_09010_ESG_Focus",
        "x_09020_ESG_Label_Or_Standard",
        "x_10020_AMF_Doctrine"
      ];
    } else if (api_name === 'PRIIPS_app_EMTv4_OUTPUT') {
      regulation_name = 'EMTv4';
      output_columns = [
        "x_00001_EMT_Version",
        "x_00002_EMT_Producer_Name",
        "x_00003_EMT_Producer_LEI",
        "x_00004_EMT_Producer_Email",
        "x_00005_File_Generation_Date_And_Time",
        "x_00006_EMT_Data_Reporting_Target_Market",
        "x_00007_EMT_Data_Reporting_Ex_Ante",
        "x_00008_EMT_Data_Reporting_Ex_Post",
        "x_00010_Financial_Instrument_Identifying_Data",
        "x_00020_Type_Of_Identification_Code_For_The_Financial_Instrument",
        "x_00030_Financial_Instrument_Name",
        "x_00040_Financial_Instrument_Currency",
        "x_00045_Financial_Instrument_Performance_Fee",
        "x_00047_Financial_Instrument_Distribution_Of_Cash",
        "x_00050_General_Reference_Date",
        "x_00060_Financial_Instrument_Product_Type",
        "x_00065_Maturity_Date",
        "x_00067_May_Be_Terminated_Early",
        "x_00070_Financial_Instrument_Manufacturer_Name",
        "x_00073_Financial_Instrument_Manufacturer_LEI",
        "x_00074_Financial_Instrument_Manufacturer_Email",
        "x_00075_Financial_Instrument_Manufacturer_Product_Governance_Process",
        "x_00080_Financial_Instrument_Guarantor_Name",
        "x_00085_Financial_Instrument_Type_Notional_Or_Item_Based",
        "x_00090_Product_Category_Or_Nature_Germany",
        "x_00095_Structured_Securities_Product_Category_Or_Nature",
        "x_00096_Structured_Securities_Quotation",
        "x_00100_Leveraged_Financial_Instrument_Or_Contingent_Liability_Instrument",
        "x_00110_Fund_Share_Class_Without_Retrocession",
        "x_00120_Ex_Post_Cost_Calculation_Basis_Italy",
        "x_01000_Target_Market_Reference_Date",
        "x_01010_Investor_Type_Retail",
        "x_01020_Investor_Type_Professional",
        "x_01030_Investor_Type_Eligible_Counterparty",
        "x_02010_Basic_Investor",
        "x_02020_Informed_Investor",
        "x_02030_Advanced_Investor",
        "x_02040_Expert_Investor_Germany",
        "x_03010_Compatible_With_Clients_Who_Can_Not_Bear_Capital_Loss",
        "x_03020_Compatible_With_Clients_Who_Can_Bear_Limited_Capital_Loss",
        "x_03030_Limited_Capital_Loss_Level",
        "x_03040_Compatible_With_Clients_Who_Do_Not_Need_Capital_Guarantee",
        "x_03050_Compatible_With_Clients_Who_Can_Bear_Loss_Beyond_Capital",
        "x_04010_Risk_Tolerance_PRIIPS_Methodology",
        "x_04020_Risk_Tolerance_UCITS_Methodology",
        "x_04030_Risk_Tolerance_Internal_Methodology_For_Non_PRIIPS_And_Non_UCITS",
        "x_04040_Risk_Tolerance_For_Non_PRIIPS_And_Non_UCITS_Spain",
        "x_04050_Not_For_Investors_With_The_Lowest_Risk_Tolerance_Germany",
        "x_05010_Return_Profile_Client_Looking_For_Preservation",
        "x_05020_Return_Profile_Client_Looking_For_Capital_Growth",
        "x_05030_Return_Profile_Client_Looking_For_Income",
        "x_05040_Return_Profile_Hedging",
        "x_05050_Option_Or_Leveraged_Return_Profile",
        "x_05070_Return_Profile_Pension_Scheme_Germany",
        "x_05080_Minimum_Recommended_Holding_Period",
        "x_05105_Does_This_Financial_Instrument_Consider_End_Client_Sustainability_Preferences",
        "x_05115_Other_Specific_Investment_Need",
        "x_06010_Execution_Only",
        "x_06020_Execution_With_Appropriateness_Test_Or_Non_Advised_Services",
        "x_06030_Investment_Advice",
        "x_06040_Portfolio_Management",
        "x_07020_Gross_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Non_Acquired",
        "x_07025_Net_One_off_Cost_Structured_Products_Entry_Cost_Non_Acquired",
        "x_07030_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Fixed_Amount_Italy",
        "x_07040_One_off_Cost_Financial_Instrument_Maximum_Entry_Cost_Acquired",
        "x_07050_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Non_Acquired",
        "x_07060_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Fixed_Amount_Italy",
        "x_07070_One_off_Costs_Financial_Instrument_Maximum_Exit_Cost_Acquired",
        "x_07080_One_off_Costs_Financial_Instrument_Typical_Exit_Cost",
        "x_07090_One_off_Cost_Financial_Instrument_Exit_Cost_Structured_Products_Prior_RHP",
        "x_07100_Financial_Instrument_Gross_Ongoing_Costs",
        "x_07105_Financial_Instrument_Borrowing_Costs_Ex_Ante_UK",
        "x_07110_Financial_Instrument_Management_Fee",
        "x_07120_Financial_Instrument_Distribution_Fee",
        "x_07130_Financial_Instrument_Transaction_Costs_Ex_Ante",
        "x_07140_Financial_Instrument_Incidental_Costs_Ex_Ante",
        "x_07150_Structured_Securities_Reference_Price_Ex_Ante",
        "x_07155_Structured_Securities_Notional_Reference_Amount_Ex_Ante",
        "x_07160_Ex_Ante_Costs_Reference_Date",
        "x_08010_Gross_One_off_Cost_Structured_Securities_Entry_Cost_Ex_Post",
        "x_08015_Net_One_off_Cost_Structured_Securities_Entry_Cost_Ex_Post",
        "x_08020_One_off_Costs_Structured_Securities_Exit_Cost_Ex_Post",
        "x_08025_One_off_Cost_Financial_Instrument_Entry_Cost_Acquired",
        "x_08030_Financial_Instrument_Ongoing_Costs_Ex_Post",
        "x_08040_Structured_Securities_Ongoing_Costs_Ex_Post_Accumulated",
        "x_08045_Financial_Instrument_Borrowing_Costs_Ex_Post_UK",
        "x_08050_Financial_Instrument_Management_Fee_Ex_Post",
        "x_08060_Financial_Instrument_Distribution_Fee_Ex_Post",
        "x_08070_Financial_Instrument_Transaction_Costs_Ex_Post",
        "x_08080_Financial_Instrument_Incidental_Costs_Ex_Post",
        "x_08090_Beginning_Of_Reference_Period",
        "x_08100_End_Of_Reference_Period",
        "x_08110_Structured_Securities_Reference_Price_Ex_Post",
        "x_08120_Structured_Securities_Notional_Reference_Amount"
      ];
    } else if (api_name === 'PRIIPS_app_DCPT_OUTPUT') {
      regulation_name = 'DCPT';
      output_columns = [
        "x_00010_Portfolio_Identifying_Data",
        "x_00020_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio",
        "x_00030_Portfolio_Name",
        "x_00040_Portfolio_Issuer_Name",
        "x_00050_Start_Of_Period",
        "x_00060_End_Of_Period",
        "x_01010_Percentage_Not_Obtained",
        "x_01020_Categories_Not_Obtained",
        "x_01030_Transaction_Costs_Methodology",
        "x_01040_Reason_Not_Obtained",
        "x_02010_Aggregation_Of_Transaction_Costs",
        "x_02020_Transaction_Costs_For_Buy_And_Sell_Transactions",
        "x_02030_Transaction_Costs_For_Lending_And_Borrowing_Transactions",
        "x_02040_Explicit_Transaction_Taxes",
        "x_02050_Explicit_Fees_And_Charges",
        "x_02060_Implicit_Costs",
        "x_02070_Indirect_Transaction_Costs",
        "x_02080_Anti_Dilution_Offset",
        "x_02090_Securities_Lending_And_Borrowing_Costs",
        "x_03010_Administration_Charges",
        "x_03020_Costs_Other_Than_Transaction_Costs",
        "x_04010_Portfolio_Turnover_Rate",
        "x_04020_Proportion_Of_Securities_Loaned_Or_Borrowed"
      ];
    } else if (api_name === 'PRIIPS_app_CEPT_OUTPUT') {
      regulation_name = 'CEPT';
      output_columns = [
        "x_30010_Portfolio_Issuer_Name",
        "x_30020_Portfolio_Identifying_Data",
        "x_30030_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio",
        "x_30040_Portfolio_Name",
        "x_30050_Share_Class_Currency",
        "x_30060_Reporting_Date",
        "x_30070_Portfolio_PRIIPS_Category",
        "x_31010_Period_1",
        "x_31020_Period_2",
        "x_31030_HP_period_3",
        "x_31050_Lump_sum_or_regular_premium_indicator",
        "x_31060_Investment_amount",
        "x_31070_Accumulated_Investment_amount",
        "x_32010_SRI",
        "x_33010_Amount_period_1_unfavourable",
        "x_33020_Amount_period_1_moderate",
        "x_33030_Amount_period_1_favourable",
        "x_33040_Amount_period_1_stress_scenario",
        "x_33050_Amount_period_2_unfavourable",
        "x_33060_Amount_period_2_moderate",
        "x_33070_Amount_period_2_favourable",
        "x_33080_Amount_period_2_stress_scenario",
        "x_33090_Amount_period_3_unfavourable",
        "x_33100_Amount_period_3_moderate",
        "x_33110_Amount_period_3_favourable",
        "x_33120_Amount_period_3_stress_scenario",
        "x_33130_Average_return_period_1_unfavourable",
        "x_33140_Average_return_period_1_moderate",
        "x_33150_Average_return_period_1_favourable",
        "x_33160_Average_return_period_1_stress_scenario",
        "x_33170_Average_return_period_2_unfavourable",
        "x_33180_Average_return_period_2_moderate",
        "x_33190_Average_return_period_2_favourable",
        "x_33200_Average_return_period_2_stress_scenario",
        "x_33210_Average_return_period_3_unfavourable",
        "x_33220_Average_return_period_3_moderate",
        "x_33230_Average_return_period_3_favourable",
        "x_33240_Average_return_period_3_stress_scenario",
        "x_34010_Cost_total_period_1",
        "x_34020_Cost_total_period_2",
        "x_34030_Cost_total_period_3",
        "x_34040_Percentage_RIY_period_1",
        "x_34050_Percentage_RIY_period_2",
        "x_34060_Percentage_RIY_period_3"
      ];
    } else if (api_name === 'PRIIPS_app_CTI_OUTPUT') {
      regulation_name = 'CTI';
      output_columns = [
        "x_00010_CTI_Portfolio_Identifying_Data",
        "x_00020_CTI_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio",
        "x_00030_CTI_Portfolio_Name",
        "x_00040_CTI_Portfolio_Issuer_Name",
        "x_00050_CTI_Start_Of_Period",
        "x_00060_CTI_End_Of_Period",
        "x_00070_CTI_Currency_Of_Report",
        "x_00080_CTI_Fund_Or_Mandate",
        "x_00090_CTI_Client_Only_Report",
        "x_00100_CTI_Underlying_Investment_Vehicles_Used",
        "x_00110_CTI_Performance_Fee_Applicable",
        "x_00120_CTI_Securities_Lending_Applicable",
        "x_00130_CTI_Securities_Lending_Agent_Name",
        "x_00140_CTI_Direct_Property_Exposure",
        "x_00150_CTI_Asset_Class_Breakdown_Provided",
        "x_02010_CTI_Client_Name",
        "x_02020_CTI_Client_Average_Value",
        "x_02030_CTI_Entry_Costs",
        "x_02040_CTI_Exit_Costs",
        "x_02050_CTI_Client_FX_Costs",
        "x_03010_CTI_Total_Transaction_Costs",
        "x_03020_CTI_Total_Explicit_Costs",
        "x_03030_CTI_Explicit_Transaction_Taxes",
        "x_03040_CTI_Explicit_Broker_Commission_And_Exchange_Fees",
        "x_03050_CTI_Explicit_Transaction_Related_Services",
        "x_03060_CTI_Explicit_Other_Charges",
        "x_03070_CTI_Implicit_Costs",
        "x_03080_CTI_Indirect_Transaction_Costs",
        "x_03090_CTI_Anti_Dilution_Offset",
        "x_04010_CTI_Ongoing_Charges",
        "x_04100_CTI_Fund_And_Management_Charges",
        "x_04110_CTI_Invoiced_Fees",
        "x_04120_CTI_Fees_And_Charges_From_NAV",
        "x_04130_CTI_Investment_Advisors_Fees_From_NAV",
        "x_04140_CTI_Research_Payments_From_NAV",
        "x_04150_CTI_Management_Fee_Rebates",
        "x_04160_CTI_Indirect_Fees_And_Charges",
        "x_04200_CTI_Administration_Charges",
        "x_04210_CTI_Investment_Administration_Fees",
        "x_04220_CTI_Custody_And_Depositary_Fees",
        "x_04230_CTI_Research_Payments",
        "x_04240_CTI_Collateral_Management_Fees",
        "x_04250_CTI_Facility_Fees",
        "x_04290_CTI_Other_Administration_Charges",
        "x_04300_CTI_Governance_Regulation_Compliance_Charges",
        "x_04310_CTI_Audit_Costs",
        "x_04320_CTI_Legal_And_Professional_Fees",
        "x_04330_CTI_Engagement_And_Voting_Fees",
        "x_04340_CTI_Performance_Measurement",
        "x_04350_CTI_Risk_Monitoring",
        "x_04360_CTI_Tax_Advice_And_Structuring_Costs",
        "x_04390_CTI_Other_Governance_Regulation_Compliance_Charges",
        "x_04400_CTI_Distribution_Communication_Client_Service_Charges",
        "x_04410_CTI_Distribution_Costs",
        "x_04420_CTI_Communication_Material",
        "x_04490_CTI_Other_Distribution_Communication_Client_Service_Charges",
        "x_05010_CTI_Incidental_Costs",
        "x_05020_CTI_Performance_Fees_Invoiced",
        "x_05030_CTI_Performance_Fees_From_NAV",
        "x_06010_CTI_Lending_And_Borrowing_Costs",
        "x_06020_CTI_Securities_Lending_Value_On_Loan",
        "x_06030_CTI_Gross_Securities_Lending_Revenue",
        "x_06040_CTI_Securities_Lending_Revenue_Portfolio_Share",
        "x_06050_CTI_Securities_Lending_Fees_Custodian_Share",
        "x_06060_CTI_Securities_Lending_Fees_Manager_Share",
        "x_06070_CTI_Securities_Lending_Fees_Agent_Share",
        "x_06080_CTI_Securities_Borrowing_Fees",
        "x_06100_CTI_Interest_On_Borrowing",
        "x_06110_CTI_Borrowing_And_Arrangement_Fees",
        "x_07010_CTI_Property_Expenses",
        "x_07020_CTI_Property_Management_Fees",
        "x_07030_CTI_Property_Leasing_Costs",
        "x_07040_CTI_Property_Maintenance_And_Repairs",
        "x_07050_CTI_Property_Utilities_And_Service_Charges",
        "x_07060_CTI_Property_Void_Costs",
        "x_07070_CTI_Property_Insurance_Costs",
        "x_07080_CTI_Property_Valuation_Fees",
        "x_07090_CTI_Property_Failed_Transaction_Costs",
        "x_07100_CTI_Indirect_Property_Expenses",
        "x_07110_CTI_Other_Property_Expenses",
        "x_08010_CTI_Gross_Return",
        "x_08020_CTI_Net_Return",
        "x_08110_CTI_Start_Asset_Value",
        "x_08120_CTI_End_Asset_Value",
        "x_08130_CTI_Portfolio_Purchases",
        "x_08140_CTI_Portfolio_Sales",
        "x_08150_CTI_Fund_Inflows",
        "x_08160_CTI_Fund_Outflows",
        "x_08210_CTI_LDI_PV01_Net_Traded",
        "x_08220_CTI_LDI_IE01_Net_Traded",
        "x_08230_CTI_LDI_PV01_Transaction_Cost",
        "x_08240_CTI_LDI_IE01_Transaction_Cost",
        "x_09010_CTI_Transaction_Costs_Methodology",
        "x_09020_CTI_Incidental_Costs_Methodology",
        "x_09030_CTI_Return_Methodology",
        "x_09040_CTI_Additional_Notes",
        "x_10000_CTI_Equities_Used",
        "x_10030_CTI_Explicit_Transaction_Taxes_Equities",
        "x_10040_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Equities",
        "x_10050_CTI_Explicit_Transaction_Related_Services_Equities",
        "x_10060_CTI_Explicit_Other_Charges_Equities",
        "x_10070_CTI_Implicit_Costs_Equities",
        "x_10080_CTI_Indirect_Transaction_Costs_Equities",
        "x_10100_CTI_Bonds_Used",
        "x_10130_CTI_Explicit_Transaction_Taxes_Bonds",
        "x_10140_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Bonds",
        "x_10150_CTI_Explicit_Transaction_Related_Services_Bonds",
        "x_10160_CTI_Explicit_Other_Charges_Bonds",
        "x_10170_CTI_Implicit_Costs_Bonds",
        "x_10180_CTI_Indirect_Transaction_Costs_Bonds",
        "x_10200_CTI_Pooled_Vehicle_Used",
        "x_10230_CTI_Explicit_Transaction_Taxes_Pooled",
        "x_10240_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Pooled",
        "x_10250_CTI_Explicit_Transaction_Related_Services_Pooled",
        "x_10260_CTI_Explicit_Other_Charges_Pooled",
        "x_10270_CTI_Implicit_Costs_Pooled",
        "x_10280_CTI_Indirect_Transaction_Costs_Pooled",
        "x_10300_CTI_ETD_Used",
        "x_10330_CTI_Explicit_Transaction_Taxes_ETD",
        "x_10340_CTI_Explicit_Broker_Commission_And_Exchange_Fees_ETD",
        "x_10350_CTI_Explicit_Transaction_Related_Services_ETD",
        "x_10360_CTI_Explicit_Other_Charges_ETD",
        "x_10370_CTI_Implicit_Costs_ETD",
        "x_10380_CTI_Indirect_Transaction_Costs_ETD",
        "x_10400_CTI_OTCD_Used",
        "x_10430_CTI_Explicit_Transaction_Taxes_OTCD",
        "x_10440_CTI_Explicit_Broker_Commission_And_Exchange_Fees_OTCD",
        "x_10450_CTI_Explicit_Transaction_Related_Services_OTCD",
        "x_10460_CTI_Explicit_Other_Charges_OTCD",
        "x_10470_CTI_Implicit_Costs_OTCD",
        "x_10480_CTI_Indirect_Transaction_Costs_OTCD",
        "x_10500_CTI_FX_Used",
        "x_10530_CTI_Explicit_Transaction_Taxes_FX",
        "x_10540_CTI_Explicit_Broker_Commission_And_Exchange_Fees_FX",
        "x_10550_CTI_Explicit_Transaction_Related_Services_FX",
        "x_10560_CTI_Explicit_Other_Charges_FX",
        "x_10570_CTI_Implicit_Costs_FX",
        "x_10580_CTI_Indirect_Transaction_Costs_FX",
        "x_10600_CTI_MMI_Used",
        "x_10630_CTI_Explicit_Transaction_Taxes_MMI",
        "x_10640_CTI_Explicit_Broker_Commission_And_Exchange_Fees_MMI",
        "x_10650_CTI_Explicit_Transaction_Related_Services_MMI",
        "x_10660_CTI_Explicit_Other_Charges_MMI",
        "x_10670_CTI_Implicit_Costs_MMI",
        "x_10680_CTI_Indirect_Transaction_Costs_MMI",
        "x_10700_CTI_Physical_Assets_Used",
        "x_10730_CTI_Explicit_Transaction_Taxes_Physical",
        "x_10740_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Physical",
        "x_10750_CTI_Explicit_Transaction_Related_Services_Physical",
        "x_10760_CTI_Explicit_Other_Charges_Physical",
        "x_10770_CTI_Implicit_Costs_Physical",
        "x_10780_CTI_Indirect_Transaction_Costs_Physical",
        "x_10800_CTI_Private_Assets_Used",
        "x_10830_CTI_Explicit_Transaction_Taxes_Private",
        "x_10840_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Private",
        "x_10850_CTI_Explicit_Transaction_Related_Services_Private",
        "x_10860_CTI_Explicit_Other_Charges_Private",
        "x_10870_CTI_Implicit_Costs_Private",
        "x_10880_CTI_Indirect_Transaction_Costs_Private",
        "x_10900_CTI_Other_Assets_Used",
        "x_10930_CTI_Explicit_Transaction_Taxes_Other",
        "x_10940_CTI_Explicit_Broker_Commission_And_Exchange_Fees_Other",
        "x_10950_CTI_Explicit_Transaction_Related_Services_Other",
        "x_10960_CTI_Explicit_Other_Charges_Other",
        "x_10970_CTI_Implicit_Costs_Other",
        "x_10980_CTI_Indirect_Transaction_Costs_Other",
        "x_12010_CTI_Start_Asset_Value_Equities",
        "x_12020_CTI_End_Asset_Value_Equities",
        "x_12030_CTI_Purchases_Equities",
        "x_12040_CTI_Sales_Equities",
        "x_12110_CTI_Start_Asset_Value_Bonds",
        "x_12120_CTI_End_Asset_Value_Bonds",
        "x_12130_CTI_Purchases_Bonds",
        "x_12140_CTI_Sales_Bonds",
        "x_12210_CTI_Start_Asset_Value_Pooled",
        "x_12220_CTI_End_Asset_Value_Pooled",
        "x_12230_CTI_Purchases_Pooled",
        "x_12240_CTI_Sales_Pooled",
        "x_12310_CTI_Start_Asset_Value_ETD",
        "x_12320_CTI_End_Asset_Value_ETD",
        "x_12410_CTI_Start_Asset_Value_OTCD",
        "x_12420_CTI_End_Asset_Value_OTCD",
        "x_12510_CTI_Start_Asset_Value_FX",
        "x_12520_CTI_End_Asset_Value_FX",
        "x_12610_CTI_Start_Asset_Value_MMI",
        "x_12620_CTI_End_Asset_Value_MMI",
        "x_12630_CTI_Purchases_MMI",
        "x_12640_CTI_Sales_MMI",
        "x_12710_CTI_Start_Asset_Value_Physical",
        "x_12720_CTI_End_Asset_Value_Physical",
        "x_12730_CTI_Purchases_Physical",
        "x_12740_CTI_Sales_Physical",
        "x_12810_CTI_Start_Asset_Value_Private",
        "x_12820_CTI_End_Asset_Value_Private",
        "x_12830_CTI_Purchases_Private",
        "x_12840_CTI_Sales_Private",
        "x_12910_CTI_Start_Asset_Value_Other",
        "x_12920_CTI_End_Asset_Value_Other",
        "x_12930_CTI_Purchases_Other",
        "x_12940_CTI_Sales_Other",
      ];
    } else if (api_name === 'PRIIPS_app_GetCEPTv2HistOutput') {
      regulation_name = 'CEPT_Hist_v2';
      output_columns = [];
      exportName = null;
    } else if (api_name === 'PRIIPS_app_CEPT_Perf_v2_Output') {
      regulation_name = 'CEPT_Perf_v2';
      output_columns = [
        'x_30001_CEPT_Version',
        'x_30010_Portfolio_Issuer_Name',
        'x_30020_Portfolio_Identifying_Data',
        'x_30030_Type_Of_Identification_Code_For_The_Fund_Share_Or_Portfolio',
        'x_30040_Portfolio_Or_Share_Class_Currency',
        'x_30050_PRIIPS_KID_Publication_Date',
        'x_30060_Portfolio_Name',
        'x_30070_Reporting_Date',
        'x_30080_Portfolio_PRIIPS_Category',
        'x_31010_Period_1',
        'x_31020_Period_2',
        'x_31030_HP_Period_3',
        'x_31050_Lump_Sum_Or_Regular_Premium_Indicator',
        'x_31060_Investment_amount',
        'x_31070_Calculation_Methodology_Used',
        'x_33010_Amount_Period_1_Unfavourable',
        'x_33020_Amount_Period_1_Moderate',
        'x_33030_Amount_Period_1_Favourable',
        'x_33040_Amount_Period_1_Stress_Scenario',
        'x_33050_Amount_Period_2_Unfavourable',
        'x_33060_Amount_Period_2_Moderate',
        'x_33070_Amount_Period_2_Favourable',
        'x_33080_Amount_Period_2_Stress_Scenario',
        'x_33090_Amount_Period_3_Unfavourable',
        'x_33100_Amount_Period_3_Moderate',
        'x_33110_Amount_Period_3_Favourable',
        'x_33120_Amount_Period_3_Stress_Scenario',
        'x_33130_Average_Return_Period_1_Unfavourable',
        'x_33140_Average_Return_Period_1_Moderate',
        'x_33150_Average_Return_Period_1_Favourable',
        'x_33160_Average_Return_Period_1_Stress_Scenario',
        'x_33170_Average_Return_Period_2_Unfavourable',
        'x_33180_Average_Return_Period_2_Moderate',
        'x_33190_Average_Return_Period_2_Favourable',
        'x_33200_Average_Return_Period_2_Stress_Scenario',
        'x_33210_Average_Return_Period_3_Unfavourable',
        'x_33220_Average_Return_Period_3_Moderate',
        'x_33230_Average_Return_Period_3_Favourable',
        'x_33240_Average_Return_Period_3_Stress_Scenario',
        'x_34010_Cost_Total_Period_1',
        'x_34020_Cost_Total_Period_2',
        'x_34030_Cost_Total_Period_3',
        'x_34040_Percentage_RIY_Period_1',
        'x_34050_Percentage_RIY_Period_2',
        'x_34060_Percentage_RIY_Period_3',
      ];
    }
    return {
      "regulation_name" : regulation_name, 
      "output_columns" : output_columns,
      "exportName" : exportName
    };
  }

  GetToolBarButtons = () => {
    let toolbarButtons = []
    if(this.state.api_name == "PRIIPS_app_GetCEPTv2HistOutput") {
      toolbarButtons.push(<GrpSelect
        items={[
          {
            display: (
              <GrpButton
                key={1}
                      onClick={() => this.makeExtractGeneratorRequest("CSV", "|","yyyy-MM-dd") }
                size="Small"
                type="Tertiary"
                text="CSV"
              />
            ),
            value: 1,
          },
          {
            display: (
              <GrpButton
                key={2}
                onClick={() => this.makeExtractGeneratorRequest("Excel",null,null) }
                size="Small"
                type="Tertiary"
                text="Excel"
              />
            ),
            value: 2,
          }
        ]}
        placeholder={{ display: 'Extract Approved', value: null }}
        alwaysDefault
      />)
    }
    toolbarButtons.push(userHasPermission(this.state.permissions, permissionTypes.approve) ? this.state.filterVal === 'Ready for Review' ? this.state.approveButton : this.state.reopenButton : null)
    return toolbarButtons
  }

  
  render() {
    return (
      <OutputView>
        <OutputBody>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        controls={this.GetToolBarButtons()}
                        processCellCallback={this.processCellCallback}
                        exportName={this.state.exportName}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                        externalFilterDropdown={{
                          title: 'Status:',
                          options: [
                            { label: 'Pending Approval', value: 'Ready for Review' },
                            { label: 'Approved', value: 'Approved' },
                          ],
                          defaultValue: { label: 'Pending Approval', value: 'Ready for Review' },
                        }}
                        externalFilterChanged={this.externalFilterChanged}
                        outputSeparator={'|'}
                        outputColumns={this.state.outputColumns}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={(this.state.level === 'EMTV31' ? this.state.emtv31Columns : this.state.emtv3Columns)}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                  externalFilter={this.doesExternalFilterPass}
                  pivotable
                />

              </>
            )
          }
        </OutputBody>
      </OutputView>
    );
  }
}

Output.contextType = AppContext;

export default Output;