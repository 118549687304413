import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClientSelection from './DrawerSelection/ClientSelection';
import ReportingDateSelection from './DrawerSelection/ReportingDateSelection';

export const Drawer = styled.div`
  position: fixed;
  right: ${(props) => (props.visible ? '0px' : '-500px')};
  top: 0;
  bottom: 0;
  width: 500px;
  background-color: #fff;
  transition: all 200ms ease-out;
  display: flex;
  flex-direction: column;
  z-index: 200;
  ${(props) => (props.visible && 'box-shadow: -5px 0 40px rgba(0, 0, 0, 0.15);')}
`;

export const Header = styled.header`
  height: 60px;
  display: flex;
  background-color: #6F08CD;
  color: #fff;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
`;

export const HeaderText = styled.h1`
  align-items: center;
  font-size: 1.5em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  margin: 0px;
`;

export const CloseButton = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  cursor: pointer;
  user-select: none;
`;

export const Body = styled.section`
  position: relative;
  height:100%;
  width:100%;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
`;

export const DrawerContext = styled.div`
  height: 60px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  border-top: 1px solid #d5d5d5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 10px;
`;

export const DrawerClientSelect = styled(ClientSelection)`
  flex: auto;
`;

export const DrawerDateSelect = styled(ReportingDateSelection)`
  flex: auto;
`;
