import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import range from 'lodash/range';
import CDATA from 'Services/CDATA';
import AppContext from 'AppContext';
import GrpButton from 'Library/GrpButton/GrpButton';
import 'Styles/SCSS/ag-theme-material.scss';
import 'react-datepicker/dist/react-datepicker.css';

class CreatePeriodModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_app_client_reporting_period',
      //   loading: true,
      //   failed: false,
      //   totalRowCount: 0,
      //   displayedRowCount: 0,
      selectedDate: new Date(),
      //   toolbarButtons: [<GrpButton key={0} onClick={() => {this.createReportingDatePrompt()}} size="Medium" type="Primary" text="Add Reporting Period" />]
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    // console.log(date);
    this.setState({
      selectedDate: date,
    });
  }

  async createReportingDate(reportingDate, clientCode) {
    // modal saying approving data
    const modalContent = <div><p>{`Creating ${reportingDate} as reporting period...`}</p></div>;
    this.context.handlers.setModal('Creating ...', modalContent, null, null, true);
    this.context.handlers.openModal();

    // actually making request
    // success closes the modal and redirects to the approval queue, adds a toast that says file x was successfully approved
    // failure removes the approval buttons? but doesn't redirect?
    try {
      await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_client_reporting_period',
        '',
        {
          ClientCode_bk: clientCode,
          Reporting_Period: reportingDate,
        },
        'We\'re sorry, there was an error creating this reporting period.',
      );

      this.context.handlers.closeModal();
      const toast = {
        type: 'success',
        body: <>You successfully created the reporting date of <span>{reportingDate}</span>.</>,
      };
      this.context.handlers.setToast(toast);
      this.props.refreshData();
    } catch (err) {
      // console.log(err);
      this.context.handlers.closeModal();
      const toast = {
        type: 'error',
        body: <>Error - something went wrong and we were not able to create the reporting date <span>{reportingDate}</span>.</>,
      };
      this.context.handlers.setToast(toast);
    }
  }

  render() {
    return (
      <div>
        <DatePicker
          renderCustomHeader={
            ({
              date,
              changeYear,
              changeMonth,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <select
                  value={date.toLocaleString('en-us', { month: 'long' })}
                  onChange={({ target: { selectedIndex } }) => { changeMonth(selectedIndex); }}
                >
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
                <select
                  style={{ marginLeft: 15 }}
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {
                    range(2016, date.getFullYear() + 4, 1).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))
                  }
                </select>
              </div>
            )
          }
          selected={this.state.selectedDate}
          // ^^fix this it doesn't update on the screen for some super annoying reason
          onChange={this.handleChange}
        />
        <GrpButton
          onClick={() => this.createReportingDate(new Date(this.state.selectedDate.getTime() - (this.state.selectedDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0], this.context.state.selectedClient.code)}
          size="Medium"
          type="Primary"
          text="Create"
        />
        <GrpButton
          onClick={() => this.context.handlers.closeModal()}
          size="Medium"
          type="Secondary"
          text="Cancel"
        />
      </div>
    );
  }
}

CreatePeriodModalContent.contextType = AppContext;

export default CreatePeriodModalContent;
