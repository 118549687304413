import React from 'react';
import PropTypes from 'prop-types';
import classes from './GrpButtonGroup.module.scss';

const cloner = (props) => {
  const content = props.children;
  const clonedContent = [];
  let i;
  for (i = 0; i < (content.length); i += 1) {
    if (i === 0) {
      clonedContent.push(
        React.cloneElement(content[0],
          {
            className: `${classes.GrpBGLeft}`,
            disabled: props.disabled,
            key: i,
            size: props.size,
            tabIndex: i,
            type: props.type,
          }),
      );
    } else if (i === (content.length - 1)) {
      clonedContent.push(
        React.cloneElement(content[i],
          {
            className: `${classes.GrpBGRight}`,
            disabled: props.disabled,
            key: i,
            size: props.size,
            tabIndex: i,
            type: props.type,
          }),
      );
    } else {
      clonedContent.push(
        React.cloneElement(content[i], {
          className: `${classes.GrpBGMid}`,
          disabled: props.disabled,
          key: i,
          size: props.size,
          tabIndex: i,
          type: props.type,
        }),
      );
    }
  }
  return (clonedContent);
};

const cssHelper = (className) => {
  const classNames = [`${classes.GrpButtonGroup}`];
  if (className) {
    classNames.push(className);
  }
  return classNames.join(' ');
};

/**
 * The `<GrpButtonGroup />` can take `x` number of `<GrpButton />` components
 * and assoiciate them as a joined Button group.
 * Size and type properties will be passed onto the child `<GrpButton />` Elements.
 */
const GrpButtonGroup = (props) => {
  const buttonArr = cloner(props);
  const passName = cssHelper(props.className);
  const content = (
    <div className={passName}>
      {buttonArr}
    </div>
  );

  return <>{content}</>;
};

GrpButtonGroup.defaultProps = {
  className: '',
  // size: 'Medium',
  // type: 'Primary',
};

GrpButtonGroup.propTypes = {
  className: PropTypes.string,
  // children: PropTypes.arrayOf(PropTypes.node).isRequired,
  // size: PropTypes.string,
  // type: PropTypes.string,
};

export default GrpButtonGroup;
