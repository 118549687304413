import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import FileRenderer from './fileRenderer';


const GrpFileBrowser = ({ rowData }) => {
  LicenseManager.setLicenseKey('CompanyName=Donnelley Financial, LLC,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=15,LicensedProductionInstancesCount=4,AssetReference=AG-011092,ExpiryDate=23_June_2022_[v2]_MTY1NTkzODgwMDAwMA==84055abc5b93544984e126141426f934');

  return (
    <div
      className="ag-theme-material"
      style={{
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
      }}
    >
      <AgGridReact
        animateRows
        autoGroupColumnDef={{
          headerNames: 'Files',
          width: 650,
          cellRendererParams: {
            checkbox: false,
            suppressCount: true,
            innerRenderer: 'fileRenderer',
          },
        }}
        columnDefs={[
          {
            headerName: 'Date Modified',
            field: 'dateModified',
            sort: 'desc',
            sortable: true,
            comparator(d1, d2) {
              return new Date(d1).getTime() < new Date(d2).getTime() ? -1 : 1;
            },
          },
          {
            headerName: 'Size',
            field: 'size',
            sortable: true,
            aggFunc: 'sum',
            valueFormatter(params) {
              if (params.value > 1000000) {
                return params.value ? `${Math.round(params.value / 1e+6)} MB` : '0 MB';
              }
              return params.value ? `${Math.round(params.value / 1000)} kB` : '0 bytes';
            },
          },
        ]}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
        }}
        frameworkComponents={{
          fileRenderer: FileRenderer,
        }}
        getDataPath={(data) => data.FilePath}
        headerHeight={40}
        pagination={false}
        reactNext
        rowData={rowData}
        rowHeight={45}
        treeData
        rowSelection="multiple"
        enableRangeSelection
        suppressCopyRowsToClipboard
      />
    </div>
  );
};

GrpFileBrowser.defaultProps = {
  rowData: [{}],
};

GrpFileBrowser.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.shape({

  })),
};

export default GrpFileBrowser;
