import React, { Component } from 'react';
import { LoadingSpinner } from '../../App.styles';

class NoAccess extends Component {
    render() {
        if (window.location.search.includes("callback")) {
            return (<LoadingSpinner text="Loading app..." />); 
        } else {
            return (<span>User does not have <label title={this.props.rule}>access</label> to this page</span>); 
        }
    };
}

export default NoAccess;