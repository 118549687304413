import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import GrpButton from '../GrpButton/GrpButton';

function GrpDropFile(zone) {
  const zoneStyle = {
    dropzone: {
      minHeight: 200,
      maxHeight: 250,
      backgroundColor: 'rgb(255, 255, 255)',
      overflow: 'hidden',
      borderRadius: '10px',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 15px',
      // marginBottom: '20px',
      borderWidth: '0px',
    },
    inputLabel: {
      userSelect: 'none', /* Standard */
      color: 'rgb(111,8,205)',
      // textColor: '#F2F1F6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '50px',
      fontFamily: 'Helvetica, sans-serif',
      fontSize: '20px',
      fontWeight: '600',
    },
    inputLabelWithFiles: {
      fontSize: '20px',
      color: '#6F08CD',
    },
    preview: {
      div: {
        color: 'green',
        backgroundColor: 'green',
      },
    },
    submitButton: {
      backgroundColor: '#6F08CD',
      color: '#F2F1F6',
    },
  };

  return (
    <div>
      <Dropzone
        id={zone.zone}
        onDrop={zone.handleInputChange}
        accept={zone.acceptedFiles}
        maxFiles={zone.maxFiles}
        multiple
      >
        {
          ({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
              style={zoneStyle.dropzone}
            >
              <input {...getInputProps()} />
              <label style={zoneStyle.inputLabel}><p>{zone.message}</p></label>
            </div>
          )
        }
      </Dropzone>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        <GrpButton
          key={0}
          onClick={zone.handleSubmit}
          size="Medium"
          type="Primary"
          text="Submit"
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        <label style={zoneStyle.inputLabelWithFiles}>
          <p>{zone.queuedFileMessage}</p>
        </label>
      </div>
    </div>
  );
}

export default GrpDropFile;
