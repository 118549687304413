import styled from 'styled-components';
import GrpDropzone from '../../Library/GrpDropzone/GrpDropzone';

export const Container = styled.div`
  display: flex;
  padding: 25px;
  flex-direction: column;
  flex: 1;
`;

export const Dropzone = styled(GrpDropzone)`

`;

export const ImportFileHeader = styled.b`
  color: rgb(45, 35, 90);
`;

export const TemplateLink = styled.a`
  :hover { 
    color: #6F08CD;
  }
`;
