import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ToggleButton = styled.div`
  color: #57576B;
  font-size: 18px;
  cursor: pointer;
  margin: 1rem;
`;

export const ApproveToggle = styled(ToggleButton)`
  color: ${(props) => (props.active ? '#2EC4B6 ' : '#57576B')};
  &:hover {
    color: #29939b;
  }
`;

export const RejectToggle = styled(ToggleButton)`
  color: ${(props) => (props.active ? '#EE4266' : '#57576B')};
  &:hover {
    color: #b43562;
  }
`;

const approvalCellRenderer = ({ value, data }) => {
  const [isApproved] = useState(value); // setApproved
  const [hasLink, setHasLink] = useState(null);

  useEffect(() => {
    data && setHasLink(data.PDFLink);
  }, [data]);

  return (
    hasLink && (
      <Container>
        <ApproveToggle active={isApproved === 100} type="radio">
          <FontAwesomeIcon icon={['fas', 'file-check']} />
        </ApproveToggle>
        <RejectToggle active={isApproved === 90} type="radio">
          <FontAwesomeIcon icon={['fas', 'file-times']} />
        </RejectToggle>
      </Container>
    )
  );
};

export default approvalCellRenderer;

approvalCellRenderer.propTypes = {
  isApproved: PropTypes.string,
};
