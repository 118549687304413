import React, { Component } from 'react';
import Viewer from '../Viewer/Viewer';
import AppContext from '../../AppContext';

class InsPublishLog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      post_data: {
        ClientCode: this.context.state.selectedClient.code,
        Reference_Date: this.context.state.selectedReportingDate.code,
      },
    });
  }

  render() {
    return (
      this.state.post_data ? <Viewer {...this.props} post_data={this.state.post_data} /> : <></>
    );
  }
}

InsPublishLog.contextType = AppContext;

export default InsPublishLog;
