/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import Select from 'react-select';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import APIM from 'Services/APIM';
import CDATA from 'Services/CDATA';
import {
  Button, DashboardView, DashboardBody, Error, Grid, Spinner, Toolbar,
} from './RPDashboard.styles';
import AppContext from 'AppContext';
import NavLinkButton from 'Library/GrpGrid/NavLinkButton/NavLinkButton';
import 'Styles/SCSS/ag-theme-material.scss';
import { renderWithPermissions } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants';

const EditButton = (data) => (data.status === 'Not Started' ? 'Create' : 'Edit');
const linkData = (data, path) => ({
  pathname: path,
  classcode_bk: data.classcode_bk,
  language: data.iso_languages,
  fundcode_bk: data.fundcode_bk,
  of_full_share_class_name: data.of_full_share_class_name,
  action_type: 'Edit',
  regulation_name: 'DCPT',
});
const EditButtonLink = (data) => (data ? linkData(data, '/regulatory-reporting/DCPTedit') : null);

let PermissionPrefix = '';

class TCdcptDasboard extends Component {
  constructor(props) {
    super(props);
    this.PermissionPrefix = props.location.pathname.toLowerCase().endsWith('dcpt') ? 'dashboard/' : '';
    this.state = {
      api_name: this.props.api_name,
      displayedRowCount: 0,
      error_or_warning: 'Error',
      failed: false,
      loading: true,
      page: window.location.pathname,
      redirect: false,
      regulation_name: 'DCPT',
      totalRowCount: 0,
      toolbarButtons: [
        renderWithPermissions(() => (
          <Button
            key={0}
            onClick={() => { this.create(); }}
            size="Medium"
            type="Secondary"
            text="Create DCPT in Bulk"
          />
        ), this.PermissionPrefix + permissionTypes.create, () => <></>),
        renderWithPermissions(() => (
          <Button
            key={1}
            onClick={() => { this.populateRuleReg(); }}
            size="Medium"
            type="Secondary"
            text="Rule Regulation Population"
          />
        ), this.PermissionPrefix + permissionTypes.generate, () => <></> ),
        <Button
          onClick={() => { this.refresh(); }}
          icon={['fas', 'sync']}
          iconSide="Round"
          size="Small"
        />,
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);

      if (this.state.api_name === 'PRIIPS_app_VALIDATION_DASHBOARD') {
        columnDefs.unshift({
          field: 'Edit',
          headerName: 'Create/Edit',
          cellRendererFramework: (props) => (
            props.data.status === 'Not Started' ? (
              <Button
                className="Secondary"
                size="Small"
                text={<div style={{color: '#8700FF'}}>Create</div>}
                onClick={() => { this.createDCPT(props.data.classcode_bk); }}
              />
            ) : (
              <>
                <NavLinkButton
                  {...props}
                  functionToGenerateContent={() => (props.data.status === 'Approved' ? 'View ' : 'Edit ')}
                  functionToGenerateLink={EditButtonLink}
                />
              </>
            )
          ),
          sortable: true,
          cellClass: 'ag-cell',
          valueGetter: (props) => EditButton(props.data),
          enablePivot: false,
          enableRowGroup: false,
          enableValue: false,
        });

        columnDefs.unshift({
          field: 'SelectRow',
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sortable: false,
          maxWidth: 45,
          resizable: false,
        });
      }

      this.setState({ columnDefs, rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const payload = {
        clientcode: this.context.state.selectedClient.code,
        reportingperiod: this.context.state.selectedReportingDate.code,
        ruleregulation: this.state.regulation_name,
      };
      if (this.state.api_name !== 'PRIIPS_app_VALIDATION_DASHBOARD') {
        payload.resulttodisplay = this.state.error_or_warning;
      }
      const data = await CDATA.makeRequest(
        'POST',
        this.state.api_name,
        '',
        payload,
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();
      this.setState({ loading: false, totalRowCount });
      if (this.state.selectedValue) { 
        const selectedValue = this.state.selectedValue; 
        this.setState(selectedValue); 
      }
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  populateRuleReg = async () => {
    try {
      const ruleReportType = this.state.regulation_name;
      const data = {
        procedure: 'app.PopulateRuleRegulationDashboard',
        clientcode: this.context.state.selectedClient.code,
        parameters: {
          ClientCode: this.context.state.selectedClient.code,
          ReportingPeriod: this.context.state.selectedReportingDate.code,
          RuleRegulation: ruleReportType,
          FundCode: null,
          ClassCode: null
        },
      };

      APIM.makeRequest(
        'transaction',
        JSON.stringify(data),
        'Error in Rule Regulation Population for ' + ruleReportType + '.',
        Auth0SessionInfo.GetSessionInfo().accessToken,
      )
        .then((res) => {
          const toast = {
            type: 'success',
            body: (
              <>
                {'You successfully queued Rule Regulation Population for ' + ruleReportType + '.'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
          this.setState({ monitorUrl: res.statusQueryGetUri , monitorType: 'Rule Regulation Population for ' + ruleReportType});
          //this.refreshData();
        })
        .catch((error) => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'error',
            body: (
              <>
                Error - something went wrong and we were not able to run Rule Regulation Population
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });

    } catch(err) {
      console.log(err);
    }
  };

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    autoHeight: true,
    cellStyle: {
      'whiteSpace': 'normal',
      'lineHeight': '24px',
      padding: '12px',
      maxHeight: '72px',
    },
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    // this is if you want to do a default sort for a specific column
    sort: x.dv_column.toLowerCase() === 'classcode_bk' ? {
      direction: 'desc',
      priority: 0,
    } : null,
    wrapText: true,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  create = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            {`Are you sure you want to create the DCPT for ${listOfRows.length} row(s)?`}
          </p>
          <Button
            onClick={() => this.createDCPTBulk(true)}
            size="Medium"
            type="Primary"
            text="Update All Fields"
          />
          <Button
            onClick={() => this.createDCPTBulk(false)}
            size="Medium"
            type="Primary"
            text="Update Only Calculated"
          />
          <p style={{ paddingTop: 10 }}>
            <Button
              onClick={() => this.context.handlers.toggleModal()}
              size="Medium"
              type="Primary"
              text="Cancel"
            />
          </p>
        </div>
      );
      this.context.handlers.setModal('Create DCPT', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to create the DCPT.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  handleSelect = (selectedValue) => {
    this.setState({
      error_or_warning: selectedValue.label === 'All Warnings' ? 'Warning' : 'Error',
      api_name: selectedValue.value,
      selectedValue: selectedValue,
      loading: true,
    }, () => { this.refresh(); });
  }

  createDCPTBulk = (update) => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimClassCodes = listOfRows.map((row) => row.classcode_bk).join(',');
    // const delimCategory = listOfRows.map((row) => row.category);
    const modalContent = <div><p>{`Creating DCPT for ${listOfRows.length} row(s)...`}</p></div>;
    this.context.handlers.setModal('Creating DCPT ...', modalContent, null, null, true);
    CDATA.makeRequest(
      'POST',
      'PRIIPS_app_CREATE_DCPT_BULK',
      '',
      {
        ClientCode_bk: this.context.state.selectedClient.code,
        Reference_Date: this.context.state.selectedReportingDate.code,
        ClassCodeList: delimClassCodes,
        UserName: this.context.state.userEmail,
        UpdateAll: update,
      },
      'Create failed.'
    )
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You have successfully created the DCPT for '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.refresh();
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to create DCPT for the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  async createDCPT(classcode_bk) {
    const modalContent = (
      <div>
        <p>{`Creating DCPT for ${classcode_bk} ...`}</p>
      </div>
    );
    this.context.handlers.setModal('Creating ...', modalContent, null, null, true);
    this.context.handlers.toggleModal();
    try {
      await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_CREATE_DCPT_BULK',
        '',
        {
          ClientCode_bk: this.context.state.selectedClient.code,
          Reference_Date: this.context.state.selectedReportingDate.code,
          ClassCodeList: classcode_bk,
          UserName: this.context.state.userEmail,
          UpdateAll: true,
        },
        'We\'re sorry, there was an error accepting the data and merging it into the application.',
      );
      this.context.handlers.closeModal();
      const toast = {
        type: 'success',
        body: (
          <>
            {'You successfully created the DCPT for '}
            <span>{classcode_bk}</span>
            .
          </>
        ),
      };
      this.context.handlers.setToast(toast);
      this.refresh();
      //this.setState({ loading: false, redirect: true });
    } catch (err) {
      this.context.handlers.closeModal();
      const toast = {
        type: 'error',
        body: (
          <>
            {'Error - something went wrong and we were not able to create the DCPT for '}
            <span>{classcode_bk}</span>
            .
          </>
        ),
      };
      this.context.handlers.setToast(toast);
    }
  }

  async refresh() {
    await this.setState({ loading: true }, () => this.getMetadata());
    await await this.getData();
  }

  render() {
    return (
      <DashboardView>
        <DashboardBody>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <Spinner text="Loading data..." /> : (
                    this.gridApi && (
                      <Toolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        exportName={this.state.api_name}
                        controls={this.state.api_name === 'PRIIPS_app_VALIDATION_DASHBOARD' ? this.state.toolbarButtons : null}
                        dropdown={(
                          <Select
                            isSearchable
                            options={[
                              { label: 'Share Class Dashboard', value: 'PRIIPS_app_VALIDATION_DASHBOARD' },
                              { label: 'Business Rule Dashboard', value: 'PRIIPS_app_VALIDATION_BY_RULE' },
                              { label: 'All Errors', value: 'PRIIPS_app_VALIDATION_RESULT_VIEW' },
                              { label: 'All Warnings', value: 'PRIIPS_app_VALIDATION_RESULT_VIEW' },
                            ]}
                            // components={{ Option: CustomOption }}
                            value={this.state.selectedValue}
                            ignoreAccents={false}
                            // filterOption={createFilter({ignoreAccents: false})}
                            onChange={this.handleSelect}
                            isOptionSelected={(option, selectedValue) => {return selectedValue.some((i) => i.label == option.label)}}
                          />
                        )}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }
                <Grid
                  apiName={this.state.api_name}
                  columnDefs={this.state.columnDefs}
                  onGridReady={this.onGridReady}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                  rawColumnData={this.state.rawColumns}
               // autoGroupColumnDef = {this.state.autoGroupColumnDef}
               // treeData={this.props.treeData}
                />
              </>
            )
          }
        </DashboardBody>
      </DashboardView>
    );
  }
}

TCdcptDasboard.contextType = AppContext;

export default TCdcptDasboard;
