import React from 'react';
import ComplexStatus from '../../../../Library/GrpGrid/ComplexStatus/ComplexStatus';

const stateCell = (props) => {
  const getStatus = (state) => {
    if (state === 'Failed') return 3;
    if (state === 'Succeeded') return 2;
    if (state === 'In queue' || state === 'Generating') return 1;
    if (state === 'Ungenerated') return 0;
    return null;
  }

  return (
    <ComplexStatus
      statusValue={getStatus(props.value)}
      textValue={props.value}
      linkValue={props.data && props.data.PDFLink}
    />
  )
}

export default stateCell;
