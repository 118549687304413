import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Header, Body, DrawerContext,
  HeaderText, CloseButton,
} from './HomeDrawer.styles';

const HomeDrawer = ({
  children, title, message, toggleDrawer, visible,
}) => (
  <Drawer
    visible={visible}
  >
    <Header>
      <HeaderText>{title}</HeaderText>
      <CloseButton
        onClick={toggleDrawer}
        icon={['fal', 'times']}
      />
    </Header>
    <Body>
      {children}
    </Body>
    {
      message && (
        <DrawerContext>
          {message}
        </DrawerContext>
      )
    }
  </Drawer>
);

HomeDrawer.defaultProps = {
  message: null,
  children: null,
};

HomeDrawer.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string,
  children: PropTypes.element,
};

export default HomeDrawer;
