import styled from 'styled-components';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpButtonGroup from 'Library/GrpButton/GrpButtonGroup/GrpButtonGroup';
import GrpDropzone from 'Library/GrpDropzone/GrpDropzone';
import GrpError from 'Components/GrpError/GrpError';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';

export const Button = styled(GrpButton)``;

export const ButtonGroup = styled(GrpButtonGroup)``;

export const DashboardBody = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const DashboardView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Dropzone = styled(GrpDropzone)``;

export const Error = styled(GrpError)``;

export const Grid = styled(GrpGrid)``;

export const Spinner = styled(GrpSpinner)``;

export const Toolbar = styled(GrpToolbar)``;
