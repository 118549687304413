/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import CDATA from '../../Services/CDATA';
import classes from '../File/Sublevels/Grid.module.scss';
import AppContext from '../../AppContext';
import GrpToolbar from '../../Library/GrpToolbar/GrpToolbar';
import GrpGrid from '../../Library/GrpGrid/GrpGrid';
import GrpSpinner from '../../Components/GrpSpinner/GrpSpinner';
import GrpError from '../../Components/GrpError/GrpError';
import '../../Styles/SCSS/ag-theme-material.scss';
import GrpButton from '../../Library/GrpButton/GrpButton';
import CreatePeriodModalContent from './CreatePeriodModalContent';


class ManageReportingPeriods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: 'PRIIPS_app_client_reporting_period',
      loading: true,
      failed: false,
      totalRowCount: 0,
      displayedRowCount: 0,
      toolbarButtons: [
        <GrpButton
          key={0}
          onClick={() => { this.createReportingDatePrompt(); }}
          size="Medium"
          type="Primary"
          text="Add Reporting Period"
        />,
      ],
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      this.setState({ columnDefs: this.mapColumns(columns.value), rawColumns: columns.value });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      const data = await CDATA.makeRequest(
        'GET',
        this.state.api_name,
        `${CDATA.addFilter(`ClientCode_bk eq '${this.context.state.selectedClient.code}'`)}&${CDATA.addOrderBy('Reporting_Period desc')}`,
        {},
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: false,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
    // this is if you want to do a default sort for a specific column
    // sort: this.props.defaultGridSort !== undefined && this.props.defaultGridSort.toLowerCase() === x.dv_column.toLowerCase() ? {
    //   direction: 'desc',
    //   priority: 0
    // } : null,
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  deletePrompt = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const modalContent = (
        <div>
          <p>
            Are you sure you want to delete {listOfRows.length} {' '} row(s)?
          </p>
          <GrpButton onClick={() => this.deleteRecords()} size="Medium" type="Primary" text="Delete" />
          <GrpButton onClick={() => this.context.handlers.toggleModal()} size="Medium" type="Secondary" text="Cancel" />
        </div>
      );
      this.context.handlers.setModal('Delete', modalContent, null, null, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to delete.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  deleteRecords = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const delimRecords = listOfRows.map((row) => row.RECORD_ID.toString()).join(',');

    const modalContent = (
      <div>
        <p>
          {`Deleting ${listOfRows.length} rows(s)...`}
        </p>
      </div>
    );
    this.context.handlers.setModal('Deleting ...', modalContent, null, null, true);

    CDATA.makeRequest('POST',
      'PRIIPS_app_DELETE_BULK',
      '',
      {
        RecordIDList: delimRecords,
        UserName: this.context.state.userEmail,
        UserID: this.context.state.userAzureID,
        ClientCode_bk: this.context.state.selectedClient.code,
        SchemaName: 'app',
        TableName: 'client_reporting_period',
      },
      'Delete failed.')
      .then(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You successfully deleted '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.gridApi.updateRowData({ remove: listOfRows });
        this.setState({ totalRowCount: (this.state.totalRowCount - listOfRows.length) });
      })
      .catch((error) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              Error - something went wrong and we were not able to delete the
              {' '}
              <span>{listOfRows.length}</span>
              {' '}
              row
              {listOfRows.length > 1 ? 's' : ''}
              {' '}
              you had selected.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        // console.log('delete error: ',error);
      });
  }

  createReportingDatePrompt() {
    this.context.handlers.setModal(
      'Create Reporting Period',
      <CreatePeriodModalContent refreshData={this.refreshData.bind(this)} />,
      false,
    );
    this.context.handlers.toggleModal();
  }

  refreshData() {
    this.setState({ loading: true }, () => {this.getData()} );
  }


  render() {
    return (
      <div className={classes.Grid}>
        <div className={classes.GridBody}>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        deleter={this.deletePrompt}
                        leftControls={this.state.toolbarButtons}
                        exportName={this.state.api_name}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  onModelUpdated={this.onModelUpdated}
                />
              </>
            )
          }
        </div>
      </div>
    );
  }
}

ManageReportingPeriods.contextType = AppContext;

export default ManageReportingPeriods;
