import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select, Caret, Display, SelectList, Item,
} from './GrpSelect.styles';

/**
 * `<GrpSelect />` is a multi-select "drop-down" control.
 * It can be used with an array of text based elemets with any desired value.
 * The value can be a primitive type or a custom type.
 * The display can be basic text or complex HTML/JSX.
 *
 * The styles of the HTML or JSX selection can actually "travel" with the use of in-line styles
 * by injecting the HTML/JSX value of the selected item.
 * This is demonstrated in the second control in the demo that is labled "Complex HTML/JSX Select".
 */
class GrpSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openState: '',
      value: {
        display: '',
      },
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.setState({ value: this.props.placeholder,
        alwaysDefault: this.props.alwaysDefault,
        disabled: this.props.disabled });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // close listBox on ClickOutside
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.openState === 'open') {
        this.setState({ openState: '' });
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  clicked = () => {
    this.setState((prevState) => {
      if (prevState.openState === 'open') {
        return { openState: '' };
      }
      return { openState: 'open' };
    });
  };

  itemClicked = (e, item) => {
    e.stopPropagation();
    this.setState({ value: item }, () => {
      if (this.props.onChange) {
        this.props.onChange(item);
      }
      this.clicked();
    });
  };

  render() {
    return (
      <Select
        sublevel={this.props.sublevel}
        name="select"
        role="menu"
        tabIndex={0}
        onClick={this.clicked}
        onKeyPress={this.clicked}
        disabled={this.state.disabled}
      >
        <Caret
          icon={['fal', 'angle-down']}
        />
        <Display
          sublevel={this.props.sublevel}
        >
          {this.state.alwaysDefault ? this.props.placeholder.display : this.state.value.display}
        </Display>
        <SelectList
          ref={this.setWrapperRef}
          sublevel={this.props.sublevel}
          openState={this.state.openState}
        >
          {
            this.props.items && this.props.items.map((item) => (
              <Item
                sublevel={this.props.sublevel}
                name="select-item"
                key={item.value}
                role="button"
                tabIndex={0}
                onClick={(e) => { this.itemClicked(e, item); }}
                onKeyPress={(e) => { this.itemClicked(e, item); }}
              >
                {item.display}
              </Item>
            ))
          }
        </SelectList>
      </Select>
    );
  }
}

GrpSelect.defaultProps = {
  items: PropTypes.shape({
    display: '',
    value: '',
  }),
  onChange: () => { },
  placeholder: PropTypes.shape({ value: '', display: '' }),
  disabled: false,
  sublevel: false,
  alwaysDefault: false,
};

GrpSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      value: PropTypes.any,
    }),
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.shape({
    value: PropTypes.string,
    display: PropTypes.string,
  }),
  sublevel: PropTypes.bool,
  alwaysDefault: PropTypes.bool,
  disabled: PropTypes.bool
};

export default GrpSelect;
