import styled, { keyframes } from 'styled-components';

// const offset = 187;
// const duration = '1.2s';
const rotator = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`;

const colors = keyframes`
  0% { stroke: #c0c0c0; }
  50% { stroke: rgb(95, 42, 163); }
`;

const dash = keyframes`
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform:rotate(450deg);
  }
`;

export const Name = styled.div`
  width: 100%;
  text-align: center;
  user-select: none;
`;

export const SpinnerBox = styled.span`
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
`;

export const SpinnerBoxWrapper = styled.span`
  background: #F2F1F6;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const Spinner = styled.svg`
  margin-left: 25px;
  margin-bottom: 15px;
  animation: ${rotator} 1.2s linear infinite;
`;

export const Path = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    ${dash} 1.2s ease-in-out infinite, 
    ${colors} 4.8s ease-in-out infinite;
`;
