/* eslint-disable class-methods-use-this */
import axios from 'axios';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import config from '../config';
import Auth0SessionInfo from '../Services/Auth0SessionInfo';
// import permissionsApi from '../Services/permissionsApi';

const { encKey } = config.sessionStorage;

const baseURLvar = config.cdata.url;
const API = axios.create({
    baseURL: baseURLvar,
    timeout: 900000
});

class CDATA {
    makeRequest(method, endpoint, urlParams, body, errorMessage, skip, pageSize) {
        if (!urlParams) {
            urlParams = '';
        }

        const appStateInSession = sessionStorage.getItem('AppStateStorage');
        const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));

        // Usually, the clientCode is NOT explicitly specified, and will be obtained from the app state.
        // However, there are a few API calls which bootstrap the application and occur before a client is selected.
        const clientCode = appState?.selectedClient?.code ?? appState.availableClientCodeAndNames[0].code;

        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': `Bearer ${Auth0SessionInfo.GetSessionInfo().accessToken}`,
            'client_code': clientCode
        }

        return new Promise(async (resolve, reject) => {
            try {
                const baseUrl = `${endpoint}/?${this.addCount()}&${urlParams}`;
                const skipParam = (typeof skip === 'number' && skip > 0) ? `&$skip=${skip}` : '';
                const pageSizeParam = (typeof pageSize === 'number' && pageSize > 0) ? `&$top=${pageSize}` : '';

                const url = baseUrl + skipParam + pageSizeParam;
                const response = await API.request(url, { method, data: body, headers });

                // Support new response object type `WebApiGenericReturn`
                if (response.data.value || response.data.Content) {
                    resolve(response.data);
                } else if (method === 'PUT' && response.status === 204) {
                    resolve(response.data);
                } else if ((method === 'DELETE' || method === 'POST') && (response.status === 200 || response.status === 201)) {
                    resolve(response.data);
                } else {
                    try {
                        const errorLogResponse = await API.request(
                            'PRIIPS_logging_app_error',
                            {
                                method: 'POST',
                                data: {
                                    ClientCode_bk: appState.selectedClient.code !== null ? appState.selectedClient.code : 'UNK',
                                    mail: appState.userEmail,
                                    app_location: window.location.pathname,
                                    api_action: endpoint,
                                    url: `${endpoint}/?${this.addCount()}&${urlParams}`,
                                    action_parameters: JSON.stringify(body),
                                    error_message: `No valid response from request to ${endpoint} with (${urlParams}) as url parameters, (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
                                    additional_info: errorMessage,
                                },
                                headers
                            });
                        reject({
                            human: errorMessage,
                            errorID: errorLogResponse.data.RECORD_ID,
                            machine: `No valid response from request to ${endpoint} with (${urlParams}) as url parameters, (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
                        });
                    } catch {
                        reject({
                            human: errorMessage,
                            machine: `No valid response from request to ${endpoint} with (${urlParams}) as url parameters, (${method}) as the method and (${JSON.stringify(body)}) as the request body.`,
                        });
                    }
                }
            } catch (err) {
                try {
                    const errorLogResponse = await API.request(
                        'PRIIPS_logging_app_error',
                        {
                            method: 'POST',
                            data: {
                                ClientCode_bk: appState.selectedClient.code !== null ? appState.selectedClient.code : 'UNK',
                                mail: appState.userEmail,
                                app_location: window.location.pathname,
                                api_action: endpoint,
                                url: `POST ${endpoint}/?${this.addCount()}&${urlParams}`,
                                action_parameters: JSON.stringify(body),
                                error_message: err.message,
                                additional_info: errorMessage,
                            },
                            headers
                        });
                    reject({
                        human: errorMessage,
                        errorID: errorLogResponse.data.RECORD_ID,
                        machine: err,
                    });
                } catch {
                    reject({
                        human: errorMessage,
                        machine: err,
                    });
                }
            }
        });
    }

    addFilter(value) {
        return `$filter=${encodeURIComponent(value)}`
    }

    addGroupBy(value) {
        return `$apply=groupby((${encodeURIComponent(value)}))`;

    }
    addSelect(value) {
        return `$select=${encodeURIComponent(value)}`
    }

    addOrderBy(value) {
        return `$orderby=${encodeURIComponent(value)}`
    }

    addCount() {
        return "$count=true";
    }

    async getAllDataChunks(method, endpoint, urlParams, body, errorMessage, pageSize) {
        const allData = [];
        let skip = 0;
        let moreData = true;
        while (moreData) {
            const response = await this.makeRequest(method, endpoint, urlParams, body, errorMessage, skip, pageSize);

            const chunk = (response && response['value']) ? response['value'] : response['Content'];
            moreData = chunk && chunk.length > 0;
            if (moreData) {
                allData.push(...chunk);
                skip += pageSize;
            }
        }
        return allData;
    }
}

export default new CDATA();
