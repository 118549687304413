/* eslint-disable no-alert */
import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileDownload from 'js-file-download';
import config from '../../config';
import CryptoAES from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import sasTokenApi from '../../Services/sasTokenApi';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';

function getIcon(filename) {
  if (filename.endsWith('.txt') || filename.endsWith('.csv')) {
    return {
      shape: ['far', 'file'],
      color: 'blue',
    };
  }
  if (filename.endsWith('.xlsx')) {
    return {
      shape: ['far', 'file-excel'],
      color: 'green',
    };
  }
  if (filename.endsWith('.pdf')) {
    return {
      shape: ['far', 'file-pdf'],
      color: 'red',
    };
  }
  if (filename.endsWith('.zip')) {
    return {
      shape: ['far', 'file-archive'],
      color: 'grey',
    };
  }
  return {
    shape: ['far', 'folder'],
    color: 'darkorange',
  };
}

class fileRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loading: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = async (event) => {
    const dlname = this.props.data.Suffix;
    let url = event.target.id;

    const { encKey } = config.sessionStorage;
    const appStateInSession = sessionStorage.getItem('AppStateStorage');
    const appState = appStateInSession && JSON.parse(CryptoAES.decrypt(appStateInSession.toString(), encKey).toString(CryptoEnc));
    var storage = sasTokenApi.splitStorageUrl(url);
    const sasToken = await sasTokenApi.get(storage.account, storage.container, null, Auth0SessionInfo.GetSessionInfo().accessToken);
    url += `?${sasToken}`;

    if (this.state.loading === false) {
      this.setState({ loading: true }, () => {
        axios.request(
          url,
          {
            method: 'GET',
            headers: {
              'x-ms-version': '2018-03-28',
            },
            responseType: 'blob',
          },
        )
          .then((res) => {
            fileDownload(res.data, dlname);
            this.setState({ loading: false });
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (error.res) {
              alert(`Code: ${error.response.data.error.code}\r\nMessage: ${error.response.data.error.message}`);
            } else {
              alert(`Error: ${error.message}`);
            }
          });
      });
    }
  }

  render() {
    const { loading } = this.state;
    const icon = getIcon(this.state.value);

    return (
      <span>
        {
          loading ? (
            <FontAwesomeIcon
              className="loading-icon fa fa-pulse"
              icon={['fas', 'spinner']}
              style={{
                color: 'black',
                fontSize: '14px',
                marginRight: '10px',
              }}
            />
          ) : (
            <FontAwesomeIcon
              className="file-icon"
              icon={icon.shape}
              style={{
                color: icon.color,
                fontSize: '14px',
                marginRight: '10px',
              }}
            />
          )
        }
        {' '}
        {(
          this.state.value.endsWith('.txt') || this.state.value.endsWith('.xlsx') || this.state.value.endsWith('.csv')
          || this.state.value.endsWith('.pdf') || this.state.value.endsWith('.zip')
        ) ? (
          <span
            className="file-name"
            id={`https://${this.props.data.account}.blob.core.windows.net/${this.props.data.outputContainer}/${this.props.data.Name}`}
            onClick={this.handleClick}
            onKeyPress={this.handleClick}
            role="button"
            style={{ fontSize: '12px' }}
            tabIndex={0}
          >
            {this.state.value}
          </span>
          ) : (
            <span
              className="folder-name"
              style={{ fontSize: '12px' }}
            >
              {this.state.value}
            </span>
          )}
      </span>
    );
  }
}


export default fileRenderer;
