/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import axios from 'axios';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';
// import PropTypes from 'prop-types';
import AppContext from '../../AppContext';
import {
  Container, FileBrowserContainer, FileBrowser, Error, Spinner,
} from './Exports.styles';
import config from '../../config';
import sasTokenApi from '../../Services/sasTokenApi';
import Auth0SessionInfo from '../../Services/Auth0SessionInfo';

const account = config.blob.pdfaccount;

class Exports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blobArray: [],
      loading: true
    };
    this.fetchData = this.listContainerBlobs.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  listContainerBlobs = async () => {
    const container = this.context.state.selectedClient.code.toLowerCase();
    const sasToken = await sasTokenApi.get(account, container, null, Auth0SessionInfo.GetSessionInfo().accessToken);
    let blobArray = [];
      axios({
        method: 'GET',
        url: `https://${account}.blob.core.windows.net/${container}?${sasToken}&include=metadata&restype=container&comp=list`,
        headers: {
          'x-ms-version': '2018-03-28',
          'x-ms-client-request-id': 'b5251709-e2b3-4fc9-9cb5-c91b08dfb2a4',
        },
      })
        .then((response) => {
          const parser = new XMLParser();
          let jsonObj = parser.parse(response.data);
          let id = 0;
            if (jsonObj.EnumerationResults?.Blobs?.Blob)
            {
              blobArray = jsonObj.EnumerationResults.Blobs.Blob.map((blob) => {
                id += 1;
                const FilePath = blob.Name.split('/');
                const Prefix = FilePath[0];
                const Suffix = FilePath[FilePath.length - 1];

                return {
                  id,
                  ...blob,
                  FilePath,
                  Prefix,
                  Suffix,
                  dateModified: blob.Properties['Last-Modified'],
                  outputContainer: container,
                  account: config.blob.pdfaccount,
                  size: blob.Properties['Content-Length'],
                };
              });
            }

          this.setState({
            blobArray,
            loading: false,
          });
        }).catch((ex) => {
          this.setState({
            blobArray,
            loading: false,
          });
        });
  };

  render() {
    const { blobArray } = this.state;

    return (
      <Container>
        <FileBrowserContainer>
          {
            this.state.failed ? <Error text={this.state.failMessage} /> : (
              this.state.loading ? <Spinner text="Loading files..." /> : (
                <FileBrowser
                  rowData={blobArray}
                />
              ))
          }
        </FileBrowserContainer>
      </Container>
    );
  }
}

// Exports.propTypes = {

// };

// Exports.defaultProps = {

// };

Exports.contextType = AppContext;

export default Exports;
